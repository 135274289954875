import { ProjectApiService } from '@services/projectapi.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@services/api.service';
import { HelperService } from '@services/helper.service';
import { RightService } from '@services/right.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-supplier-contract',
  templateUrl: './supplier-contract.component.html',
  styleUrls: ['./supplier-contract.component.scss']
})
export class SupplierContractComponent implements OnInit {
  getRight: any;
  RightObj: {};
  @Input() supplier;
  supplierContractList : [];
  constructor(
    public apiService: ApiService,
    public rightService: RightService,
    public helperService: HelperService,
    public activeModal: NgbActiveModal,
    public ProjectApiService:ProjectApiService
  ) {
    this.getRight = this.rightService.getRight();}
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  ngOnInit(): void {
    this.dtOptions = this.helperService.setDtOptions();
    this.RightObj = this.getRight['__zone_symbol__value'];
    this.reloadData();
  }

  contract=[];
  async getContractCal(projectId,elem) {
    //合約與計價
      (await this.ProjectApiService.getContact(projectId)).subscribe((res) => {
        this.contract = (res as any).filter(w => w['validateDate'] != null && w['masterContractId'] == null && w['contractNo'] == elem['contractNo'])[0];
        if(this.contract){
          elem['calculateSum'] = this.contract['calculateSum'];
          elem['subtotal'] = this.contract['subtotal'];
        }
      });
  }

  async reloadData() {
    (await this.apiService.getSupplierContract("","","",this.supplier._id)).subscribe((res) => {
      this.supplierContractList = res as any;
      this.supplierContractList.forEach(element => {
        this.getContractCal(element['projectId'],element);
      })
      this.dtTrigger.next(null);
    });
  }

  async refreshData() {

    (await this.apiService.getSupplierContract("","","",this.supplier._id)).subscribe((res) => {
      this.supplierContractList = res as any;
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next(null);
      });
    });
  }



}
