<div class="modal-header">
  <h4 class="modal-title">
    流程管理
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row  mb-3">
    <div class="col-md-3 col-12" id="flowGroupList">
      <div class="row mb-3">
        <div class="col-12">
          <button type="button" class="btn btn-info" (click)="addNewFlowGroup()">
            <i class="fas fa-plus"></i> 新增副流程
          </button>
        </div>
      </div>
      <hr>
      <div #fgl>
        <div *ngFor="let item of FlowGroupList; let index=index" class="alert alert-secondary groupTitleCard" data-group="{{FlowGroupList[index]}}" role="alert" (click)="switchGroup(FlowGroupList[index])">
          <span class="groupTitle" *ngIf="!editGroupFlow" (click)="editGroupFlow=!editGroupFlow">{{FlowGroupList[index]}}</span>
          <input *ngIf="editGroupFlow"  (change)="editGroupFlowE($event,FlowGroupList[index],index)" type="text" class="form-control" style="background-color: transparent;"
            value="{{FlowGroupList[index]}}">
        </div>
      </div>
    </div>
    <div class="col-md-9 col-12">
      <div class="row mb-3">
        <div class="col-12">
          <button type="button" class="btn btn-info ml-2  float-right" (click)="addNewFormFlow(null,'last',1)">
            <i class="fas fa-plus"></i> 新增預設事件
          </button>
          <button type="button" class="btn btn-info ml-2  float-right" (click)="addNewFormFlow(null,'last',0)">
            <i class="fas fa-plus"></i> 新增流程
          </button>

        </div>
      </div>
      <hr>

      <ng-template #container></ng-template>

    </div>
  </div>
</div>
<div class="modal-footer justify-content-between">
  <button type="button" class="btn btn-default btn-outline-dark" (click)="activeModal.close('Close click')">關閉</button>
  <!-- <button class="btn btn-primary" (click)="saveFormSection('add')">確定</button> -->
</div>
