import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { HelperService } from '@services/helper.service';
import { RightService } from '@services/right.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-contract-addlog',
  templateUrl: './contract-addlog.component.html',
  styleUrls: ['./contract-addlog.component.scss']
})
export class ContractAddlogComponent implements OnInit {
  @Input() addFormLogList;
  getRight: any;

  RightObj: {};
  dtOptions: DataTables.Settings = {};
  constructor(
    public activeModal: NgbActiveModal,
    public rightService: RightService,
    public helperService: HelperService,
  ) {
    this.getRight = this.rightService.getRight();
  }

  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  ngOnInit(): void {
    this.dtOptions = this.helperService.setDtOptions();
    this.RightObj = this.getRight['__zone_symbol__value'];

    setTimeout(() => {
      this.dtTrigger.next(null);
    }, 300);
  }

}
