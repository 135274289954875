import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ActivatedRoute, Router } from '@angular/router';
import { FormtaskapiService } from '@services/formtaskapi.service';
import { RightService } from '@services/right.service';
import { data } from 'jquery';
import { HelperService } from '@services/helper.service';
import { PurchaseFormAddComponent } from '../form/purchase-form/purchase-form-add/purchase-form-add.component';
import { CalculateFormAddComponent } from '@pages/form/calculate-form/calculate-form-add/calculate-form-add.component';
import { AddFormAddComponent } from '@pages/form/add-form/add-form-add/add-form-add.component';
import { FormSignFlowComponent } from '@pages/form-sign/form-sign-flow/form-sign-flow.component';

@Component({
  selector: 'app-form-task',
  templateUrl: './form-task.component.html',
  styleUrls: ['./form-task.component.scss']
})

export class FormTaskComponent implements OnInit {
  formId = null;
  currentFormId = "";
  projectId = null;
  currentprojectId = "";

  formName = "";
  formEName = "";
  thisComponent;

  options: any = {
    multiple: true
  }
  expandColList = [];
  taskStatusId = 'all';
  public tableData = [];
  emergencyTypes = [];

  taskStatus = [
    {
      _id: 'all',
      taskStatusName: 'All'
    }
  ];
  initFlag = false;
  formAndProjectData = {
    formId: '',
    projectId: '',
    projectName: '',
    purchaseDeadLine: ''
  }

  loginUserId;

  suppliers = [];
  workTypes = [];

  constructor(
    private modalService: NgbModal,
    public apiService: FormtaskapiService,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    public rightService: RightService,
    public helperService: HelperService,
  ) {
    this.subscribeRouteChange();
  }
  subscribeRouteChange() {
    this.activatedRoute.params.subscribe((params = {}) => {
      this.taskStatusId = 'all';
      this.formId = params.id;
      this.projectId = params.projectId;
      this.formAndProjectData.formId = params.id;
      this.formAndProjectData.projectId = params.projectId;

      if (this.initFlag) {
        // window.location.reload();
        this.refreshData();
      }
      else {
        this.reloadData();
      }
    });
  }


  ngOnInit(): void {
    this.loginUserId = JSON.parse(localStorage.getItem('auth-user'))['id'];
  }

  async reloadData() {
    const param = {
      formId: this.formId,
      taskStatusId: this.taskStatusId,
      projectId: this.projectId,
    };
    (await this.apiService.getFormTask(param)).subscribe(async (res) => {
      this.tableData = res as any;

      this.tableData.forEach(element => {
        var tmp = element?.taskSiteList;
        element['status'] = '';
        element['signer'] = '';
        element['comment1'] = '';
        element['status1'] = '';
        if (tmp == null || tmp.length == 1) {
          element['comment1'] = element.comment;
        }
        if (tmp != null) {
          for (var j = 0; j < tmp.length; j++) {
            if (tmp[j].current) {
              element['signer'] = tmp[j].signer;
              (this.apiService.getEmployeeById(tmp[j].signer)).subscribe((res) => {
                element['status'] = res.department.depName + "-" + res.empName;
              });
            }
            if (j == tmp.length - 2) {
              element['comment1'] = tmp[j].resultComment;
              (this.apiService.getEmployeeById(tmp[j].signer)).subscribe((res) => {
                element['status1'] = res.department.depName + "-" + res.empName;
              });
            }
          }
        }
      })
      this.reloadBasicData();
      this.initFlag = true;
      this.currentFormId = this.formId;
      this.currentprojectId = this.projectId;
    });
  }

  async refreshData() {
    if(this.taskStatusId == '2022102718360006'){
      this.taskStatusId = 'all';
    }
    const param = {
      formId: this.formId,
      taskStatusId: this.taskStatusId,
      projectId: this.projectId,
    };
    (await this.apiService.getFormTask(param)).subscribe(async (res) => {
      this.tableData = res as any;

      if (this.taskStatusId !== 'all') {
        if (this.taskStatusId !== '2022102718360006') {
          this.tableData = this.tableData.filter(w => w.taskStatusId === this.taskStatusId);
        } else {

          this.tableData = this.tableData.filter(w => w.taskSiteList.filter(w2 => !w2.current && w2.signer == this.loginUserId).length > 0);
        }
      }

      this.tableData.forEach(element => {
        var tmp = element?.taskSiteList;
        element['status'] = '';
        element['signer'] = '';
        element['comment1'] = '';
        element['status1'] = '';
        if (tmp == null || tmp.length == 1) {
          element['comment1'] = element.comment;
        }
        if (tmp != null) {
          for (var j = 0; j < tmp.length; j++) {
            if (tmp[j].current) {
              element['signer'] = tmp[j].signer;
              (this.apiService.getEmployeeById(tmp[j].signer)).subscribe((res) => {
                element['status'] = res.department.depName + "-" + res.empName;
              });
            }
            if (j == tmp.length - 2) {
              element['comment1'] = tmp[j].resultComment;
              (this.apiService.getEmployeeById(tmp[j].signer)).subscribe((res) => {
                element['status1'] = res.department.depName + "-" + res.empName;
              });
            }
          }
        }
      })
      this.reloadBasicData();
    });
  }

  projectNo = "";
  async reloadBasicData() {
    const param = {
      formId: this.formId,
      projectId: this.projectId,
    };
    (await this.apiService.getBasicData(param)).subscribe(async (res) => {
      if (res.formAndProjectData) {

        this.formAndProjectData = res.formAndProjectData
      }
      if (this.currentFormId !== this.formId || this.currentprojectId !== this.projectId) {
        this.taskStatus = [
          {
            _id: 'all',
            taskStatusName: 'All'
          },
        ];
        this.currentFormId = this.formId;
        this.currentprojectId = this.projectId;
      }

      this.workTypes = res.workTypes;
      this.suppliers = res.suppliers;

      this.emergencyTypes = res.emergencyTypes;
      if (res.taskStatus != null) {
        res.taskStatus.forEach(element => {
          if (this.taskStatus.filter(w => w['taskStatusName'] === element['taskStatusName']).length == 0) {
            this.taskStatus.push(element);
          }
        })
      }

      (await this.apiService.getForm(param)).subscribe((res) => {
        this.formName = res['formName'];
        this.formEName = res['formEName'];
        switch (this.formId) {
          case "20221027173636377":
            this.thisComponent = PurchaseFormAddComponent;
            this.calculateDataNo('max_purchaseNo');
            break;
          case "20221027173807501":
            this.thisComponent = CalculateFormAddComponent;
            this.calculateDataNo('max_calculateNo');
            break;
          case "20221027174030564":
            this.thisComponent = AddFormAddComponent;
            this.calculateDataNo('max_addNo');
            break;
        }
      });
    });


  }


  openModal(type, com) {
    if (type == 'add') {
      var modalRef = this.modalService.open(com, { windowClass: 'add-form' });
      modalRef.componentInstance.formAndProjectData = this.formAndProjectData;
      modalRef.componentInstance.workTypeList = this.workTypes;
      modalRef.componentInstance.emergencyTypeList = this.emergencyTypes;
      modalRef.componentInstance.recommendSupplierList = this.suppliers;
      modalRef.componentInstance.tableData = this.tableData;
      modalRef.closed.subscribe((result) => {
      });
    }
  }

  formSignLog(_id) {
    const modalRef = this.modalService.open(FormSignFlowComponent, { size: 'xl' });
    modalRef.componentInstance._id = _id;
  }


  onChange(val): void {
    this.taskStatusId = val;
    this.refreshData()
  }

  go(data, detail) {
    this.router.navigateByUrl('/formTask/' + this.formId + "/" + this.projectId + "/" + data._id + "/" + data.taskStatusId + "/" + detail);
  }

  calculateDataNo(setItem) {
    (this.apiService.getFormTaskMax(this.formAndProjectData['projectNo'] + "-" + "P")).subscribe((res) => {
      var max_val = res.max;
      if (max_val == -Infinity) {
        localStorage.setItem(setItem, '1')
      }
      else {
        localStorage.setItem(setItem, (Number(max_val) + 1) + '')
      }
    });

  }
}
