<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>工項類型管理</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a [routerLink]="['/']" href="/">Home</a></li>
          <li class="breadcrumb-item active">工項類型管理</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3">
        <div class="card">
          <div class="card-header">
            <button type="button" class="btn btn-default float-right" (click)="showAll()">
              全部工項
            </button>
            <h5 class="modal-title">工項樹狀圖</h5>
          </div>
          <div class="card-body">
            <div #jstree id="jstree">
            </div>

            <input id="selectNode" type="hidden" class="form-control">
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <div class="card">
          <div class="card-header">
            <button type="button" *ngIf="this.RightObj['insert']" class="btn btn-info float-right"
              (click)="open('add', null)">
              <i class="fas fa-plus"></i> 新增
            </button>
            <h5 class="modal-title">工項類型列表</h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                  class="table table-striped table-bordered table-hover nowrap">
                  <thead>
                    <tr>
                      <th>工項類型名稱</th>
                      <th>上層工項</th>
                      <th>排序</th>
                      <!-- <th>階層</th> -->
                      <th>啟用中</th>
                      <th *ngIf="this.RightObj['update'] || this.RightObj['delete']">編輯</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let item of workTypes">
                    <tr >
                      <td>{{ item.workTypeName }}</td>
                      <td>{{ item.rootWorkTypeName }}</td>
                      <td>{{ item.sort }}</td>
                      <!-- <td>{{ item.level }}</td> -->
                      <td>{{ item.enable === true ? "Y" : "N" }}</td>
                      <td *ngIf="this.RightObj['update'] || this.RightObj['delete'] ">
                        <button type="button" *ngIf="this.RightObj['update']" class="btn btn-tool"
                          data-widget="editData" data-toggle="tooltip" title="修改工項類型" (click)="open('edit', item)">
                          <i class="fas fa-edit"></i>
                        </button>
                        <button type="button" *ngIf="this.RightObj['delete'] && item.enable"
                          class="btn btn-tool text-primary" data-widget="delData" data-toggle="tooltip" title="停用工項類型"
                          (click)="open('disabled', item)">
                          <i class="fas fa-lock"></i>
                        </button>
                      </td>
                    </tr>
                  </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </div>

  </div>
  <!-- /.card -->
</section>
<!-- /.content -->
