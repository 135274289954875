import { Component, EventEmitter, Input, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { FormtaskapiService } from '@services/formtaskapi.service';
import { FormTaskProcessApiService } from '@services/form-task-process-api.service';
import { ApiService } from '@services/api.service';
import { Subject, tap } from 'rxjs';
import { RightService } from '@services/right.service';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';


import { __classPrivateFieldGet } from 'tslib';
import { ToastrService } from 'ngx-toastr';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { HelperService } from '@services/helper.service';

@Component({
  selector: 'app-purchase-form-process',
  templateUrl: './purchase-form-process.component.html',
  styleUrls: ['./purchase-form-process.component.scss']
})
export class PurchaseFormProcessComponent implements OnInit {
  @Input() formId;
  @Input() projectId;

  @Input() parentComp
  detailData: any;

  @Input() formTaskId;
  @Input() taskStatusId;
  @Input() component;

  formTaskNo: "";
  formAndProjectData: any;
  workTypes: [];
  emergencyTypes: any;
  suppliers: any;
  taskStatus: any;
  siteResult: any;
  contractTypes: any;
  scope: any;
  rule: any;

  @Output() setInitComponent = new EventEmitter<object>();
  illustration: any;
  calculation: any;

  formData = new FormData();

  result: any;
  remark: any;
  pccesData: any = [];
  inquiryData: any = [];
  quotation: string;


  constructor(
    public task_apiService: FormtaskapiService,
    public apiService: FormTaskProcessApiService,
    public master_apiService: ApiService,
    public rightService: RightService,
    public helperService: HelperService,
  ) {
  }

  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;


  ngOnInit(): void {
    this.reloadFormTaskDetailData();

  }


  async reloadFormTaskDetailData() {
    (await this.apiService.getFormTaskDetailData(this.formTaskId, this.taskStatusId)).subscribe((res) => {
      this.detailData = res;
      this.formTaskNo = this.detailData.formTaskNo;
      this.loadBasicData();
      this.setInitComponent.emit({ detailData: this.detailData });
    });
  }

  async refreshFormTaskDetailData() {
    (await this.apiService.getFormTaskDetailData(this.formTaskId, this.taskStatusId)).subscribe((res) => {
      this.detailData = res;
      this.formTaskNo = this.detailData.formTaskNo;
      this.setInitComponent.emit({ detailData: this.detailData });
    });
  }

  async loadBasicData() {
    const param = {
      formId: this.formId,
      projectId: this.projectId,
    };
    (await this.task_apiService.getBasicData(param)).subscribe((res) => {
      this.formAndProjectData = res.formAndProjectData
      this.workTypes = res.workTypes;
      this.emergencyTypes = res.emergencyTypes;
      this.suppliers = res.suppliers;
      this.taskStatus = res.taskStatus;
      this.scope = res.scope;
      this.rule = res.rule;
      this.contractTypes = res.contractTypes;
      this.siteResult = res.siteResult;
    });
  }

}
