import { ApiService } from '@services/api.service';
import { filter } from 'rxjs/operators';
import { HelperService } from './../../../../../services/helper.service';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, Renderer2, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { FormTaskProcessApiService } from '@services/form-task-process-api.service';
import { FormSectionService } from '@services/form-section.service';

import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { openCloseAnimation, rotateAnimation } from '../../../../../../app/pages/form-task-process/detail-section/detail-section.animations'
import { ProjectApiService } from '@services/projectapi.service';
import { FormtaskapiService } from '@services/formtaskapi.service';
import { ContractItemAddComponent } from './contract-item-add/contract-item-add.component';
import { ContractItemDetailComponent } from './contract-item-detail/contract-item-detail.component';
import { ContractItemDetailAddComponent } from './contract-item-detail-add/contract-item-detail-add.component';

@Component({
  selector: 'app-add-form-process-section2',
  templateUrl: './add-form-process-section2.component.html',
  styleUrls: ['./add-form-process-section2.component.scss'],
  animations: [openCloseAnimation, rotateAnimation]

})
export class AddFormProcessSection2Component implements OnInit {
  public isExpandable: boolean = false;
  public isMainActive: boolean = false;
  public isOneOfChildrenActive: boolean = false;
  @Input() title;
  @Input() action;
  @Input() isMenuExtended;
  @Input() component;
  @Input() formTaskId;
  @Input() taskStatusId;
  @Input() projectId;
  @Input() contractId;
  @Input() detailData;
  @Input() workTypesList;

  formId = "";
  dtOptions: DataTables.Settings = {};
  dtTrigger_contractItem: Subject<any> = new Subject<any>();

  result: any;

  constructor(
    public master_apiService: ApiService,
    public apiService: FormTaskProcessApiService,
    public formtaskapiService: FormtaskapiService,

    private toastr: ToastrService,
    public formSectionService: FormSectionService,
    public helperService: HelperService,
    public ProjectApiService: ProjectApiService,
    public elementRef: ElementRef,
    public renderer: Renderer2,
    private modalService: NgbModal,


  ) { }
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  ngOnInit(): void {
    this.dtOptions = this.helperService.setDtOptions();

    this.isExpandable = true;
    this.formSectionService.calculateIsActive(this.isMenuExtended);

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.detailData != undefined && changes.detailData.currentValue) {
      this.helperService.AutoSave(this.renderer, this.elementRef.nativeElement, "formTask", this.detailData?._id);

      localStorage.setItem('inputValidate2', "true");
      if (this.detailData?.formTaskContent?.addForm?.contractItemList == null || this.detailData?.formTaskContent?.addForm?.contractItemList.length == 0) {
        localStorage.setItem('inputValidate2', "false");
      }
      this.helperService.AutoSave(this.renderer, this.elementRef.nativeElement, "formTask", this.detailData?._id);
      this.formId = this.detailData['formId'];

      if (changes.detailData.previousValue != undefined && this.dtElement.dtInstance != undefined) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          // dtInstance.destroy();
          // this.dtTrigger.next(null);
        });
      } else {
        setTimeout(() => {
          this.dtOptions = this.helperService.setDtOptions();
          this.dtTrigger_contractItem.next(null);
        }, 1000);

      }
    }

    if (changes.workTypesList != undefined && changes.workTypesList.currentValue) {
      var contractItemList = this.detailData?.formTaskContent?.addForm?.contractItemList;
      if (contractItemList != null) {
        contractItemList.forEach(element => {
          element['workTypeName'] = this.mappingWorkType(element['workTypeId']);
        });
      }
    }
  }

  mappingWorkType(current) {
    if (current != null) {
      var element = this.workTypesList.filter(w => w._id == current)[0];
      if (element != undefined) {
        var selectText = element['workTypeName'];
        if (element['rootWorkTypeId'] != '' && element['rootWorkTypeId'] != null) {
          selectText = this.helperService.getRootWorkType(this.workTypesList.sort((a, b) => a.sort - b.sort), selectText, element['rootWorkTypeId'])
        }
        return selectText;
      }
    }
  }

  async refresh() {
    (await this.apiService.getFormTaskDetailData(this.formTaskId, this.taskStatusId)).subscribe((res) => {
      this.detailData = res;

      localStorage.setItem('inputValidate2', "true");
      if (this.detailData?.formTaskContent?.addForm?.contractItemList == null || this.detailData?.formTaskContent?.addForm?.contractItemList.length == 0) {
        localStorage.setItem('inputValidate2', "false");
      }

      var contractItemList = this.detailData?.formTaskContent?.addForm?.contractItemList;
      if (contractItemList != null) {
        contractItemList.forEach(element => {
          element['workTypeName'] = this.mappingWorkType(element['workTypeId']);
        });
      }
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger_contractItem.next(null);
      });
    })
  }


  handleMainMenuAction() {
    this.formSectionService.handleMainMenuAction(this.isExpandable, this.isMenuExtended)
  }

  deleteContractItem(id) {
    Swal.fire({
      title: '確定要刪除?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: '確定',
      confirmButtonColor: '#17A2B8',
      cancelButtonText: '取消',
      icon: 'question'
    }).then((result) => {
      if (result.isConfirmed) {
        this.apiService
          .delContractItem(this.formTaskId, id)
          .subscribe((res) => {
            this.refresh();
          });
      }
    });

  }

  openModal(action, datas) {
    let modalRef = null;
    if (action == 'contract-item') {
      modalRef = this.modalService.open(ContractItemAddComponent, { windowClass: 'contract-item', size: 'xl' });
      modalRef.componentInstance.projectId = this.projectId;
      modalRef.componentInstance.formTaskId = this.formTaskId;
      modalRef.componentInstance.contractItemList = this.detailData?.formTaskContent?.addForm?.contractItemList;
      modalRef.closed.subscribe((result) => {
        this.refresh();
      });
    }
    if (action == 'exist') {
      modalRef = this.modalService.open(ContractItemDetailComponent, { windowClass: 'exist', size: 'xl' });
      modalRef.componentInstance.projectId = this.projectId;
      modalRef.componentInstance.formTaskId = this.formTaskId;
      modalRef.componentInstance.contractItemList = datas;
      modalRef.componentInstance.action = this.action;
      modalRef.componentInstance.detailData = this.detailData;
      modalRef.componentInstance.refresh.subscribe((result) => {
        this.refresh();
      })
    }
    if (action == 'addnew') {
      modalRef = this.modalService.open(ContractItemDetailAddComponent, { windowClass: 'addnew', size: 'xl' });
      modalRef.componentInstance.projectId = this.projectId;
      modalRef.componentInstance.formTaskId = this.formTaskId;
      modalRef.componentInstance.contractItemList = datas;
      modalRef.componentInstance.action = this.action;
      modalRef.componentInstance.detailData = this.detailData;
      modalRef.componentInstance.refresh.subscribe((result) => {
        this.refresh();
      })
    }
  }
}
