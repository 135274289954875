<div class="card">
  <div class="card-header">
    <h5 class="card-title mt-2">
      {{title}}
    </h5>
    <div class="card-tools">
      <a *ngIf="!action.allowEdit" href="{{api_url}}/pdf/getFormTaskPurchase/{{formTaskId}}" target="_blank"
        type="button" data-widget="" data-toggle="tooltip" title="下載合約" class="btn btn-success mr-2">下載合約</a>
      <button type="button" class="btn bnt-tool float-right mr-2" data-card-widget="collapse"
        (click)="handleMainMenuAction()">
        <i [ngClass]="isMenuExtended ? 'fas fa-minus' : 'fas fa-plus'" [@rotate]="isMenuExtended"></i>
      </button>
      <ng-container *ngFor="let data of component">
        <button *ngIf="action.allowEdit" (click)="openModal('purchase-form-edit')" type="button"
          class="btn btn-info float-right mr-2">
          <i class="{{data.icon}}"></i> {{data.name}}
        </button>
      </ng-container>
    </div>
  </div>
  <div class="card-body" [@openClose]="isMenuExtended">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label><span class="text-danger">*</span>工程名稱</label>
          <input type="text" class="form-control" [value]="formAndProjectData?.projectName" placeholder="工程名稱" disabled>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <div class="form-group">
          <label><span class="text-danger">*</span>工項名稱</label>
          <input type="text" class="form-control" [value]="workTypeName" placeholder="工項名稱" disabled>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label><span class="text-danger">*</span>速別</label>
          <input type="text" class="form-control" [value]="detailData?.emergencyType?.emergencyName" placeholder="請採速別"
            disabled>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>請採管制表</label>
          <input *ngIf="detailData?.formTaskContent?.purchaseForm?.purchaseControlId" type="text" class="form-control" [value]="detailData?.formTaskContent?.purchaseForm?.purchaseControl?.controlItem +'-'+ detailData?.formTaskContent?.purchaseForm?.purchaseControl?.controlName" placeholder="請採管制表" disabled>
          <input *ngIf="!detailData?.formTaskContent?.purchaseForm?.purchaseControlId" type="text" class="form-control" value="無" placeholder="請採管制表" disabled>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label><span class="text-danger">*</span>請採期限</label>
          <input type="date" class="form-control"
            [value]="detailData?.formTaskContent?.purchaseForm?.purchaseDeadLine.substr(0,10)" disabled>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label><span class="text-danger">*</span>工項預計開始日</label>
          <input type="date" class="form-control"
            [value]="detailData?.formTaskContent?.purchaseForm?.workBeginTime.substr(0,10)" disabled>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label><span class="text-danger">*</span>工項預計結束日</label>
          <input type="date" class="form-control"
            [value]="detailData?.formTaskContent?.purchaseForm?.workEndTime.substr(0,10)" disabled>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>應出工數</label>
          <input type="text" class="form-control" [value]="detailData?.formTaskContent?.purchaseForm?.workers"
            placeholder="應出工數" disabled>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>推薦廠商</label>
          <input type="text" class="form-control"
            [value]="detailData?.formTaskContent?.purchaseForm?.recommendSupplierId" placeholder="推薦廠商" disabled>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>推薦金額</label>
          <input type="text" class="form-control" [value]="detailData?.formTaskContent?.purchaseForm?.supplierAmount"
            placeholder="推薦金額" disabled>
        </div>
      </div>
    </div>

  </div>
</div>
