<div class="card">
  <div class="card-header">
    <h5 class="card-title mt-2">
      {{title}}
    </h5>
    <div class="card-tools">
      <button type="button" class="btn bnt-tool float-right mr-2" data-card-widget="collapse"
        (click)="handleMainMenuAction()">
        <i [ngClass]="isMenuExtended ? 'fas fa-minus' : 'fas fa-plus'" [@rotate]="isMenuExtended"></i>
      </button>
    </div>
  </div>
  <div class="card-body" [@openClose]="isMenuExtended">

    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger_inquiry"
      class="table table-striped table-bordered table-hover nowrap" id="inquiry_table">
      <thead>
        <tr>
          <th>廠商名稱</th>
          <th>統一編號</th>
          <th>聯絡人</th>
          <th>電話號碼</th>
          <th>既有/開發</th>
          <th>不良紀錄</th>
          <th>預付款</th>
          <th>總金額</th>
          <th>比例</th>
          <th>備註</th>
          <th>物料明細</th>
          <th>報價單</th>
          <th>選擇</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let datas of inquiryData;let i = index;">
          <tr *ngIf="datas.supplier!==null">
            <td>{{datas.supplier?.supplierName}}</td>
            <td>{{datas.supplier?.businessNo}}</td>
            <td>{{datas.supplier?.contactName}}</td>
            <td>{{datas.supplier?.contactPhone}}</td>
            <td>{{datas.new?"開發": "既有"}}</td>
            <td>{{datas.supplier?.badRecord? "是":"否"}}</td>
            <td>
              <span *ngIf="datas.prepayMoney">{{datas.prepayMoney| currency:'TWD': 'symbol':'1.0'}}</span>
              <span *ngIf="!datas.prepayMoney">0</span>
            </td>
            <td>
              <span *ngIf="datas.subtotal">{{datas.subtotal| currency:'TWD': 'symbol':'1.0'}}</span>
              <span *ngIf="!datas.subtotal">0</span>
            </td>
            <td>{{datas.prepayMoney && datas.subtotal? ((datas.prepayMoney)/(datas.subtotal)*100).toFixed(2)+"%":
              '0%'}}</td>
            <td>{{datas.supplier?.remark}}</td>
            <td>
              <button type="button" class="btn btn-default"
                (click)="openModal('inquiry-detail-add', datas._id, {allowEdit: false, allowShow: true })">檢視
              </button>
            </td>
            <td>
              <button type="button" class="btn btn-default" (click)="viewPdf(datas.quotation)">檢視</button>
            </td>
            <td>

              <ng-container *ngIf="action.allowEdit">
                <div>
                  <label>
                    <input type="checkbox" style="accent-color: #dc3545!important;"
                      [checked]="datas.choosePriority?(datas.choosePriority===1?true:false):false"
                      (click)="choosePriority($event, 1, datas._id)">
                    正選
                  </label>
                </div>
                <div>
                  <label>
                    <input type="checkbox" [checked]="datas.choosePriority?(datas.choosePriority===2?true:false):false"
                      (click)="choosePriority($event, 2, datas._id)">
                    備選
                  </label>
                </div>
              </ng-container>

              <ng-container *ngIf="!(action.allowEdit)">
                <div>
                  <label>
                    <input type="checkbox" style="accent-color: #dc3545!important;"
                      [checked]="datas.choosePriority?(datas.choosePriority===1?true:false):false"
                      (click)="choosePriority($event, 1, datas._id)" disabled>
                    正選
                  </label>
                </div>
                <div>
                  <label>
                    <input type="checkbox" [checked]="datas.choosePriority?(datas.choosePriority===2?true:false):false"
                      (click)="choosePriority($event, 2, datas._id)" disabled>
                    備選
                  </label>
                </div>
              </ng-container>


            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>



  </div>
</div>
