<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>{{formName}}
        </h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active">{{formName}}</li>
        </ol>
      </div>
    </div>
  </div>
</section>

<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12" *ngIf="sectionRight.length !== 0">

        <app-detail-section *ngIf="detail!=='true'" [expand]="false" [title]="component[0].title" [formId]="formId"
          [action]="component[0].actions" [parentComp]="this" [isMenuExtended]="false" [component]="component[0].btns"
          [formTaskId]="formTaskId" [taskStatusId]="taskStatusId">
        </app-detail-section>

        <!-- 採購申請 -->
        <app-purchase-form-process *ngIf="formId ==='20221027173636377'"
          (setInitComponent)="setInitComponent($event.detailData)" [formId]="formId" [projectId]="projectId"
          [parentComp]="this" [formTaskId]="formTaskId" [taskStatusId]="taskStatusId" [component]="component">
        </app-purchase-form-process>

        <!-- 計價請款 -->
        <app-calculate-form-process *ngIf="formId ==='20221027173807501'"
          (setInitComponent)="setInitComponent($event.detailData)" [formId]="formId" [projectId]="projectId"
          [parentComp]="this" [formTaskId]="formTaskId" [taskStatusId]="taskStatusId" [component]="component">
        </app-calculate-form-process>

        <!-- 追加減辦理 -->
        <app-add-form-process *ngIf="formId ==='20221027174030564'"
          (setInitComponent)="setInitComponent($event.detailData)" [formId]="formId" [projectId]="projectId"
          [parentComp]="this" [formTaskId]="formTaskId" [taskStatusId]="taskStatusId" [component]="component">
        </app-add-form-process>

      </div>
    </div>
  </div>
</section>
