import { filter } from 'rxjs/operators';
import { HelperService } from '../../../../../services/helper.service';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, Renderer2, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { FormTaskProcessApiService } from '@services/form-task-process-api.service';
import { FormSectionService } from '@services/form-section.service';

import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { openCloseAnimation, rotateAnimation } from '../../../../form-task-process/detail-section/detail-section.animations'
import { ProjectApiService } from '@services/projectapi.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-calculate-form-process-section5',
  templateUrl: './calculate-form-process-section5.component.html',
  styleUrls: ['./calculate-form-process-section5.component.scss'],
  animations: [openCloseAnimation, rotateAnimation]
})
export class CalculateFormProcessSection5Component implements OnInit {
  public isExpandable: boolean = false;
  public isMainActive: boolean = false;
  public isOneOfChildrenActive: boolean = false;
  @Input() title;
  @Input() action;
  @Input() isMenuExtended;
  @Input() component;
  @Input() formTaskId;
  @Input() taskStatusId;
  @Input() projectId;
  @Input() contractId;
  @Input() detailData;
  constructor(
    public apiService: FormTaskProcessApiService,
    private toastr: ToastrService,
    public formSectionService: FormSectionService,
    private modalService: NgbModal,
    public helperService: HelperService,
    public ProjectApiService: ProjectApiService,
    public elementRef: ElementRef,
    public renderer: Renderer2,) { }

  ngOnInit(): void {
    this.renderer.listen(this.elementRef.nativeElement, 'change', () => {
      setTimeout(() => {
        this.refresh();
      }, 1000);
    });
  }

  async refresh() {
    (await this.apiService.getFormTaskDetailData(this.formTaskId, this.taskStatusId)).subscribe((res) => {
      this.detailData = res;
      this.validate();
    })
  }

  contractPayMethod = "";
  actualPayMethod = "";
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.detailData != undefined && changes.detailData.currentValue) {
      this.helperService.AutoSave(this.renderer, this.elementRef.nativeElement, "formTask", this.detailData?._id);
      this.contractPayMethod = this.detailData?.formTaskContent?.calculateForm?.contractPayMethod;
      this.actualPayMethod = this.detailData?.formTaskContent?.calculateForm?.actualPayMethod;
      this.validate();
    }
  }

  validate() {
    localStorage.setItem('inputValidate5', "true");
    if (!this.helperService.checkVal(this.detailData?.formTaskContent?.calculateForm?.contractPayMethod)) {
      localStorage.setItem('inputValidate5', "false");
    }
    if (!this.helperService.checkVal(this.detailData?.formTaskContent?.calculateForm?.actualPayMethod)) {
      localStorage.setItem('inputValidate5', "false");
    }
  }


  handleMainMenuAction() {
    this.formSectionService.handleMainMenuAction(this.isExpandable, this.isMenuExtended)
  }
}
