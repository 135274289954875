<div class="modal-header">
    <h4 class="modal-title">簽核表單</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">×</span>
    </button>
</div>
<div class="modal-body">
    <form class="form-horizontal" [formGroup]="createForm" method="post" action="">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <textarea formControlName="comment" class="form-control" cols="30" rows="5" placeholder="請填寫意見"></textarea>
                </div>
            </div>
            <div class="col-md-12" *ngIf="action !== 'back' && !parentComp.hasNextSigner"><b class="text-danger">您是最後一關簽核者，若同意，表單將跑完流程並結案</b></div>
            <div class="col-md-12" *ngIf="((action==='agree' || action==='send') && parentComp.hasNextSigner) || action==='back'">
                <div class="form-group">
                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                      class="table table-striped table-bordered table-hover nowrap" id="inquiry_table">
                        <thead>
                            <tr>
                                <th>請選擇</th>
                                <th>部門</th>
                                <th>人員</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let datas of employee;">
                                <td style="text-align: center">
                                    <input [checked]="(datas._id === nextSigner)" (click)="selectNextSinger(datas._id, datas.formFlowId)" type="radio">
                                </td>
                                <td>{{datas.departmentName}}</td>
                                <td>{{datas.empName}} ({{datas.account}})</td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-default" data-dismiss="modal" (click)="activeModal.close('Close click')">關閉</button>
    <button type="button" class="btn btn-info" (click)="save()">確定</button>
</div>
