import { FormSectionService } from '@services/form-section.service';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { openCloseAnimation, rotateAnimation } from '../../../../../../app/pages/form-task-process/detail-section/detail-section.animations'
import { ApiService } from '@services/api.service';
import { HelperService } from '@services/helper.service';
import { ProjectApiService } from '@services/projectapi.service';
import { FormTaskProcessApiService } from '@services/form-task-process-api.service';
import { Select2OptionData } from 'ng-select2';
import { api_url } from '@services/api_config';

@Component({
  selector: 'app-add-form-process-section1',
  templateUrl: './add-form-process-section1.component.html',
  styleUrls: ['./add-form-process-section1.component.scss'],
  animations: [openCloseAnimation, rotateAnimation]
})
export class AddFormProcessSection1Component implements OnInit {
  @Input() detailData;

  public isExpandable: boolean = false;
  public isMainActive: boolean = false;
  public isOneOfChildrenActive: boolean = false;
  @Input() parentComp;
  @Input() title;
  @Input() formTaskNo;
  @Input() action;
  @Input() isMenuExtended;
  @Input() component;
  @Input() projectId;

  formTaskId: "";
  taskStatusId: "";
  api_url = api_url;

  constructor(
    public apiService: ApiService,
    public formSectionService: FormSectionService,
    public helperService: HelperService,
    public ProjectApiService: ProjectApiService,
    public elementRef: ElementRef,
    public renderer: Renderer2,
    public formTaskProcessApiService: FormTaskProcessApiService,

  ) {

  }

  ngOnInit(): void {
    this.isExpandable = true;
    this.formSectionService.calculateIsActive(this.isMenuExtended);
    this.ddlAddSubType();
    setTimeout(() => {
    this.ddlAddSubScheduleType();
    }, 100);
    this.ddlAddSubChangeType();
    this.renderer.listen(this.elementRef.nativeElement, 'change', () => {
      setTimeout(() => {
        this.refresh();
      }, 1000);
    });

  }

  async refresh() {
    (await this.formTaskProcessApiService.getFormTaskDetailData(this.formTaskId, this.taskStatusId)).subscribe((res) => {
      this.detailData = res;
      this.validate();
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.detailData != null && changes.detailData.currentValue) {
      this.formTaskId = this.detailData._id;
      this.taskStatusId = this.detailData.taskStatusId;

      this.helperService.AutoSave(this.renderer, this.elementRef.nativeElement, "formTask", this.detailData?._id);



      this.validate();
    }

    if (changes.formTaskNo != null && changes.formTaskNo.currentValue) {
      var newValue = changes.formTaskNo.currentValue;
      if (newValue != '(undefined)') {
        this.title = this.title.replace('{addNumber}', newValue)
      }
    }
  }

  validate() {
    localStorage.setItem('inputValidate1', "true");
    if (!this.helperService.checkVal(this.detailData?.formTaskContent?.addForm?.addSubTypeId)) {
      localStorage.setItem('inputValidate1', "false");
    }
    if (!this.helperService.checkVal(this.detailData?.formTaskContent?.addForm?.addSubScheduleTypeId)) {
      localStorage.setItem('inputValidate1', "false");
    }
    if (!this.helperService.checkVal(this.detailData?.formTaskContent?.addForm?.changeContent)) {
      localStorage.setItem('inputValidate1', "false");
    }
    if (!this.helperService.checkVal(this.detailData?.formTaskContent?.addForm?.addSubChangeTypeId)) {
      localStorage.setItem('inputValidate1', "false");
    }
  }


  handleMainMenuAction() {
    this.formSectionService.handleMainMenuAction(this.isExpandable, this.isMenuExtended)
  }


  s2AddSubTypes: Select2OptionData[];
  AllAddSubType = [];
  async ddlAddSubType() {
    let arrAddSubType = [];
    let k = 0;
    (await this.apiService.getAddSubTypes()).subscribe((res) => {
      this.AllAddSubType = (res as any);
      this.AllAddSubType.forEach(element => {
        arrAddSubType.push({ id: element['_id'], text: element['addSubTypeName'] });
        k++;
        if (k == this.AllAddSubType.length) {
          arrAddSubType = arrAddSubType.sort((a, b) => a.text.localeCompare(b.text))
          this.s2AddSubTypes = arrAddSubType;
        }
      });
    });
  }

  s2AddSubScheduleTypes: Select2OptionData[];
  AllAddSubScheduleType = [];
  async ddlAddSubScheduleType() {
    let arrAddSubScheduleType = [];
    let k = 0;
    (await this.apiService.getAddSubScheduleTypes()).subscribe((res) => {
      this.AllAddSubScheduleType = (res as any);
      this.AllAddSubScheduleType.forEach(element => {
        arrAddSubScheduleType.push({ id: element['_id'], text: element['addSubScheduleTypeName'] });
        k++;
        if (k == this.AllAddSubType.length) {
          arrAddSubScheduleType = arrAddSubScheduleType.sort((a, b) => a.text.localeCompare(b.text))
          this.s2AddSubScheduleTypes = arrAddSubScheduleType;
        }
      });
    });
  }

  s2AddSubChangeTypes: Select2OptionData[];
  AllAddSubChangeType = [];
  async ddlAddSubChangeType() {
    let arrAddSubChangeType = [];
    let k = 0;
    (await this.apiService.getAddSubChangeTypes()).subscribe((res) => {
      this.AllAddSubChangeType = (res as any);
      this.AllAddSubChangeType.forEach(element => {
        arrAddSubChangeType.push({ id: element['_id'], text: element['addSubChangeTypeName'] });
        k++;
        if (k == this.AllAddSubChangeType.length) {
          arrAddSubChangeType = arrAddSubChangeType.sort((a, b) => a.text.localeCompare(b.text))
          this.s2AddSubChangeTypes = arrAddSubChangeType;
        }
      });
    });
  }



  onChange_AddSubType(val: string) {

    if (val != null && val != this.detailData?.formTaskContent?.addForm?.addSubTypeId) {
      var oldV = this.s2AddSubTypes.filter(w => w.id == this.detailData?.formTaskContent?.addForm?.addSubTypeId)[0]['text'];
      var newV = this.s2AddSubTypes.filter(w => w.id == val)[0]['text'];
      var formGroup_oldV = this.detailData?.flowGroup;

      var whereObj = [{ key: "_id", value: this.detailData?._id, subdocName: "" }]
      this.helperService.callUpdate("formTask", whereObj, this.detailData?._id, "flowGroup", formGroup_oldV.replace(oldV, newV), "副流程", "String")

      var whereObj2 = [{ key: "_id", value: this.detailData?._id, subdocName: "" }, { key: "_id", value: this.detailData?.formTaskContent?.addForm?._id, subdocName: "formTaskContent.addForm", subdocumnet_type: "" }]
      this.helperService.callUpdate("formTask", whereObj2, this.detailData?._id, "addSubTypeId", val, "追加減類型", "String")
      this.refresh();
    }
  }

  onChange_AddSubScheduleType(val: string) {
    if (val != null && val != this.detailData?.formTaskContent?.addForm?.addSubScheduleTypeId) {
      var oldV = this.s2AddSubScheduleTypes.filter(w => w.id == this.detailData?.formTaskContent?.addForm?.addSubScheduleTypeId)[0]['text'];
      var newV = this.s2AddSubScheduleTypes.filter(w => w.id == val)[0]['text'];
      var formGroup_oldV = this.detailData?.flowGroup;

      var whereObj = [{ key: "_id", value: this.detailData?._id, subdocName: "" }]
      this.helperService.callUpdate("formTask", whereObj, this.detailData?._id, "flowGroup", formGroup_oldV.replace(oldV, newV), "副流程", "String")

      var whereObj2 = [{ key: "_id", value: this.detailData?._id, subdocName: "" }, { key: "_id", value: this.detailData?.formTaskContent?.addForm?._id, subdocName: "formTaskContent.addForm", subdocumnet_type: "" }]
      this.helperService.callUpdate("formTask", whereObj2, this.detailData?._id, "addSubScheduleTypeId", val, "變更時程類型", "String")
      this.refresh();
    }
  }

  onChange_AddSubChangeType(val: string) {
    if (val != null && val != this.detailData?.formTaskContent?.addForm?.addSubChangeTypeId) {
      var whereObj = [{ key: "_id", value: this.detailData?._id, subdocName: "" }, { key: "_id", value: this.detailData?.formTaskContent?.addForm?._id, subdocName: "formTaskContent.addForm", subdocumnet_type: "" }]
      this.helperService.callUpdate("formTask", whereObj, this.detailData?._id, "addSubChangeTypeId", val, "變更原因", "String")
      this.refresh();
    }
  }
}
