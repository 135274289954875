import { element } from 'protractor';
import { filter } from 'rxjs/operators';
import { FormSignFlowComponent } from './form-sign-flow/form-sign-flow.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormtaskapiService } from '@services/formtaskapi.service';
import { HelperService } from '@services/helper.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormmngapiService } from '@services/formmngapi.service';

@Component({
  selector: 'app-form-sign',
  templateUrl: './form-sign.component.html',
  styleUrls: ['./form-sign.component.scss']
})
export class FormSignComponent implements OnInit {
  public tableData = [];
  public formData = [];
  initFlag = false;
  taskStatusId = 'all';
  taskStatus = [
    {
      _id: 'all',
      taskStatusName: 'All'
    }
  ];
  formId = 'all';


  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: true })
  dtElement: DataTableDirective;

  loginUserId;

  constructor(
    public apiService: FormtaskapiService,
    public helperService: HelperService,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private modalService: NgbModal,
    public formmngapiService: FormmngapiService,
  ) {
    this.subscribeRouteChange();
  }
  subscribeRouteChange() {
    this.activatedRoute.params.subscribe((params = {}) => {
      this.taskStatusId = 'all';
      if (this.initFlag) {
        this.refreshData('all');
      }
      else {
        this.reloadData();
      }
    });
  }

  ngOnInit(): void {
    this.loginUserId = JSON.parse(localStorage.getItem('auth-user'))['id'];
    this.dtOptions = this.helperService.setDtOptions();
    this.ddltaskStatus();

  }

  async getFormData() {
    (await this.formmngapiService.getFormData()).subscribe((res) => {
      this.formData = (res as any).filter(w => w['formEName'] != null);
      this.formData.forEach(element => {
        element['count'] = this.tableData.filter(w => w['formId'] == element['_id'] && ((w.taskSiteList[0] !== undefined && w.taskSiteList[0].current && w.taskSiteList[0].signer === this.loginUserId) || w.creatorId === this.loginUserId)).length;
      })
    });
  }

  async reloadData() {
    (await this.apiService.getAllFormTask(this.sdate, this.edate)).subscribe(async (res) => {
      this.tableData = res as any;
      this.tableData.forEach(element => {
        this.getFormData();
        var tmp = element?.taskSiteList;
        element['status'] = '';
        element['signer'] = '';
        element['comment1'] = '';
        element['status1'] = '';
        if (tmp == null || tmp.length == 1) {
          element['comment1'] = element.comment;
        }
        if (tmp != null) {
          for (var j = 0; j < tmp.length; j++) {
            if (tmp[j].current) {
              element['signer'] = tmp[j].signer;
              (this.apiService.getEmployeeById(tmp[j].signer)).subscribe((res) => {
                element['status'] = res.department.depName + "-" + res.empName;
              });
            }
            if (j == tmp.length - 2) {
              element['comment1'] = tmp[j].resultComment;
              (this.apiService.getEmployeeById(tmp[j].signer)).subscribe((res) => {
                element['status1'] = res.department.depName + "-" + res.empName;
              });
            }
          }
        }

        var newTmp = tmp.filter(w => (w.current && w.signer === this.loginUserId) || element.creatorId === this.loginUserId)[0];
        element.taskSiteList = [];
        element.taskSiteList[0] = newTmp;
      })
      this.initFlag = true;
      this.dtTrigger.next(null);
    });
  }

  go(formId, projectId, _id, taskStatusId, status) {
    this.router.navigateByUrl('/formTask/' + formId + "/" + projectId + "/" + _id + "/" + taskStatusId + "/" + status);
  }

  async refreshData(formId) {
    (await this.apiService.getAllFormTask(this.sdate, this.edate)).subscribe(async (res) => {
      this.tableData = res as any;


      if (formId !== 'all') {
        this.tableData = this.tableData.filter(w => w.formId === formId);
      }

      if (this.taskStatusId !== 'all') {
        if (this.taskStatusId !== '2022102718360006') {
          this.tableData = this.tableData.filter(w => w.taskStatusId === this.taskStatusId);
        } else {
          this.tableData = this.tableData.filter(w => w.taskSiteList.filter(w2 => !w2.current && w2.signer == this.loginUserId).length > 0);
        }
      }

      this.tableData.forEach(element => {
        var tmp = element?.taskSiteList;
        element['status'] = '';
        element['signer'] = '';
        element['comment1'] = '';
        element['status1'] = '';
        if (tmp == null || tmp.length == 1) {
          element['comment1'] = element.comment;
        }
        if (tmp != null) {
          for (var j = 0; j < tmp.length; j++) {
            if (tmp[j].current) {
              element['signer'] = tmp[j].signer;
              (this.apiService.getEmployeeById(tmp[j].signer)).subscribe((res) => {
                element['status'] = res.department.depName + "-" + res.empName;
              });
            }
            if (j == tmp.length - 2) {
              element['comment1'] = tmp[j].resultComment;
              (this.apiService.getEmployeeById(tmp[j].signer)).subscribe((res) => {
                element['status1'] = res.department.depName + "-" + res.empName;
              });
            }
          }
        }

        var newTmp = tmp.filter(w => (w.current && w.signer === this.loginUserId) || element.creatorId === this.loginUserId)[0];
        element.taskSiteList = [];
        element.taskSiteList[0] = newTmp;
      })

      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next(null);
      });
    });

  }

  onChange(val): void {
    this.taskStatusId = val;
    if (this.initFlag) {
      this.refreshData(this.formId);
    }
  }

  selectedDate
  sdate = '';
  edate = '';
  onDateChange(val): void {
    this.selectedDate = val;
    if ($("#selectedDate").val() == "") {
      this.sdate = '';
      this.edate = '';
    } else {
      var QueryDate = [];
      QueryDate[0] = this.helperService.formatDate(this.selectedDate['startDate']);
      QueryDate[1] = this.helperService.formatDate(this.selectedDate['endDate']);
      this.sdate = QueryDate[0];
      this.edate = QueryDate[1];
    }
    if (this.sdate != 'NaN-NaN-NaN' && this.edate != 'NaN-NaN-NaN' && this.initFlag) {
      this.refreshData(this.formId);
    }
  }

  open(_id) {
    const modalRef = this.modalService.open(FormSignFlowComponent, { size: 'xl' });
    modalRef.componentInstance._id = _id;
  }


  async ddltaskStatus() {
    (await this.apiService.getTaskStatus()).subscribe((res) => {
      this.taskStatus = this.taskStatus.concat(res);
    });
  }
}
