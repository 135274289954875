import { HelperService } from '@services/helper.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormTaskProcessApiService } from '@services/form-task-process-api.service';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '@services/api.service';
import { ScopeDescriptionComponent } from './scope-description/scope-description.component';
import { RuleDescriptionComponent } from './rule-description/rule-description.component';
import { DataTableDirective } from 'angular-datatables';
import { FormSectionService } from '@services/form-section.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { openCloseAnimation, rotateAnimation } from '../../../../../../app/pages/form-task-process/detail-section/detail-section.animations'

@Component({
  selector: 'app-purchase-form-process-section3',
  templateUrl: './purchase-form-process-section3.component.html',
  styleUrls: ['./purchase-form-process-section3.component.scss'],
  animations: [openCloseAnimation, rotateAnimation]
})
export class PurchaseFormProcessSection3Component implements OnInit {
  public isExpandable: boolean = false;
  public isMainActive: boolean = false;
  public isOneOfChildrenActive: boolean = false;
  @Input() title;
  @Input() action;
  @Input() isMenuExtended;
  @Input() component;

  @Input() formTaskId;
  @Input() taskStatusId;
  @Input() scope
  @Input() rule

  illustration: any;
  calculation: any;
  engineeringScope: any;
  engineeringSpec: any;
  loaded: any = 0;
  load_selected: any = 0;
  remark: any;
  result: any;

  formData = new FormData();

  constructor(
    public apiService: FormTaskProcessApiService,
    private toastr: ToastrService,
    public master_apiService: ApiService,
    public formSectionService: FormSectionService,
    private modalService: NgbModal,
    public helperService: HelperService,
  ) { }



  ngOnInit(): void {
    this.isExpandable = true;
    this.formSectionService.calculateIsActive(this.isMenuExtended);
    this.reloadFormTaskDetailData();
  }

  handleMainMenuAction() {
    this.formSectionService.handleMainMenuAction(this.isExpandable, this.isMenuExtended)
  }


  async reloadFormTaskDetailData() {
    (await this.apiService.getFormTaskDetailData(this.formTaskId, this.taskStatusId)).subscribe((res) => {
      this.illustration = res?.formTaskContent?.purchaseForm?.illustration;
      this.calculation = res?.formTaskContent?.purchaseForm?.calculation;
      this.engineeringScope = res?.formTaskContent?.purchaseForm?.engineeringScope;
      this.engineeringSpec = res?.formTaskContent?.purchaseForm?.engineeringSpec;
      this.remark = res?.formTaskContent?.purchaseForm?.remark;

      localStorage.setItem('inputValidate3', "true");
      if (!this.helperService.checkVal(this.illustration)) {
        localStorage.setItem('inputValidate3', "false");
      }

      if (!this.helperService.checkVal(this.engineeringScope)) {
        localStorage.setItem('inputValidate3', "false");
      }
      if (!this.helperService.checkVal(this.engineeringSpec)) {
        localStorage.setItem('inputValidate3', "false");
      }

    });
  }

  async refreshFormTaskDetailData() {
    (await this.apiService.getFormTaskDetailData(this.formTaskId, this.taskStatusId)).subscribe((res) => {
      this.illustration = res?.formTaskContent?.purchaseForm?.illustration;
      this.calculation = res?.formTaskContent?.purchaseForm?.calculation;
      this.engineeringScope = res?.formTaskContent?.purchaseForm?.engineeringScope;
      this.engineeringSpec = res?.formTaskContent?.purchaseForm?.engineeringSpec;
      this.remark = res?.formTaskContent?.purchaseForm?.remark;

      localStorage.setItem('inputValidate3', "true");
      if (!this.helperService.checkVal(this.illustration)) {
        localStorage.setItem('inputValidate3', "false");
      }

      if (!this.helperService.checkVal(this.engineeringScope)) {
        localStorage.setItem('inputValidate3', "false");
      }
      if (!this.helperService.checkVal(this.engineeringSpec)) {
        localStorage.setItem('inputValidate3', "false");
      }
    });
  }



  openModal(type: any, actions) {
    if (type == 'scope') {
      const modalRef = this.modalService.open(ScopeDescriptionComponent, { windowClass: 'scope' });
      modalRef.componentInstance.scope = this.scope;
      modalRef.componentInstance.formTaskId = this.formTaskId;
      modalRef.componentInstance.engineeringScope = this.engineeringScope;
      modalRef.componentInstance.actions = actions;
      modalRef.closed.subscribe((result) => {
        this.refreshFormTaskDetailData();
      });
    } else if (type == 'rule') {
      const modalRef = this.modalService.open(RuleDescriptionComponent, { windowClass: 'rule' });
      modalRef.componentInstance.rule = this.rule;
      modalRef.componentInstance.formTaskId = this.formTaskId;
      modalRef.componentInstance.engineeringSpec = this.engineeringSpec;
      modalRef.componentInstance.actions = actions;
      modalRef.closed.subscribe((result) => {
        this.refreshFormTaskDetailData();
      });
    }

  }

  illustrationFileSelected(e) {
    const file: File = e.target.files[0];
    if (file) {
      this.illustration = file.name;
      var FileExtensionArr = ["pdf"];
      if (FileExtensionArr.indexOf((this.illustration.split('.')[this.illustration.split('.').length - 1]).toLowerCase()) > -1) {
        var FileExtensionArr = ["pdf"];
        this.formData.delete("url");
        this.formData.delete("uploadFile");
        this.formData.append('url', "formTask_PurchaseForm_illustration");
        this.formData.append('uploadFile', file);

        this.apiService
          .uploadData(
            this.formData
          )
          .subscribe({
            next: (event: any) => {
              if (event.type === HttpEventType.UploadProgress) {
                this.load_selected = "illustration";
                this.loaded = Math.round(100 * event.loaded / event.total);
              } else if (event instanceof HttpResponse) {
                setTimeout(() => {
                  this.loaded = 0;
                }, 200);

                let formData = {
                  illustration: "formTask_PurchaseForm_illustration/" + event.body.name,
                  formTaskId: this.formTaskId
                }
                this.apiService.addIllustration(formData)
                  .subscribe((res1) => {
                    this.result = res1 as any;
                    if (this.result.status == false) {
                      Swal.fire({
                        title: this.result.message,
                        icon: 'error'
                      });
                    }  else if (event.body.msg != null) {
                      Swal.fire({
                        title: event.body.msg,
                        icon: 'error'
                      });
                      this.illustration = "";
                    }else {
                      this.toastr.success("資料已儲存");
                      this.illustration = "formTask_PurchaseForm_illustration/" + event.body.name;
                      this.refreshFormTaskDetailData();

                    }
                  });
              }
            },
            error: (err: any) => {

              this.loaded = 0;
              console.log(err);
            }
          });
      }
    }
    else {
      this.illustration = "";
      Swal.fire({
        title: '檔案類型不正確!',
        icon: 'error'
      });
    }
  }


  calculationFileSelected(e) {
    const file: File = e.target.files[0];
    if (file) {
      this.calculation = file.name;
      var FileExtensionArr = ["pdf"];
      if (FileExtensionArr.indexOf((this.calculation.split('.')[this.calculation.split('.').length - 1]).toLowerCase()) > -1) {
        this.formData.delete("url");
        this.formData.delete("uploadFile");
        this.formData.append('url', "formTask_PurchaseForm_calculation");
        this.formData.append('uploadFile', file);

        this.apiService
          .uploadData(
            this.formData
          )
          .subscribe({

            next: (event: any) => {
              if (event.type === HttpEventType.UploadProgress) {
                this.load_selected = "calculation";
                this.loaded = Math.round(100 * event.loaded / event.total);
              } else if (event instanceof HttpResponse) {

                setTimeout(() => {
                  this.loaded = 0;
                }, 200);

                let formData = {
                  calculation: "formTask_PurchaseForm_calculation/" + event.body.name,
                  formTaskId: this.formTaskId
                }
                this.apiService.addCalculation(formData)
                  .subscribe((res1) => {
                    this.result = res1 as any;
                    if (this.result.status == false) {
                      Swal.fire({
                        title: this.result.message,
                        icon: 'error'
                      });
                    } else if (event.body.msg != null) {
                      Swal.fire({
                        title: event.body.msg,
                        icon: 'error'
                      });
                      this.calculation = "";
                    }else {
                      this.toastr.success("資料已儲存");
                      this.calculation = "formTask_PurchaseForm_calculation/" + event.body.name;
                      this.refreshFormTaskDetailData();

                    }
                  });
              }
            },
            error: (err: any) => {
              this.loaded = 0;
              console.log(err);
            }
          });

      } else {
        this.calculation = "";
        Swal.fire({
          title: '檔案類型不正確!',
          icon: 'error'
        });
      }
    }
  }

  viewPdf(url) {
    this.formSectionService.viewPdf(url);
  }

  addRemark() {
    let formData = {
      remark: this.remark,
      formTaskId: this.formTaskId,
      form: 'PurchaseForm'
    }
    this.apiService.addRemark(formData)
      .subscribe((res) => {
        this.result = res as any;
        if (this.result.status == false) {
          Swal.fire({
            title: this.result.message,
            icon: 'error'
          });
        } else {
          this.toastr.success("資料已儲存");
        }
      });
  }

  remarkChange(event) {
    const value = (event.target as any).value;
    this.remark = value;
  }
}
