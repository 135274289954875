import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormTaskProcessApiService } from '@services/form-task-process-api.service';
import { RightService } from '@services/right.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '@services/helper.service';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class ActionComponent implements OnInit {

  createForm: any;
  @Input() parentComp;
  @Input() action;

  employee: any;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  nextSigner: any = '';
  result: any;


  constructor(
    public activeModal: NgbActiveModal,
    public router: Router,
    public apiService: FormTaskProcessApiService,
    public rightService: RightService,
    public helperService: HelperService,
    private toastr: ToastrService,
  ) {

  }

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;


  ngOnInit(): void {
    var order = [[0, 'asc']];
    this.dtOptions = this.helperService.setDtOptions(order);
    this.createForm = new FormGroup({
      formTaskId: new FormControl('', Validators.required),
      previousFormTaskSiteId: new FormControl('', Validators.required),
      nextFormFlowId: new FormControl('', Validators.required),
      newFormFlowId: new FormControl('', Validators.required),
      comment: new FormControl('', Validators.required),
      nextSigner: new FormControl('', Validators.required),
      action: new FormControl('', Validators.required),
    });

    this.createForm.get('formTaskId').setValue(this.parentComp.formTaskId);
    this.createForm.get('previousFormTaskSiteId').setValue(this.parentComp.previousFormTaskSiteId);
    this.createForm.get('nextFormFlowId').setValue(this.parentComp.nextFormFlowId);
    this.createForm.get('action').setValue(this.action);

    if (this.action == "agree" || this.action == "send") {
      this.loadNextSingers()
    } else if (this.action == "back") {
      this.loadAlreadySingers()
    }

  }

  newFormFlowId;
  async loadNextSingers() {
    if (this.parentComp.hasNextSigner) {
      let flowType = this.getNextFormFlow(this.parentComp.nextFormFlowId).flowType;
      let targetId = this.getNextFormFlow(this.parentComp.nextFormFlowId).targetId;
      let creatorId = this.parentComp.detailData.creatorId;
      targetId = targetId == '' ? 'no' : targetId;
      (await this.apiService.getNextSingers(this.parentComp.formTaskId, flowType, targetId, creatorId)).subscribe((res) => {
        this.employee = res;
        if (this.employee != null && this.employee.length != 0) {
          this.newFormFlowId = this.employee[0]['formFlowId'];
          this.nextSigner = this.employee[0]._id;
          this.createForm.get('newFormFlowId').setValue(this.newFormFlowId);
          if (this.action == "back") {
            this.createForm.get('nextFormFlowId').setValue(this.employee[0].formFlowId);

          }
        }
        this.dtTrigger.next(null);
      });
    }
  }

  async loadAlreadySingers() {
    (await this.apiService.getAlreadySingers(this.parentComp.formTaskId, this.parentComp.detailData.creatorId)).subscribe((res) => {
      this.employee = res;
      this.employee = this.employee.sort((a, b) => (a.formFlowSort).toString().localeCompare((b.formFlowSort).toString()))
      this.dtTrigger.next(null);
    });
  }


  getNextFormFlow(nextFormFlowId) {
    let flowlist = this.parentComp.formFlowList.filter(w => w.flowType != '0');
    for (var i = 0; i < flowlist.length; i++) {
      if (flowlist[i]._id == nextFormFlowId) {
        return flowlist[i];
      }
    }
  }

  selectNextSinger(id, formFlowId) {
    this.nextSigner = id;
    if (this.action == "back") {
      this.createForm.get('nextFormFlowId').setValue(formFlowId);
      this.createForm.get('newFormFlowId').setValue(this.newFormFlowId);
    }
  }

  save() {
    if (this.action == "agree" || this.action == "send") {
      var sectionLen = this.parentComp.component.length - 1;
      var inputValidate = true;
      var inputValidateMsg = ""

      for (var i = 1; i <= sectionLen; i++) {
        if (localStorage.getItem("inputValidate" + i) == "false") {
          inputValidate = false;
          inputValidateMsg += this.parentComp.component[i]['title'] + "\r\n";
        }
      }
      if (!inputValidate) {
        Swal.fire({
          title: "請填寫必填欄位:\r\n" + inputValidateMsg,
          icon: 'error'
        });
        return;
      } else {
        for (var i = 1; i <= sectionLen; i++) {
          localStorage.removeItem("inputValidate" + i);
        }
      }
    }


    this.createForm.get('nextSigner').setValue(this.nextSigner);
    if (this.parentComp.hasNextSigner) {
      if (this.action != "reject") {
        if (this.nextSigner == '') {
          Swal.fire({
            title: "請選擇簽核者",
            icon: 'error'
          });
          return;
        }
      }
    }


    if (this.createForm.get('comment').value == '') {
      Swal.fire({
        title: "請填寫原因",
        icon: 'error'
      });
      return;
    }

    var titleAction = "";
    switch (this.action) {
      case 'agree':
        titleAction = "同意";
        break;
      case 'send':
        titleAction = "送出";
        break;
      case 'back':
        titleAction = "退回";
        break;
      case 'reject':
        titleAction = "否決";
        break;
    }

    Swal.fire({
      title: '你確定要' + titleAction + '表單?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: "#17a2b8",
      confirmButtonText: '確定',
      cancelButtonText: '取消',
      focusCancel: true
    }).then((result) => {
      if (result.value) {
        this.apiService.formAction(this.createForm.value).subscribe((res) => {
          this.result = res;
          if (this.result.status == false) {
            Swal.fire({
              title: this.result.message,
              icon: 'error'
            });
          } else {
            this.activeModal.close();
            this.toastr.success("資料已儲存");
            setTimeout(() => {
              this.router.navigateByUrl('/formTask/' + this.parentComp.formId + "/" + this.parentComp.projectId);
            }, 1000);
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log();
      }
    })
  }

}
