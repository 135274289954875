<div class="card">
  <div class="card-header">
    <h5 class="card-title mt-2">
      {{title}}
    </h5>
    <div class="card-tools">
      <button type="button" class="btn bnt-tool float-right mr-2" data-card-widget="collapse"
        (click)="handleMainMenuAction()">
        <i [ngClass]="isMenuExtended ? 'fas fa-minus' : 'fas fa-plus'" [@rotate]="isMenuExtended"></i>
      </button>
      <button *ngIf="action.allowEdit" (click)="openModal('contract-item',null)" type="button"
        class="btn btn-info float-right mr-2">
        查詢現有物料
      </button>
    </div>
  </div>
  <div class="card-body" [@openClose]="isMenuExtended">


    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger_contractItem"
      class="table table-striped table-bordered table-hover nowrap" id="contractItem_table">
      <thead>
        <tr>
          <th>合約</th>
          <th>工項</th>
          <th>預付款</th>
          <th>物料清單</th>
          <th *ngIf="action.allowEdit">新物料</th>
          <th *ngIf="action.allowEdit">刪除</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let datas of detailData?.formTaskContent?.addForm?.contractItemList;">
          <td>{{datas.contractNo}}</td>
          <td>{{datas.workTypeName}}</td>
          <td>
            <input *ngIf="action.allowEdit" type="number" name="prePay" placeholder="預付款"
            [value]="datas.prePay"
            class="form-control  updateDataByColumn updateDataByListColumn" data-label="預付款"
            data-subdocName="formTaskContent.addForm.contractItemList"
            data-subdocId="{{datas._id}}" data-subdocumnet_type="list">

            <span  *ngIf="!action.allowEdit" >{{datas.prePay}}</span>
          </td>
          <td> <button *ngIf="action.allowEdit" (click)="openModal('exist',datas)" type="button"
              class="btn btn-info mr-2">
              編輯
            </button>
            <button *ngIf="!action.allowEdit" (click)="openModal('exist',datas)" type="button"
              class="btn btn-default mr-2">
              檢視
            </button>
          </td>
          <td *ngIf="action.allowEdit"> <button *ngIf="action.allowEdit" (click)="openModal('addnew',datas)"
              type="button" class="btn btn-info mr-2">
              新增
            </button></td>
          <td *ngIf="action.allowEdit" style="text-align: center;"><a style="cursor: pointer;"
              (click)="deleteContractItem(datas._id)"><i class="fas fa-trash-alt text-danger mr-2"></i></a></td>
        </tr>
      </tbody>
    </table>


  </div>
</div>
