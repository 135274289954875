<div class="modal-header">
  <h4 class="modal-title">請採管制</h4>
  <button type="button" class="btn btn-tool" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <i class="fas fa-times"></i>
  </button>
</div>
<div class="modal-body">

    <div class="row">
      <div class="col-md-12">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
        class="table table-striped table-bordered table-hover nowrap">
        <thead>
          <tr>
            <th>項次/採發項目</th>
            <th>規格</th>
            <th>單位</th>
            <th>數量</th>
            <th>純料</th>
            <th>純工</th>
            <th>工料合併</th>
            <th>送審資料</th>
            <th>採購期限</th>
            <th>採購申請日期</th>
            <th>廠商與完成日</th>
            <th>材料送審</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of purchaseControls">
            <td>
              {{item.controlItem}}<br>
              {{item.controlName}}
            </td>
            <td>{{item.controlSpec}}</td>
            <td>{{item.controlUnit}}</td>
            <td>{{item.controlNumber.toFixed(2)}}</td>
            <td>{{item.material === true ? "Y" : "N"}}</td>
            <td>{{item.work === true ? "Y" : "N"}}</td>
            <td>{{item.both === true ? "Y" : "N"}}</td>
            <td>{{item.data === true ? "Y" : "N"}}</td>
            <td>{{item.purchaseDate | date:'yyyy/MM/dd'}}<br></td>
            <td>{{item.applyDate | date:'yyyy/MM/dd'}}<br></td>
            <td>
              <div *ngFor="let item2 of item.supplierList">
                <span *ngIf="item2.contractDate" class="badge badge-info right">{{item2.contractDate |
                  date:'yyyy/MM/dd'}} 完成</span>
                  <span *ngIf="item2.supplier"> {{item2.supplier.supplierName}}<br>({{item2.supplier.contactPhone}})</span>
              </div>
            </td>
            <td>
              <span *ngIf="item.materialData && item.materialData?.pass" class="badge bg-success">審核通過</span>
              <span *ngIf="item.materialData && !item.materialData?.pass" class="badge bg-danger">審核未通過 </span>
              <br>
              {{item.materialData?.materialItem}}<br>{{item.materialData?.materialName}}
            </td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>

  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-default" (click)="activeModal.close('Close click')">關閉</button>
  </div>
