import { filter } from 'rxjs/operators';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@services/api.service';
import { Subject } from 'rxjs';
import { RightService } from '@services/right.service';
import { HelperService } from '@services/helper.service';
import { ProjectApiService } from '@services/projectapi.service';
import Swal from 'sweetalert2';
import { ContractCalComponent } from './contract-cal/contract-cal.component';
import { DataTableDirective } from 'angular-datatables';
import { ContractAddlogComponent } from './contract-addlog/contract-addlog.component';

@Component({
  selector: 'app-project-contract',
  templateUrl: './project-contract.component.html',
  styleUrls: ['./project-contract.component.scss']
})
export class ProjectContractComponent implements OnInit {
  @Input() projectId;
  @Input() contractSubtotal;
  @Input() calculateSubtotal;
  percentSubtotal;

  public contract;
  getRight: any;
  RightObj: {};
  dtOptions: DataTables.Settings = {};

  public result;
  constructor(
    public activeModal: NgbActiveModal,
    public rightService: RightService,
    public helperService: HelperService,
    public apiService: ApiService,
    private modalService: NgbModal,
    public ProjectApiService: ProjectApiService,

  ) {
    this.getRight = this.rightService.getRight();
  }

  dtTrigger: Subject<any> = new Subject<any>();

  ngOnInit(): void {
    this.dtOptions = this.helperService.setDtOptions();
    this.RightObj = this.getRight['__zone_symbol__value'];
    var percent = Math.round(this.calculateSubtotal / this.contractSubtotal * 100);
    if (percent == Infinity || percent.toString() == 'NaN') { percent = 0 }
    this.percentSubtotal = percent + "%";
    this.getWorkType();
  }


  async reloadData() {
    (await this.ProjectApiService.getContact(this.projectId)).subscribe((res) => {
      this.contract = (res as any).filter(w => w['validateDate'] != null && w['masterContractId'] == null);
      this.contract.forEach(element => {
        element['workTypeName'] = this.mappingWorkType(element['workType']._id);
        var percent = Math.round(element['calculateSum'] / element['subtotal'] * 100);
        if (percent == Infinity || percent.toString() == 'NaN') { percent = 0 }
        element['calculatePercent'] = percent + "%";
      })
      this.dtTrigger.next(null);
    });


  }

  workTypesList = [];
  async getWorkType() {
    (await this.apiService.getWorkTypes()).subscribe((res) => {
      this.workTypesList = res as any;
      this.reloadData();
    });
  }

  mappingWorkType(current) {
    if (current != null) {
      var element = this.workTypesList.filter(w => w._id == current)[0];
      if (element != undefined) {

        var selectText = element['workTypeName'];
        if (element['rootWorkTypeId'] != '' && element['rootWorkTypeId'] != null) {
          selectText = this.helperService.getRootWorkType(this.workTypesList.sort((a, b) => a.sort - b.sort), selectText, element['rootWorkTypeId'])
        }
        return selectText;
      }
    }
  }

  download(url) {
    this.apiService.download(url).subscribe((res) => {
      if (url.indexOf('pdf') > -1) {
        this.apiService.downLoadFile(res, 'application/pdf');
      }
    },
      (err) => {
        Swal.fire({
          title: '系統找不到指定的檔案',
          confirmButtonText: '確定',
          confirmButtonColor: '#17A2B8',
          icon: 'error'
        });
      });
  }

  open(calculateForm, id, subtotal) {
    const modalRef = this.modalService.open(ContractCalComponent, { size: 'xl' });
    modalRef.componentInstance.calculateForm = calculateForm;
    modalRef.componentInstance.contractId = id;
    modalRef.componentInstance.subtotal = subtotal;
  }

  open2(addFormLogList) {
    const modalRef = this.modalService.open(ContractAddlogComponent, { size: 'xl' });
    modalRef.componentInstance.addFormLogList = addFormLogList;
  }

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  async queryData(type) {
    (await this.ProjectApiService.getContact(this.projectId)).subscribe((res) => {
      this.contract = (res as any).filter(w => w['validateDate'] != null && w['masterContractId'] == null);
      this.contract.forEach(element => {
        element['workTypeName'] = this.mappingWorkType(element['workType']._id);
        var percent = Math.round(element['calculateSum'] / element['subtotal'] * 100);
        if (percent == Infinity || percent.toString() == 'NaN') { percent = 0 }
        element['calculatePercent'] = percent + "%";
      })
      switch (type) {
        case "all":
          break;
        case "p":
          this.contract = this.contract.filter(w => w.purchaseFormId != null);
          break;
        case "a":
          this.contract = this.contract.filter(w => w.addFormId != null);
          break;

      }

      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next(null);
      });
    });
  }
}
