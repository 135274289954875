<div class="modal-header">
  <h4 class="modal-title">修改證照類型</h4>
  <button type="button" class="btn btn-tool" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <i class="fas fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form class="form-horizontal" [formGroup]="EditForm" method="post" action="">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label><code>*</code>證照類型名稱</label>
          <input type="text" formControlName="licenseTypeName" class="form-control">
        </div>
      </div>
    </div>
  </form>

  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-default" (click)="activeModal.close('Close click')">關閉</button>
    <button type="button" class="btn btn-info" (click)="editLicenseType()">確定</button>
  </div>
