<div class="modal-header">
  <h4 class="modal-title">新增職務權限</h4>
  <button type="button" class="btn btn-tool" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <i class="fas fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form class="form-horizontal" [formGroup]="CreateForm" method="post" action="">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label><code>*</code>職務</label>
          <select #selectParentgetdepartments class="form-control" formControlName="jobId">
            <option value="">請選擇職務</option>
            <option *ngFor="let item of Parentgetjobs" [value]="item.id">
              {{item.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label><code>*</code>功能模組</label>
          <select #selectParentgetdepartments class="form-control" formControlName="moduleId">
            <option value="">請選擇功能模組</option>
            <option *ngFor="let item of ParentgetfunctionModules" [value]="item.id">
              {{item.name}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label><code>*</code>排序</label>
          <input type="number" formControlName="sort" class="form-control">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <br>
          <label>
            <input type="checkbox" [value]="true" formControlName="showInMenu">
            顯示於選單中
          </label>
        </div>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label>
            <input type="checkbox" [value]="true" formControlName="insert">
            新增權限
          </label>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>
            <input type="checkbox" [value]="true" formControlName="update">
            修改權限
          </label>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>
            <input type="checkbox" [value]="true" formControlName="delete1">
            刪除權限
          </label>
        </div>
      </div>
    </div>
  </form>

  <div class="modal-footer justify-content-between">
    <div>
      <button type="button" class="btn btn-default" (click)="activeModal.close('Close click')">關閉</button>

    </div>
    <div>
      <button type="button" class="btn btn-info mr-2" (click)="addJobModules(0)">確定</button>
      <button type="button" class="btn btn-info" (click)="addJobModules(1)">確定並繼續</button>
    </div>

  </div>
