import { element } from 'protractor';
import { FormtaskapiService } from '@services/formtaskapi.service';
import { filter } from 'rxjs/operators';
import { FormTaskProcessApiService } from '@services/form-task-process-api.service';
import { ApiService } from '@services/api.service';
import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select2OptionData } from 'ng-select2';
import { ProjectApiService } from '@services/projectapi.service';
import { HelperService } from '@services/helper.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { outputAst } from '@angular/compiler';
import { FormSectionService } from '@services/form-section.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { api_url } from '@services/api_config';

@Component({
  selector: 'app-add-contract',
  templateUrl: './add-contract.component.html',
  styleUrls: ['./add-contract.component.scss']
})
export class AddContractComponent implements OnInit {
  @Input() projectId;
  @Input() formTaskId;
  @Input() contractItemList;
  @Input() action;
  @Input() detailData;
  @Input() contract;
  @Input() MasterId;
  subContractDate;
  contractTypeId: String = '';
  contractTypes = [];
  api_url = api_url;
  constructor(
    public apiService: ApiService,
    public activeModal: NgbActiveModal,
    public ProjectApiService: ProjectApiService,
    public helperService: HelperService,
    public FormTaskProcessApiService: FormTaskProcessApiService,
    private toastr: ToastrService,
    public formSectionService: FormSectionService,


  ) { }

  ngOnInit(): void {
    if (this.helperService.checkVal(this.contractItemList['subContractDate'])) {
      this.subContractDate = this.helperService.formatDate(this.contractItemList['subContractDate'], "-");
    }
    if (this.helperService.checkVal(this.contractItemList['subContractFile'])) {
      this.subContractFile =  this.contractItemList['subContractFile'];
    }
  }


  formData = new FormData();
  loaded: any = 0;
  load_selected = '';
  subContractFile: String;
  contractFileSelected(e) {
    const file: File = e.target.files[0];
    if (file) {
      this.subContractFile = file.name;
      var FileExtensionArr = ['pdf'];
      if (
        FileExtensionArr.indexOf(
          this.subContractFile
            .split('.')
          [
            this.subContractFile.split('.').length - 1
          ].toLowerCase()
        ) > -1
      ) {
        this.formData.delete('url');
        this.formData.delete('uploadFile');
        this.formData.append('url', 'formTask_AddForm_contract');
        this.formData.append('uploadFile', file);
        this.apiService.uploadData(this.formData).subscribe({
          next: (event: any) => {
            this.subContractFile = 'formTask_AddForm_contract/' + event.name;
            if (event.type === HttpEventType.UploadProgress) {
              this.load_selected = 'contractFile';
              this.loaded = Math.round(
                (100 * event.loaded) / event.total
              );
            } else if (event instanceof HttpResponse) {
              setTimeout(() => {
                this.loaded = 0;
              }, 200);
            }
          },
          error: (err: any) => {
            this.loaded = 0;
            console.log(err);
          }
        });

      } else {
        this.subContractFile = '';
        Swal.fire({
          title: '檔案類型不正確!',
          icon: 'error'
        });
      }
    }
  }

  viewPdf(url) {
    this.formSectionService.viewPdf(url);
  }
  valueChanged(val: String) {
    this.contractTypeId = val;
  }


  result: any;
  save() {
    if (!this.helperService.checkVal(this.subContractDate) || !this.helperService.checkVal(this.subContractFile)) {
      Swal.fire({
        title: '請填寫必填欄位',
        icon: 'warning',
        confirmButtonColor: '#17a2b8'
      });
    } else {
      var formData = {
        contractDate: this.subContractDate,
        contractFile: this.subContractFile,
      }

      this.FormTaskProcessApiService.updateContractItem(this.MasterId,this.formTaskId, formData)
        .subscribe((res) => {
          this.result = res as any;
          if (this.result.status == false) {
            Swal.fire({
              title: this.result.message,
              icon: 'error'
            });
          } else {
            this.toastr.success('資料已儲存');
            this.activeModal.close('Close click');
          }
        });
    }


  }

}
