import { filter } from 'rxjs/operators';
import { ProjectProgressComponent } from './project-progress/project-progress.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '@services/api.service';
import { ProjectApiService } from '@services/projectapi.service';
import { Subject } from 'rxjs';
import {
  NgbModalConfig,
  NgbModal,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import { ProjectAddComponent } from './project-add/project-add.component';
import { ProjectEditComponent } from './project-edit/project-edit.component';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { RightService } from '@services/right.service';
import { ProjectStepComponent } from './project-step/project-step.component';
import { ProjectMaterialComponent } from './project-material/project-material.component';
import { ProjectDailyReportComponent } from './project-dailyreport/project-dailyreport.component';
import { ProjectReceiptComponent } from './project-receipt/project-receipt.component';
import { ProjectContractComponent } from './project-contract/project-contract.component';
import { HelperService } from '@services/helper.service';
import { ProjectPurchaseControlComponent } from './project-purchase-control/project-purchase-control.component';

@Component({
  selector: 'app-project-manage',
  templateUrl: './project-manage.component.html',
  styleUrls: ['./project-manage.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class ProjectManageComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  getRight: any;
  RightObj: {};

  public projects;
  dtTrigger: Subject<any> = new Subject<any>();

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  constructor(
    public apiService: ApiService,
    public ProjectApiService: ProjectApiService,
    public rightService: RightService,
    public helperService: HelperService,
    config: NgbModalConfig,
    private modalService: NgbModal
  ) {
    config.backdrop = true;
    config.keyboard = false;
    config.centered = true;
    this.getRight = this.rightService.getRight();

  }
  ngOnInit(): void {
    this.dtOptions = this.helperService.setDtOptions();
    this.RightObj = this.getRight['__zone_symbol__value'];
    this.reloadData();
  }

  async reloadData() {
    (await this.ProjectApiService.getAllProject()).subscribe((res) => {
      this.projects = res as any;

      this.projects.forEach(async element => {
        var step = element.projectStepList;
        if(step != undefined && step.length != 0) {
          var last = step[step.length - 1];
          element['stepName'] = last['projectStepName'];
        }

        var materialsPass = 0;
        (await this.ProjectApiService.getMaterial(element['_id'])).subscribe((res) => {
          var materials = res as any;
          var pass = materials.filter(w => w.pass).length;
          materialsPass = Math.round( pass / materials.length * 100);
          element['materialsPass'] = materialsPass.toString() === 'NaN' ? 0 : materialsPass;
        });

        var purchaseControlPass = 0;
        (await this.ProjectApiService.getPurchaseControl(element['_id'])).subscribe((res) => {
          var purchaseControls = res as any;
          var pass = purchaseControls.filter(w => w.materialData && w.materialData.pass).length;
          purchaseControlPass = Math.round( pass / purchaseControls.length * 100);
          element['purchaseControlPass'] = purchaseControlPass.toString() === 'NaN' ? 0 : purchaseControlPass;
        });

      })
      this.dtTrigger.next(null);
    });

  }

  async refreshData() {
    (await this.ProjectApiService.getAllProject()).subscribe((res) => {
      this.projects = res as any;
      this.projects.forEach(async element => {
        var step = element.projectStepList;
        if(step != undefined && step.length != 0) {
          var last = step[step.length - 1];
          element['stepName'] = last['projectStepName'];
        }

        var materialsPass = 0;
        (await this.ProjectApiService.getMaterial(element['_id'])).subscribe((res) => {
          var materials = res as any;
          var pass = materials.filter(w => w.pass).length;
          materialsPass =  Math.round(pass / materials.length * 100);
          element['materialsPass'] = materialsPass.toString() === 'NaN' ? 0 : materialsPass;
        });
      })
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next(null);
      });
    });
  }

  open(type, project) {
    if (type == 'add') {
      const modalRef = this.modalService.open(ProjectAddComponent, { size: 'xl' });
      modalRef.closed.subscribe((result) => {
        this.refreshData();
      });
    } else if (type == 'edit') {
      const modalRef = this.modalService.open(ProjectEditComponent, { size: 'xl' });
      modalRef.componentInstance.project = project;
      modalRef.closed.subscribe((result) => {
        this.refreshData();
      });
    } else if (type == 'step') {
      const modalRef = this.modalService.open(ProjectStepComponent, { size: 'lg' });
      modalRef.componentInstance.project = project;
      modalRef.closed.subscribe((result) => {
        this.refreshData();
      });
    }
    else if (type == 'material') {
      const modalRef = this.modalService.open(ProjectMaterialComponent, { size: 'xl' });
      modalRef.componentInstance.projectId = project._id;
      modalRef.closed.subscribe((result) => {
        this.refreshData();
      });
    }
    else if (type == 'purchaseControl') {
      const modalRef = this.modalService.open(ProjectPurchaseControlComponent, { size: 'xl' });
      modalRef.componentInstance.projectId = project._id;
      modalRef.closed.subscribe((result) => {
        this.refreshData();
      });
    }
    else if (type == 'DailyReportManage') {
      const modalRef = this.modalService.open(ProjectDailyReportComponent, { size: 'xl' });
      modalRef.componentInstance.projectId = project._id;
      modalRef.closed.subscribe((result) => {
        this.refreshData();
      });
    }
    else if (type == 'receipt') {
      const modalRef = this.modalService.open(ProjectReceiptComponent, { size: 'xl' });
      modalRef.componentInstance.project = project;
      modalRef.closed.subscribe((result) => {
        this.refreshData();
      });
    }
    else if (type == 'progress') {
      const modalRef = this.modalService.open(ProjectProgressComponent, { size: 'xl' });
      modalRef.componentInstance.projectId = project._id;
      modalRef.closed.subscribe((result) => {
        this.refreshData();
      });
    }
    else if (type == 'contract') {
      const modalRef = this.modalService.open(ProjectContractComponent, { size: 'xl' });
      modalRef.componentInstance.projectId = project._id;
      modalRef.componentInstance.contractSubtotal =project.contractSubtotal;
      modalRef.componentInstance.calculateSubtotal =project.calculateSubtotal;

      modalRef.closed.subscribe((result) => {
        this.refreshData();
      });
    }
  }
}
