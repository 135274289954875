import { ProjectApiService } from '@services/projectapi.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormTaskProcessApiService } from '@services/form-task-process-api.service';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '@services/helper.service';

@Component({
  selector: 'app-purchase-form-edit',
  templateUrl: './purchase-form-edit.component.html',
  styleUrls: ['./purchase-form-edit.component.scss']
})
export class PurchaseFormEditComponent implements OnInit {

  @Input() parentComp;

  purchaseForm: FormGroup;
  workTypeList: any;
  emergencyTypeList: any;
  recommendSupplierList: any;
  result: any;
  emergencyTypeId;

  constructor(
    public activeModal: NgbActiveModal,
    public apiService: FormTaskProcessApiService,
    private toastr: ToastrService,
    public helperService: HelperService,
    public ProjectApiService: ProjectApiService,
  ) { }



  ngOnInit(): void {
    this.purchaseForm = new FormGroup({
      projectName: new FormControl({ value: '', disabled: true }),
      purchaseDeadLine: new FormControl({ value: '', disabled: true }),
      workTypeId: new FormControl(''),
      emergencyTypeId: new FormControl(''),
      workEndTime: new FormControl(''),
      workBeginTime: new FormControl(''),
      workers: new FormControl(''),
      recommendSupplierId: new FormControl(''),
      supplierAmount: new FormControl(''),
      purchaseControlId: new FormControl(''),
    });

    this.workTypeList = this.parentComp.workTypes;
    this.emergencyTypeList = this.parentComp.emergencyTypes;
    this.recommendSupplierList = this.parentComp.suppliers
    this.purchaseForm.get("projectName").setValue(this.parentComp?.formAndProjectData?.projectName);
    this.purchaseForm.get("workTypeId").setValue(this.parentComp?.detailData?.formTaskContent?.purchaseForm?.workTypeId);
    this.purchaseForm.get("purchaseDeadLine").setValue(this.parentComp?.detailData?.formTaskContent?.purchaseForm?.purchaseDeadLine.substr(0, 10));
    this.purchaseForm.get("emergencyTypeId").setValue(this.parentComp?.detailData?.emergencyTypeId);
    this.purchaseForm.get("workBeginTime").setValue(this.parentComp?.detailData?.formTaskContent?.purchaseForm?.workBeginTime.substr(0, 10));
    this.purchaseForm.get("workEndTime").setValue(this.parentComp?.detailData?.formTaskContent?.purchaseForm?.workEndTime.substr(0, 10));
    this.purchaseForm.get("recommendSupplierId").setValue(this.parentComp?.detailData?.formTaskContent?.purchaseForm?.recommendSupplierId);
    this.purchaseForm.get("workers").setValue(this.parentComp?.detailData?.formTaskContent?.purchaseForm?.workers);
    this.purchaseForm.get("supplierAmount").setValue(this.parentComp?.detailData?.formTaskContent?.purchaseForm?.supplierAmount);

    this.emergencyTypeId = this.parentComp?.detailData?.emergencyTypeId;
    this.ddlWorkType();
    this.ddlPurchaseControl();
  }

  async ddlWorkType() {
    let arrWorkType = [];
    this.workTypeList = this.workTypeList.filter(w => w.enable).sort((a, b) => a.sort - b.sort);
    this.workTypeList.forEach(element => {
      if ((element['enable']) && element['level'] === 3) {
        var selectText = element['workTypeName'];
        if (element['rootWorkTypeId'] != '' && element['rootWorkTypeId'] != null) {
          selectText = this.helperService.getRootWorkType(this.workTypeList, selectText, element['rootWorkTypeId'])
        }
        arrWorkType.push({ _id: element['_id'], workTypeName: selectText });
      }
    })
    this.workTypeList = arrWorkType;
  }



  purchaseControlList = []
  async ddlPurchaseControl() {
    (await this.ProjectApiService.getPurchaseControl(this.parentComp.projectId)).subscribe((res) => {
      this.purchaseControlList = (res as any).filter(w => w['purchaseDate']);
      setTimeout(() => {
        var purchaseControlId = (this.parentComp?.detailData?.formTaskContent?.purchaseForm?.purchaseControlId) ? this.parentComp?.detailData?.formTaskContent?.purchaseForm?.purchaseControlId : "無";
        this.purchaseForm.get("purchaseControlId").setValue(purchaseControlId);
      }, 100);
    });
  }


  editPurchaseForm() {

    if (!this.purchaseForm.valid) {
      Swal.fire({
        title: "請填寫必填欄位",
        icon: 'warning',
        confirmButtonColor: "#17a2b8",
      });
      return;
    }

    var purchaseControlId = (this.purchaseForm.get('purchaseControlId').value === '無') ? null : this.purchaseForm.get('purchaseControlId').value;

    let formdata = {
      workTypeId: this.purchaseForm.get('workTypeId').value,
      emergencyTypeId: this.purchaseForm.get('emergencyTypeId').value,
      workBeginTime: this.purchaseForm.get('workBeginTime').value,
      workEndTime: this.purchaseForm.get('workEndTime').value,
      purchaseDeadLine: this.purchaseForm.get('purchaseDeadLine').value,
      recommendSupplierId: this.purchaseForm.get('recommendSupplierId').value,
      workers: this.purchaseForm.get('workers').value,
      supplierAmount: this.purchaseForm.get('supplierAmount').value,
      purchaseControlId: purchaseControlId,

    };


    this.apiService
      .editPurchaseForm(formdata, this.parentComp.formTaskId)
      .subscribe((res) => {
        this.result = res as any;
        if (this.result.status == false) {
          Swal.fire({
            title: this.result.message,
            icon: 'error'
          });
        } else {
          this.activeModal.close();
          this.toastr.success("資料已儲存");
        }
      });
  }

  purchaseDeadLineChange(value, purchaseControlId = null) {
    this.emergencyTypeId = value;
    let purchaseDeadLine;
    if (purchaseControlId != null) {
      this.purchaseForm.get('purchaseControlId').setValue(purchaseControlId);
    }
    if (this.purchaseForm.get('purchaseControlId').value && this.purchaseForm.get('purchaseControlId').value !== '無') {
      purchaseDeadLine = this.purchaseControlList.filter(w => w._id === this.purchaseForm.get('purchaseControlId').value)[0]['purchaseDate']
      this.purchaseForm.get("purchaseDeadLine").setValue(this.helperService.formatDate(purchaseDeadLine));
      return
    }
    // if (workBeginTime != "") {
      if (this.emergencyTypeId == "2022102810220001") {
        purchaseDeadLine = new Date(Date.parse(this.parentComp?.detailData?.beginTime));
        console.log("0", purchaseDeadLine);
        purchaseDeadLine.setDate(purchaseDeadLine.getDate() + 21);
        console.log("1", purchaseDeadLine);
        purchaseDeadLine = purchaseDeadLine.toISOString().substring(0, 10);
        console.log("2", purchaseDeadLine);
        this.purchaseForm.get("purchaseDeadLine").setValue(purchaseDeadLine);
      } else if (this.emergencyTypeId == "2022102810230001") {
        purchaseDeadLine = new Date(Date.parse(this.parentComp?.detailData?.beginTime));
        purchaseDeadLine.setDate(purchaseDeadLine.getDate() + 14);
        purchaseDeadLine = purchaseDeadLine.toISOString().substring(0, 10);
        this.purchaseForm.get("purchaseDeadLine").setValue(purchaseDeadLine);
      } else if (this.emergencyTypeId == "2022102810230002") {
        purchaseDeadLine = new Date(Date.parse(this.parentComp?.detailData?.beginTime));
        purchaseDeadLine.setDate(purchaseDeadLine.getDate() + 7);
        purchaseDeadLine = purchaseDeadLine.toISOString().substring(0, 10);
        this.purchaseForm.get("purchaseDeadLine").setValue(purchaseDeadLine);
      }
    // }
  }

}
