<div class="login-page" style="width:100%;height:100%">
    <div class="login-background"> 
    </div>
    <div class="login-logo">
        <img [src]="'assets/img/cc_logo.png'" [alt]="'imageAlt'" />
    </div>
    <div class="card card-outline login-box">
        <div class="card-body">
            <div class="login-box-msg">
                <h2>欽成營造</h2>
                <h2>工程管理系統</h2>
            </div>
            <form [formGroup]="loginForm" (ngSubmit)="loginByAuth()">
                <div class="input-group mb-3">
                    <input
                        formControlName="account"
                        type="text"
                        class="form-control"
                        placeholder="員工編號"
                        required
                        [id]="'input0'"
                        (keyup.enter)="focusNext(0)"
                    />
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <span class="fas fa-envelope"></span>
                        </div>
                    </div>
                </div>
                <div class="input-group mb-3">
                    <input
                        formControlName="password"
                        type="password"
                        class="form-control"
                        placeholder="密碼"
                        required
                        [id]="'input1'"
                        (keyup.enter)="focusNext(1)"
                    />
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <span class="fas fa-lock"></span>
                        </div>
                    </div>
                </div>
                <div class="row" style="margin-bottom:100px;">
                    <div class="col-7">
                        <div class="icheck-primary">
                            <input type="checkbox" id="remember" />
                            <label for="remember"> Remember Me </label>
                        </div>
                    </div>
                    <!-- /.col -->
                    <div class="col-5">
                        <pf-button
                            [type]="'submit'"
                            [block]="true"
                            theme="info"
                            [loading]="isAuthLoading"
                            [disabled]="isFacebookLoading || isGoogleLoading"
                        >
                            LogIn
                        </pf-button>
                    </div>
                    <!-- /.col -->
                </div>
            </form>
    
            <!-- <div class="social-auth-links text-center mb-3">
                <pf-button
                    class="mb-2"
                    [block]="true"
                    [loading]="isFacebookLoading"
                    [disabled]="isAuthLoading || isGoogleLoading"
                    (click)="loginByFacebook()"
                >
                    <i class="fab fa-facebook mr-2"></i>
                    Sign in using Facebook
                </pf-button>
                <pf-button
                    [block]="true"
                    [loading]="isGoogleLoading"
                    [disabled]="isAuthLoading || isFacebookLoading"
                    theme="danger"
                    (click)="loginByGoogle()"
                >
                    <i class="fab fa-google mr-2"></i>
                    Sign in using Google+
                </pf-button>
            </div> -->
    
            <p class="mb-1 text-center">
                <a [routerLink]="'/login'" (click)="forgotPasswordModal()">I forgot my password</a>
                <!-- <a [routerLink]="['/forgot-password']">I forgot my password</a> -->
            </p>
            <!-- <p class="mb-0">
                <a [routerLink]="['/register']" class="text-center">
                    Register a new membership
                </a>
            </p> -->
        </div>
        <!-- /.login-card-body -->
    </div>
</div>
    
