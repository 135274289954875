<div class="card">
  <div class="card-header">
    <h5 class="card-title mt-2">
      {{title}}
    </h5>
    <div class="card-tools">
      <button type="button" class="btn bnt-tool float-right mr-2" data-card-widget="collapse"
        (click)="handleMainMenuAction()">
        <i [ngClass]="isMenuExtended ? 'fas fa-minus' : 'fas fa-plus'" [@rotate]="isMenuExtended"></i>
      </button>
    </div>
  </div>
  <div class="card-body" [@openClose]="isMenuExtended">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label for=""><span class="text-danger">*</span>變更圖面</label>
          <div *ngIf="action.allowEdit">
            <div class="input-group">
              <div class="custom-file">
                <input #fileUpload type="file" class="custom-file-input" accept=".pdf"
                  (change)="FileSelected($event,'changeDiagram')">
                <label class="custom-file-label" for="fileUpload">{{changeDiagram ? changeDiagram :
                  '請上傳檔案'}}</label>
              </div>

            </div>
            <div *ngIf="loaded !== 0 && load_selected === 'changeDiagram'" class="progress my-3">
              <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                attr.aria-valuenow="{{ loaded }}" aria-valuemin="0" aria-valuemax="100"
                [ngStyle]="{ width: loaded+ '%' }">
                {{ loaded }}%
              </div>
            </div>
          </div>
          <div>
            <button *ngIf="!(action.allowEdit)" type="button" class="btn btn-default"
              (click)="viewPdf(changeDiagram)">檢視</button>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for=""><span class="text-danger">*</span>變更數量計算式</label>
          <div *ngIf="action.allowEdit">
            <div class="input-group">
              <div class="custom-file">
                <input #fileUpload type="file" class="custom-file-input" accept=".pdf"
                  (change)="FileSelected($event,'changeSpec')">
                <label class="custom-file-label" for="fileUpload">{{changeSpec ? changeSpec : '請上傳檔案'}}</label>
              </div>
            </div>
            <div *ngIf="loaded !== 0 && load_selected === 'changeSpec'" class="progress my-3">
              <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                attr.aria-valuenow="{{ loaded }}" aria-valuemin="0" aria-valuemax="100"
                [ngStyle]="{ width: loaded+ '%' }">
                {{ loaded }}%
              </div>
            </div>
          </div>
          <div>
            <button *ngIf="!(action.allowEdit)" type="button" class="btn btn-default"
              (click)="viewPdf(changeSpec)">檢視</button>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="">其他附件</label>
          <div *ngIf="action.allowEdit">
            <div class="input-group">
              <div class="custom-file">
                <input #fileUpload type="file" class="custom-file-input" accept=".pdf"
                  (change)="FileSelected($event,'changeOther')">
                <label class="custom-file-label" for="fileUpload">{{changeOther ? changeOther : '請上傳檔案'}}</label>
              </div>
            </div>
            <div *ngIf="loaded !== 0 && load_selected === 'changeOther'" class="progress my-3">
              <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                attr.aria-valuenow="{{ loaded }}" aria-valuemin="0" aria-valuemax="100"
                [ngStyle]="{ width: loaded+ '%' }">
                {{ loaded }}%
              </div>
            </div>
          </div>
          <div>
            <button *ngIf="!(action.allowEdit)" type="button" class="btn btn-default"
              (click)="viewPdf(changeOther)">檢視</button>
          </div>
        </div>
      </div>

      <div class="col-md-12 form-group">
        <label for="">文件備註</label>
        <ng-container *ngIf="action.allowEdit">
          <input type="text" class="form-control  updateDataByColumn updateDataByListColumn" [value]="changeRemark" (ngModel)="changeRemark"
            cols="30" rows="3" placeholder="文件備註" data-label="文件備註" data-subdocName="formTaskContent.addForm"
            name="changeRemark" data-subdocId="{{detailData?.formTaskContent?.addForm?._id}}" data-subdocumnet_type="" />
        </ng-container>
        <ng-container *ngIf="!(action.allowEdit)">
          <input type="text"  readonly class="form-control"
            [value]="changeRemark" (ngModel)="changeRemark">
        </ng-container>

      </div>
    </div>



  </div>
</div>
