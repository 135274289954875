import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormtaskapiService } from '@services/formtaskapi.service';
import { FormTaskProcessApiService } from '@services/form-task-process-api.service';
import { ApiService } from '@services/api.service';
import { ActivatedRoute } from '@angular/router';
import { RightService } from '@services/right.service';
import { DataTableDirective } from 'angular-datatables';
import { __classPrivateFieldGet } from 'tslib';
import { HelperService } from '@services/helper.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-form-task-process',
  templateUrl: './form-task-process.component.html',
  styleUrls: ['./form-task-process.component.scss']
})
export class FormTaskProcessComponent implements OnInit {
  formId: any;
  projectId: any;
  formTaskId: any;
  formName = "";
  formEName = "";
  taskStatusId: any;
  detailData: any;
  dtOptions: DataTables.Settings = {};

  sectionRight: any = [];
  component: any;

  formFlowList: any = [];
  nextFormFlowId: any = '';
  currentFormFlowId: any;
  previousFormTaskSiteId: any;
  hasNextSigner: any = true;

  detail: any = "";

  constructor(
    public task_apiService: FormtaskapiService,
    public apiService: FormTaskProcessApiService,
    public master_apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    public rightService: RightService,
    public helperService: HelperService,
  ) {
    this.subscribeRouteChange();
  }

  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;

  subscribeRouteChange() {
    this.activatedRoute.params.subscribe((params = {}) => {
      this.formId = params.id;
      this.projectId = params.projectId;
      this.formTaskId = params.formTaskId;
      this.taskStatusId = params.taskStatusId;
      this.detail = params.detail;
    });
  }

  ngOnInit(): void {
    var order = [[0, 'asc']];
    this.dtOptions = this.helperService.setDtOptions(order);
    this.component =
      [
        {
          btns: [
            {
              'name': "退回/Back",
              'icon': 'fas fa-arrow-left',
              'action': 'back'
            },
            {
              'name': "同意/Agree",
              'icon': 'fa fa-check',
              'action': 'agree'
            },
            {
              'name': "否決/Reject",
              'icon': 'fa fa-times',
              'action': 'reject'
            },
          ],
          actions: {
            'allowEdit': true,
            'allowShow': true,
          },
          title: ''
        }
      ];

    this.reloadFormTaskDetailData();

  }

  async loadBasicData() {
    const param = {
      formId: this.formId
    };

    (await this.task_apiService.getForm(param)).subscribe((res) => {
      this.formName = res['formName'];
      this.formEName = res['formEName'];

      var formSectionList = this.detailData.form.formSectionList;
      formSectionList.forEach(element => {
        var obj = {
          btns: [
          ],
          actions: {
            'allowEdit': null,
            'allowShow': null,
          },
          title: ''
        };
        this.component.push(obj);

      })

      for (var i = 1; i <= this.component.length; i++) {
        localStorage.removeItem("inputValidate" + i);
      }
      this.setInitComponent(this.detailData);
    });
  }


  async reloadFormTaskDetailData() {
    (await this.apiService.getFormTaskDetailData(this.formTaskId, this.taskStatusId)).subscribe((res) => {
      this.detailData = res;
      this.loadBasicData();
    });
  }

  setInitComponent(detailData) {
    this.detailData = detailData;
    let t = this.detailData.form.formSectionList;
    if (this.component.length == 1) {
      window.location.reload();
    }
    for (var i = 0; i < t.length; i++) {
      this.component[t[i]['formSectionNo']]['title'] = t[i]['formSectionName'];
      this.component[t[i]['formSectionNo']]['formSectionId'] = t[i]['_id'];

    }
    this.formFlowList = this.detailData.form.formFlowList.sort((a, b) => a.sort - b.sort);
    this.sectionRight = this.getSectionRight();
    if (this.detailData?.taskSiteList != undefined && this.detailData?.taskSiteList != null && this.detailData?.taskSiteList.length != 0) {
      let taskSiteList = this.detailData.taskSiteList;

      for (var i = 0; i < taskSiteList.length; i++) {
        if (taskSiteList[i].current == true) {
          this.currentFormFlowId = taskSiteList[i].formFlowId;
          this.previousFormTaskSiteId = taskSiteList[i]._id;
          this.setNextFormFlowId(this.currentFormFlowId);
        }
      }
    } else {
      this.setNextFormFlowId('');
      this.previousFormTaskSiteId = '';
      this.currentFormFlowId = '';
    }

    if (this.currentFormFlowId == "") {
      this.component[0].btns =
        [
          {
            'name': "送出簽核",
            'icon': 'fas fa-check',
            'action': 'send'
          },

        ];
    }
    for (var i = 0; i < this.sectionRight.length; i++) {
      if (this.sectionRight.length > 0) {
        var thisRight = this.sectionRight.filter(w => w.formSectionId == this.component[i + 1]['formSectionId'])[0];
        var formSectionIdList = this.sectionRight.map(a => a.formSectionId);
        var cc = this.component.filter(w =>w['formSectionId'] != undefined && formSectionIdList.indexOf(w['formSectionId']) == -1)[0];
        if(cc && cc['actions']){
          cc['actions']['allowShow']=false;
          cc['actions']['allowEdit']=false;
        }
        if (thisRight) {
          this.component[i + 1]['actions']['allowEdit'] = thisRight['allowEdit'];
          if (thisRight['allowEdit']) {
            this.component[i + 1].btns =
              [
                {
                  'name': "編輯",
                  'icon': 'fas fa-edit',
                  'action': ''
                },
              ];
          }
          this.component[i + 1]['actions']['allowShow'] = thisRight['allowShow'];
        }
      } else {
        this.component[i + 1]['actions']['allowEdit'] = true;
        this.component[i + 1].btns =
          [
            {
              'name': "編輯",
              'icon': 'fas fa-edit',
              'action': ''
            },
          ];
        this.component[i + 1]['actions']['allowShow'] = true;
      }
    }

    if (this.detail == "true") {
      for (var i = 0; i < t.length; i++) {
        if (this.sectionRight[i] == undefined) {
          this.sectionRight[i] = {};
        } else {
          this.component[i + 1]['actions']['allowEdit'] = false;
        }
      }

      console.log(this.component);
    }
  }

  setNextFormFlowId(currentFormFlowId) {
    let flowlist = this.formFlowList.filter(w => w.flowType != '0' && w.flowType != '99' && w.flowGroup == this.detailData.flowGroup);
    if (currentFormFlowId == '') {
      this.nextFormFlowId = flowlist[0]._id;
    } else {
      for (var i = 0; i < flowlist.length; i++) {
        if (flowlist[i]._id == currentFormFlowId) {
          if (i + 1 == flowlist.length) {
            this.hasNextSigner = false;
          }
          if (this.hasNextSigner)
            this.nextFormFlowId = flowlist[i + 1]._id;
        }
      }
    }
  }

  getSectionRight() {
    let data = this.formFlowList.filter(w => w.flowGroup == this.detailData.flowGroup);
    if (this.detail == "true") {
      data = this.formFlowList.filter((item) => item.flowType == '99');
    }

    if (this.currentFormFlowId !== undefined && this.currentFormFlowId !== '') {
      data = this.formFlowList.filter((item) => item._id == this.currentFormFlowId);
    } else {
      if (this.detailData.taskStatusId == '2022102718360001') {
        data = this.formFlowList.filter((item) => item.flowType == '0');
      }
    }

    if (data.length != 0) {
      var d = data.filter(w => w.flowGroup == this.detailData.flowGroup)[0].sectionRightList;
      return d;
    }
    return [];
  }

}
