import { filter } from 'rxjs/operators';
import { FormSectionService } from '@services/form-section.service';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { openCloseAnimation, rotateAnimation } from '../../../../../../app/pages/form-task-process/detail-section/detail-section.animations'
import { ApiService } from '@services/api.service';
import { HelperService } from '@services/helper.service';
import { ProjectApiService } from '@services/projectapi.service';
import { FormTaskProcessApiService } from '@services/form-task-process-api.service';
import { TokenStorageService } from '@services/token-storage.service';
import{api_url} from '@services/api_config';
@Component({
  selector: 'app-calculate-form-process-section1',
  templateUrl: './calculate-form-process-section1.component.html',
  styleUrls: ['./calculate-form-process-section1.component.scss'],
  animations: [openCloseAnimation, rotateAnimation]
})
export class CalculateFormProcessSection1Component implements OnInit {
  @Input() formAndProjectData;
  @Input() detailData;
  @Output() refreshFormTaskDetailData = new EventEmitter<object>();
  api_url=api_url;
  public isExpandable: boolean = false;
  public isMainActive: boolean = false;
  public isOneOfChildrenActive: boolean = false;
  @Input() parentComp;
  @Input() title;
  @Input() formTaskNo;
  @Input() action;
  @Input() isMenuExtended;
  @Input() component;
  @Input() projectId;
  @Input() contractId;

  public workTypeName;

  formTaskId = "";
  taskStatusId= "";

  constructor(
    public apiService: ApiService,
    public formSectionService: FormSectionService,
    public helperService: HelperService,
    public ProjectApiService: ProjectApiService,
    public elementRef: ElementRef,
    public renderer: Renderer2,
    public formTaskProcessApiService: FormTaskProcessApiService,
    private tokenStorage: TokenStorageService) { }


  getUser;
  ngOnInit(): void {
    this.getUser = this.tokenStorage.getUser();
    this.isExpandable = true;
    this.formSectionService.calculateIsActive(this.isMenuExtended);

    this.renderer.listen(this.elementRef.nativeElement, 'change', () => {
      setTimeout(() => {
        this.refresh();
      }, 1000);
    });
  }

  async refresh() {
    (await this.formTaskProcessApiService.getFormTaskDetailData(this.formTaskId, this.taskStatusId)).subscribe((res) => {
      this.detailData = res;
      this.validate();
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.detailData != null && changes.detailData.currentValue) {
      this.formTaskId = this.detailData._id;
      this.taskStatusId = this.detailData.taskStatusId;
      this.helperService.AutoSave(this.renderer, this.elementRef.nativeElement, "formTask", this.detailData?._id);
      this.getContract();
      this.c_tax = this.detailData?.formTaskContent?.calculateForm?.tax;
      this.c_total = this.detailData?.formTaskContent?.calculateForm?.amount;
      this.c_taxType = (this.detailData?.formTaskContent?.calculateForm?.taxType != undefined) ? this.detailData?.formTaskContent?.calculateForm?.taxType : '應稅';
      this.cTotal(0);
      this.validate();


    }
    if (changes.formTaskNo != null && changes.formTaskNo.currentValue) {
      var newValue = changes.formTaskNo.currentValue;
      if (newValue != '(undefined)') {
        this.title = this.title.replace('{calculateNumber}', newValue)
      }
    }
  }

  validate() {
    localStorage.setItem('inputValidate1', "true");
    if (!this.helperService.checkVal(this.detailData?.formTaskContent?.calculateForm?.calculateDate)) {
      localStorage.setItem('inputValidate1', "false");
    }
    if (!this.helperService.checkVal(this.detailData?.formTaskContent?.calculateForm?.invoiceNumber)) {
      localStorage.setItem('inputValidate1', "false");
    }
    if (!this.helperService.checkVal(this.detailData?.formTaskContent?.calculateForm?.invoiceDate)) {
      localStorage.setItem('inputValidate1', "false");
    }
    if (!this.helperService.checkVal(this.detailData?.formTaskContent?.calculateForm?.amonutNoTax)) {
      localStorage.setItem('inputValidate1', "false");
    }
    if (!this.helperService.checkVal(this.detailData?.formTaskContent?.calculateForm?.taxType)) {
      localStorage.setItem('inputValidate1', "false");
    }
    if (!this.helperService.checkVal(this.detailData?.formTaskContent?.calculateForm?.tax)) {
      localStorage.setItem('inputValidate1', "false");
    }
    if (!this.helperService.checkVal(this.detailData?.formTaskContent?.calculateForm?.amount)) {
      localStorage.setItem('inputValidate1', "false");
    }

  }

  contract: [];
  prepay=0;
  async getContract() {
    (await this.ProjectApiService.getContact(this.projectId)).subscribe((res) => {
      this.contract = (res as any).filter(w => w._id == this.contractId);
      this.detailData['formTaskContent']['calculateForm']['contract'] = this.contract.filter(w => w['_id'] == this.detailData['formTaskContent']['calculateForm']['contract']['_id'])[0];
      this.ddlWorkType(this.detailData?.formTaskContent?.calculateForm?.contract?.workType?._id);
      var supplierId = this.detailData['formTaskContent']['calculateForm']['supplier']['_id'];
      var inquiryData = this.detailData['formTaskContent']['calculateForm']['contract']['inquiryData'];
      this.prepay = inquiryData.filter(w => w.supplierId == supplierId)[0]['prepayMoney'];
    });
  }

  AllWorkType = [];
  async ddlWorkType(current) {
    if (current != null) {
      (await this.apiService.getWorkTypes()).subscribe((res) => {
        this.AllWorkType = (res as any).sort((a, b) => a.sort - b.sort);
        var element = this.AllWorkType.filter(w => w.enable && w._id == current)[0];
        if (element != undefined && element['_id'] === current) {
          var selectText = element['workTypeName'];
          if (element['rootWorkTypeId'] != '' && element['rootWorkTypeId'] != null) {
            selectText = this.helperService.getRootWorkType(this.AllWorkType, selectText, element['rootWorkTypeId'])
          }
          this.workTypeName = selectText;
        }
      });
    }
  }


  handleMainMenuAction() {
    this.formSectionService.handleMainMenuAction(this.isExpandable, this.isMenuExtended)
  }

  c_tax = 0;
  c_total = 0;
  c_taxType = '未稅';
  cTotal(s) {
    var amonutNoTax = $("#amonutNoTax").val();
    if (amonutNoTax != "") {
      var amonutNoTax_int = parseInt(amonutNoTax.toString());
      var taxType = $("#taxType").val();
      var tax = $("#tax").val();
      var tax_int = parseInt(tax.toString());

      if (taxType == '應稅' && s == 0) {
        tax_int = Math.round(amonutNoTax_int * 0.05);
      }
      if (taxType == '未稅' && s == 0) {
        tax_int = 0;
      }

      this.c_tax = tax_int;
      this.c_total = amonutNoTax_int + tax_int;

      var whereObj = [{ key: "_id", value: this.detailData?._id, subdocName: "" }, { key: "_id", value: this.detailData?.formTaskContent?.calculateForm?._id, subdocName: "formTaskContent.calculateForm", subdocumnet_type: "" }]
      this.helperService.callUpdate("formTask", whereObj, this.detailData?._id, "tax", this.c_tax, "稅額", "String")
      this.helperService.callUpdate("formTask", whereObj, this.detailData?._id, "amount", this.c_total, "總計", "String")
      this.validate();
    }


  }
}
