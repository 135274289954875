import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {NgbdModalConfigComponent} from './modal-config';

@NgModule({
    imports: [BrowserModule, NgbModule],
    declarations: [NgbdModalConfigComponent],
    exports: [NgbdModalConfigComponent],
    bootstrap: [NgbdModalConfigComponent]
})
export class NgbdModalConfigModule {}
