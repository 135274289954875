import { filter } from 'rxjs/operators';
import { HelperService } from '../../../../../services/helper.service';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, Renderer2, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { FormTaskProcessApiService } from '@services/form-task-process-api.service';
import { FormSectionService } from '@services/form-section.service';

import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { openCloseAnimation, rotateAnimation } from '../../../../form-task-process/detail-section/detail-section.animations'
import { ProjectApiService } from '@services/projectapi.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-add-form-process-section3',
  templateUrl: './add-form-process-section3.component.html',
  styleUrls: ['./add-form-process-section3.component.scss'],
  animations: [openCloseAnimation, rotateAnimation]
})
export class AddFormProcessSection3Component implements OnInit {
  public isExpandable: boolean = false;
  public isMainActive: boolean = false;
  public isOneOfChildrenActive: boolean = false;
  @Input() title;
  @Input() action;
  @Input() isMenuExtended;
  @Input() component;
  @Input() formTaskId;
  @Input() taskStatusId;
  @Input() projectId;
  @Input() contractId;
  @Input() detailData;
  constructor(
    public apiService: FormTaskProcessApiService,
    private toastr: ToastrService,
    public formSectionService: FormSectionService,
    private modalService: NgbModal,
    public helperService: HelperService,
    public ProjectApiService: ProjectApiService,
    public elementRef: ElementRef,
    public renderer: Renderer2,
    public formTaskProcessApiService: FormTaskProcessApiService,
  ) { }

  ngOnInit(): void {

  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.detailData != undefined && changes.detailData.currentValue) {
      this.helperService.AutoSave(this.renderer, this.elementRef.nativeElement, "formTask", this.detailData?._id);
      this.isInvoice = this.detailData?.formTaskContent?.addForm?.isInvoice;
      this.isDataCorrect = this.detailData?.formTaskContent?.addForm?.isDataCorrect;
      this.isCheck = this.detailData?.formTaskContent?.addForm?.isCheck;

      this.changeDiagram = this.detailData?.formTaskContent?.addForm?.changeDiagram;
      this.changeSpec = this.detailData?.formTaskContent?.addForm?.changeSpec;
      this.changeOther = this.detailData?.formTaskContent?.addForm?.changeOther;
      this.changeRemark = this.detailData?.formTaskContent?.addForm?.changeRemark;
      this.validate();
    }
  }
  isInvoice = false;
  isDataCorrect = false;
  isCheck = false;
  changeDiagram = "";
  changeSpec = "";
  changeOther = "";
  formData = new FormData();
  loaded: any = 0;
  load_selected: any = 0;

  validate() {
    localStorage.setItem('inputValidate3', "true");
    if (!this.helperService.checkVal(this.detailData?.formTaskContent?.addForm?.changeDiagram)) {
      localStorage.setItem('inputValidate3', "false");
    }
    if (!this.helperService.checkVal(this.detailData?.formTaskContent?.addForm?.changeSpec)) {
      localStorage.setItem('inputValidate3', "false");
    }

  }
  FileSelected(e, type) {
    const file: File = e.target.files[0];
    if (file) {
      switch (type) {
        case "changeDiagram":
          this.changeDiagram = file.name;
          break;
        case "changeSpec":
          this.changeSpec = file.name;
          break;
        case "changeOther":
          this.changeOther = file.name;
          break;
      }
      var FileExtensionArr = ["pdf"];
      if (FileExtensionArr.indexOf((file.name.split('.')[file.name.split('.').length - 1]).toLowerCase()) > -1) {
        var FileExtensionArr = ["pdf"];
        this.formData.delete("url");
        this.formData.delete("uploadFile");
        this.formData.append('url', "formTask_AddForm_" + type);
        this.formData.append('uploadFile', file);

        this.apiService
          .uploadData(
            this.formData
          )
          .subscribe({
            next: (event: any) => {
              if (event.type === HttpEventType.UploadProgress) {
                this.load_selected = type;
                this.loaded = Math.round(100 * event.loaded / event.total);
              } else if (event instanceof HttpResponse) {
                setTimeout(() => {
                  this.loaded = 0;
                }, 200);

                let formData = {
                  fileName: event.body.name,
                  formTaskId: this.formTaskId,
                  type: type
                }
                this.apiService.uploadAddData(formData)
                  .subscribe((res1) => {
                    var result = res1 as any;
                    if (result.status == false) {
                      Swal.fire({
                        title: result.message,
                        icon: 'error'
                      });
                    } else if (event.body.msg != null) {
                      Swal.fire({
                        title: event.body.msg,
                        icon: 'error'
                      });
                      switch (type) {
                        case "changeDiagram":
                          this.changeDiagram = "";
                          break;
                        case "changeSpec":
                          this.changeSpec = "";
                          break;
                        case "changeOther":
                          this.changeOther = "";
                          break;
                      }
                    } else {
                      this.toastr.success("資料已儲存");
                      switch (type) {
                        case "changeDiagram":
                          this.changeDiagram = "formTask_AddForm_" + type + "/" + event.body.name;
                          break;
                        case "changeSpec":
                          this.changeSpec =  "formTask_AddForm_" + type + "/" + event.body.name;
                          break;
                        case "changeOther":
                          this.changeOther =  "formTask_AddForm_" + type + "/" + event.body.name;
                          break;

                      }

                      this.refresh();
                    }
                  });
              }
            },
            error: (err: any) => {
              this.loaded = 0;
              console.log(err);
            }
          });
      }
    }
    else {
      switch (type) {
        case "changeDiagram":
          this.changeDiagram = "";
          break;
        case "changeSpec":
          this.changeSpec = "";
          break;
        case "changeOther":
          this.changeOther = "";
          break;
      }
      Swal.fire({
        title: '檔案類型不正確!',
        icon: 'error'
      });
    }
  }


  async refresh() {
    (await this.formTaskProcessApiService.getFormTaskDetailData(this.formTaskId, this.taskStatusId)).subscribe((res) => {
      this.detailData = res;
      this.validate();
    })
  }

  handleMainMenuAction() {
    this.formSectionService.handleMainMenuAction(this.isExpandable, this.isMenuExtended)
  }

  viewPdf(url) {
    this.formSectionService.viewPdf(url);
  }

  changeRemark = "";

}
