<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>廠商關懷紀錄</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a [routerLink]="['/']" href="/">Home</a></li>
          <li class="breadcrumb-item active">廠商關懷紀錄</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h5 class="modal-title">關懷紀錄列表</h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-2">
            <div class="form-group">
              <label>維護採發</label>
              <ng-select2 [data]="s2Employees" [width]="'100%'"  [placeholder]="'請選擇'"
                (valueChanged)="onChange_Employees($event)" [allowClear]="true">
              </ng-select2>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label>選擇地區</label>
              <ng-select2 [data]="s2Locations" [width]="'100%'"  [placeholder]="'請選擇'"
                (valueChanged)="onChange_Locations($event)" [allowClear]="true" [options]="options">
              </ng-select2>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <label>選擇廠商</label>
              <ng-select2 [data]="s2Suppliers" [width]="'100%'"  [placeholder]="'請選擇'"
                (valueChanged)="onChange_Suppliers($event)" [allowClear]="true" [options]="options">
              </ng-select2>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>選擇日期</label>
              <input type="text" id="selectedDate"  ngxDaterangepickerMd [(ngModel)]="selected" class="form-control" [locale]="{applyLabel: '確定',clearLabel:'清除', format: 'YYYY/MM/DD'}"/>
            </div>
          </div>
          <div class="col-md-3">
              <button class="btn btn-info my-4" (click)="refreshData()"><i class="fa fa-search" ></i> 查詢</button>
          </div>
        </div>
        <div class="row">

          <div class="col-md-12">
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
              class="table table-striped table-bordered table-hover nowrap nowrap">
              <thead>
                <tr>
                  <th>關懷日期</th>
                  <th>廠商名稱</th>
                  <th>地區</th>
                  <th>採發人員</th>
                  <th>內容</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of Suppliers">
                  <td>{{ item.contentDate | date:'yyyy/MM/dd' }}</td>
                  <td>{{ item.supplierName }}</td>
                  <td>{{ item.locationCName  }}</td>
                  <td>{{ item.empName }}</td>
                  <td>{{ item.content }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
  </div>
  <!-- /.card -->
</section>
<!-- /.content -->
