<div class="card">
  <div class="card-header">
      <h5 class="card-title mt-2">
          {{title}}
      </h5>
      <div class="card-tools">
          <button  type="button" class="btn bnt-tool float-right mr-2" data-card-widget="collapse" (click)="handleMainMenuAction()">
              <i
                  [ngClass]="isMenuExtended ? 'fas fa-minus' : 'fas fa-plus'"
                  [@rotate]="isMenuExtended"
              ></i>
          </button>
      </div>
  </div>
  <div class="card-body" [@openClose]="isMenuExtended">
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label for=""><span class="text-danger">*</span>工程範圍上傳</label>
          <div>
            <button *ngIf="action.allowEdit" type="button" class="btn btn-info"
              (click)="openModal('scope', action)">範本製作</button>
            <button *ngIf="!(action.allowEdit)" type="button" class="btn btn-default"
              (click)="openModal('scope', action)">檢視</button>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label for=""><span class="text-danger">*</span>工程規範上傳</label>
          <div>
            <button *ngIf="action.allowEdit" type="button" class="btn btn-info"
              (click)="openModal('rule', action)">範本製作</button>
            <button *ngIf="!(action.allowEdit)" type="button" class="btn btn-default"
              (click)="openModal('rule', action)">檢視</button>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for=""><span class="text-danger">*</span>採購範圍圖說上傳</label>
          <div *ngIf="action.allowEdit">
            <div class="input-group">
              <div class="custom-file">
                <input #fileUpload type="file" class="custom-file-input" accept=".pdf"
                  (change)="illustrationFileSelected($event)">
                <label class="custom-file-label" for="fileUpload">{{illustration ? illustration :
                  '請上傳檔案'}}</label>
              </div>

            </div>
            <div *ngIf="loaded !== 0 && load_selected === 'illustration'" class="progress my-3">
              <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                attr.aria-valuenow="{{ loaded }}" aria-valuemin="0" aria-valuemax="100"
                [ngStyle]="{ width: loaded+ '%' }">
                {{ loaded }}%
              </div>
            </div>
          </div>
          <div>
            <button *ngIf="!(action.allowEdit)" type="button" class="btn btn-default"
              (click)="viewPdf(illustration)">檢視</button>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="">計價數量計算式</label>
          <div *ngIf="action.allowEdit">
            <div class="input-group">
              <div class="custom-file">
                <input #fileUpload type="file" class="custom-file-input" accept=".pdf"
                  (change)="calculationFileSelected($event)">
                <label class="custom-file-label" for="fileUpload">{{calculation ? calculation : '請上傳檔案'}}</label>
              </div>
            </div>
            <div *ngIf="loaded !== 0 && load_selected === 'calculation'" class="progress my-3">
              <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                attr.aria-valuenow="{{ loaded }}" aria-valuemin="0" aria-valuemax="100"
                [ngStyle]="{ width: loaded+ '%' }">
                {{ loaded }}%
              </div>
            </div>
          </div>
          <div>
            <button *ngIf="!(action.allowEdit)" type="button" class="btn btn-default"
              (click)="viewPdf(calculation)">檢視</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 form-group">
        <label for="">備註</label>
        <ng-container *ngIf="action.allowEdit">
          <textarea class="form-control" (focusout)="addRemark()" (input)="remarkChange($event)" [value]="remark"
            (ngModel)="remark" cols="30" rows="3">
                </textarea>
        </ng-container>
        <ng-container *ngIf="!(action.allowEdit)">
          <textarea disabled class="form-control" (focusout)="addRemark()" (input)="remarkChange($event)"
            [value]="remark"  (ngModel)="remark" cols="30" rows="3">
                </textarea>
        </ng-container>

      </div>
    </div>



  </div>
</div>







