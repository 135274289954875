<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>部門人員管理</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a [routerLink]="['/']" href="/">Home</a></li>
          <li class="breadcrumb-item active">員工管理</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-3">
      <!-- Main content -->
      <section class="content">
        <!-- Default box -->

          <div class="card">
            <div class="card-header">
              <h5 class="card-title mt-2">部門組織</h5>
              <button type="button" class="btn btn-default float-right" (click)="showAll()">
                全部人員
              </button>
            </div>
            <div class="card-body">
              <div #jstree id="jstree">
              </div>

              <input id="selectDepartmentNode" type="hidden" class="form-control">
            </div>
            <!-- /.card-body -->

        </div>
        <!-- /.card -->
      </section>
      <!-- /.content -->

    </div>
    <div class="col-md-9">
      <!-- Main content -->
      <section class="content">
        <!-- Default box -->

          <div class="card">
            <div class="card-header">
              <button type="button" *ngIf="this.RightObj['insert']" class="btn btn-info float-right" (click)="open('add', null)">
                <i class="fas fa-plus"></i> 新增
              </button>
              <h5 class="card-title mt-2">人員資料</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label>證照類別</label>
                    <ng-select2 [data]="s2LicenseType" [width]="'100%'"  [placeholder]="'請選擇'"
                      (valueChanged)="onChange_LicenseType($event)"  [allowClear]="true">
                    </ng-select2>
                  </div>
                </div>
                <div class="col-md-3">
                  <button class="btn btn-info my-4" (click)="showAll()"><i class="fa fa-search" ></i> 查詢</button>
              </div>
              </div>
<br/>
              <div class="row">
                <div class="col-md-12">
                  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                    class="table table-striped table-bordered table-hover nowrap">
                    <thead>
                      <tr>
                        <th>員工編號</th>
                        <th>員工名稱</th>
                        <th>部門職務</th>
                        <th>主管</th>
                        <th>證照</th>
                        <th>信箱</th>
                        <th>到職日</th>
                        <th *ngIf="this.RightObj['update']">編輯</th>
                        <th>檢視</th>
                        <th *ngIf="this.RightObj['delete']" >停用</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of employees">
                        <td>{{ item.account }}</td>
                        <td>{{ item.empName}}</td>
                        <td>
                          {{ item.departmentName}}
                          <br>
                          <span class="badge bg-info" style="margin-right: 5px;" *ngFor="let job of item.jobListName">
                            {{job}}
                          </span>
                        </td>
                        <td>{{ item.boss === true ? "Y" : "N" }}</td>
                        <td>
                          <span class="badge bg-secondary" style="margin-right: 5px;"
                            *ngFor="let license of item.LicenseCountObj">
                            {{license.LicenseTypeName}} x {{license.Count}}
                          </span>
                        </td>
                        <td>{{ item.email }}</td>
                        <td>{{ item.onBoardDate | date:'yyyy/MM/dd'}}</td>
                        <td *ngIf="this.RightObj['update']">
                          <button *ngIf="this.RightObj['update']" type="button" data-widget="editData" data-toggle="tooltip" title="修改員工"
                            class="btn btn-default" (click)="open('edit', item)">編輯</button>
                        </td>
                        <td>
                          <button type="button" data-widget="editData" data-toggle="tooltip" title="檢視員工"
                            class="btn btn-default" (click)="open('view', item)">檢視</button>
                          <!-- <button type="button" class="btn btn-tool text-danger" data-widget="addData" data-toggle="tooltip"
                        title="刪除員工" (click)="open('delete', item)">
                        <i class="fas fa-trash-alt"></i>
                      </button> -->
                        </td>

                        <td *ngIf="this.RightObj['delete']" >
                          <button type="button" *ngIf="item.lockDate === null && this.RightObj['delete']" data-widget="disabledData"
                            data-toggle="tooltip" title="停用員工" class="btn btn-default"
                            (click)="open('disabled', item)">停用</button>

                          <code *ngIf="item.lockDate !== null">{{item.lockDate | date:'yyyy/MM/dd'}} </code>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- /.card-body -->
          </div>

        <!-- /.card -->
      </section>
      <!-- /.content -->
    </div>
  </div>
</div>
