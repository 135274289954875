import { FormSectionService } from '@services/form-section.service';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { PurchaseFormEditComponent } from './purchase-form-edit/purchase-form-edit.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  openCloseAnimation,
  rotateAnimation
} from '../../../../../../app/pages/form-task-process/detail-section/detail-section.animations';
import { ApiService } from '@services/api.service';
import { HelperService } from '@services/helper.service';
import { api_url } from '../../../../../services/api_config';

@Component({
  selector: 'app-purchase-form-process-section1',
  templateUrl: './purchase-form-process-section1.component.html',
  styleUrls: ['./purchase-form-process-section1.component.scss'],
  animations: [openCloseAnimation, rotateAnimation]
})
export class PurchaseFormProcessSection1Component implements OnInit {
  @Input() formAndProjectData;
  @Input() detailData;
  @Output() refreshFormTaskDetailData = new EventEmitter<object>();

  public isExpandable: boolean = false;
  public isMainActive: boolean = false;
  public isOneOfChildrenActive: boolean = false;
  @Input() parentComp;
  @Input() title;
  @Input() formTaskNo;
  @Input() action;
  @Input() isMenuExtended;
  @Input() component;
  @Input() formTaskId;

  public workTypeName;
  api_url = api_url;

  constructor(
    public apiService: ApiService,
    public formSectionService: FormSectionService,
    private modalService: NgbModal,
    public helperService: HelperService
  ) { }

  ngOnInit(): void {
    this.isExpandable = true;
    this.formSectionService.calculateIsActive(this.isMenuExtended);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.detailData != null && changes.detailData.currentValue) {
      this.ddlWorkType(
        this.detailData?.formTaskContent?.purchaseForm?.workType?._id
      );
    }
    if (changes.formTaskNo != null && changes.formTaskNo.currentValue) {
      var newValue = changes.formTaskNo.currentValue;
      if (newValue != '(undefined)') {
        this.title = this.title.replace('{purchaseNumber}', newValue);
      }
    }
  }

  AllWorkType = [];
  async ddlWorkType(current) {
    if (current != null) {
      (await this.apiService.getWorkTypes()).subscribe((res) => {
        this.AllWorkType = (res as any).sort((a, b) => a.sort - b.sort);
        var element = this.AllWorkType.filter(
          (w) => w.enable && w._id == current
        )[0];
        if (element['enable'] && element['_id'] === current) {
          var selectText = element['workTypeName'];
          if (
            element['rootWorkTypeId'] != '' &&
            element['rootWorkTypeId'] != null
          ) {
            selectText = this.helperService.getRootWorkType(
              this.AllWorkType,
              selectText,
              element['rootWorkTypeId']
            );
          }
          this.workTypeName = selectText;
        }
      });
    }
  }

  handleMainMenuAction() {
    this.formSectionService.handleMainMenuAction(
      this.isExpandable,
      this.isMenuExtended
    );
  }

  openModal(action) {
    let modalRef = null;
    if (action == 'purchase-form-edit') {
      modalRef = this.modalService.open(PurchaseFormEditComponent, {
        windowClass: 'purchase-form-edit'
      });
      modalRef.componentInstance.parentComp = this.parentComp;
      modalRef.closed.subscribe((result) => {
        this.refreshFormTaskDetailData.emit();
      });
    }
  }
}
