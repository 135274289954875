import { filter } from 'rxjs/operators';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '@services/api.service';
import { Subject } from 'rxjs';
import {
  NgbModalConfig,
  NgbModal,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import { WorkTypeAddComponent } from './work-type-add/work-type-add.component';
import { WorkTypeEditComponent } from './work-type-edit/work-type-edit.component';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { RightService } from '@services/right.service';
import { HelperService } from '@services/helper.service';
declare var $: any;

@Component({
  selector: 'app-work-type-manage',
  templateUrl: './work-type-manage.component.html',
  styleUrls: ['./work-type-manage.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class WorkTypeManageComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  getRight: any;
  RightObj = { "insert": false, "update": false, "delete": false };

  public workTypes;

  dtTrigger: Subject<any> = new Subject<any>();

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  constructor(
    public apiService: ApiService,
    public rightService: RightService,
    public helperService: HelperService,
    config: NgbModalConfig,
    private modalService: NgbModal
  ) {
    config.backdrop = true;
    config.keyboard = false;
    config.centered = true;

    this.getRight = this.rightService.getRight();

  }
  @ViewChild('jstree') jstree: ElementRef;

  ngOnInit(): void {
    var order = [[2, 'asc']];
    this.dtOptions = this.helperService.setDtOptions(order);
    this.RightObj = this.getRight['__zone_symbol__value'];

    this.reloadData();
    this.getWorkTypeTree();
  }

  async reloadData() {
    (await this.apiService.getWorkTypes()).subscribe((res) => {
      this.workTypes = res as any;
      this.dtTrigger.next(null);
    });
  }

  async refreshData(id) {
    $('#jstree').jstree('select_node', id);
    $("#jstree").jstree("open_node", id);

    var WorkTypeList = [];
    this.getWorkTypeTreeList(WorkTypeList, id);

    (await this.apiService.getWorkTypes()).subscribe((res) => {
      this.workTypes = res as any;
      if(id != null){
        this.workTypes = this.workTypes.filter(w => w.rootWorkTypeId == id);
      }
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next(null);
      });
    });
  }

  getWorkTypeTreeList(WorkTypeList, id) {
    if (WorkTypeList.indexOf(id) == -1) {
      WorkTypeList.push(id);
      this.workTypes.forEach((element) => {
        if (element['rootWorkTypeId'] == id) {
          this.getWorkTypeTreeList(WorkTypeList, element['_id']);
        }
      })
    }
  }

  async getWorkTypeTree() {
    var component = this;
    (await this.apiService.getWorkTypes()).subscribe((res) => {
      var AllWorkType = res as any;
      AllWorkType = AllWorkType.sort((a, b) => a.sort - b.sort)
      var TreeCode = "<ul>";
      AllWorkType.forEach(element => {
        if (element['level'] === 1) {
          var NodeName = element['workTypeName'];
          TreeCode += "<li id='" + element['_id'] + "'  data-jstree='{\"icon\":\"fa fa-object-group\"}'>" + NodeName;
          var ChildObj = AllWorkType.filter((node) => node.rootWorkTypeId == element['_id'])
          if (ChildObj.length > 0) {
            var ChildResult = this.ChildCode(AllWorkType, ChildObj, TreeCode);
            TreeCode = ChildResult;
          }
        }
        TreeCode += "</li>";
      })
      TreeCode += " <ul>";

      this.jstree.nativeElement.insertAdjacentHTML('beforeend', TreeCode);

      //初始化jstree
      $(function () {
        $('#jstree').jstree({ "core": { multiple: false } }).on('ready.jstree', function () { $(this).jstree('open_all') });
        $('#jstree').on("changed.jstree", function (e, data) {
          if (data.node !== undefined) {
            $("#selectNode").val(data.node.id);
            component.refreshData(data.node.id);
          }
        });
      });
    });
  }

  ChildCode(AllWorkType, ChildObj, TreeCode) {
    ChildObj.forEach(element => {
      TreeCode += "<ul>";
      var NodeName = element['workTypeName'];
      TreeCode += "<li id='" + element['_id'] + "'  data-jstree='{\"icon\":\"fa fa-gavel\"}'>" + NodeName;
      var ChildObj = AllWorkType.filter((node) => node.rootWorkTypeId == element['_id']);
      if (ChildObj.length > 0) {
        var ChildResult = this.ChildCode(AllWorkType, ChildObj, TreeCode);
        TreeCode = ChildResult;
      }
      TreeCode += "</li>";
      TreeCode += "</ul>";
    });
    return TreeCode;
  }

  showAll() {
    $("#jstree").jstree("deselect_all");
    this.refreshData(null);
  }



  open(type, workType) {
    if (type == 'add') {
      const modalRef = this.modalService.open(WorkTypeAddComponent, { size: 'lg' });
      modalRef.componentInstance.selectNode = $("#selectNode").val();
      modalRef.closed.subscribe((result) => {
        $("#jstree").jstree("deselect_all");
        this.refreshData(result);
      });
    } else if (type == 'edit') {
      const modalRef = this.modalService.open(WorkTypeEditComponent, { size: 'lg' });
      modalRef.componentInstance.workType = workType;
      modalRef.closed.subscribe((result) => {
        $("#jstree").jstree("deselect_all");
        this.refreshData(result);
      });
      // } else if (type == 'delete') {
      //   Swal.fire({
      //     title: '確定要刪除工項類型?',
      //     showConfirmButton: true,
      //     showCancelButton: true,
      //     confirmButtonText: '確定',
      //     confirmButtonColor: '#17A2B8',
      //     cancelButtonText: '取消',
      //     icon: 'question'
      //   }).then((result) => {
      //     if (result.isConfirmed) {
      //       this.apiService.deleteWorkTypes(workType._id).subscribe((res) => {
      //         this.refreshData();
      //       });
      //     }
      //   });
      // }
    } else if (type == 'disabled') {
      Swal.fire({
        title: '確定要停用工項類型?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: '確定',
        confirmButtonColor: '#17A2B8',
        cancelButtonText: '取消',
        icon: 'question'
      }).then((result) => {
        if (result.isConfirmed) {
          this.apiService.editWorkTypes(workType._id, {
            workTypeName: workType.workTypeName, rootWorkTypeId: workType.rootWorkTypeId,
            sort: workType.sort,
            level: workType.level, enable: false
          }).subscribe((res) => {
            $("#jstree").jstree("deselect_all");
            this.refreshData(workType._id);
          });
        }
      });
    }

  }
}
