import { DailyPictureListComponent } from './daily-picture-list/daily-picture-list.component';
import { ProjectApiService } from '@services/projectapi.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '@services/api.service';
import { RightService } from '@services/right.service';
import { Subject } from 'rxjs';
import {
  NgbModalConfig,
  NgbModal,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { HelperService } from '@services/helper.service';
import { Select2OptionData } from 'ng-select2';
import { api_url } from '../../services/api_config';

@Component({
  selector: 'app-daily-picture',
  templateUrl: './daily-picture.component.html',
  styleUrls: ['./daily-picture.component.scss']
})
export class DailyPictureComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  getRight: any
  RightObj: {};

  public dailyReport;
  public projectId;

  dtTrigger: Subject<any> = new Subject<any>();

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  constructor(
    public rightService: RightService,
    public helperService: HelperService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    public ProjectApiService: ProjectApiService
  ) {
    config.backdrop = true;
    config.keyboard = false;
    config.centered = true;

    this.getRight = this.rightService.getRight();

  }

  ngOnInit(): void {
    var order = [[0, 'desc']];
    this.dtOptions = this.helperService.setDtOptions(order);
    this.RightObj = this.getRight['__zone_symbol__value'];
    this.ddlProject();
    this.reloadData();

  }

  async reloadData() {
    (await this.ProjectApiService.getDailyReport(this.projectId)).subscribe((res) => {
      this.dailyReport = res as any;
      this.dtTrigger.next(null);
    });
  }

  async refreshData() {
    (await this.ProjectApiService.getDailyReport(this.projectId)).subscribe((res) => {
      this.dailyReport = res as any;
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next(null);
      });
    });
  }


  s2Projects: Select2OptionData[];
  async ddlProject() {
    let arrProjects = [];
    let k = 0;
    (await this.ProjectApiService.getProjects()).subscribe((res) => {
      var Projects = res as any;
      Projects.forEach(element => {
        arrProjects.push({ id: element['_id'], text: element['projectName'] });
        k++;
        if (k == Projects.length) {
          this.s2Projects = arrProjects;
          this.projectId = this.s2Projects[0]['id'];
          this.refreshData();
        }
      })

    });
  }

  onChange_Projects(val: String): void {
    this.projectId = val;
  }

  open(dailyDate, workImgList) {
    const modalRef = this.modalService.open(DailyPictureListComponent, { size: 'xl' });
    modalRef.componentInstance.dailyDate = dailyDate;
    modalRef.componentInstance.workImgList = workImgList;
    modalRef.closed.subscribe((result) => {
      this.refreshData();
    });
  }

  downloadworkImg(workImgList,projectShortName,dailyDate) {
    var workImgStr = "";
    workImgList.forEach(element => {
      workImgStr += element['workImg'] + ",";
    })
    workImgStr = workImgStr.replace(/,+$/, '');
    var fn = projectShortName + "_" + this.helperService.formatDate(dailyDate) + "施工照片";
    window.open(api_url + "/downloadZip?path=" + workImgStr + "&fileName=" + fn);

  }
}
