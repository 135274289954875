<div class="modal-header">
    <h4 class="modal-title">忘記密碼</h4>
    <button type="button" class="btn btn-tool" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <i class="fas fa-times"></i>
    </button>
</div>
<div class="modal-body">
    <p class="login-box-msg">
        請輸入員工編號，系統會將密碼寄至您的公司信箱.
    </p>
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()">
        <div class="input-group mb-3">
            <input
                formControlName="account"
                type="text"
                class="form-control"
                placeholder="請輸入員工編號"
                required
                [id]="'modal_input0'"
                (keyup.enter)="focusNext(0)"
            />
            <div class="input-group-append">
                <div class="input-group-text">
                    <span class="fas fa-user"></span>
                </div>
            </div>
        </div>
        <div class="input-group mb-3">
            <input
                formControlName="personalNo"
                type="text"
                class="form-control"
                placeholder="請輸入身分證字號"
                required
                [id]="'modal_input1'"
                (keyup.enter)="focusNext(1)"
            />
            <div class="input-group-append">
                <div class="input-group-text">
                    <span class="fas fa-id-card"></span>
                </div>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-12">
                <pf-button [type]="'submit'" [block]="true">
                    Request new password
                </pf-button>
            </div>
        </div> -->
    </form>
</div>
<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">關閉</button>
    <button type="submit" (click)="forgotPassword()" class="btn btn-primary">確定</button>
    <!-- <button type="button" class="btn btn-info" (click)="forgotPassword()">Save</button> -->
</div>
