<div class="card">
  <div class="card-header">
    <h5 class="card-title mt-2">
      {{title}}
    </h5>
    <div class="card-tools">
      <button type="button" class="btn bnt-tool float-right mr-2" data-card-widget="collapse"
        (click)="handleMainMenuAction()">
        <i [ngClass]="isMenuExtended ? 'fas fa-minus' : 'fas fa-plus'" [@rotate]="isMenuExtended"></i>
      </button>
    </div>
  </div>
  <div class="card-body" [@openClose]="isMenuExtended">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for=""><span class="text-danger">*</span>對業主報價單製本</label>
          <div *ngIf="action.allowEdit">
            <div class="input-group">
              <div class="custom-file">
                <input #fileUpload type="file" class="custom-file-input" accept=".pdf"
                  (change)="FileSelected($event,'customerBudget')">
                <label class="custom-file-label" for="fileUpload">{{customerBudget ? customerBudget :
                  '請上傳檔案'}}</label>
              </div>

            </div>
            <div *ngIf="loaded !== 0 && load_selected === 'customerBudget'" class="progress my-6">
              <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                attr.aria-valuenow="{{ loaded }}" aria-valuemin="0" aria-valuemax="100"
                [ngStyle]="{ width: loaded+ '%' }">
                {{ loaded }}%
              </div>
            </div>
          </div>
          <div>
            <button *ngIf="!(action.allowEdit)" type="button" class="btn btn-default"
              (click)="viewPdf(customerBudget)">檢視</button>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for=""><span class="text-danger">*</span>業主簽認單</label>
          <div *ngIf="action.allowEdit">
            <div class="input-group">
              <div class="custom-file">
                <input #fileUpload type="file" class="custom-file-input" accept=".pdf"
                  (change)="FileSelected($event,'customerSayYes')">
                <label class="custom-file-label" for="fileUpload">{{customerSayYes ? customerSayYes : '請上傳檔案'}}</label>
              </div>
            </div>
            <div *ngIf="loaded !== 0 && load_selected === 'customerSayYes'" class="progress my-6">
              <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                attr.aria-valuenow="{{ loaded }}" aria-valuemin="0" aria-valuemax="100"
                [ngStyle]="{ width: loaded+ '%' }">
                {{ loaded }}%
              </div>
            </div>
          </div>
          <div>
            <button *ngIf="!(action.allowEdit)" type="button" class="btn btn-default"
              (click)="viewPdf(customerSayYes)">檢視</button>
          </div>
        </div>
      </div>

    </div>



  </div>
</div>
