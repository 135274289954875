<div class="modal-header">
  <h4 class="modal-title">上傳合約</h4>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close"
    (click)="activeModal.close('Close click')">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">
  <form class="form-horizontal" [formGroup]="createForm" method="post" action="">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label>合約類型</label>
          <ng-container *ngIf="actions.allowEdit">
            <ng-select2 width="100%" (valueChanged)="valueChanged($event)" [value]="contract.contractTypeId">
              <option *ngFor="let data of contractTypes" [value]="data._id">{{data.contractTypeName}}</option>

            </ng-select2>
          </ng-container>

          <ng-container *ngIf="!(actions.allowEdit)">
            <ng-select2 width="100%" (valueChanged)="valueChanged($event)" [value]="contract.contractTypeId"
              disabled="true">
              <option *ngFor="let data of contractTypes" [value]="data._id">{{data.contractTypeName}}</option>

            </ng-select2>
          </ng-container>


        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>合約簽定日</label>
          <ng-container *ngIf="actions.allowEdit">
            <input type="date" formControlName="contractDate" class="form-control">
          </ng-container>
          <ng-container *ngIf="!(actions.allowEdit)">
            <input type="date" formControlName="contractDate" class="form-control" disabled="true">
          </ng-container>

        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="">上傳已蓋印廠商合約</label>
          <div *ngIf="actions.allowEdit">
            <div class="input-group">
              <div class="custom-file">
                <input #fileUpload type="file" class="custom-file-input" accept=".pdf"
                  (change)="contractFileSelected($event)">
                <label class="custom-file-label" for="fileUpload">{{contractFileName ? contractFileName :
                  '請上傳pdf檔'}}</label>
              </div>
            </div>
            <div *ngIf="loaded !== 0 && load_selected === 'contractFile'" class="progress my-3">
              <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                attr.aria-valuenow="{{ loaded }}" aria-valuemin="0" aria-valuemax="100"
                [ngStyle]="{ width: loaded+ '%' }">
                {{ loaded }}%
              </div>
            </div>
          </div>
          <div *ngIf="!(actions.allowEdit)">
            <button type="button" (click)="viewPdf(contract.contractFile)"
              class="btn btn-default">檢視
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
          class="table table-striped table-bordered table-hover nowrap">
          <thead>
            <tr>
              <th>物料編號</th>
              <th>物料名稱</th>
              <th>圖說編號</th>
              <th>規格</th>
              <th>申購數量</th>
              <th>單位</th>
              <th>單價</th>
              <th>小計</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let datas of gridData;">
              <td>{{datas.pccesId}}</td>
              <td>{{datas.description}}</td>
              <td>{{datas.diagramNo}}</td>
              <td>{{datas.spec}}</td>
              <td>{{datas.quantity}}</td>
              <td>{{datas.unit}}</td>
              <td>{{datas.price}}</td>
              <td>{{datas.summary?datas.summary:'0'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer justify-content-between">
  <div>
    <button type="button" class="btn btn-default" data-dismiss="modal"
      (click)="activeModal.close('Close click')">關閉</button>
  </div>
  <div>
    <a href="{{api_url}}/pdf/getPurchaseContract/{{inquiryDataId}}/1" target="_blank" type="button" data-widget=""
      data-toggle="tooltip" title="下載合約" class="btn btn-success mr-2">下載合約</a>
    <button *ngIf="actions.allowEdit" type="button" class="btn btn-info" (click)="saveContractDetail()">確定</button>
  </div>
</div>
