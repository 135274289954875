import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { FormtaskapiService } from '@services/formtaskapi.service';
import { HelperService } from '@services/helper.service';

@Component({
  selector: 'app-form-sign-flow',
  templateUrl: './form-sign-flow.component.html',
  styleUrls: ['./form-sign-flow.component.scss']
})
export class FormSignFlowComponent implements OnInit {
  @Input() _id;
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  process = [];

  constructor(
    public activeModal: NgbActiveModal,
    public apiService: FormtaskapiService,
    public helperService: HelperService,
  ) { }

  ngOnInit(): void {
    this.dtOptions = this.helperService.setDtOptions();
    this.reloadData();

  }

  async reloadData() {
    this.process = [];
    (await this.apiService.getFormTaskProcess(this._id)).subscribe((res) => {
      var element = (res as any)[0];

      var creatorP = { "current": false, "depName": element['creatorDepName'], empName: element['creatorName'], finishTime: element['beginTime'] , siteResult: '起單',resultComment:element['comment'] };
      this.process.push(creatorP);
      var k = 0;
      res[0]['process'].forEach(element2 => {
        switch (element2['siteResultId']) {
          case 'result0':
            element2['siteResult'] = '起單';
            break;
          case 'result1':
            element2['siteResult'] = '同意';
            break;
          case 'result2':
            element2['siteResult'] = '否決';
            break;
          case 'result3':
            element2['siteResult'] = '退回';
            break;
        }
        k++;
        if (k == res[0]['process'].length) {
          if(element2['siteResultId'] == 'result1'){
            element2 = { "current": element2['current'], "depName": element2['depName'], empName: element2['empName'], finishTime: element2['finishTime'],resultComment:element2['resultComment'],siteResult:'結案' };
          }else{
            element2 = { "current": element2['current'], "depName": element2['depName'], empName: element2['empName'], finishTime: element2['finishTime'],resultComment:element2['resultComment'] };
          }
        }
        this.process.push(element2);

      });
      this.dtTrigger.next(null);
    });
  }




}
