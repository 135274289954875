<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>材料送審</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a [routerLink]="['/']" href="/">Home</a></li>
          <li class="breadcrumb-item active">材料送審資料</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h5 class="modal-title">材料送審資料</h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="alert alert-info alert-dismissible">
              <h5 class="mt-2"> 全部 {{totalItem}} 項，審核通過 {{checkItem}} 項，完成率：{{checkPercent}}%</h5>
            </div>
          </div>
          <div class="col-md-12">
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
              class="table table-striped table-bordered table-hover nowrap">
              <thead>
                <tr>
                  <th>材料/設備名稱</th>
                  <th>數量</th>
                  <th>取樣試驗</th>
                  <th>送審日期</th>
                  <th>廠驗</th>
                  <th>協力廠商</th>
                  <th>型錄</th>
                  <th>試驗報告</th>
                  <th>樣品</th>
                  <th>其他</th>
                  <th>審查日</th>
                  <th>備註</th>
                  <th>編輯</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of materials">
                  <td>
                    {{item.materialItem}}<br>
                    {{item.materialName}}
                  </td>
                  <td>{{item.materialQuantity}}</td>
                  <td>{{item.getSampleTest === true ? "Y" : "N"}}</td>
                  <td>
                    預定 {{item.materialExpectDate}}<br>
                    實際 {{item.materialActualDate}}
                  </td>
                  <td>{{item.materialCheckDate !== null ? item.materialCheckDate : ""}}</td>
                  <td>
                    <button type="button" *ngIf="item.supplierFile !== null" data-widget="" data-toggle="tooltip"
                      title="檢視" class="btn btn-default"  (click)="download(item.supplierFile)">檢視</button>
                  </td>
                  <td>
                    <button type="button" *ngIf="item.coverFile !== null" data-widget="" data-toggle="tooltip"
                      title="檢視" class="btn btn-default"  (click)="download(item.coverFile)">檢視</button>
                  </td>
                  <td>
                    <button type="button" *ngIf="item.testReport !== null" data-widget="" data-toggle="tooltip"
                      title="檢視" class="btn btn-default"  (click)="download(item.testReport)">檢視</button>
                  </td>
                  <td>{{item.haveSample === true ? "Y" : "N"}}</td>
                  <td>{{item.materialOthers}} </td>
                  <td>{{item.materialReviewDate}}<br>
                    <span *ngIf="item.pass" class="badge bg-success">審核通過</span>
                    <span *ngIf="!item.pass" class="badge bg-danger">審核未通過 </span>
                  </td>
                  <td>{{item.materialRemark}}</td>
                  <td>
                    <button type="button" class="btn btn-tool" data-widget="editData" data-toggle="tooltip"
                      title="修改材料送審" (click)="open('edit', item)">
                      <i class="fas fa-edit"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
  </div>
  <!-- /.card -->
</section>
<!-- /.content -->
