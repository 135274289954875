import { HelperService } from './../../../../../services/helper.service';
import { Component, EventEmitter, Input, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { FormTaskProcessApiService } from '@services/form-task-process-api.service';
import { FormSectionService } from '@services/form-section.service';

import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { PccesAddComponent } from './pcces-add/pcces-add.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { openCloseAnimation, rotateAnimation } from '../../../../../../app/pages/form-task-process/detail-section/detail-section.animations'


@Component({
  selector: 'app-purchase-form-process-section2',
  templateUrl: './purchase-form-process-section2.component.html',
  styleUrls: ['./purchase-form-process-section2.component.scss'],
  animations: [openCloseAnimation, rotateAnimation]
})
export class PurchaseFormProcessSection2Component implements OnInit {

  public isExpandable: boolean = false;
  public isMainActive: boolean = false;
  public isOneOfChildrenActive: boolean = false;
  @Input() title;
  @Input() action;
  @Input() isMenuExtended;
  @Input() component;
  @Input() formTaskId;
  @Input() taskStatusId;
  dtOptions: DataTables.Settings = {};
  dtTrigger_pcces: Subject<any> = new Subject<any>();
  result: any;
  pccesData: any = [];

  constructor(
    public apiService: FormTaskProcessApiService,
    private toastr: ToastrService,
    public formSectionService: FormSectionService,
    private modalService: NgbModal,
    public helperService: HelperService
  ) { }
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  ngOnInit(): void {
    this.dtOptions = this.helperService.setDtOptions();

    this.isExpandable = true;
    this.formSectionService.calculateIsActive(this.isMenuExtended);
    this.reloadFormTaskDetailData();
  }

  handleMainMenuAction() {
    this.formSectionService.handleMainMenuAction(this.isExpandable, this.isMenuExtended)
  }


  async reloadFormTaskDetailData() {
    (await this.apiService.getFormTaskDetailData(this.formTaskId, this.taskStatusId)).subscribe((res) => {
      this.pccesData = res?.formTaskContent?.purchaseForm?.pccesDataList;

      localStorage.setItem('inputValidate2', "true");
      if (this.pccesData.length == 0) {
        localStorage.setItem('inputValidate2', "false");
      }
      this.dtTrigger_pcces.next(null);
    });
  }

  async refreshFormTaskDetailData() {
    (await this.apiService.getFormTaskDetailData(this.formTaskId, this.taskStatusId)).subscribe((res) => {
      this.pccesData = res?.formTaskContent?.purchaseForm?.pccesDataList;

      localStorage.setItem('inputValidate2', "true");
      if (this.pccesData.length == 0) {
        localStorage.setItem('inputValidate2', "false");
      }
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtElement.dtTrigger.next(null);
      });
    });
  }


  openModal(action) {
    let modalRef = null;
    if (action == 'pcces-add') {
      modalRef = this.modalService.open(PccesAddComponent, { windowClass: 'pcces-add' });
      modalRef.componentInstance.formTaskId = this.formTaskId;
      modalRef.componentInstance.refreshFormTaskDetailData.subscribe(($e) => {
        this.refreshFormTaskDetailData();
      })
    }
  }
  quantityChange(event, id) {
    var value = event.target.value;
    if(value < 0) {value=0;event.target.value=0;}
    let formData = {
      quantity: value,
      formTaskId: this.formTaskId,
      id: id
    }
    this.apiService.updatePccesDataQuantity(formData)
      .subscribe((res) => {
        this.result = res as any;
        if (this.result.status == false) {
          Swal.fire({
            title: this.result.message,
            icon: 'error'
          });
        } else {
          this.toastr.success("資料已儲存");
        }
      });

  }

  deletePccesData(id: any) {
    let formData = {
      id: id,
      formTaskId: this.formTaskId
    }
    this.apiService.deletePccesData(formData)
      .subscribe((res) => {
        this.result = res as any;
        if (this.result.status == false) {
          Swal.fire({
            title: this.result.message,
            icon: 'error'
          });
        } else {
          this.toastr.success("資料已刪除");
          this.refreshFormTaskDetailData();
        }
      });
  }
}
