import { outputAst } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormmngapiService } from '@services/formmngapi.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-form-flow-group',
  templateUrl: './form-flow-group.component.html',
  styleUrls: ['./form-flow-group.component.scss']
})
export class FormFlowGroupComponent implements OnInit {
  flowGroup = "";
  editGroupFlow = true;
  enable = true;
  @Input() form_id;
  @Output() reloadData = new EventEmitter<object>();
  constructor(
    public apiService: FormmngapiService,
  ) { }

  ngOnInit(): void {
    if (this.flowGroup == "") {
      this.flowGroup = "new";
    }
    setTimeout(() => {
      $(".fg")[0].focus();

    }, 100);
  }

  editGroupFlowE(e, item) {
    if (e.target.value != '') {
      this.editGroupFlow = !this.editGroupFlow;
      if (item == "") {
        item = "null"
      }
      this.apiService.editGroupFlow(this.form_id, item, e.target.value).subscribe((res) => {
        if (res.message == "ok") {
          e.path[1].firstElementChild.textContent = e.target.value;
          this.flowGroup = e.target.value;
          this.reloadData.emit({ d: e.target.value });
        } else {
          if (item == "new") {
            this.enable = false;
          }
          Swal.fire({
            title: res.message,
            icon: 'error',
            confirmButtonColor: "#17a2b8",
          });
        }
      })
    } else {
      Swal.fire({
        title: "副流程名稱不得為空",
        icon: 'error',
        confirmButtonColor: "#17a2b8",
      });
    }

  }

  switchGroup(flowGroup) {
    $(".groupTitleCard").each(function () {
      if ($(this)[0]['group'] == flowGroup) {
        $(this).addClass("alert-info")
        $(this).removeClass("alert-secondary")
      } else {
        $(this).removeClass("alert-info")
        $(this).addClass("alert-secondary")
      }
    })
    this.reloadData.emit({ d: flowGroup });
  }
}
