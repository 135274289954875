<div class="card">
  <div class="card-header">
    <h5 class="card-title mt-2">
      {{title}}
    </h5>
    <div class="card-tools">
      <button type="button" class="btn bnt-tool float-right mr-2" data-card-widget="collapse"
        (click)="handleMainMenuAction()">
        <i [ngClass]="isMenuExtended ? 'fas fa-minus' : 'fas fa-plus'" [@rotate]="isMenuExtended"></i>
      </button>
    </div>
  </div>
  <div class="card-body" [@openClose]="isMenuExtended">
    <div class="alert alert-info alert-dismissible">
      <h5 class="mt-2"> 累計追加金額：{{total1 | currency:'TWD': 'symbol':'1.0'}}元 ｜ 累計追減金額：{{total2 | currency:'TWD':
        'symbol':'1.0' }}元 ｜ 追加減總金額：{{total1+total2 | currency:'TWD': 'symbol':'1.0'}}元</h5>
    </div>

    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger_contractItem"
      class="table table-striped table-bordered table-hover nowrap" id="contractItem_table">
      <thead>
        <tr>
          <th>廠商名稱</th>
          <th>合約編號/工項</th>
          <th>聯絡人</th>
          <th>預付款</th>
          <th>總金額</th>
          <th>比例</th>
          <th>備註</th>
          <th>追加單價</th>
          <th>報價單 <i title="若有超過新台幣10萬的追加減，請回饋合約"
            class="fas fa-exclamation-triangle fa-fw text-danger"></i></th>
          <th>合約上傳</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let datas of detailData?.formTaskContent?.addForm?.contractItemList;">
          <td>{{datas.businessNo}}<br>{{datas.supplierName}}</td>
          <td><span class="badge badge-info right">{{datas.contractNo}}</span><br>{{datas.workTypeName}}</td>
          <td>{{datas.contactName}}<br>{{datas.contactPhone}}</td>
          <td>
            <input *ngIf="action.allowEdit" type="number" name="prePay" placeholder="預付款" [value]="datas.prePay"
              class="form-control  updateDataByColumn updateDataByListColumn" data-label="預付款"
              data-subdocName="formTaskContent.addForm.contractItemList" data-subdocId="{{datas._id}}"
              data-subdocumnet_type="list" readonly>
            <span *ngIf="!action.allowEdit">{{datas.prePay | currency:'TWD': 'symbol':'1.0'}}</span>
          </td>
          <td>{{datas.total | currency:'TWD': 'symbol':'1.0'}}</td>
          <td>{{datas.percent}}</td>
          <td>{{datas.remark}}</td>
          <td>
            <button (click)="openModal('setPrice',datas)" type="button" class="btn btn-default mr-2">
              檢視
            </button>
          </td>
          <td>
            <button type="button" (click)="viewPdf(datas.quotation)"
              class="btn btn-default">檢視
            </button>
          </td>
        <td>
           <!--    <label *ngIf="datas.subContractNo !== null">{{datas.subContractNo}}</label>
            <br *ngIf="datas.subContractNo !== null">
            <button *ngIf="datas.subContractNo === null && action.allowEdit" (click)="createContractNo(datas)"
              type="button" class="btn btn-default mr-2">
              取號
            </button>-->

            <button *ngIf="action.allowEdit" (click)="openModal('contract',datas)"
              type="button" class="btn btn-info mr-2">
              編輯
            </button>

            <button *ngIf="!action.allowEdit" (click)="openModal('contract',datas)"
              type="button" class="btn btn-default mr-2">
              檢視
            </button>

          </td>

        </tr>
      </tbody>
    </table>


  </div>
</div>
