<div #ChangePriceList>
  <div class="row"  id="c_{{k1}}">
    <div class="col-md-3">
      <input type="date" class="form-control"  name="ChangePrice[{{k1}}].changeDate" >
    </div>
    <div class="col-md-3">
      <input type="text" class="form-control"  name="ChangePrice[{{k1}}].changeReason">
    </div>
    <div class="col-md-2">
     <select class="form-control" name="ChangePrice[{{k1}}].math">
      <option value="0">增加</option>
      <option value="1">減少</option>
     </select>
    </div>
    <div class="col-md-3">
      <input type="number" class="form-control"  name="ChangePrice[{{k1}}].changePrice">
      <br>
    </div>
    <div class="col-md-1">
      <button type="button" class="btn btn-tool text-danger" data-toggle="tooltip" title="刪除"
      (click)="del()">
      <i class="fas fa-trash-alt"></i>
    </button>
      <br>
    </div>
  </div>

</div>
