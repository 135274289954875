<div #EventList>
    <div class="row" id="e_{{formFlowDataId}}_{{k}}">
        <div class="col-md-3">
            <div class="form-group">
                <select class="form-control" name="eventList_{{formFlowDataId}}[{{k}}].siteResultId" class="form-control">
                    <option value="result1">同意(Approve)</option>
                    <option value="result2">否決(Reject)</option>
                    <option value="reslut3">退回(Back)</option>
                    <option value="result0">流程起始(Begin)</option>
                </select>
            </div>
        </div>
        <div class="col-md-5">
            <div class="form-group">
                <input type="text" class="form-control" name="eventList_{{formFlowDataId}}[{{k}}].handleClass" class="form-control">
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <input type="text" class="form-control" name="eventList_{{formFlowDataId}}[{{k}}].remark" class="form-control">
            </div>
        </div>
        <div class="col-md-1">
            <div class="form-group">
                <button type="button" class="btn btn-tool text-danger" data-toggle="tooltip" title="刪除"
                    (click)="del(formFlowDataId,k)">
                    <i class="fas fa-trash-alt"></i>
                </button>
            </div>
        </div>
    </div>
</div>
