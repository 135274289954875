<div class="modal-header">
    <h4 class="modal-title">表單區塊</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="formSection">
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label>表單區塊編號</label>
                    <input type="text" formControlName="formSectionNo" class="form-control" placeholder="auto numbering" disabled>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>表單區塊名稱</label>
                    <input type="text" formControlName="formSectionName" class="form-control" required>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>區塊順序</label>
                    <input type="number" formControlName="sort" class="form-control" required>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group" style="text-align:center">
                    <label></label>
                    <div style="margin-top: -5px;">
                        <span class="mt-1 mr-2 btn btn-info" (click)="saveFormSection('add')"><i class="fas fa-plus"></i></span>
                        <span class="mt-1 btn btn-primary" (click)="saveFormSection('edit')"><i class="fas fa-edit"></i></span>
                    </div>
                </div>
            </div>
      </div>
    </form>
    <div class="card-body">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="formsection_table"
            class="table table-striped table-bordered table-hover">
            <thead>
                <tr>
                <th>表單區塊編號</th>
                <th>表單區塊名稱</th>
                <th>區塊順序</th>
                <th>編輯</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let datas of tableData">
                    <td>
                    {{datas.formSectionNo}}
                    </td>
                    <td>
                    {{datas.formSectionName}}
                    </td>
                    <td>
                    {{datas.sort}}
                    </td>
                    <td>
                        <a style="cursor: pointer;" (click)="editFormSection(datas)"><i class="fas fa-edit mr-2"></i></a>
                        <a style="cursor: pointer;" (click)="deleteFormSection(datas._id)"><i class="fas fa-trash-alt text-danger mr-2"></i></a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-default btn-outline-dark" (click)="activeModal.close('Close click')">關閉</button>
    <!-- <button class="btn btn-primary" (click)="saveFormSection('add')">確定</button> -->
</div>
