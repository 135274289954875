<div class="modal-header">
  <h4 class="modal-title">上傳合約
  </h4>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close"
    (click)="activeModal.close('Close click')">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <label>簽約日期</label>
        <input *ngIf="action.allowEdit" type="date" [(ngModel)]="subContractDate" class="form-control">
        <input *ngIf="!action.allowEdit" type="date" [(ngModel)]="subContractDate" class="form-control" disabled="true">
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label for="">合約上傳</label>
        <div *ngIf="action.allowEdit">
          <div class="input-group">
            <div class="custom-file">
              <input #fileUpload type="file" class="custom-file-input" accept=".pdf"
                (change)="contractFileSelected($event)">
              <label class="custom-file-label" for="fileUpload">{{subContractFile ? subContractFile :
                '請上傳pdf檔'}}</label>
            </div>
          </div>
          <div *ngIf="loaded !== 0 && load_selected === 'contractFile'" class="progress my-3">
            <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
              attr.aria-valuenow="{{ loaded }}" aria-valuemin="0" aria-valuemax="100"
              [ngStyle]="{ width: loaded+ '%' }">
              {{ loaded }}%
            </div>
          </div>
        </div>
        <div *ngIf="!(action.allowEdit)">
          <button type="button" (click)="viewPdf(subContractFile)"
            class="btn btn-default">檢視
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer justify-content-between">

  <button *ngIf="action.allowEdit" type="button" class="btn btn-info" (click)="save()">確定</button>

</div>
