<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="addform_table_{{projectId}}"
  class="table table-striped table-bordered table-hover">
  <thead>
    <tr>
      <th>表單編號</th>
      <th>申請單位</th>
      <th>申請者</th>
      <th>申請日期</th>
      <th>變更內容</th>
      <th>變更時程日期</th>
      <th>變更圖面</th>
      <th>變更試算式</th>
      <th>附件</th>
      <th>明細</th>
      <th>簽核狀態</th>
      <!-- <th>意見回饋</th> -->
      <th>簽核紀錄</th>

    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let datas of tableData; let i = index;">
      <td>{{datas.formTaskNo}}</td>
      <td>{{datas.department.depName}}</td>
      <td>{{datas.employee.empName}}</td>
      <td>{{datas?.beginTime | date : 'yyyy/MM/dd'}}</td>
      <td>{{datas.formTaskContent?.addForm?.changeContent}}</td>
      <td>{{datas.formTaskContent?.addForm?.changeDate | date : 'yyyy/MM/dd'}}</td>
      <td>{{datas.formTaskContent.addForm?.changeDiagram ? "Y" : "N"}}</td>
      <td>{{datas.formTaskContent?.addForm?.changeSpec ? "Y" : "N"}}</td>
      <td>{{datas.formTaskContent?.addForm?.changeOther ? "Y" : "N"}}</td>

      <td class="td-actions">
        <div *ngIf="datas.taskStatusId==='2022102718360001' || datas.showSignBtn; else other_content">
          <span *ngIf="datas.taskStatusId==='2022102718360001'" style="cursor: pointer;" class="btn btn-info mr-2"
            (click)="sgo(datas, 'false')">編輯</span>
          <span *ngIf="datas.showSignBtn" style="cursor: pointer;" class="btn btn-info mr-2"
            (click)="sgo(datas, 'false')">簽核</span>
        </div>
        <ng-template #other_content>
          <span style="cursor: pointer;" class="btn btn-default mr-2" (click)="sgo(datas, 'true')">明細</span>
        </ng-template>
      </td>

      <td>{{datas.taskStatus.taskStatusName}}<br />
        <ng-container *ngIf="datas.taskStatusId==='2022102718360004'">
          <span *ngIf="datas.taskSiteList[datas.taskSiteList.length-1].siteResultId === 'result2'"
            class="badge bg-danger"> {{datas.taskSiteList[datas.taskSiteList.length-1].resultComment}}</span>
          <span *ngIf="datas.taskSiteList[datas.taskSiteList.length-1].siteResultId === 'result1'"
            class="badge bg-secondary"> {{datas.taskSiteList[datas.taskSiteList.length-1].resultComment}}</span>
        </ng-container>
        <ng-container *ngIf="datas.taskStatusId!=='2022102718360004'">
          <span class="badge bg-secondary">{{datas.status}}</span>
        </ng-container>
      </td>
      <!-- <td>{{datas.comment1}}<br />
        <span class="badge bg-secondary">{{datas.status1}}</span>
      </td> -->
      <td>
        <button *ngIf="datas.taskStatusId !== '2022102718360001'" (click)="sformSignLog(datas._id)" target="_blank" type="button" data-widget=""
          data-toggle="tooltip" title="簽核紀錄" class="btn btn-default mr-2">紀錄</button>
      </td>
    </tr>
  </tbody>
</table>
