import { filter } from 'rxjs/operators';
import { HelperService } from './../../../../../services/helper.service';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, Renderer2, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { FormTaskProcessApiService } from '@services/form-task-process-api.service';
import { FormSectionService } from '@services/form-section.service';

import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { openCloseAnimation, rotateAnimation } from '../../../../../../app/pages/form-task-process/detail-section/detail-section.animations'
import { ProjectApiService } from '@services/projectapi.service';
import { FormtaskapiService } from '@services/formtaskapi.service';

@Component({
  selector: 'app-calculate-form-process-section2',
  templateUrl: './calculate-form-process-section2.component.html',
  styleUrls: ['./calculate-form-process-section2.component.scss'],
  animations: [openCloseAnimation, rotateAnimation]

})
export class CalculateFormProcessSection2Component implements OnInit {
  public isExpandable: boolean = false;
  public isMainActive: boolean = false;
  public isOneOfChildrenActive: boolean = false;
  @Input() title;
  @Input() action;
  @Input() isMenuExtended;
  @Input() component;
  @Input() formTaskId;
  @Input() taskStatusId;
  @Input() projectId;
  @Input() contractId;
  @Input() detailData;
  formId = "";
  dtOptions: DataTables.Settings = {};
  dtTrigger_pcces: Subject<any> = new Subject<any>();
  total = 0;
  result: any;

  constructor(
    public apiService: FormTaskProcessApiService,
    public formtaskapiService: FormtaskapiService,

    private toastr: ToastrService,
    public formSectionService: FormSectionService,
    public helperService: HelperService,
    public ProjectApiService: ProjectApiService,
    public elementRef: ElementRef,
    public renderer: Renderer2,

  ) { }
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  ngOnInit(): void {
    this.dtOptions = this.helperService.setDtOptions();

    this.isExpandable = true;
    this.formSectionService.calculateIsActive(this.isMenuExtended);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.detailData != undefined && changes.detailData.currentValue) {
      localStorage.setItem('inputValidate2', "true");

      this.helperService.AutoSave(this.renderer, this.elementRef.nativeElement, "formTask", this.detailData?._id);
      this.formId = this.detailData['formId'];
      this.getFormTask();

      if (changes.detailData.previousValue != undefined && this.dtElement.dtInstance != undefined) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          // dtInstance.destroy();
          // this.dtTrigger.next(null);
        });
      } else {
        setTimeout(() => {
          if (!this.action.allowEdit) {
            if (this.detailData?.formTaskContent?.calculateForm?.contractDetailList) {
              var nEditResult = (this.detailData?.formTaskContent?.calculateForm.contractDetailList as any).filter(w => w.this_quantity > 0);
              this.detailData.formTaskContent.calculateForm.contractDetailList = nEditResult;
            }
          }
          this.dtOptions = this.helperService.setDtOptions();
          this.dtTrigger_pcces.next(null);
        }, 1000);

      }
    }

  }


  handleMainMenuAction() {
    this.formSectionService.handleMainMenuAction(this.isExpandable, this.isMenuExtended)
  }

  async getPccesData(pccesId, element) {
    (await this.apiService.getPccesById(pccesId))
      .subscribe((res) => {
        this.result = res as any;
        if (this.result.status == false) {
          Swal.fire({
            title: this.result.message,
            icon: 'error'
          });
        } else {
          element['description'] = res['description'];
          element['unit'] = res['unit'];
        }
      });
  }

  tableData: any;
  async getFormTask() {
    (await this.formtaskapiService.findCalculateFormByContractId(this.detailData?.formTaskContent?.calculateForm?.contractId, this.detailData?.formTaskContent?.calculateForm?.calculateNumber)).subscribe(async (res) => {
      this.tableData = res as any;

      this.detailData?.formTaskContent?.calculateForm?.contractDetailList.forEach(element => {
        var pccesId = element['pccesId'];
        this.getPccesData(pccesId, element);
        this.calculateQuantity(element);
      })
    })
  }

  getTime(date?: Date) {
    return date != null ? date.getTime() : 0;
  }

  calculateQuantity(element, changeQ = null) {
    var pccesId = element['pccesId'];
    this.getPccesData(pccesId, element);
    var calculateNumber = this.detailData?.formTaskContent?.calculateForm?.calculateNumber;

    var price = parseInt(element['price']);
    var totalQuantity = parseInt(element['quantity']);
    element['p_quantity'] = 0;
    element['p_price'] = 0;
    element['this_id'] = "";
    element['this_quantity'] = 0;
    element['this_price'] = 0;
    element['a_quantity'] = 0;
    element['a_price'] = 0;
    element['percent'] = 0;
    var all_quantity = 0;

    //本期數量
    var this_quantity = 0;
    var thiseachQuantity = this.detailData?.formTaskContent?.calculateForm?.eachQuantity;
    if (thiseachQuantity.length > 0) {
      if (thiseachQuantity.filter(w => w.contractDetailId == element['_id'])[0] != null) {
        this_quantity = parseInt(thiseachQuantity.filter(w => w.contractDetailId == element['_id'])[0]['quantity']);
        element['this_id'] = thiseachQuantity.filter(w => w.contractDetailId == element['_id'])[0]['_id'];
      }
    }
    element['this_quantity'] = this_quantity;
    if (changeQ != null) {
      this_quantity = changeQ;
      element['this_quantity'] = changeQ;
    }
    //本期金額
    element['this_price'] = this_quantity * price;


    var historyForm = this.tableData?.filter(w => w.formTaskContent.calculateForm.contractId == this.contractId && new Date(w.endTime) <= new Date()/* && w.formTaskContent.calculateForm.calculateNumber <= (calculateNumber)*/);
    var lastForm = historyForm.filter(w => w.taskStatusId == '2022102718360004' && w.formTaskNo != this.detailData.formTaskNo).sort((a, b) => this.getTime(new Date(b.endTime)) - this.getTime(new Date(a.endTime)))[0];
    var isFind = false;
    historyForm.forEach(element2 => {
      if (element2['formTaskNo'] === this.detailData.formTaskNo || (element2['formTaskNo'] != this.detailData.formTaskNo && element2['taskStatusId'] === '2022102718360004')) {
        var eachQuantity = element2['formTaskContent']['calculateForm']['eachQuantity'].filter(w => w['contractDetailId'] == element['_id']);
        if (eachQuantity.length > 0) {
          eachQuantity.forEach(element3 => {
            if (!isFind && lastForm && lastForm['_id']== element2['_id']/* && element2.formTaskContent.calculateForm.calculateNumber == (calculateNumber - n)*/) {
              isFind = true;
              //前期數量
              element['p_quantity'] = element3['quantity'];
              //前期金額
              element['p_price'] = element3['quantity'] * price;
            }
            all_quantity += parseInt(element3['quantity']);
            if (element2.formTaskContent.calculateForm.calculateNumber == calculateNumber && changeQ != null) {
              all_quantity -= parseInt(element3['quantity']);
              all_quantity += parseInt(changeQ);
              changeQ = null;
            }
            //累計數量
          });

        }
      }
    });

    //累計數量
    element['a_quantity'] = all_quantity;
    //累計金額
    element['a_price'] = all_quantity * price;
    //完成率
    element['percent'] = this.roundDecimal((all_quantity / totalQuantity) * 100, 2);

    if ($("#q_" + element['_id']).val() != undefined) {
      var max = totalQuantity - all_quantity + parseInt(element['this_quantity']);
      $("#q_" + element['_id']).attr("max", max);
      var v = element['this_quantity'];
      element['danger'] = false;
      if (parseInt(v) > max) {
        element['danger'] = true;
        // $("#q_" + element['_id']).val(max);
        //this.calculateQuantity(element, max);
      }
    }
    this.total += element['this_price'];
  }

  roundDecimal = function (val, precision) {
    return Math.round(Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10) / Math.pow(10, (precision || 0));
  }

  quantityChange(event, contractId, element) {
    var target = event.target;
    var value = target.value;
    var subId = target.subId;
    if (value == '') {
      value = 0;
    }
    // if (subId == "") {
    //   this.helperService.AddDataByColumn(
    //     "formTask",
    //     "formTaskContent.calculateForm.eachQuantity",
    //     this.detailData._id,
    //   )
    //     .subscribe((res) => {
    //       if (res['status'] !== 'error') {
    //         target.subId = res['_id'];
    //         var whereObj = [{ key: "_id", value: this.detailData?._id, subdocName: "" }, { key: "_id", value: res['_id'], subdocName: "formTaskContent.calculateForm.eachQuantity", subdocumnet_type: "list" }]
    //         this.helperService.callUpdate("formTask", whereObj, this.detailData?._id, "contractDetailId", contractId, "資料儲存成功", "String")
    //         this.helperService.callUpdate("formTask", whereObj, this.detailData?._id, "quantity", value, "本期數量", "String")
    //         this.calculateQuantity(element, value);
    //       }
    //     });
    // } else {
    var whereObj = [{ key: "_id", value: this.detailData?._id, subdocName: "" }, { key: "_id", value: subId, subdocName: "formTaskContent.calculateForm.eachQuantity", subdocumnet_type: "list" }]
    this.helperService.callUpdate("formTask", whereObj, this.detailData?._id, "quantity", value, "本期數量", "String")
    this.calculateQuantity(element, value);
    // }
  }
}
