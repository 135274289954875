<div #otherFeeList1>
  <div class="row" id="o_{{k1}}">
    <div class="col-md-2">
      <div class="custom-control custom-radio">
        <input class="custom-control-input  updateDataByColumn updateDataByListColumn" type="radio"
          id="otherFeeList[{{k1}}].isPlus0" value="true" name="otherFeeList[{{k1}}].isPlus"
          data-label=" 其他加項減項{{k1+1}}:加減項" data-subdocName="formTaskContent.calculateForm.otherFeeList"
          data-subdocId="{{id}}" data-subdocumnet_type="list">
        <label for="otherFeeList[{{k1}}].isPlus0" class="custom-control-label"><i class="fas fa-plus text-danger"></i>
          加項</label>
      </div>
      <div class="custom-control custom-radio">
        <input class="custom-control-input  updateDataByColumn updateDataByListColumn" type="radio"
          id="otherFeeList[{{k1}}].isPlus1" value="false" name="otherFeeList[{{k1}}].isPlus"
          data-label=" 其他加項減項{{k1+1}}:加減項" data-subdocName="formTaskContent.calculateForm.otherFeeList"
          data-subdocId="{{id}}" data-subdocumnet_type="list" checked="true">
        <label for="otherFeeList[{{k1}}].isPlus1" class="custom-control-label"><i class="fas fa-minus text-primary"></i>
          減項</label>
      </div>
    </div>
    <div class="col-md-5">
      <div class="form-group">
        <ng-select2 width="100%" placeholder="款項名稱"
          (valueChanged)="onChange_PaymentType($event,k1,id)" [data]="s2PaymentTypes">
        </ng-select2>

      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <input type="text" name="otherFeeList[{{k1}}].fee"
          class="form-control  updateDataByColumn updateDataByListColumn" data-label=" 其他加項減項{{k1+1}}:金額"
          data-subdocName="formTaskContent.calculateForm.otherFeeList" data-subdocId="{{id}}"
          data-subdocumnet_type="list">
      </div>
    </div>
    <div class="col-md-1">
      <div class="form-group">
        <button type="button" class="btn btn-tool text-danger  updateDataByColumn" data-toggle="tooltip" title="刪除"
          (click)="del(k1)">
          <i class="fas fa-trash-alt"></i>
        </button>
      </div>
    </div>
  </div>
</div>
