import { element } from 'protractor';
import { FormtaskapiService } from '@services/formtaskapi.service';
import { filter, min } from 'rxjs/operators';
import { FormTaskProcessApiService } from '@services/form-task-process-api.service';
import { ApiService } from '@services/api.service';
import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select2OptionData } from 'ng-select2';
import { ProjectApiService } from '@services/projectapi.service';
import { HelperService } from '@services/helper.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { outputAst } from '@angular/compiler';

@Component({
  selector: 'app-contract-item-detail',
  templateUrl: './contract-item-detail.component.html',
  styleUrls: ['./contract-item-detail.component.scss']
})
export class ContractItemDetail2Component implements OnInit {

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  @Input() projectId;
  @Input() formTaskId;
  @Input() contractItemList;
  thisContractItemList
  @Input() action;
  @Input() detailData;
  @Output() refresh: EventEmitter<any> = new EventEmitter();
  constructor(
    public apiService: ApiService,
    public activeModal: NgbActiveModal,
    public ProjectApiService: ProjectApiService,
    public helperService: HelperService,
    public FormTaskProcessApiService: FormTaskProcessApiService,
    private toastr: ToastrService,

  ) { }

  ngOnInit(): void {
    var order = [[0, 'desc']];
    this.dtOptions = this.helperService.setDtOptions(order);

    this.getContract();

  }

  contract: [];
  hasCalculate = [];
  async getContract() {
    (await this.ProjectApiService.getContact(this.projectId)).subscribe((res) => {
      this.thisContractItemList = JSON.parse(JSON.stringify(this.contractItemList));
      this.contract = (res as any).filter(w => this.helperService.checkVal(w.validateDate) && w['_id'] < this.detailData['_id']);
      //主約+附約
      var thisContract = this.contract.filter(w => w['_id'] == this.thisContractItemList['contractId'] || w['masterContractId'] == this.thisContractItemList['contractId']);
      thisContract = (thisContract as any).sort((a, b) => a._id.localeCompare(b._id));

      //主約
      var mainContract = thisContract.filter(w => w['_id'] == this.thisContractItemList['contractId'])[0];
      var mainContractDetail = mainContract['contractDetailList'] as any;

      //已計價
      this.hasCalculate = [];
      var calculateFormTask = (mainContract['calculateForm'] as any).filter(w => w.taskStatusId == '2022102718360004' && w['_id'] < this.detailData['_id']);
      calculateFormTask.forEach(element => {
        var calculateForm = element['formTaskContent']['calculateForm'];
        calculateForm['eachQuantity'].forEach(element2 => {
          if (this.hasCalculate.filter(w => w['contractDetailId'] == element2['contractDetailId']).length == 0) {
            this.hasCalculate.push({ contractDetailId: element2['contractDetailId'], quantity: element2['quantity'] });
          } else {
            var exist = this.hasCalculate.filter(w => w['contractDetailId'] == element2['contractDetailId'])[0];
            exist['quantity'] = parseInt(exist['quantity']) + parseInt(element2['quantity']);
          }
        });
      });
      if (this.thisContractItemList['contractDetailList'] == null) {
        this.thisContractItemList['contractDetailList'] = [];
      }

      //將主約明細加入
      mainContractDetail.forEach(element => {
        element['contractNo'] = thisContract.filter(w => w['_id'] == this.thisContractItemList['contractId'])[0]['contractNo'];
        element['contractId'] = this.thisContractItemList['contractId'];
        element['isMaster'] = true;
        var thisElement = this.thisContractItemList['contractDetailList'].filter(w => w['addContractId'] == element['contractId'] && w['pccesId'] == element['pccesId'] && w['diagramNo'] == element['diagramNo'] && w['spec'] == element['spec']);
        element['old_quantity'] = element['quantity'];
        element['old_price'] = element['price'];
        element['price'] = 0;
        element['quantity'] = 0;
        if (thisElement.length != 0) {
          element['quantity'] = thisElement[0]['quantity'];
          element['price'] = thisElement[0]['price'];
          this.thisContractItemList['contractDetailList'] = this.thisContractItemList['contractDetailList'].filter(w => w != thisElement[0]);
        }
        this.thisContractItemList['contractDetailList'].push(element);

      });
      //將附約明細加入
      thisContract.filter(w => w['_id'] != this.thisContractItemList['contractId']).forEach(element => {
        (element['contractDetailList'] as any).forEach(element2 => {
          element2['contractNo'] = element['contractNo'];
          element2['contractId'] = element['_id'];
          element2['rootContractId'] = element['rootContractId'];
          element2['isMaster'] = false;
          var thisElement = this.thisContractItemList['contractDetailList'].filter(w => w['addContractId'] == element2['contractId'] && w['pccesId'] == element2['pccesId'] && w['diagramNo'] == element2['diagramNo'] && w['spec'] == element2['spec']);
          element2['old_quantity'] = element2['quantity'];
          element2['old_price'] = element2['price'];
          element2['price'] = 0;
          element2['quantity'] = 0;
          if (thisElement.length != 0) {
            element2['quantity'] = thisElement[0]['quantity'];
            element2['price'] = thisElement[0]['price'];
            this.thisContractItemList['contractDetailList'] = this.thisContractItemList['contractDetailList'].filter(w => w != thisElement[0]);
          }
          this.thisContractItemList['contractDetailList'].push(element2);
        });
      });

      this.thisContractItemList['contractDetailList'].forEach(element => {
        this.getPccesData(element['pccesId'], element);
        //新物料標記
        if (mainContractDetail.filter(w => w['pccesId'] == element['pccesId'] && w['diagramNo'] == element['diagramNo'] && w['spec'] == element['spec']).length == 0) {
          element['isAdd'] = true;
          if (!this.helperService.checkVal(element['contractId'])) {
            element['old_quantity'] = 0;
            element['old_price'] = 0;
          }
        }

        //剩餘可追減計算
        element['lave_quantity'] = element['old_quantity'];
        element['hasAdd_quantity'] = 0;

        //已計價
        var hc = this.hasCalculate.filter(w => w['contractDetailId'] == element['_id'])[0];
        if (hc != null) {
          element['hasCalculate_quantity'] = hc['quantity'];
          element['lave_quantity'] = parseInt(element['old_quantity']) - parseInt(hc['quantity']);
        } else {
          element['hasCalculate_quantity'] = 0;
        }

        //已被追減
        if (this.helperService.checkVal(element['rootContractId'])) {
          //上層合約
          var root = this.thisContractItemList['contractDetailList'].filter(w => w['contractId'] == element['rootContractId'] && w['pccesId'] == element['pccesId'] && w['diagramNo'] == element['diagramNo'] && w['spec'] == element['spec']);
          root.forEach(relement => {
            if (element['old_quantity'] < 0) {
              relement['lave_quantity'] = parseInt(relement['lave_quantity']) + parseInt(element['old_quantity']);
              relement['hasAdd_quantity'] += parseInt(element['old_quantity']);
            }
          });
        }

        //數量警告
        element['allQ'] = parseInt(element['old_quantity']) + parseInt(element['quantity']) - parseInt(element['hasCalculate_quantity']);
        element['danger'] = false;
        if (element['old_quantity'] > 0 && parseInt(element['quantity']) > parseInt(element['old_quantity']) * 0.3) {
          element['danger'] = true;
        }
      });

      if (!this.action.allowEdit) {
        this.thisContractItemList.contractDetailList = this.thisContractItemList.contractDetailList.filter(w => w.quantity != 0);
      }
      this.dtTrigger.next(null);
    });
  }


  async getPccesData(pccesId, element) {
    (await this.FormTaskProcessApiService.getPccesById(pccesId))
      .subscribe((res) => {
        element['description'] = res['description'];
        element['unit'] = res['unit'];
      });
  }


  quantityChange(event, element) {
    var p_quantity =element['quantity'];
    if (event != null) {
      element['quantity'] = event.target.value;
    }

    //數量警告
    element['allQ'] = parseInt(element['old_quantity']) + parseInt(element['quantity']) - parseInt(element['hasCalculate_quantity']);
    element['danger'] = false;
    if (element['old_quantity'] != 0 && parseInt(element['quantity']) > parseInt(element['old_quantity']) * 0.3) {
      element['danger'] = true;
    }

    // if (element['allQ'] < 0) {
    //   setTimeout(() => {
    //     element['quantity'] = 0;
    //     this.quantityChange(null, element);
    //   }, 100);
    //   return false;
    // }

    var price = 0;
    if (parseInt(element['quantity']) < 0) {
      price = element['old_price'];
      element['price'] = price;
    } else if(p_quantity < 0) {
      element['price'] = 0;
    }
    var formData = {
      addContractId: element['addContractId'],
      pccesId: element['pccesId'],
      price: parseInt(element['price']),
      diagramNo: element['diagramNo'],
      spec: element['spec'],
      quantity: parseInt(element['quantity']),
      summary: 0,
    };
    if (element['contractId'] == undefined) { element['contractId'] = element['addContractId'] }
    this.FormTaskProcessApiService
      .editContractItem(formData, this.formTaskId, element['contractId'], this.contractItemList._id)
      .subscribe((res) => {
        this.toastr.success("資料已儲存");
        this.refresh.emit();
      });

  }

  priceChange(event, element) {
    if (event != null) {
      element['price'] = event.target.value;
    }

    var formData = {
      addContractId: element['addContractId'],
      pccesId: element['pccesId'],
      price: parseInt(element['price']),
      diagramNo: element['diagramNo'],
      spec: element['spec'],
      quantity: parseInt(element['quantity']),
      summary: 0,
    };
    if (element['contractId'] == undefined) { element['contractId'] = element['addContractId'] }
    this.FormTaskProcessApiService
      .editContractItem(formData, this.formTaskId, element['contractId'], this.contractItemList._id)
      .subscribe((res) => {
        this.toastr.success("資料已儲存");
        this.refresh.emit();
      });
  }
}
