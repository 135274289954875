import { outputAst } from '@angular/compiler';
import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FormmngapiService } from '@services/formmngapi.service';
import { FormtaskapiService } from '@services/formtaskapi.service';
import { filter } from 'rxjs/operators';
import { openCloseAnimation, rotateAnimation } from './menu-item.animations';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  animations: [openCloseAnimation, rotateAnimation]
})
export class MenuItemComponent implements OnInit {
  @Input() menuItem: any = null;
  @Input() formSignAllCount;
  public isExpandable: boolean = false;
  @HostBinding('class.nav-item') isNavItem: boolean = true;
  @HostBinding('class.menu-open') isMenuExtended: boolean = false;
  public isMainActive: boolean = false;
  public isOneOfChildrenActive: boolean = false;

  constructor(
    private router: Router,
    public formmngapiService: FormmngapiService,
    public formtaskapiService: FormtaskapiService,

  ) { }

  ngOnInit(): void {
    if (
      this.menuItem &&
      this.menuItem.children &&
      this.menuItem.children.length > 0
    ) {
      this.isExpandable = true;
    }
    console.log(this.router.url);
    this.calculateIsActive(this.router.url);
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.calculateIsActive(event.url);
      });
    this.getFormData();
  }

  loginUserId;
  formSignCountList = [];
  async getFormData() {
    this.loginUserId = JSON.parse(localStorage.getItem('auth-user'))['id'];

    (await this.formtaskapiService.getAllFormTask('', '')).subscribe(async (res) => {
      var tableData = res as any;
      var projects = tableData.map(m => ({ projectId: m.projectId })).map(item => item.projectId).filter((value, index, self) => self.indexOf(value) === index);
      (await this.formmngapiService.getFormData()).subscribe((res) => {
        var formData = (res as any).filter(w => w['formEName'] != null);
        projects.forEach(element => {
          formData.forEach(element2 => {
            var count = tableData.filter(w => w['projectId'] == element && w['formId'] == element2['_id'] && (w.taskSiteList[0] !== undefined && w.taskSiteList.filter(w2 => w2.current && w2.signer == this.loginUserId).length > 0)).length;
            this.formSignCountList.push({ projectId: element, formId: element2['_id'], count: count });
          })
        });

        this.menuItem.children.forEach(element => {
          var path = element.path[0];
          if (path.indexOf('formTask') > -1) {
            var formId = path.split('/')[2];
            var projectId = path.split('/')[3];
            if (this.formSignCountList.filter(w => w['formId'] == formId && w['projectId'] == projectId)[0] != undefined) {
              element['formSignCount'] = this.formSignCountList.filter(w => w['formId'] == formId && w['projectId'] == projectId)[0]['count'];
            }
          }
        });
      });
    });
  }

  public handleMainMenuAction() {
    console.log(this.isMenuExtended, this.isExpandable);
    if (this.isExpandable) {
      this.toggleMenu();
      return;
    }
    this.router.navigate(this.menuItem.path);
  }

  public toggleMenu() {
    this.isMenuExtended = !this.isMenuExtended;
  }

  public calculateIsActive(url: string) {
    this.isMainActive = false;
    this.isOneOfChildrenActive = false;
    if (this.isExpandable) {
      this.menuItem.children.forEach((item) => {
        if (item.path[0] === url) {
          this.isOneOfChildrenActive = true;
          this.isMenuExtended = true;
        }
      });
    } else if (this.menuItem.path[0] === url) {
      this.isMainActive = true;
    }
    if (!this.isMainActive && !this.isOneOfChildrenActive) {
      this.isMenuExtended = false;
    }
  }
}
