<div class="modal-header">
  <h4 class="modal-title">追加減紀錄</h4>
  <button type="button" class="btn btn-tool" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <i class="fas fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
        class="table table-striped table-bordered table-hover nowrap">
        <thead>
          <tr>
            <th>日期</th>
            <th>表單編號</th>
            <th>申請者</th>
            <th>金額</th>
            <th>預付款</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of addFormLogList">
            <td>
              <span *ngIf="item?.creator!=='原合約'">{{item?.beginTime | date : "yyyy/MM/dd hh:mm"}}</span>
              <span *ngIf="item?.creator==='原合約'" class="text-danger">原合約</span>
            </td>
            <td>
              <span *ngIf="item?.creator!=='原合約'">{{item?.formTaskNo}}</span>
              <span *ngIf="item?.creator==='原合約'" class="text-danger">原合約</span>
            </td>
            <td>
              <span *ngIf="item?.creator!=='原合約'">{{item?.creator}}</span>
              <span *ngIf="item?.creator==='原合約'" class="text-danger">原合約</span>
            </td>
            <td>
              <span *ngIf="item?.creator!=='原合約'">{{item?.subtotal | currency:'TWD': 'symbol':'1.0'}}</span>
              <span *ngIf="item?.creator==='原合約'" class="text-danger">{{item?.subtotal | currency:'TWD': 'symbol':'1.0'}}</span>
            </td>
            <td>
              <span *ngIf="item?.creator!=='原合約'">{{item?.prePay | currency:'TWD': 'symbol':'1.0'}}</span>
              <span *ngIf="item?.creator==='原合約'" class="text-danger">{{item?.prePay | currency:'TWD': 'symbol':'1.0'}}</span>

            </td>

          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-default" (click)="activeModal.close('Close click')">關閉</button>
  </div>
