<div class="card">
  <div class="card-header">
    <h5 class="card-title mt-2">
      {{title}}
    </h5>
    <div class="card-tools">
      <button type="button" class="btn bnt-tool float-right mr-2" data-card-widget="collapse"
        (click)="handleMainMenuAction()">
        <i [ngClass]="isMenuExtended ? 'fas fa-minus' : 'fas fa-plus'" [@rotate]="isMenuExtended"></i>
      </button>
    </div>
  </div>
  <div class="card-body" [@openClose]="isMenuExtended">
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input *ngIf="action.allowEdit" class="custom-control-input  updateDataByColumn updateDataByListColumn"
              type="checkbox" id="isInvoice" [checked]="isInvoice" name="isInvoice" data-label="廠商發票收送"
              data-subdocName="formTaskContent.calculateForm"
              data-subdocId="{{detailData?.formTaskContent?.calculateForm?._id}}" data-subdocumnet_type="">


            <input *ngIf="!action.allowEdit" disabled class="custom-control-input" type="checkbox" id="isInvoice"
              [checked]="isInvoice" name="isInvoice" data-label="廠商發票收送">
            <label for="isInvoice" class="custom-control-label">廠商發票收送<span class="text-danger">*</span></label>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input *ngIf="action.allowEdit" class="custom-control-input  updateDataByColumn updateDataByListColumn"
              type="checkbox" id="isDataCorrect" [checked]="isDataCorrect" name="isDataCorrect" data-label="發票資料正確"
              data-subdocName="formTaskContent.calculateForm"
              data-subdocId="{{detailData?.formTaskContent?.calculateForm?._id}}" data-subdocumnet_type="">

            <input *ngIf="!action.allowEdit" disabled class="custom-control-input" type="checkbox" id="isDataCorrect"
              [checked]="isDataCorrect" name="isDataCorrect" data-label="發票資料正確">
            <label for="isDataCorrect" class="custom-control-label">發票資料正確<span class="text-danger">*</span></label>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input *ngIf="action.allowEdit" class="custom-control-input  updateDataByColumn updateDataByListColumn"
              type="checkbox" id="isCheck" [checked]="isCheck" name="isCheck" data-label="代雇工確認簽單"
              data-subdocName="formTaskContent.calculateForm"
              data-subdocId="{{detailData?.formTaskContent?.calculateForm?._id}}" data-subdocumnet_type="">

            <input *ngIf="!action.allowEdit" disabled class="custom-control-input" type="checkbox" id="isCheck"
              [checked]="isCheck" name="isCheck" data-label="代雇工確認簽單">
            <label for="isCheck" class="custom-control-label">代雇工確認簽單</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for=""><span class="text-danger">*</span>施工前置會議記錄上傳</label>
          <div *ngIf="action.allowEdit">
            <div class="input-group">
              <div class="custom-file">
                <input #fileUpload type="file" class="custom-file-input" accept=".pdf"
                  (change)="FileSelected($event,'meetingRecordFile')">
                <label class="custom-file-label" for="fileUpload">{{meetingRecordFile ? meetingRecordFile :
                  '請上傳檔案'}}</label>
              </div>

            </div>
            <div *ngIf="loaded !== 0 && load_selected === 'meetingRecordFile'" class="progress my-3">
              <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                attr.aria-valuenow="{{ loaded }}" aria-valuemin="0" aria-valuemax="100"
                [ngStyle]="{ width: loaded+ '%' }">
                {{ loaded }}%
              </div>
            </div>
          </div>
          <div>
            <button *ngIf="!(action.allowEdit)" type="button" class="btn btn-default"
              (click)="viewPdf(meetingRecordFile)">檢視</button>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for=""><span class="text-danger">*</span>計價圖面上傳</label>
          <div *ngIf="action.allowEdit">
            <div class="input-group">
              <div class="custom-file">
                <input #fileUpload type="file" class="custom-file-input" accept=".pdf"
                  (change)="FileSelected($event,'designFile')">
                <label class="custom-file-label" for="fileUpload">{{designFile ? designFile : '請上傳檔案'}}</label>
              </div>
            </div>
            <div *ngIf="loaded !== 0 && load_selected === 'designFile'" class="progress my-3">
              <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                attr.aria-valuenow="{{ loaded }}" aria-valuemin="0" aria-valuemax="100"
                [ngStyle]="{ width: loaded+ '%' }">
                {{ loaded }}%
              </div>
            </div>
          </div>
          <div>
            <button *ngIf="!(action.allowEdit)" type="button" class="btn btn-default"
              (click)="viewPdf(designFile)">檢視</button>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for=""><span class="text-danger">*</span>計價數量計算式</label>
          <div *ngIf="action.allowEdit">
            <div class="input-group">
              <div class="custom-file">
                <input #fileUpload type="file" class="custom-file-input" accept=".pdf"
                  (change)="FileSelected($event,'calculateFile')">
                <label class="custom-file-label" for="fileUpload">{{calculateFile ? calculateFile : '請上傳檔案'}}</label>
              </div>
            </div>
            <div *ngIf="loaded !== 0 && load_selected === 'calculateFile'" class="progress my-3">
              <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                attr.aria-valuenow="{{ loaded }}" aria-valuemin="0" aria-valuemax="100"
                [ngStyle]="{ width: loaded+ '%' }">
                {{ loaded }}%
              </div>
            </div>
          </div>
          <div>
            <button *ngIf="!(action.allowEdit)" type="button" class="btn btn-default"
              (click)="viewPdf(calculateFile)">檢視</button>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">其他附件</label>
          <div *ngIf="action.allowEdit">
            <div class="input-group">
              <div class="custom-file">
                <input #fileUpload type="file" class="custom-file-input" accept=".pdf"
                  (change)="FileSelected($event,'otherFile')">
                <label class="custom-file-label" for="fileUpload">{{otherFile ? otherFile : '請上傳檔案'}}</label>
              </div>
            </div>
            <div *ngIf="loaded !== 0 && load_selected === 'otherFile'" class="progress my-3">
              <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                attr.aria-valuenow="{{ loaded }}" aria-valuemin="0" aria-valuemax="100"
                [ngStyle]="{ width: loaded+ '%' }">
                {{ loaded }}%
              </div>
            </div>
          </div>
          <div>
            <button *ngIf="!(action.allowEdit)" type="button" class="btn btn-default"
              (click)="viewPdf(otherFile)">檢視</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 form-group">
        <label for="">備註</label>
        <ng-container *ngIf="action.allowEdit">
          <textarea class="form-control  updateDataByColumn updateDataByListColumn" [value]="remark" (ngModel)="remark"
            cols="30" rows="3" placeholder="備註" data-label="備註" data-subdocName="formTaskContent.calculateForm"
            name="remark" data-subdocId="{{detailData?.formTaskContent?.calculateForm?._id}}" data-subdocumnet_type="">
                </textarea>
        </ng-container>
        <ng-container *ngIf="!(action.allowEdit)">
          <textarea readonly class="form-control"
            [value]="remark" (ngModel)="remark" cols="30" rows="3">
                </textarea>
        </ng-container>

      </div>
    </div>



  </div>
</div>
