<div class="modal-header">
  <h4 class="modal-title">合約紀錄</h4>

</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <table  datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered table-hover" >
        <thead>
          <tr>
            <th>合約編號</th>
            <th>工程名稱</th>
            <th>工項名稱</th>
            <th>合約日期</th>
            <th>執行採發</th>
            <th>金額</th>
            <th>計價金額</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of supplierContractList">
            <td><span class="badge bg-secondary">{{item.contractTypeName}}</span><br>
              {{item.contractNo}}
            </td>
            <td>  {{item.projectName}} <br>({{item.projectShortName}})</td>
            <td>  {{item.workTypeName}}<br>
              {{item.workBeginTime | date:'yyyy/MM/dd'}} - {{item.workEndTime | date:'yyyy/MM/dd'}}
            </td>
            <td>{{item.contractDate | date:'yyyy/MM/dd'}} </td>
            <td>  {{item.purchaseManagerName}}</td>
            <td>{{item.subtotal | currency:'TWD': 'symbol':'1.0'}}</td>
            <td>{{item.calculateSum | currency:'TWD': 'symbol':'1.0'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>

<div class="modal-footer justify-content-between">
  <button type="button" class="btn btn-default" (click)="activeModal.close('Close click')">關閉</button>
  <button type="button" class="btn btn-info" (click)="activeModal.close('Close click')">確定</button>
</div>
