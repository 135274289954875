<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>表單簽核</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active">表單簽核</li>
        </ol>
      </div>
    </div>
  </div><!-- /.container-fluid -->
</section>

<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h5 class="modal-title">表單簽核列表</h5>
          </div>
          <!-- /.card-header -->
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="row" style="justify-content: center;align-items: center;">
                  <div *ngFor="let item of formData" class="mr-2">
                    <button class="btn btn-info" (click)="refreshData(item._id)"> {{item.formName}} <span
                        class="badge badge-danger">{{
                        item.count}}</span>
                    </button>
                  </div>
                </div>

              </div>

            </div>
            <div class="row">
              <div class="col-12 col-md-3">
                <div class="form-group">
                  <label>選擇簽核狀態</label>
                  <div class="select2-info">
                    <ng-select2 [width]="300" [placeholder]="'please select'" (valueChanged)="onChange($event)">
                      <option *ngFor="let data of taskStatus" [value]="data._id">
                        {{data.taskStatusName}}</option>
                    </ng-select2>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-3">
                <div class="form-group">
                  <label>選擇日期</label>
                  <input type="text" id="selectedDate" ngxDaterangepickerMd [(ngModel)]="selectedDate"  (change)="onDateChange($event)"
                    class="form-control" [locale]="{applyLabel: '確定',clearLabel:'清除', format: 'YYYY/MM/DD'}" />
                </div>
              </div>
            </div>
            <div>
              <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="purchaseform_table"
                class="table table-striped table-bordered table-hover">
                <thead>
                  <tr>
                    <th>專案名稱</th>
                    <th>表單編號</th>
                    <th>表單名稱</th>
                    <th>申請單位</th>
                    <th>申請者</th>
                    <th>申請日期</th>
                    <th>簽核</th>
                    <th>簽核狀態</th>
                    <th>簽核紀錄</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let datas of tableData; let i = index;">
                    <tr>
                      <td>{{datas.projectName}}</td>
                      <td>{{datas.formTaskNo}}</td>
                      <td>{{datas.formName}}</td>
                      <td>{{datas.depName}}</td>
                      <td>{{datas.creatorName}}</td>
                      <td>{{datas?.beginTime?datas?.beginTime.substring(0,10):""}}</td>
                      <td class="td-actions">
                        <div *ngIf="datas.taskSiteList[0] !== undefined && datas.taskSiteList[0].current && datas.taskSiteList[0].signer === loginUserId; else other_content">
                          <span
                          style="cursor: pointer;" class="btn btn-info mr-2"
                         (click)="go(datas.formId,datas.projectId,datas._id,datas.taskStatusId,'false')">簽核</span>
                     </div>
                      <ng-template #other_content>
                        <span
                        style="cursor: pointer;" class="btn btn-default mr-2"
                        (click)="go(datas.formId,datas.projectId,datas._id,datas.taskStatusId,'true')">明細</span>
                      </ng-template>
                      </td>
                      <td>{{datas.taskStatusName}}<br />
                        <ng-container *ngIf="datas.taskStatusId==='2022102718360004'">
                          <span *ngIf="datas.taskSiteList[datas.taskSiteList.length-1] !== undefined && datas.taskSiteList[datas.taskSiteList.length-1].siteResultId === 'result2'"
                            class="badge bg-danger">
                            {{datas.taskSiteList[datas.taskSiteList.length-1].resultComment}}</span>
                          <span *ngIf="datas.taskSiteList[datas.taskSiteList.length-1] !== undefined && datas.taskSiteList[datas.taskSiteList.length-1].siteResultId === 'result1'"
                            class="badge bg-secondary">
                            {{datas.taskSiteList[datas.taskSiteList.length-1].resultComment}}</span>
                        </ng-container>
                        <ng-container *ngIf="datas.taskStatusId!=='2022102718360004'">
                          <span class="badge bg-secondary">{{datas.status}}</span>
                        </ng-container>
                      </td>
                      <td>
                        <button *ngIf="datas.taskStatusId !== '2022102718360001'" (click)="open(datas._id)" target="_blank" type="button" data-widget=""
                          data-toggle="tooltip" title="簽核紀錄" class="btn btn-default mr-2">紀錄</button>
                      </td>
                    </tr>

                  </ng-container>
                </tbody>
              </table>


            </div>
          </div>
        </div>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
</section>
