import { HelperService } from '@services/helper.service';
import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '@services/api.service';
@Component({
  selector: 'app-form-flow-template-event-card',
  templateUrl: './form-flow-template-event-card.component.html',
})
export class FormFlowTemplateEventCardComponent implements OnInit {
  @Input() k;
  @Input() formFlowDataId;
  constructor(
    public apiService: ApiService,
    public helperService: HelperService,
  ) { }

  ngOnInit(): void {
  }


  del(formFlowDataId,index) {
    $("input[name='eventList_"+formFlowDataId+"[" + index + "].handleClass']").val('')
    $("#e_" +formFlowDataId+"_"+ index).hide();
  }

}
