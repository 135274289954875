<div class="modal-header">
  <h4 class="modal-title">修改專案</h4>
  <button type="button" class="btn btn-tool" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <i class="fas fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form class="form-horizontal" [formGroup]="EditForm" method="post" action="">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label><code>*</code>完整工程名稱</label>
          <input type="text"class="form-control" formControlName="projectName">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label><code>*</code>工程代稱(左側選單7個字)</label>
          <input type="text"class="form-control" formControlName="projectShortName">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label><code>*</code>政府採購編號</label>
          <input type="text"class="form-control" formControlName="projectGovNo">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label><code>*</code>得標金額</label>
          <input type="number"class="form-control" formControlName="bidAmount">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label><code>*</code>工程所在縣市</label>
          <ng-select2 [data]="s2Locations" [width]="'100%'" [placeholder]="'請選擇'"
          (valueChanged)="onChange_Locations($event)" [value]="locationAreaList_locationName" [allowClear]="true">
        </ng-select2>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label><code>*</code>工程所在地區</label>
          <ng-select2 [data]="s2Areas" [width]="'100%'" [placeholder]="'請選擇'" (valueChanged)="onChange_Areas($event)"  [value]="locationAreaList_areaName" [options]="options" [allowClear]="true">
          </ng-select2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label><code>*</code>專案預計開始日</label>
          <input type="date"class="form-control" formControlName="beginDate">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label><code>*</code>專案預計結束日</label>
          <input type="date"class="form-control"  formControlName="endDate">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label><code>*</code>專案主管</label>
          <ng-select2 [data]="s2Department1" [width]="'100%'" [value]="Department1" [placeholder]="'請選擇'" (valueChanged)="onChange_Departments($event,1)" [allowClear]="true">
          </ng-select2>
        </div>
        <div class="form-group">
          <ng-select2 [data]="s2Employee1" [width]="'100%'" [value]="Employee1" [placeholder]="'請選擇'" (valueChanged)="onChange_Employees($event,1)" [allowClear]="true">
          </ng-select2>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label><code>*</code>工地主任</label>
          <ng-select2 [data]="s2Department2" [width]="'100%'"  [value]="Department2" [placeholder]="'請選擇'" (valueChanged)="onChange_Departments($event,2)" [allowClear]="true">
          </ng-select2>
        </div>
        <div class="form-group">
          <ng-select2 [data]="s2Employee2" [width]="'100%'" [value]="Employee2" [placeholder]="'請選擇'" (valueChanged)="onChange_Employees($event,2)" [allowClear]="true">
          </ng-select2>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label><code>*</code>執行採發</label>
          <ng-select2 [data]="s2Department3" [width]="'100%'"  [value]="Department3" [placeholder]="'請選擇'" (valueChanged)="onChange_Departments($event,3)" [allowClear]="true">
          </ng-select2>
        </div>
        <div class="form-group">
          <ng-select2 [data]="s2Employee3" [width]="'100%'" [value]="Employee3" [placeholder]="'請選擇'" (valueChanged)="onChange_Employees($event,3)" [allowClear]="true">
          </ng-select2>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label><code>*</code>工管部主管</label>
          <ng-select2 [data]="s2Department4" [width]="'100%'"  [value]="Department4" [placeholder]="'請選擇'" (valueChanged)="onChange_Departments($event,4)" [allowClear]="true">
          </ng-select2>
        </div>
        <div class="form-group">
          <ng-select2 [data]="s2Employee4" [width]="'100%'" [value]="Employee4" [placeholder]="'請選擇'" (valueChanged)="onChange_Employees($event,4)" [allowClear]="true">
          </ng-select2>
        </div>
      </div>
    </div>
  </form>
  <form class="form-horizontal" id="ChangePriceForm">
    <div class="row">
      <div class="col-md-12 bg-light">
        <div class="row">
          <div class="col-md-12">
            變更契約金額
            <button type="button" class="btn btn-info float-right" id="AddChangePriceList"
              (click)="AddChangePriceList()">
              <i class="fas fa-plus"></i> 新增
            </button>
          </div>
        </div>
        <div #ChangePriceList>
          <div class="row">
            <div class="col-md-3"> <label>變更日期</label></div>
            <div class="col-md-3"> <label>變更原因</label></div>
            <div class="col-md-2"> <label>增減</label></div>
            <div class="col-md-3"> <label>金額</label></div>
          </div>
          <div class="row" *ngFor="let item of ChangePrice let index=index;" id="c_{{index}}">
            <div class="col-md-3">
              <input type="date" class="form-control"  name="ChangePrice[{{index}}].changeDate"
              [value]="item.changeDate" >
            </div>
            <div class="col-md-3">
              <input type="text" class="form-control"  name="ChangePrice[{{index}}].changeReason"
              [value]="item.changeReason" >
            </div>
            <div class="col-md-2">
             <select class="form-control"  name="ChangePrice[{{index}}].math" [value]="item.math">
              <option value="0">增加</option>
              <option value="1">減少</option>
             </select>
            </div>
            <div class="col-md-3">
              <input type="number" class="form-control"  name="ChangePrice[{{index}}].changePrice"
              [value]="item.changePrice" >
              <br>

            </div>
            <div class="col-md-1">
              <button type="button" class="btn btn-tool text-danger" data-toggle="tooltip" title="刪除"
              (click)="del(index)">
              <i class="fas fa-trash-alt"></i>
            </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </form>

  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-default" (click)="activeModal.close('Close click')">關閉</button>
    <button type="button" class="btn btn-info" (click)="editProject()">確定</button>
  </div>
