import { filter } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '@services/api.service';
import { ProjectApiService } from '@services/projectapi.service';
import { Subject } from 'rxjs';
import {
  NgbModalConfig,
  NgbModal,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import { PurchaseControlEditComponent } from './purchase-control-edit/purchase-control-edit.component';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';
import { RightService } from '@services/right.service';
import { ActivatedRoute } from '@angular/router';
import { HelperService } from '@services/helper.service';

@Component({
  selector: 'app-purchase-control-manage',
  templateUrl: './purchase-control-manage.component.html',
  styleUrls: ['./purchase-control-manage.component.scss']
})
export class PurchaseControlManageComponent implements OnInit {

  public projectId;
  getRight: any;
  RightObj: {};
  dtOptions: DataTables.Settings = {};

  public purchaseControls;

  dtTrigger: Subject<any> = new Subject<any>();

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  totalItem;
  checkItem;
  checkPercent;

  constructor(
    public apiService: ApiService,
    public ProjectApiService: ProjectApiService,
    public rightService: RightService,
    public helperService: HelperService,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
  ) {
    config.backdrop = true;
    config.keyboard = false;
    config.centered = true;
    // this.getRight = this.rightService.getRight();

    this.subscribeRouteChange();
  }

  initFlag = false;
  subscribeRouteChange() {
    this.activatedRoute.params.subscribe((params = {}) => {
      this.projectId = params.projectId;
      if (this.initFlag) {
        window.location.reload();
      }
      this.initFlag = true;

    })
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params = {}) => {
      this.projectId = params['id'];
    })
    this.dtOptions = this.helperService.setDtOptions();
    // this.RightObj = this.getRight['__zone_symbol__value'];

    this.reloadData();
  }


  async reloadData() {
    (await this.ProjectApiService.getPurchaseControl(this.projectId)).subscribe((res) => {
      this.purchaseControls = res as any;
      this.totalItem = this.purchaseControls.length;
      this.checkItem = this.purchaseControls.filter(w => w.materialData?.pass).length;
      this.checkPercent = Math.round(this.checkItem / this.totalItem * 100);

      this.dtTrigger.next(null);
    });
  }

  async refreshData() {
    (await this.ProjectApiService.getPurchaseControl(this.projectId)).subscribe((res) => {
      this.purchaseControls = res as any;
      this.totalItem = this.purchaseControls.length;
      this.checkItem = this.purchaseControls.filter(w => w.materialData?.pass).length;
      this.checkPercent = Math.round(this.checkItem / this.totalItem * 100);
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next(null);
      });
    });
  }

  download(url) {
    this.apiService.download(url).subscribe((res) => {
      if (url.indexOf('pdf') > -1) {
        this.apiService.downLoadFile(res, 'application/pdf');
      }
    },
      (err) => {
        Swal.fire({
          title: '系統找不到指定的檔案',
          confirmButtonText: '確定',
          confirmButtonColor: '#17A2B8',
          icon: 'error'
        });
      });
  }

  open(type, purchaseControl) {
    if (type == 'edit') {
      const modalRef = this.modalService.open(PurchaseControlEditComponent, { size: 'lg' });
      modalRef.componentInstance.purchaseControl = purchaseControl;
      modalRef.closed.subscribe((result) => {
        this.refreshData();
      });
    }
  }
}
