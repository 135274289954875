<div class="modal-header">
  <h4 class="modal-title">物料清單</h4>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close"
    (click)="activeModal.close('Close click')">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
        class="table table-striped table-bordered table-hover">
        <thead>
          <tr>
            <th>主/附約</th>
            <th style="width:30%">物料編號</th>
            <th>圖說/規格</th>
            <th>單價/數量</th>
            <th>已計價數量</th>
            <th>已追減數量</th>
            <th style="min-width: 5rem;">追加減數量</th>
            <th style="min-width: 5rem;">追加單價</th>
            <th>累計數量</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of thisContractItemList?.contractDetailList">
            <td>
              <span *ngIf="item.isMaster">主約</span>
              <span *ngIf="!item.isMaster && item.contractNo!==undefined">{{item._id.substring(0,8)}}<br>追加附約
              </span>
              <span *ngIf="!item.isMaster && item.contractNo===undefined">本次追加</span>
            </td>
            <td>
              <i *ngIf="item.danger && !(item.old_quantity === 0)" title="數量超過原數量30%，建議重新採購。"
                class="fas fa-exclamation-triangle fa-fw text-danger"></i>
              <i *ngIf="item.isAdd" title="新物料" class="fa fa-check-square text-success" aria-hidden="true"></i>
              {{item.pccesId}}<br>{{item.description}} {{item.unit}}
            </td>
            <td>
              圖說編號:<br>{{item.diagramNo}}<br>
              規格:<br>{{item.spec}}</td>
            <td>
              單價:{{item.old_price}}<br>
             數量:{{item.old_quantity}}</td>
            <td>{{-item.hasCalculate_quantity}}</td>
            <td>{{item.hasAdd_quantity}}</td>
            <td style="min-width: 5rem;">
              {{item.quantity}}
            </td>
            <td style="min-width: 5rem;">
              <input *ngIf="action.allowEdit && item.quantity > 0" type="number" [value]="item.price"
                class="form-control" (focusout)="PriceChange($event,item)">
              {{action.allowEdit && item.quantity > 0?'':item.price}}
            </td>
            <td>
              {{item.allQ}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="modal-footer justify-content-between">
  <button type="button" class="btn btn-default" data-dismiss="modal"
    (click)="activeModal.close('Close click')">關閉</button>
  <a href="{{api_url}}/pdf/getAddFormContract/{{detailData?.formTaskContent?.addForm?._id}}/{{MasterId}}"
    target="_blank" type="button" data-widget="" data-toggle="tooltip" title="下載合約"
    class="btn btn-success mr-2">下載合約</a>
</div>
