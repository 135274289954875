import { filter } from 'rxjs/operators';
import { ProjectApiService } from '@services/projectapi.service';
import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { FormtaskapiService } from '@services/formtaskapi.service';
import { Router } from '@angular/router';
import { HelperService } from '@services/helper.service';

@Component({
  selector: 'app-purchase-form-add',
  templateUrl: './purchase-form-add.component.html',
  styleUrls: ['./purchase-form-add.component.scss']
})
export class PurchaseFormAddComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    public apiService: FormtaskapiService,
    public router: Router,
    public helperService: HelperService,
    public ProjectApiService: ProjectApiService
  ) { }


  purchaseFormSection = new FormGroup({

    projectName: new FormControl({ value: '', disabled: true }),
    purchaseDeadLine: new FormControl({ value: '', disabled: true }),
    workTypeId: new FormControl(''),
    emergencyTypeId: new FormControl(''),
    workEndTime: new FormControl(''),
    workBeginTime: new FormControl(''),
    recommendSupplierId: new FormControl(''),
    supplierAmount: new FormControl(''),
    workers: new FormControl(0),
    purchaseControlId: new FormControl(''),
  });

  @Input() workTypeList;
  @Input() emergencyTypeList;
  @Input() recommendSupplierList
  @Input() formAndProjectData

  emergencyTypeId;

  savePurchaseForm(type: String) {
    let max_purchaseNo = ('00' + localStorage.getItem('max_purchaseNo')).slice(-3);
    var purchaseControlId = (this.purchaseFormSection.get('purchaseControlId').value === '無') ? null : this.purchaseFormSection.get('purchaseControlId').value;
    let formdata = {
      formId: this.formAndProjectData.formId,
      formTaskNo: this.formAndProjectData.projectNo + '-P' + max_purchaseNo,
      taskSiteList: null,
      emergencyTypeId: this.purchaseFormSection.get('emergencyTypeId').value,
      formTaskContent: {
        purchaseForm: {
          projectId: this.formAndProjectData.projectId,
          workTypeId: this.purchaseFormSection.get('workTypeId').value,
          purchaseNo: max_purchaseNo,
          workBeginTime: this.purchaseFormSection.get('workBeginTime').value,
          workEndTime: this.purchaseFormSection.get('workEndTime').value,
          purchaseDeadLine: this.purchaseFormSection.get('purchaseDeadLine').value,
          recommendSupplierId: this.purchaseFormSection.get('recommendSupplierId').value,
          supplierAmount: this.purchaseFormSection.get('supplierAmount').value,
          workers: this.purchaseFormSection.get('workers').value,
          purchaseControlId: purchaseControlId,
        }
      }
    };
    if (formdata.emergencyTypeId == '' || formdata.formTaskContent.purchaseForm.purchaseControlId == '' ||formdata.formTaskContent.purchaseForm.workTypeId == '' || formdata.formTaskContent.purchaseForm.workBeginTime == '' || formdata.formTaskContent.purchaseForm.workEndTime == '') {
      Swal.fire({
        title: "請填寫必填欄位",
        icon: 'warning',
        confirmButtonColor: "#17a2b8",
      });
      return;
    }
    this.apiService
      .addFormTask(formdata)
      .subscribe((res) => {
        if (res.status == false) {
          Swal.fire({
            title: res.message,
            icon: 'error'
          });
        } else {
          this.activeModal.close();
          this.router.navigateByUrl('/formTask/' + this.formAndProjectData.formId + "/" + this.formAndProjectData.projectId + "/" + res._id + "/" + res.taskStatusId + "/" + "false");
        }
      });
  }

  purchaseDeadLineChange(value, purchaseControlId = null) {
    this.emergencyTypeId = value;
    let purchaseDeadLine;
    if (purchaseControlId != null) {
      this.purchaseFormSection.get('purchaseControlId').setValue(purchaseControlId);
    }
    if (this.purchaseFormSection.get('purchaseControlId').value && this.purchaseFormSection.get('purchaseControlId').value !== '無') {
      purchaseDeadLine = this.purchaseControlList.filter(w => w._id === this.purchaseFormSection.get('purchaseControlId').value)[0]['purchaseDate']
      this.purchaseFormSection.get("purchaseDeadLine").setValue(this.helperService.formatDate(purchaseDeadLine));
      return
    }
    // let workBeginTime = this.purchaseFormSection.get("workBeginTime").value;
    // if (workBeginTime != "") {
    if (this.emergencyTypeId == "2022102810220001") {
      purchaseDeadLine = new Date();
      console.log("0", purchaseDeadLine);
      purchaseDeadLine.setDate(purchaseDeadLine.getDate() + 21);
      console.log("1", purchaseDeadLine);
      purchaseDeadLine = purchaseDeadLine.toISOString().substring(0, 10);
      console.log("2", purchaseDeadLine);
      this.purchaseFormSection.get("purchaseDeadLine").setValue(purchaseDeadLine);
    } else if (this.emergencyTypeId == "2022102810230001") {
      purchaseDeadLine = new Date();
      purchaseDeadLine.setDate(purchaseDeadLine.getDate() + 14);
      purchaseDeadLine = purchaseDeadLine.toISOString().substring(0, 10);
      this.purchaseFormSection.get("purchaseDeadLine").setValue(purchaseDeadLine);
    } else if (this.emergencyTypeId == "2022102810230002") {
      purchaseDeadLine = new Date();
      purchaseDeadLine.setDate(purchaseDeadLine.getDate() + 7);
      purchaseDeadLine = purchaseDeadLine.toISOString().substring(0, 10);
      this.purchaseFormSection.get("purchaseDeadLine").setValue(purchaseDeadLine);
    }
    // }
  }

  ngOnInit(): void {
    this.purchaseFormSection.get('projectName').setValue(this.formAndProjectData.projectName)
    this.ddlWorkType();
    this.ddlPurchaseControl();
    // this.purchaseFormSection.get('purchaseDeadLine').setValue(this.formAndProjectData.purchaseDeadLine)
  }

  async ddlWorkType() {
    let arrWorkType = [];
    this.workTypeList = this.workTypeList.filter(w => w.enable).sort((a, b) => a.sort - b.sort);
    this.workTypeList.forEach(element => {
      if ((element['enable']) && element['level'] === 3) {
        var selectText = element['workTypeName'];
        if (element['rootWorkTypeId'] != '' && element['rootWorkTypeId'] != null) {
          selectText = this.helperService.getRootWorkType(this.workTypeList.sort((a, b) => a.sort - b.sort), selectText, element['rootWorkTypeId'])
        }
        arrWorkType.push({ _id: element['_id'], workTypeName: selectText, sort: element['sort'] });
      }
    })
    this.workTypeList = arrWorkType.sort((a, b) => a.sort - b.sort);
  }

  purchaseControlList = []
  async ddlPurchaseControl() {
    (await this.ProjectApiService.getPurchaseControl(this.formAndProjectData.projectId)).subscribe((res) => {
      this.purchaseControlList = (res as any).filter(w => w['purchaseDate']);
      setTimeout(() => {
        this.purchaseFormSection.get('purchaseControlId').setValue('');
      }, 100);
    });
  }

  // private dateToString = (date) => {
  //   return date?date.year+"-"+('0'+date.month).slice(-2)+"-"+('0'+date.day).slice(-2):'';
  // };
}
