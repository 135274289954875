import { HelperService } from '@services/helper.service';
import { ProjectApiService } from '@services/projectapi.service';
import { ApiService } from '@services/api.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { api_url } from '../../../services/api_config';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-daily-picture-list',
  templateUrl: './daily-picture-list.component.html',
  styleUrls: ['./daily-picture-list.component.scss']
})
export class DailyPictureListComponent implements OnInit {
  @Input() dailyDate;
  @Input() workImgList;
  _albums = new Array();
  constructor(
    public helperService:HelperService,
    public activeModal: NgbActiveModal,
    public apiService: ApiService,
    public ProjectApiService: ProjectApiService,
    private _lightbox: Lightbox
    ) { }

  ngOnInit(): void {
    if (this.workImgList) {
      this.workImgList.forEach(element => {
        var imgsrc = api_url + "/download?path=" + element['workImg'];
        var imgsrcThumb = api_url + "/downloadPic?path=" + element['workImg']+"&thumb=true";
        var album = { src: imgsrc, caption: element['workImgTitle'], thumb: imgsrcThumb, _id: element['_id'] };
        this._albums.push(album);
      })
    }
  }

  openWorkImg(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }

}
