import { FormtaskapiService } from '@services/formtaskapi.service';
import { filter } from 'rxjs/operators';
import { FormTaskProcessApiService } from '@services/form-task-process-api.service';
import { ApiService } from '@services/api.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select2OptionData } from 'ng-select2';
import { ProjectApiService } from '@services/projectapi.service';
import { HelperService } from '@services/helper.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contract-item-add',
  templateUrl: './contract-item-add.component.html',
  styleUrls: ['./contract-item-add.component.scss']
})
export class ContractItemAddComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  @Input() projectId;
  @Input() formTaskId;
  @Input() contractItemList;
  constructor(
    public apiService: ApiService,
    public activeModal: NgbActiveModal,
    public ProjectApiService: ProjectApiService,
    public helperService: HelperService,
    public FormTaskProcessApiService: FormTaskProcessApiService,


  ) { }

  ngOnInit(): void {
    this.dtOptions = this.helperService.setDtOptions();
    this.getContract();
  }

  contract: [];
  async getContract() {
    (await this.ProjectApiService.getContact(this.projectId)).subscribe((res) => {
      this.contract = (res as any).filter(w => this.helperService.checkVal(w.validateDate) && w.masterContractId == null);


      this.contract.forEach(element => {
        //附約
        var subContract = (res as any).filter(w => w['masterContractId'] == element['_id']);
        subContract.forEach(element2 => {
          (element['contractDetailList'] as any) = (element['contractDetailList'] as any).concat(element2['contractDetailList']);
        });
        if (element['contractDetailList'] != null || (element['contractDetailList'] as any).length > 0) {
          (element['contractDetailList'] as any).forEach(element2 => {
            this.getPccesData(element2['pccesId'], element2);
          })
        }
      })

      this.ddlWorkType();

    });
  }

  workType = new Array();
  s2WorkTypes: Select2OptionData[];
  async ddlWorkType() {
    let arrWorkType = [];
    this.contract.forEach(element => {
      if (this.workType.filter(w => w['_id'] == element['workType']['_id']).length == 0) {
        this.workType.push(element['workType']);
      }
    });

    (await this.apiService.getWorkTypes()).subscribe((res) => {
      this.workType.filter(w => w['_id']).forEach(element => {
        if (element['level'] === 3) {
          var selectText = element['workTypeName'];
          if (element['rootWorkTypeId'] != '' && element['rootWorkTypeId'] != null) {
            selectText = this.helperService.getRootWorkType((res as any).sort((a, b) => a.sort - b.sort), selectText, element['rootWorkTypeId'])
          }
          arrWorkType.push({ id: element['_id'], text: selectText });
        }
      })
      this.s2WorkTypes = arrWorkType;
    });
  }


  async getPccesData(pccesId, element) {
    (await this.FormTaskProcessApiService.getPccesById(pccesId))
      .subscribe((res) => {
        element['description'] = res['description'];
      });
  }

  select_WorkTypes: String;
  onChange_WorkTypes(val: String) {
    this.select_WorkTypes = val;
  }



  queryPcces = "";
  contractList = [];
  qdiagramNo = [];
  qspec = [];
  isInit = true;
  query() {
    this.contractList = [];
    // var pccesId = this.queryPcces.split(" ")[0];
    // var pccesDes = this.queryPcces.replace(pccesId + " ", "");
    if (this.helperService.checkVal(this.queryPcces) || this.helperService.checkVal(this.select_WorkTypes)) {
      var queryContract = this.contract.filter(w =>  (w['contractDetailList'] as any).length > 0 && ((w['contractDetailList'] as any).filter(w2 => this.helperService.checkVal(w2['pccesId']) && w2['pccesId'].indexOf(this.queryPcces) > -1).length > 0 || (w['contractDetailList'] as any).filter(w2 => this.helperService.checkVal(w2['description']) && w2['description'].indexOf(this.queryPcces) > -1).length > 0));
      if(this.helperService.checkVal(this.select_WorkTypes)){
        queryContract = queryContract.filter(w => w['workType']['_id'] == this.select_WorkTypes);
      }
      queryContract.forEach(element => {
        (element['contractDetailList'] as any).forEach(element2 => {
          if (this.contractList.filter(w => w['_id'] == element['_id']).length == 0 && ((this.helperService.checkVal(element2['pccesId']) && element2['pccesId'].indexOf(this.queryPcces) > -1) || (this.helperService.checkVal(element2['description']) && element2['description'].indexOf(this.queryPcces) > -1))) {
            var thisP = element['contractDetailList'][0];
            var ckb = true;
            if (this.contractItemList != null && this.contractItemList.filter(w => w['contractId'] == element['_id']).length > 0) {
              ckb = false;
            }
            if (element['masterContractId']) {
              var elementM = this.contract.filter(w => w['_id'] == element['masterContractId'])[0];
              this.contractList.push({ _id: elementM['_id'], pccesId: element2['pccesId'], pccesDes: element2['description'], contractNo: elementM['contractNo'], supplierName: elementM['supplierName'], diagramNo: thisP['diagramNo'], spec: thisP['spec'], ckb: ckb })
            } else {
              this.contractList.push({ _id: element['_id'], pccesId: element2['pccesId'], pccesDes: element2['description'], contractNo: element['contractNo'], supplierName: element['supplierName'], diagramNo: thisP['diagramNo'], spec: thisP['spec'], ckb: ckb })
            }
            if (this.contractList.length == 1 && this.isInit) {
              this.dtTrigger.next(null);
              this.isInit = false;
            }
          }
        });

      })
      if (!this.isInit) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next(null);
        });
      }
    }
    // else {
    //   Swal.fire({
    //     title: "工項及物料為必填!",
    //     icon: 'error',
    //     confirmButtonColor: "#17a2b8",
    //   });
    // }
  }

  save() {
    var ckb = $("input[name='ckbItem']:checked");
    var formdata = [];
    ckb.each(function () {
      if ($(this).val() != "") {
        formdata.push($(this).val());
      }
    })

    this.FormTaskProcessApiService
      .addContractItem(formdata, this.formTaskId, this.select_WorkTypes)
      .subscribe((res) => {
        if (res['status'] == false) {
          Swal.fire({
            title: res['message'],
            icon: 'error'
          });
        } else {
          this.activeModal.close();
        }
      });
  }
}
