<li class="nav-item">
  <a (click)="handleMainMenuAction()" class="nav-link" [ngClass]="{active: isMainActive || isOneOfChildrenActive}">
    <i class="nav-icon {{ menuItem.iconClasses }}"></i>
    <p>{{ menuItem.name }}
      <span *ngIf="menuItem.path !== null && menuItem.path[0] === '/FormSign' "
        class="badge badge-info right">{{formSignAllCount}}</span>
      <i *ngIf="isExpandable && menuItem.children.length > 0" class="right fas fa-angle-right"
        [@rotate]="isMenuExtended"></i>
    </p>
  </a>
  <ul class="nav" *ngFor="let item of menuItem.children" [@openClose]="isMenuExtended">
    <li class="nav-item">
      <a [routerLink]="item.path" [routerLinkActive]="''" class="nav-link">
        <i class="nav-icon {{ item.iconClasses || 'far fa-circle nav-icon' }}"></i>
        <p>{{ item.name }}</p>
        <span class="badge badge-info right">{{item.formSignCount}}</span>
      </a>
    </li>
  </ul>
</li>
