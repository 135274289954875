import { HelperService } from '@services/helper.service';
import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { ApiService } from '@services/api.service';
import { Select2OptionData } from 'ng-select2';
import { ProjectApiService } from '@services/projectapi.service';

@Component({
  selector: 'app-supplier-contract-card',
  templateUrl: './supplier-contract-card.component.html',
  styleUrls: ['./supplier-contract-card.component.scss']
})
export class SupplierContractCardComponent implements OnInit {
  @Input() k3;
  @Input() id;
  @Input() reportId;
  s2WorkTypes: Select2OptionData[];
  @Input() SupplierContract_WorkType;
  @Input() s2Suppliers
  @Input() SupplierContract_Supplier;
  @Input() contract;
  constructor(
    public apiService: ApiService,
    public helperService: HelperService,
    public elementRef: ElementRef,
    public renderer: Renderer2,
    public ProjectApiService: ProjectApiService,
  ) { }

  options: any = {
    multiple: true
  };

  ngOnInit(): void {

  }



  AllWorkType = [];
  async ddlWorkType(val, index) {
    let arrWorkType = [];
    let k = 0;
    var checkedItem = [];
    var contractBySupplierId = this.contract.filter(w => w['supplierId'] == val);
    var workTypeByContract = [];
    contractBySupplierId.forEach(element => {
      workTypeByContract.push(element['workType']);
    });

    (await this.apiService.getWorkTypes()).subscribe((res) => {
      this.AllWorkType = (res as any).filter(w => w.enable).sort((a, b) => a.sort - b.sort);
      this.AllWorkType.forEach(element => {
        if (element['level'] === 3 && workTypeByContract.filter(w => w._id === element._id).length > 0) {
          var selectText = element['workTypeName'];
          if (element['rootWorkTypeId'] != '' && element['rootWorkTypeId'] != null) {
            selectText = this.helperService.getRootWorkType(this.AllWorkType, selectText, element['rootWorkTypeId'])
          }
          arrWorkType.push({ id: element['_id'], text: selectText });
        }
        k++;
        if (k == this.AllWorkType.length) {
          // arrWorkType = arrWorkType.sort((a, b) => a.text.localeCompare(b.text));

          this.s2WorkTypes = arrWorkType;
        }
      });
    });
  }






  onChange_Suppliers(val: String, k, subdocId): void {
    if (val != "" && this.SupplierContract_Supplier[k] != val) {
      var whereObj = [{ key: "_id", value: this.reportId, subdocName: "" }, { key: "_id", value: subdocId, subdocName: "supplierContractList",subdocumnet_type:"list" }]
      this.helperService.callUpdate("dailyReport", whereObj, this.id, "supplierId", val, "廠商出工狀態清單" + (k + 1) + ".廠商", "String")
    }
    this.SupplierContract_Supplier[k] = val;
    this.ddlWorkType(val, k);
  }

  onChange_WorkTypes(val: String, k, subdocId): void {
    if (val != "" && this.SupplierContract_Supplier[k] != val) {
      var whereObj = [{ key: "_id", value: this.reportId, subdocName: "" }, { key: "_id", value: subdocId, subdocName: "supplierContractList",subdocumnet_type:"list" }]
      this.helperService.callUpdate("dailyReport", whereObj, this.id, "workTypeId", val, "廠商出工狀態清單" + (k + 1) + ".工項", "String")
    }
    this.SupplierContract_WorkType[k] = val;
  }


  del(index) {
    $("#s2_" + index).hide();
    (this.helperService.DelDataByColumn("dailyReport", this.reportId, "supplierContractList", this.id)).subscribe((res) => { });
  }

}
