import { HelperService } from '@services/helper.service';
import { ProjectApiService } from '@services/projectapi.service';
import { Component, OnInit, Input, ComponentRef, ViewContainerRef, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '@services/api.service';
import Swal from 'sweetalert2';
import { Select2OptionData } from 'ng-select2';
import { ChangePriceCardComponent } from '../change-price-card/change-price-card.component';

@Component({
  selector: 'app-project-edit',
  templateUrl: './project-edit.component.html',
  styleUrls: ['./project-edit.component.scss']
})
export class ProjectEditComponent implements OnInit {
  @Input() project;
  public result;
  public EditForm: FormGroup;
  constructor(
    public activeModal: NgbActiveModal,
    public apiService: ApiService,
    public ProjectApiService: ProjectApiService,
    public helperService: HelperService,
    private resolver: ComponentFactoryResolver,
  ) { }

  options: any = {
    multiple: true
  };

  s2Locations: Select2OptionData[];
  s2Areas: Select2OptionData[];
  LocationObj = [];
  locationAreaList_locationName: String;
  locationAreaList_areaName: String[];

  DepartmentObj = [];
  EmployeeObj = [];
  s2Department1: Select2OptionData[];
  s2Department2: Select2OptionData[];
  s2Department3: Select2OptionData[];
  s2Department4: Select2OptionData[];
  s2Employee1: Select2OptionData[];
  s2Employee2: Select2OptionData[];
  s2Employee3: Select2OptionData[];
  s2Employee4: Select2OptionData[];
  Employee1: String;
  Employee2: String;
  Employee3: String;
  Employee4: String;
  Department1: String;
  Department2: String;
  Department3: String;
  Department4: String;

  ChangePrice = [];
  k1 = 0;

  @ViewChild('ChangePriceList', { static: false, read: ViewContainerRef }) target: ViewContainerRef;
  private componentRef: ComponentRef<any>;

  ngOnInit(): void {
    this.EditForm = new FormGroup({
      companyId: new FormControl('20221020183346803', Validators.required),
      projectNo: new FormControl(''),
      projectName: new FormControl('', Validators.required),
      projectShortName: new FormControl('', Validators.required),
      projectGovNo: new FormControl('', Validators.required),
      bidAmount: new FormControl('', Validators.required),
      locationAreaList: new FormControl(null, Validators.required),
      beginDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
      projectManagerId: new FormControl('', Validators.required),
      engineerManagerId: new FormControl('', Validators.required),
      purchaseManagerId: new FormControl('', Validators.required),
      workManagerId: new FormControl('', Validators.required),
      changePriceList: new FormControl(),
      changeAmount:new FormControl(this.project.changeAmount),
      employeeList:new FormControl(this.project.employeeList),
      tenderImportant:new FormControl(this.project.tenderImportant),
      bossImportant:new FormControl(this.project.bossImportant),
      constructionImg:new FormControl(this.project.constructionImg),
      isEnable:new FormControl(this.project.isEnable),
      projectStepId: new FormControl('20221008202444348', Validators.required),
      projectStepList:new FormControl([]),
    });
    this.EditForm.controls['projectNo'].setValue(this.project.projectNo);
    this.EditForm.controls['projectName'].setValue(this.project.projectName);
    this.EditForm.controls['projectShortName'].setValue(this.project.projectShortName);
    this.EditForm.controls['projectGovNo'].setValue(this.project.projectGovNo);
    this.EditForm.controls['bidAmount'].setValue(this.project.bidAmount);
    this.locationAreaList_locationName = this.project.locationAreaList.locationName;
    this.locationAreaList_areaName = this.project.locationAreaList.areaNameList;
    this.EditForm.controls['locationAreaList'].setValue(this.project.locationAreaList);
    this.EditForm.controls['beginDate'].setValue(this.helperService.formatDate(this.project.beginDate));
    this.EditForm.controls['endDate'].setValue(this.helperService.formatDate(this.project.endDate));

    this.Department1 = this.project.projectManager.departmentId;
    this.Employee1 = this.project.projectManagerId;
    this.EditForm.controls['projectManagerId'].setValue(this.project.projectManagerId);
    this.Department2 = this.project.engineerManager.departmentId;
    this.Employee2 = this.project.engineerManagerId;
    this.EditForm.controls['engineerManagerId'].setValue(this.project.engineerManagerId);
    this.Department3 = this.project.purchaseManager.departmentId;
    this.Employee3 = this.project.purchaseManagerId;
    this.EditForm.controls['purchaseManagerId'].setValue(this.project.purchaseManagerId);
    this.Department4 = this.project.workManager.departmentId;
    this.Employee4 = this.project.workManagerId;
    this.EditForm.controls['workManagerId'].setValue(this.project.workManagerId);

    // this.ChangePrice = this.project.ChangePrice;
    if (this.project.changePriceList != null && this.project.changePriceList.length > 0) {
      this.project.changePriceList.forEach(element => {
        element['changeDate'] = this.helperService.formatDate(element['changeDate']);
        if (element['changePrice'].toString().indexOf('-') > -1) {
          element['math'] = "1";
          element['changePrice'] = element['changePrice'].toString().replace("-", "");
        } else {
          element['math'] = "0";
        }
        this.k1++;
        this.ChangePrice.push(element);
      })
    } else {
      this.ChangePrice.push({ changeDate: "", changeReason: "", math: "0", changePrice: "" });
    }


    this.EditForm.controls['projectStepId'].setValue(this.project.projectStepId);
    this.EditForm.controls['projectStepList'].setValue(this.project.projectStepList);
    this.ddlLocations();
    this.ddlDepartment();
  }


  async ddlLocations() {
    let arrLocations = [];
    let k = 0;
    (await this.apiService.getLocations()).subscribe((res) => {
      this.LocationObj = res['_embedded']['locations'] as any;
      this.LocationObj.forEach((element, index) => {
        var idArr = element['_links']['self']['href'].split('/');
        var id = idArr[idArr.length - 1];
        this.LocationObj[index]['id'] = id;
        arrLocations.push({ id: id, text: element['locationName'] });
        k++;
        if (k == this.LocationObj.length) {
          this.s2Locations = arrLocations;
        }
      });
    })
  }


  async ddlAreas(locationId) {
    let arrAreas = [];
    let k = 0;
    var filterLocation = this.LocationObj.filter(s => s.id == locationId);
    if (filterLocation.length == 1) {
      var AllAreas = filterLocation[0]['areaNameList'];
      AllAreas.forEach(element => {
        arrAreas.push({ id: element, text: element });
        k++;
        if (k == AllAreas.length) {
          this.s2Areas = arrAreas;
        }
      });
    }
  }

  async ddlDepartment() {
    let arrDepartments = [];
    let k = 0;
    (await this.apiService.getDepartments()).subscribe((res) => {
      this.DepartmentObj = res as any;
      this.DepartmentObj.forEach((element, index) => {
        this.DepartmentObj[index]['id'] = element['_id'];
        arrDepartments.push({ id: element['_id'], text: element['depName'] });
        k++;
        if (k == this.DepartmentObj.length) {
          this.s2Department1 = arrDepartments;
          this.s2Department2 = arrDepartments;
          this.s2Department3 = arrDepartments;
          this.s2Department4 = arrDepartments;
        }
      });
    })
  }


  async ddlEmployees(depId, s) {
    let arrEmployees = [];
    let k = 0;
    (await this.apiService.getEmployees()).subscribe((res) => {
      this.EmployeeObj = (res['content'] as any).filter(w => !w.lock);
      this.EmployeeObj = this.EmployeeObj.filter(s => s.departmentId == depId);
      if (this.EmployeeObj.length == 0) {
        switch (s) {
          case 1:
            this.s2Employee1 = [];
            break;
          case 2:
            this.s2Employee2 = [];
            break;
          case 3:
            this.s2Employee3 = [];
            break;
          case 4:
            this.s2Employee4 = [];
            break;
        }
      }
      this.EmployeeObj.forEach((element, index) => {
        this.EmployeeObj[index]['id'] = element['_id'];
        arrEmployees.push({ id: element['_id'], text: element['empName'] });
        k++;
        if (k == this.EmployeeObj.length) {
          switch (s) {
            case 1:
              this.s2Employee1 = arrEmployees;
              break;
            case 2:
              this.s2Employee2 = arrEmployees;
              break;
            case 3:
              this.s2Employee3 = arrEmployees;
              break;
            case 4:
              this.s2Employee4 = arrEmployees;
              break;
          }
        }

      });
    })
  }

  onChange_Locations(val: String): void {
    this.locationAreaList_locationName = val;
    if (val != undefined) {
      this.ddlAreas(val);
    }
  }


  onChange_Areas(val: []): void {
    this.locationAreaList_areaName = val;
  }

  onChange_Departments(val: String, s): void {
    switch (s) {
      case 1:
        this.Department1 = val;
        break;
      case 2:
        this.Department2 = val;
        break;
      case 3:
        this.Department3 = val;
        break;
      case 4:
        this.Department4 = val;
        break;
    }

    if (val != undefined) {
      this.ddlEmployees(val, s);
    }
  }

  onChange_Employees(val: String, s): void {
    switch (s) {
      case 1:
        this.EditForm.controls['projectManagerId'].setValue(val);
        break;
      case 2:
        this.EditForm.controls['engineerManagerId'].setValue(val);
        break;
      case 3:
        this.EditForm.controls['purchaseManagerId'].setValue(val);
        break;
      case 4:
        this.EditForm.controls['workManagerId'].setValue(val);
        break;
    }
  }


  AddChangePriceList() {
    this.k1++;
    let childComponent = this.resolver.resolveComponentFactory(ChangePriceCardComponent);
    this.componentRef = this.target.createComponent(childComponent);
    this.componentRef.instance.k1 = this.k1;
  }

  del(index) {
    $("#c_" + index).hide();
    $("input[name='ChangePrice[" + index + "].changeDate']").val('')
    $("input[name='ChangePrice[" + index + "].changeReason']").val('')
    $("input[name='ChangePrice[" + index + "].changePrice']").val('')
  }

  editProject() {

    //變更契約金額
    var ChangePriceObj = $('#ChangePriceForm').serializeArray();
    var ChangePriceLen = ChangePriceObj.length / 4;
    var j = 0;
    this.ChangePrice = [];
    for (var i = 0; i < ChangePriceLen; i++) {
      if (ChangePriceObj[j].value != "") {
        var changeDate = ChangePriceObj[j].value;
        var changeReason = ChangePriceObj[j + 1].value;
        var math = ChangePriceObj[j + 2].value;
        var changePrice = ChangePriceObj[j + 3].value;
        if (math == "1") {
          changePrice = "-" + changePrice;
        }
        this.ChangePrice.push({ changeDate, changeReason, changePrice });
      }
      j = j + 4;
    }

    var locationAreaListObj = { locationName: this.locationAreaList_locationName, areaNameList: this.locationAreaList_areaName };
    this.EditForm.controls['locationAreaList'].setValue(locationAreaListObj);
    this.EditForm.controls['changePriceList'].setValue(this.ChangePrice);

    if (this.EditForm.valid) {
      this.ProjectApiService
        .editProjects(
          this.EditForm.value,
          this.project._id
        )
        .subscribe((res) => {
          this.result = res as any;
          if (this.result.result_status == false) {
            Swal.fire({
              title: this.result.result_message,
              icon: 'error'
            });
          } else {
            this.activeModal.close();
          }
        });
    } else {
      Swal.fire({
        title: '請填寫必填欄位',
        icon: 'error'
      });
    }
  }
}
