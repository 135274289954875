<div class="modal-header">
  <h4 class="modal-title">新增追加減辦理</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <form [formGroup]="addFormSection">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label><span class="text-danger">*</span>速別</label>
          <ng-select2 width="100%" placeholder="速別" formControlName="emergencyTypeId"
            (valueChanged)="onChange_EmergencyType($event)">
            <option *ngFor="let data of emergencyTypeList" [value]="data._id">{{data.emergencyName}}</option>
          </ng-select2>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label><span class="text-danger">*</span>追加減類型</label>
          <ng-select2 width="100%" placeholder="追加減類型" formControlName="addSubTypeId"
            (valueChanged)="onChange_AddSubType($event)" [data]="s2AddSubTypes">
          </ng-select2>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label><span class="text-danger">*</span>變更時程類型</label>
          <ng-select2 width="100%" placeholder="變更時程類型" formControlName="addSubScheduleTypeId"
            (valueChanged)="onChange_AddSubScheduleType($event)" [data]="s2AddSubScheduleTypes">
          </ng-select2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label><span class="text-danger">*</span>變更日期</label>
          <input type="date" class="form-control" formControlName="changeDate">
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label><span class="text-danger">*</span>變更內容</label>
          <input type="text" class="form-control" formControlName="changeContent" placeholder="變更內容">

        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label><span class="text-danger">*</span>變更原因</label>
          <ng-select2 width="100%" placeholder="變更原因" formControlName="addSubChangeTypeId"
            (valueChanged)="onChange_AddSubChangeType($event)" [data]="s2AddSubChangeTypes">
          </ng-select2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>備註說明</label>
          <input type="text" class="form-control" formControlName="remark" placeholder="選填">
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer justify-content-between">
  <button type="button" class="btn btn-default btn-outline-dark" (click)="activeModal.close('Close click')">關閉</button>
  <button class="btn btn-info" (click)="saveAddForm('add')">確定</button>
</div>
