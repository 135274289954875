<div class="modal-header">
    <h4 class="modal-title">簽核紀錄</h4>

  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
        class="table table-striped table-bordered table-hover nowrap">
          <thead>
            <tr>
              <th>簽核日期</th>
              <th>簽核單位</th>
              <th>簽核者</th>
              <th>簽核結果</th>
              <th>說明</th>

            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of process">
             <td>
              {{item.finishTime | date:'yyyy/MM/dd HH:mm'}}</td>
             <td>{{item.depName}}</td>
             <td>{{item.empName}}</td>
             <td>{{item.siteResult}}</td>
             <td>{{item.resultComment}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>

  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-default" (click)="activeModal.close('Close click')">關閉</button>
    <button type="button" class="btn btn-info" (click)="activeModal.close('Close click')">確定</button>
  </div>
