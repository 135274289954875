import { filter } from 'rxjs/operators';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@services/api.service';
import { Subject } from 'rxjs';
import { RightService } from '@services/right.service';
import { HelperService } from '@services/helper.service';
import { ProjectApiService } from '@services/projectapi.service';
import Swal from 'sweetalert2';
import { FormtaskapiService } from '@services/formtaskapi.service';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-contract-cal',
  templateUrl: './contract-cal.component.html',
  styleUrls: ['./contract-cal.component.scss']
})
export class ContractCalComponent implements OnInit {
  @Input() calculateForm;
  @Input() contractId;
  @Input() subtotal;

  getRight: any;
  RightObj: {};
  dtOptions: DataTables.Settings = {};

  public result;
  constructor(
    public activeModal: NgbActiveModal,
    public rightService: RightService,
    public helperService: HelperService,
    public apiService: ApiService,
    private modalService: NgbModal,
    public ProjectApiService: ProjectApiService,
    public formtaskapiService: FormtaskapiService,

  ) {
    this.getRight = this.rightService.getRight();
  }

  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  ngOnInit(): void {
    var order = [[0, 'asc']];
    this.dtOptions = this.helperService.setDtOptions(order);
    this.RightObj = this.getRight['__zone_symbol__value'];
    this.calculateForm = this.calculateForm.filter(w => w.taskStatusId == '2022102718360004');
    this.reloadData();

    setTimeout(() => {
      this.dtTrigger.next(null);
    }, 300);
  }

  tableData: any;

  async reloadData() {
    this.calculateForm.forEach(element => {
      var price1 = 0; //計價金額
      var price2 = 0; //累計計價
      var calculateForm1 = element.formTaskContent?.calculateForm;
      calculateForm1.contractDetailList.forEach(element2 => {
        this.getFormTask(element2, calculateForm1);

        setTimeout(() => {
          price1 += element2['this_price'];
          price2 += element2['a_price'];
          element['price1'] = price1;
          element['price2'] = price2;
          element['percent'] = Math.round(price2 / this.subtotal * 100) + "%";
        }, 300);
      });
    });
  }


  async getFormTask(element2, calculateForm1) {
    (await this.formtaskapiService.findCalculateFormByContractId(this.contractId, calculateForm1['calculateNumber'])).subscribe(async (res) => {
      this.tableData = res as any;
      this.calculateQuantity(element2, null, calculateForm1)
    });
  }

  calculateQuantity(element, changeQ = null, calculateForm) {
    var calculateNumber = calculateForm['calculateNumber'];

    var price = parseInt(element['price']);
    var totalQuantity = parseInt(element['quantity']);
    element['p_quantity'] = 0;
    element['p_price'] = 0;
    element['this_id'] = "";
    element['this_quantity'] = 0;
    element['this_price'] = 0;
    element['a_quantity'] = 0;
    element['a_price'] = 0;
    element['percent'] = 0;
    var all_quantity = 0;

    //本期數量
    var this_quantity = 0;
    var thiseachQuantity = calculateForm['eachQuantity'];
    if (thiseachQuantity.length > 0) {
      if (thiseachQuantity.filter(w => w.contractDetailId == element['_id'])[0] != null) {
        this_quantity = parseInt(thiseachQuantity.filter(w => w.contractDetailId == element['_id'])[0]['quantity']);
        element['this_id'] = thiseachQuantity.filter(w => w.contractDetailId == element['_id'])[0]['_id'];
      }
    }
    element['this_quantity'] = this_quantity;
    if (changeQ != null) {
      this_quantity = changeQ;
      element['this_quantity'] = changeQ;
    }
    //本期金額
    element['this_price'] = this_quantity * price;


    var historyForm = this.tableData?.filter(w => w.formTaskContent.calculateForm.contractId == this.contractId && new Date(w.endTime) <= new Date()/* && w.formTaskContent.calculateForm.calculateNumber <= (calculateNumber)*/);

    historyForm.forEach(element2 => {

      var eachQuantity = element2['formTaskContent']['calculateForm']['eachQuantity'].filter(w => w['contractDetailId'] == element['_id']);
      if (eachQuantity.length > 0) {
        eachQuantity.forEach(element3 => {
          if (element2.formTaskContent.calculateForm.calculateNumber == (calculateNumber - 1)) {
            //前期數量
            element['p_quantity'] = element3['quantity'];
            //前期金額
            element['p_price'] = element3['quantity'] * price;
          }
          all_quantity += parseInt(element3['quantity']);

          if (element2.formTaskContent.calculateForm.calculateNumber == calculateNumber && changeQ != null) {
            all_quantity -= parseInt(element3['quantity']);
            all_quantity += parseInt(changeQ);
          }
          //累計數量
        });
      }
    });

    //累計數量
    element['a_quantity'] = all_quantity;
    //累計金額
    element['a_price'] = all_quantity * price;
    //完成率
    element['percent'] = Math.round(all_quantity / totalQuantity) * 100;

  }

}
