<div class="modal-header">
  <h4 class="modal-title">計價</h4>
  <button type="button" class="btn btn-tool" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <i class="fas fa-times"></i>
  </button>
</div>
<div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered table-hover nowrap">
          <thead>
            <tr>
              <th>期數</th>
              <th>日期</th>
              <th>發票</th>
              <th>金額(含稅)</th>
              <th>計價金額</th>
              <th>累計計價</th>
              <th>計價率</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of calculateForm">
              <td>{{item?.formTaskContent?.calculateForm?.calculateNumber}}</td>
              <td>{{item?.formTaskContent?.calculateForm?.calculateDate | date : "yyyy/MM/dd"}}</td>
              <td>{{item?.formTaskContent?.calculateForm?.invoiceNumber}}</td>
              <td>{{item?.formTaskContent?.calculateForm?.amount | currency:'TWD': 'symbol':'1.0'}}</td>
              <td>{{item?.price1 | currency:'TWD': 'symbol':'1.0'}}</td>
              <td>{{item?.price2 | currency:'TWD': 'symbol':'1.0'}}</td>
              <td>{{item?.percent}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-default" (click)="activeModal.close('Close click')">關閉</button>
  </div>
