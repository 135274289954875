<div class="card">
  <div class="card-header">
    <h5 class="card-title mt-2">
      {{title}}
    </h5>
    <div class="card-tools">
      <a href="{{api_url}}/pdf/getAddFormContract/{{detailData?.formTaskContent?.addForm?._id}}" target="_blank" type="button" data-widget=""
      data-toggle="tooltip" title="下載詢價資料" class="btn btn-success mr-2">下載詢價資料</a>
      <button type="button" class="btn bnt-tool float-right mr-2" data-card-widget="collapse"
        (click)="handleMainMenuAction()">
        <i [ngClass]="isMenuExtended ? 'fas fa-minus' : 'fas fa-plus'" [@rotate]="isMenuExtended"></i>
      </button>
    </div>
  </div>
  <div class="card-body" [@openClose]="isMenuExtended">
    <div class="row mb-3">
      <div class="col-md-4">
        <div class="form-group">
          <label><span class="text-danger">*</span>追加減類型</label>
          <ng-select2 *ngIf="action.allowEdit" width="100%" placeholder="追加減類型" (valueChanged)="onChange_AddSubType($event)"
            [data]="s2AddSubTypes" [value]="detailData?.formTaskContent?.addForm?.addSubTypeId" [disabled]="true">
          </ng-select2>

          <ng-select2 *ngIf="!action.allowEdit" [disabled]="true" width="100%" placeholder="追加減類型" (valueChanged)="onChange_AddSubType($event)"
          [data]="s2AddSubTypes" [value]="detailData?.formTaskContent?.addForm?.addSubTypeId">
        </ng-select2>

        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label><span class="text-danger">*</span>變更時程類型</label>
          <ng-select2 *ngIf="action.allowEdit" width="100%" placeholder="變更時程類型" (valueChanged)="onChange_AddSubScheduleType($event)"
            [data]="s2AddSubScheduleTypes" [value]="detailData?.formTaskContent?.addForm?.addSubScheduleTypeId">
          </ng-select2>

          <ng-select2  *ngIf="!action.allowEdit" [disabled]="true" width="100%" placeholder="變更時程類型" (valueChanged)="onChange_AddSubScheduleType($event)"
          [data]="s2AddSubScheduleTypes" [value]="detailData?.formTaskContent?.addForm?.addSubScheduleTypeId">
        </ng-select2>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label><span class="text-danger">*</span>變更日期</label>
          <input type="date" class="form-control" [value]="detailData?.formTaskContent?.addForm?.changeDate"
            placeholder="變更日期" disabled>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-4">
        <div class="form-group">
          <label><span class="text-danger">*</span>變更內容</label>
          <input type="text" *ngIf="action.allowEdit" [value]="detailData?.formTaskContent?.addForm?.changeContent" placeholder="變更內容"
            class="form-control  updateDataByColumn updateDataByListColumn" data-label="變更內容"
            data-subdocName="formTaskContent.addForm" name="changeContent"
            data-subdocId="{{detailData?.formTaskContent?.addForm?._id}}" data-subdocumnet_type="">

            <input type="text" *ngIf="!action.allowEdit" [value]="detailData?.formTaskContent?.addForm?.changeContent" placeholder="變更內容"
            class="form-control " readonly>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label><span class="text-danger">*</span>變更原因</label>
          <ng-select2 *ngIf="action.allowEdit"  width="100%" placeholder="變更原因" (valueChanged)="onChange_AddSubChangeType($event)"
            [data]="s2AddSubChangeTypes" [value]="detailData?.formTaskContent?.addForm?.addSubChangeTypeId">
          </ng-select2>

          <ng-select2 *ngIf="!action.allowEdit" [disabled]="true" width="100%"  placeholder="變更原因" (valueChanged)="onChange_AddSubChangeType($event)"
          [data]="s2AddSubChangeTypes" [value]="detailData?.formTaskContent?.addForm?.addSubChangeTypeId">
        </ng-select2>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label><span class="text-danger">*</span>變更備註</label>
          <input type="text" class="form-control" [value]="detailData?.formTaskContent?.addForm?.remark"
            placeholder="變更備註" disabled>
        </div>
      </div>
    </div>
  </div>
</div>
