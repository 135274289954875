import { ApiService } from '@services/api.service';
import { filter } from 'rxjs/operators';
import { HelperService } from '../../../../../services/helper.service';
import { Component, ComponentFactoryResolver, ComponentRef, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, Renderer2, SimpleChanges, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { FormTaskProcessApiService } from '@services/form-task-process-api.service';
import { FormSectionService } from '@services/form-section.service';

import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { openCloseAnimation, rotateAnimation } from '../../../../form-task-process/detail-section/detail-section.animations'
import { ProjectApiService } from '@services/projectapi.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { OtherFeeCardComponent } from './other-fee-card/other-fee-card.component';
import { Select2OptionData } from 'ng-select2';

@Component({
  selector: 'app-calculate-form-process-section3',
  templateUrl: './calculate-form-process-section3.component.html',
  styleUrls: ['./calculate-form-process-section3.component.scss'],
  animations: [openCloseAnimation, rotateAnimation]
})
export class CalculateFormProcessSection3Component implements OnInit {
  public isExpandable: boolean = false;
  public isMainActive: boolean = false;
  public isOneOfChildrenActive: boolean = false;
  @Input() title;
  @Input() action;
  @Input() isMenuExtended;
  @Input() component;
  @Input() formTaskId;
  @Input() taskStatusId;
  @Input() projectId;
  @Input() contractId;
  @Input() detailData;
  constructor(
    public apiService: FormTaskProcessApiService,
    private toastr: ToastrService,
    public formSectionService: FormSectionService,
    private modalService: NgbModal,
    public helperService: HelperService,
    public ProjectApiService: ProjectApiService,
    public master_apiService: ApiService,
    private resolver: ComponentFactoryResolver,
    public elementRef: ElementRef,
    public renderer: Renderer2,
  ) { }

  ngOnInit(): void {
  }

  defaultFee1 = 0;
  defaultFee2 = 0;
  defaultFee3 = 0;
  defaultFee1_id = 0;
  defaultFee2_id = 0;
  defaultFee3_id = 0;
  otherFeeList = []
  ngOnChanges(changes: SimpleChanges): void {

    if (changes.detailData != undefined && changes.detailData.currentValue) {
      this.helperService.AutoSave(this.renderer, this.elementRef.nativeElement, "formTask", this.detailData?._id);
      this.ddlPaymentType()
      this.otherFeeList = this.detailData?.formTaskContent?.calculateForm?.otherFeeList.filter(w => w.feeName != '保留款' && w.feeName != '訂金' && w.feeName != '郵資');
      this.defaultFee1 = this.detailData?.formTaskContent?.calculateForm?.otherFeeList.filter(w => w.feeName == '保留款')[0]['fee'];
      this.defaultFee1_id = this.detailData?.formTaskContent?.calculateForm?.otherFeeList.filter(w => w.feeName == '保留款')[0]['_id'];
      this.defaultFee2 = this.detailData?.formTaskContent?.calculateForm?.otherFeeList.filter(w => w.feeName == '訂金')[0]['fee'];
      this.defaultFee2_id = this.detailData?.formTaskContent?.calculateForm?.otherFeeList.filter(w => w.feeName == '訂金')[0]['_id'];
      this.defaultFee3 = this.detailData?.formTaskContent?.calculateForm?.otherFeeList.filter(w => w.feeName == '郵資')[0]['fee'];
      this.defaultFee3_id = this.detailData?.formTaskContent?.calculateForm?.otherFeeList.filter(w => w.feeName == '郵資')[0]['_id'];
      if (this.otherFeeList.length == 0 && this.action.allowEdit) {
        this.AddCard();
      } else {
        this.k1 = this.otherFeeList.length - 1;
      }
    }
  }

  del(index, id) {
    $("#o_" + index).hide();
    (this.helperService.DelDataByColumn("formTask", this.formTaskId, "formTaskContent.calculateForm.otherFeeList", id)).subscribe((res) => { });
  }



  @ViewChild('otherFeeList1', { static: false, read: ViewContainerRef }) target: ViewContainerRef;
  private componentRef: ComponentRef<any>;
  k1 = 0;
  AddCard() {
    this.helperService.AddDataByColumn(
      "formTask",
      "formTaskContent.calculateForm.otherFeeList",
      this.formTaskId,
    ).subscribe((res) => {
      var id = res['_id'];
      this.k1++;
      let childComponent = this.resolver.resolveComponentFactory(OtherFeeCardComponent);
      this.componentRef = this.target.createComponent(childComponent);
      this.componentRef.instance.k1 = this.k1;
      this.componentRef.instance.id = id;
      this.componentRef.instance.formTaskId = this.formTaskId;
    })
  }

  handleMainMenuAction() {
    this.formSectionService.handleMainMenuAction(this.isExpandable, this.isMenuExtended)
  }

  s2PaymentTypes: Select2OptionData[];
  AllPaymentType = [];
  async ddlPaymentType() {
    let arrPaymentType = [];
    let k = 0;
    (await this.master_apiService.getPaymentTypes()).subscribe((res) => {
      this.AllPaymentType = (res as any);
      this.AllPaymentType.forEach(element => {
        arrPaymentType.push({ id: element['paymentTypeName'], text: element['paymentTypeName'] });
        k++;
        if (k == this.AllPaymentType.length) {
          arrPaymentType = arrPaymentType.sort((a, b) => a.text.localeCompare(b.text))
          this.s2PaymentTypes = arrPaymentType;
        }
      });
    });
  }

  onChange_PaymentType(val: String, k, subdocId): void {
    if (val == null) val = "";
    if (val != undefined && this.otherFeeList[k].feeName != val) {
      var whereObj = [{ key: "_id", value: this.detailData?._id, subdocName: "" }, { key: "_id", value: subdocId, subdocName: "formTaskContent.calculateForm.otherFeeList", subdocumnet_type: "list" }]
      this.helperService.callUpdate("formTask", whereObj, this.detailData?._id, "feeName", val, "其他加項減項" + (k+1) + ":款項名稱", "String")
    }
  }
}
