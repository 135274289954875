import { filter } from 'rxjs/operators';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { ContractAddComponent } from './contract-add/contract-add.component';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { FormSectionService } from '@services/form-section.service';
import { FormTaskProcessApiService } from '@services/form-task-process-api.service';
import { InquiryDetailAddComponent } from '../purchase-form-process-section4/inquiry-detail-add/inquiry-detail-add.component';
import { openCloseAnimation, rotateAnimation } from '../../../../../../app/pages/form-task-process/detail-section/detail-section.animations'
import { HelperService } from '@services/helper.service';

@Component({
  selector: 'app-purchase-form-process-section6',
  templateUrl: './purchase-form-process-section6.component.html',
  styleUrls: ['./purchase-form-process-section6.component.scss'],
  animations: [openCloseAnimation, rotateAnimation]
})

export class PurchaseFormProcessSection6Component implements OnInit {
  public isExpandable: boolean = false;
  public isMainActive: boolean = false;
  public isOneOfChildrenActive: boolean = false;
  @Input() title;
  @Input() action;
  @Input() isMenuExtended;
  @Input() component;
  @Input() parentComp;

  @Input() formTaskId;
  @Input() taskStatusId;
  @Input() formAndProjectData;

  dtOptions: DataTables.Settings = {};
  dtTrigger_inquiry: Subject<any> = new Subject<any>();
  result: any;
  pccesData: any;
  inquiryData: any;
  purchaseFormId: any;
  workTypeId = "";
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  constructor(
    public formSectionService: FormSectionService,
    public apiService: FormTaskProcessApiService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    public helperService: HelperService,

  ) { }

  ngOnInit(): void {
    this.dtOptions = this.helperService.setDtOptions();
    this.isExpandable = true;
    this.formSectionService.calculateIsActive(this.isMenuExtended);
    this.reloadFormTaskDetailData();
  }

  async reloadFormTaskDetailData() {
    (await this.apiService.getFormTaskDetailData(this.formTaskId, this.taskStatusId)).subscribe((res) => {
      this.inquiryData = (res?.formTaskContent?.purchaseForm?.inquiryDataList);
      this.purchaseFormId = res?.formTaskContent?.purchaseForm?._id;
      this.workTypeId = res?.formTaskContent?.purchaseForm?.workTypeId;
      localStorage.setItem('inputValidate6', "true");

      if (this.inquiryData.filter(w => (this.helperService.checkVal(w.contract) && (this.helperService.checkVal(w.contract.contractDate) && this.helperService.checkVal(w.contract.contractFile)))).length == 0) {
        localStorage.setItem('inputValidate6', "false");
      }

      if (this.inquiryData.filter(w => this.helperService.checkVal(w.contract) && this.helperService.checkVal(w['contract']['contractFile'])).length > 0) {
        this.inquiryData.forEach(element => {
          if (element['contract'] != null && this.helperService.checkVal(element['contract']['contractFile'])) {
            element['showContractBtn'] = true;
          } else {
            element['showContractBtn'] = false;
          }
        });
      } else {
        this.inquiryData.forEach(element => { element['showContractBtn'] = true; });
      }
      this.getPccesData();
      this.dtTrigger_inquiry.next(null);
    });
  }

  async refreshFormTaskDetailData() {
    (await this.apiService.getFormTaskDetailData(this.formTaskId, this.taskStatusId)).subscribe((res) => {
      this.inquiryData = (res?.formTaskContent?.purchaseForm?.inquiryDataList);
      localStorage.setItem('inputValidate6', "true");

      if (this.inquiryData.filter(w => (this.helperService.checkVal(w.contract) && (this.helperService.checkVal(w.contract.contractDate) && this.helperService.checkVal(w.contract.contractFile)))).length == 0) {
        localStorage.setItem('inputValidate6', "false");
      }

      if (this.inquiryData.filter(w => this.helperService.checkVal(w.contract) && this.helperService.checkVal(w['contract']['contractFile'])).length > 0) {
        this.inquiryData.forEach(element => {
          if (element['contract'] != null && this.helperService.checkVal(element['contract']['contractFile'])) {
            element['showContractBtn'] = true;
          } else {
            element['showContractBtn'] = false;
          }
        });
      } else {
        this.inquiryData.forEach(element => { element['showContractBtn'] = true; });
      }

      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtElement.dtTrigger.next(null);
      });
    });
  }


  async getPccesData() {
    (await this.apiService.getFormTaskDetailData(this.formTaskId, this.taskStatusId)).subscribe((res) => {
      this.pccesData = res?.formTaskContent?.purchaseForm?.pccesDataList;
    });
  }


  handleMainMenuAction() {
    this.formSectionService.handleMainMenuAction(this.isExpandable, this.isMenuExtended)
  }


  createContractNo(supplierId, codeName, inquiryDataId) {
    Swal.fire({
      title: '確定取得合約編號?',
      // text: 'Really, Would you delete this record?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: "#17a2b8",
      confirmButtonText: '確定',
      cancelButtonText: '取消',
      focusCancel: true
    }).then((result) => {
      if (result.value) {
        let formData = {
          supplierId: supplierId,
          inquiryDataId: inquiryDataId,
          purchaseFormId: this.purchaseFormId,
          contractNo: this.formAndProjectData.depChineseCode + codeName + this.formAndProjectData?.projectNo + "-P-" + new Date().toISOString().substring(2, 10).replace("-", "").replace("-", "") + "-01",
          workTypeId: this.workTypeId
        }
        this.apiService.createContractNo(formData)
          .subscribe((res) => {
            this.result = res as any;
            if (this.result.status == false) {
              Swal.fire({
                title: this.result.message,
                icon: 'error'
              });
            } else {
              this.refreshFormTaskDetailData();
            }
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log();
      }
    })


  }

  viewPdf(url) {
    this.formSectionService.viewPdf(url);
  }

  choosePriority(event, value, id) {
    console.log(event);
    let checked = event.target.checked
    console.log(checked);
    for (var i = 0; i < this.inquiryData.length; i++) {
      if (this.inquiryData[i].choosePriority == value && checked) {
        event.preventDefault();
        return;
      }
    }
    if (!checked) {
      value = "0"
    }
    let formData = {
      priority: value,
      formTaskId: this.formTaskId,
      inquiryDataId: id
    }
    this.apiService.updateInquiryDataPriority(formData)
      .subscribe((res) => {
        this.result = res as any;
        if (this.result.status == false) {
          Swal.fire({
            title: this.result.message,
            icon: 'error'
          });
        } else {
          this.toastr.success("資料已儲存");
          this.refreshFormTaskDetailData();
        }
      });
  }


  openContractDetailAddModal(inquiryDataId: any, contract, actions) {
    const modalRef = this.modalService.open(ContractAddComponent, { windowClass: 'contract' });
    modalRef.componentInstance.contractTypes = this.parentComp.contractTypes;
    modalRef.componentInstance.pccesData = this.pccesData;
    modalRef.componentInstance.inquiryData = this.inquiryData.filter((item) => item._id == inquiryDataId)[0];
    modalRef.componentInstance.formTaskId = this.formTaskId;
    modalRef.componentInstance.inquiryDataId = inquiryDataId;
    modalRef.componentInstance.contract = contract;
    modalRef.componentInstance.actions = actions;
    modalRef.closed.subscribe((result) => {
      this.refreshFormTaskDetailData();
    });
  }

  openModal(type, id: any, actions) {
    let modalRef = null;
    if (type == 'inquiry-detail-add') {
      modalRef = this.modalService.open(InquiryDetailAddComponent, { windowClass: 'inquiry-detail-add' });
      modalRef.componentInstance.pccesData = this.pccesData;
      modalRef.componentInstance.inquiryData = this.inquiryData.filter((item) => item._id == id)[0];
      modalRef.componentInstance.formTaskId = this.formTaskId;
      modalRef.componentInstance.inquiryDataId = id;
      modalRef.componentInstance.actions = actions;
      modalRef.componentInstance.parentComp = this.parentComp;
      modalRef.closed.subscribe((result) => {
        this.refreshFormTaskDetailData();
      });
    }
  }

}
