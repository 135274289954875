<div #MaterialList>
  <div class="row" id="m_{{k2}}">
    <div class="col-md-5">
      <div class="form-group">
        <input type="text" class="form-control" name="materialItem" data-label="材料送審項目{{k2+1}}:項次名稱"
          class="form-control updateDataByColumn updateDataByListCollection" data-subdocId="{{id}}"   data-collectionName="material">
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <input type="text" class="form-control" name="materialName" data-label="材料送審項目{{k2+1}}:材料/設備名稱"
          class="form-control updateDataByColumn updateDataByListCollection" data-subdocId="{{id}}"   data-collectionName="material">
      </div>
    </div>
    <div class="col-md-1">
      <div class="form-group">
        <button type="button" class="btn btn-tool text-danger" data-toggle="tooltip" title="刪除" (click)="del(k2)">
          <i class="fas fa-trash-alt"></i>
        </button>
      </div>
    </div>
  </div>
</div>
