<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>PCCES物料編碼管理</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a [routerLink]="['/']" href="/">Home</a></li>
          <li class="breadcrumb-item active">PCCES物料編碼管理</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <button type="button" *ngIf="this.RightObj['insert']"  class="btn btn-info float-right" (click)="open('add', null)">
          <i class="fas fa-plus"></i> 新增
        </button>
        <h5 class="modal-title">PCCES物料編碼管理</h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
              class="table table-striped table-bordered table-hover nowrap">
              <thead>
                <tr>
                  <th>編碼</th>
                  <th>編碼類型</th>
                  <th>物料名稱</th>
                  <th>單位</th>
                  <th>編輯</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of pccesCodes">
                  <td>{{ item._id }}</td>
                  <td>{{ item.itemKind }}</td>
                  <td>{{ item.description}}</td>
                  <td>{{ item.unit }}</td>
                  <td>
                    <button type="button" *ngIf="this.RightObj['update']" class="btn btn-tool " data-widget="editData" data-toggle="tooltip"
                      title="修改PCCES物料編碼" (click)="open('edit', item)">
                      <i class="fas fa-edit"></i>
                    </button>
                    <button type="button" *ngIf="this.RightObj['delete']" class="btn btn-tool text-danger" data-widget="delData" data-toggle="tooltip"
                      title="刪除PCCES物料編碼" (click)="open('delete', item)">
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
  </div>
  <!-- /.card -->
</section>
<!-- /.content -->
