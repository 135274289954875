

<div class="card">
  <div class="card-header">
      <h5 class="card-title mt-2">
          {{title}}
      </h5>
      <div class="card-tools">
          <button  type="button" class="btn bnt-tool float-right mr-2" data-card-widget="collapse" (click)="handleMainMenuAction()">
              <i
                  [ngClass]="isMenuExtended ? 'fas fa-minus' : 'fas fa-plus'"
                  [@rotate]="isMenuExtended"
              ></i>
          </button>
          <ng-container *ngFor="let data of component">
              <button  *ngIf="action.allowEdit" (click)="openModal('inquiry-add','','')" type="button" class="btn btn-info float-right mr-2">
                  <i class="{{data.icon}}"></i> {{data.name}}
              </button>
          </ng-container>
      </div>
  </div>
  <div class="card-body" [@openClose]="isMenuExtended">

    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger_inquiry"
    class="table table-striped table-bordered table-hover nowrap" id="inquiry_table">
    <thead>
      <tr>
        <th>廠商名稱</th>
        <th>統一編號</th>
        <th>聯絡人</th>
        <th>電話號碼</th>
        <th>既有/開發</th>
        <th>不良紀錄</th>
        <th>預付款</th>
        <th>總金額</th>
        <th>比例</th>
        <th>備註</th>
        <th>物料明細</th>
        <th>報價單</th>
        <th *ngIf="action.allowEdit">刪除</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let datas of inquiryData;">
        <tr *ngIf="datas.supplier !== null">
          <td>{{datas.supplier?.supplierName}}</td>
          <td>{{datas.supplier?.businessNo}}</td>
          <td>{{datas.supplier?.contactName}}</td>
          <td>{{datas.supplier?.contactPhone}}</td>
          <td>{{datas.new?"開發": "既有"}}</td>
          <td>{{datas.supplier?.badRecord? "是":"否"}}</td>
          <td>
            <span *ngIf="datas.prepayMoney">{{datas.prepayMoney| currency:'TWD': 'symbol':'1.0'}}</span>
            <span *ngIf="!datas.prepayMoney">0</span>
          </td>
          <td>
            <span *ngIf="datas.subtotal">{{datas.subtotal| currency:'TWD': 'symbol':'1.0'}}</span>
            <span *ngIf="!datas.subtotal">0</span>
          </td>
          <td>{{datas.prepayMoney && datas.subtotal? ((datas.prepayMoney)/(datas.subtotal)*100).toFixed(2)+"%":
            '0%'}}</td>
          <td>{{datas.supplier?.remark}}</td>
          <td>
            <button *ngIf="action.allowEdit" type="button" class="btn btn-info"
              (click)="openModal('inquiry-detail-add', datas._id, action)">編輯
            </button>
            <button *ngIf="!(action.allowEdit)" type="button" class="btn btn-default"
              (click)="openModal('inquiry-detail-add', datas._id, action)">檢視
            </button>
          </td>
          <td>
            <span *ngIf="action.allowEdit" class="btn btn-info btn-file mr-2">
              上傳
              <input #fileUpload type="file" accept=".pdf" (change)="quotationUpload($event, datas._id)">
            </span>

            <div *ngIf="loaded !== 0 && load_selected === 'quotation'" class="progress my-3">
              <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                attr.aria-valuenow="{{ loaded }}" aria-valuemin="0" aria-valuemax="100"
                [ngStyle]="{ width: loaded+ '%' }">
                {{ loaded }}%
              </div>
            </div>

            <button *ngIf="!(action.allowEdit) || datas.quotation" type="button"
              (click)="viewPdf(datas.quotation)" class="btn btn-default">檢視
            </button>
          </td>
          <td *ngIf="action.allowEdit" style="text-align: center;"><a style="cursor: pointer;"
              (click)="deleteInquiryData(datas._id)"><i class="fas fa-trash-alt text-danger mr-2"></i></a></td>

        </tr>
      </ng-container>

    </tbody>
  </table>



  </div>
</div>






