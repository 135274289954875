import { filter } from 'rxjs/operators';
import { ApiService } from '@services/api.service';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ActivatedRoute, Router } from '@angular/router';


import { AddFormAddComponent } from './add-form-add/add-form-add.component';
import { FormtaskapiService } from '@services/formtaskapi.service';
import { RightService } from '@services/right.service';
import { HelperService } from '@services/helper.service';
import { ProjectApiService } from '@services/projectapi.service';

@Component({
  selector: 'app-add-form',
  templateUrl: './add-form.component.html',
  styleUrls: ['./add-form.component.scss']
})

export class AddFormComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};
  @Output() formSignLog = new EventEmitter<object>();

  @Input() tableData;
  @Input() loginUserId;
  @Input() workTypesList;
  @Input() projectId;
  @Output() go = new EventEmitter<object>();

  public workTypeName = [];

  constructor(
    public apiService: FormtaskapiService,
    public apiService1: ApiService,
    public router: Router,
    public rightService: RightService,
    public helperService: HelperService,
    public ProjectApiService: ProjectApiService,
  ) {

  }


  ngOnInit(): void {
  }


  ngOnChanges(changes: SimpleChanges): void {

    if (changes.tableData != undefined && changes.tableData.currentValue) {
      this.tableData.forEach((element, index) => {
        element['showSignBtn'] = false;
        if (element.taskSiteList.filter(w => w['current'] && w['signer'] == this.loginUserId).length > 0) {
          element['showSignBtn'] = true;
        }
      });
      if (changes.tableData.previousValue != undefined && this.dtElement.dtInstance != undefined) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          // dtInstance.destroy();
          // this.dtTrigger.next(null);
        });
      } else {
        this.dtOptions = this.helperService.setDtOptions();
        this.dtTrigger.next(null);
      }
    }

  }




  sgo(data, detail) {
    this.go.emit({ data: data, detail: detail });
  }

  sformSignLog(_id) {
    this.formSignLog.emit({ _id: _id });
  }

}
