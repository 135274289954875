import { filter } from 'rxjs/operators';
import { ApiService } from '@services/api.service';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ActivatedRoute, Router } from '@angular/router';


import { PurchaseFormAddComponent } from './purchase-form-add/purchase-form-add.component';
import { FormtaskapiService } from '@services/formtaskapi.service';
import { RightService } from '@services/right.service';
import { HelperService } from '@services/helper.service';

@Component({
  selector: 'app-purchase-form',
  templateUrl: './purchase-form.component.html',
  styleUrls: ['./purchase-form.component.scss']
})

export class PurchaseFormComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};

  @Input() tableData;
  @Input() loginUserId;
  @Input() workTypesList;
  @Input() projectId;
  @Output() go = new EventEmitter<object>();
  @Output() formSignLog = new EventEmitter<object>();

  public workTypeName = [];

  constructor(
    public apiService: FormtaskapiService,
    public apiService1: ApiService,
    public router: Router,
    public rightService: RightService,
    public helperService: HelperService,
  ) {

  }


  ngOnInit(): void {

  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.workTypesList != undefined && changes.workTypesList.currentValue) {
      this.tableData.forEach((element, index) => {
        var current = element.formTaskContent?.purchaseForm?.workType?._id;
        this.workTypeName[index] = this.mappingWorkType(current);
      })

    }

    if (changes.tableData != undefined && changes.tableData.currentValue) {

      this.tableData.forEach((element, index) => {
        var current = element.formTaskContent?.purchaseForm?.workType?._id;
        this.workTypeName[index] = this.mappingWorkType(current);

        element['showSignBtn'] = false;
        if (element.taskSiteList.filter(w => w['current'] && w['signer'] == this.loginUserId).length > 0) {
          element['showSignBtn'] = true;
        }
      })

      if (changes.tableData.previousValue != undefined && this.dtElement.dtInstance != undefined) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          // dtInstance.destroy();
          // this.dtTrigger.next(null);
        });
      } else {
        this.dtOptions = this.helperService.setDtOptions();
        this.dtTrigger.next(null);
      }
    }

  }

  mappingWorkType(current) {
    if (current != null) {
      var element = this.workTypesList.filter(w => w._id == current)[0];
      if (element != undefined) {
        var selectText = element['workTypeName'];
        if (element['rootWorkTypeId'] != '' && element['rootWorkTypeId'] != null) {
          selectText = this.helperService.getRootWorkType(this.workTypesList.sort((a, b) => a.sort - b.sort), selectText, element['rootWorkTypeId'])
        }
        return selectText;
      }
    }
  }



  sgo(data, detail) {
    this.go.emit({ data: data, detail: detail });
  }
  sformSignLog(_id) {
    this.formSignLog.emit({ _id: _id });
  }

}
