import { filter } from 'rxjs/operators';
import { ApiService } from '@services/api.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { FormtaskapiService } from '@services/formtaskapi.service';
import { Router } from '@angular/router';
import { HelperService } from '@services/helper.service';
import { ProjectApiService } from '@services/projectapi.service';
import { Select2OptionData } from 'ng-select2';
import { UUID } from 'angular2-uuid';

@Component({
  selector: 'app-add-form-add',
  templateUrl: './add-form-add.component.html',
  styleUrls: ['./add-form-add.component.scss']
})
export class AddFormAddComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    public master_apiService: ApiService,
    public apiService: FormtaskapiService,
    public router: Router,
    public helperService: HelperService,
    public ProjectApiService: ProjectApiService,
  ) { }


  addFormSection = new FormGroup({
    emergencyTypeId: new FormControl('', Validators.required),
    addSubTypeId: new FormControl('', Validators.required),
    addSubScheduleTypeId: new FormControl('', Validators.required),
    changeDate: new FormControl('', Validators.required),
    changeContent: new FormControl('', Validators.required),
    addSubChangeTypeId: new FormControl('', Validators.required),
    remark: new FormControl(''),
  });

  @Input() emergencyTypeList;
  @Input() formAndProjectData
  @Input() tableData;

  projectId;

  saveAddForm(type: String) {
    let max_addNo = ('00' + localStorage.getItem('max_addNo')).slice(-3);
    var flowGroup = null;
    var addSubTypeId = this.addFormSection.controls['addSubTypeId'].value;
    var addSubScheduleTypeId = this.addFormSection.controls['addSubScheduleTypeId'].value;

    if (this.helperService.checkVal(addSubTypeId) && this.helperService.checkVal(addSubScheduleTypeId)) {
      flowGroup = this.s2AddSubScheduleTypes.filter(w => w.id == addSubScheduleTypeId)[0]['text'] + "X" + this.s2AddSubTypes.filter(w => w.id == addSubTypeId)[0]['text'];
    }

    var Notype = (this.addFormSection.controls['addSubTypeId'].value == "20221107160120699") ? "業" : "自";
    let formdata = {
      formId: this.formAndProjectData.formId,
      formTaskNo: this.formAndProjectData.projectNo + '-' + Notype + max_addNo,
      taskSiteList: null,
      emergencyTypeId: this.addFormSection.controls['emergencyTypeId'].value,
      flowGroup: flowGroup,
      formTaskContent: {
        addForm: {
          projectId: this.formAndProjectData.projectId,
          addNo: max_addNo,
          addSubTypeId: addSubTypeId,
          addSubScheduleTypeId: addSubScheduleTypeId,
          changeDate: this.addFormSection.controls['changeDate'].value,
          changeContent: this.addFormSection.controls['changeContent'].value,
          addSubChangeTypeId: this.addFormSection.controls['addSubChangeTypeId'].value,
          remark: this.addFormSection.controls['remark'].value,
        }
      }
    };
    if (!this.addFormSection.valid) {
      Swal.fire({
        title: "請填寫必填欄位",
        icon: 'warning',
        confirmButtonColor: "#17a2b8",
      });
      return;
    }
    this.apiService
      .addFormTask(formdata)
      .subscribe((res) => {
        if (res.status == false) {
          Swal.fire({
            title: res.message,
            icon: 'error'
          });
        } else {
          this.activeModal.close();
          this.router.navigateByUrl('/formTask/' + this.formAndProjectData.formId + "/" + this.formAndProjectData.projectId + "/" + res._id + "/" + res.taskStatusId + "/" + "false");
        }
      });
  }


  ngOnInit(): void {
    this.projectId = this.formAndProjectData.projectId;
    this.ddlAddSubType();
    setTimeout(() => {
      this.ddlAddSubScheduleType();
    }, 100);
    this.ddlAddSubChangeType();
  }


  s2AddSubTypes: Select2OptionData[];
  AllAddSubType = [];
  async ddlAddSubType() {
    let arrAddSubType = [];
    let k = 0;
    (await this.master_apiService.getAddSubTypes()).subscribe((res) => {
      this.AllAddSubType = (res as any);
      this.AllAddSubType.forEach(element => {
        arrAddSubType.push({ id: element['_id'], text: element['addSubTypeName'] });
        k++;
        if (k == this.AllAddSubType.length) {
          arrAddSubType = arrAddSubType.sort((a, b) => a.text.localeCompare(b.text))
          this.s2AddSubTypes = arrAddSubType;
        }
      });
    });
  }

  s2AddSubScheduleTypes: Select2OptionData[];
  AllAddSubScheduleType = [];
  async ddlAddSubScheduleType() {
    let arrAddSubScheduleType = [];
    let k = 0;
    (await this.master_apiService.getAddSubScheduleTypes()).subscribe((res) => {
      this.AllAddSubScheduleType = (res as any);
      this.AllAddSubScheduleType.forEach(element => {
        arrAddSubScheduleType.push({ id: element['_id'], text: element['addSubScheduleTypeName'] });
        k++;
        if (k == this.AllAddSubType.length) {
          arrAddSubScheduleType = arrAddSubScheduleType.sort((a, b) => a.text.localeCompare(b.text))
          this.s2AddSubScheduleTypes = arrAddSubScheduleType;
        }
      });
    });
  }

  s2AddSubChangeTypes: Select2OptionData[];
  AllAddSubChangeType = [];
  async ddlAddSubChangeType() {
    let arrAddSubChangeType = [];
    let k = 0;
    (await this.master_apiService.getAddSubChangeTypes()).subscribe((res) => {
      this.AllAddSubChangeType = (res as any);
      this.AllAddSubChangeType.forEach(element => {
        arrAddSubChangeType.push({ id: element['_id'], text: element['addSubChangeTypeName'] });
        k++;
        if (k == this.AllAddSubChangeType.length) {
          arrAddSubChangeType = arrAddSubChangeType.sort((a, b) => a.text.localeCompare(b.text))
          this.s2AddSubChangeTypes = arrAddSubChangeType;
        }
      });
    });
  }



  onChange_EmergencyType(val: string) {
    this.addFormSection.controls['emergencyTypeId'].setValue(val);
  }

  onChange_AddSubType(val: string) {
    this.addFormSection.controls['addSubTypeId'].setValue(val);
  }

  onChange_AddSubScheduleType(val: string) {
    this.addFormSection.controls['addSubScheduleTypeId'].setValue(val);
  }

  onChange_AddSubChangeType(val: string) {
    this.addFormSection.controls['addSubChangeTypeId'].setValue(val);
  }

}
