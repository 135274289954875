import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@services/api.service';
import { ProjectApiService } from '@services/projectapi.service';
import { Subject } from 'rxjs';
import { RightService } from '@services/right.service';
import { HelperService } from '@services/helper.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-project-material',
  templateUrl: './project-material.component.html',
  styleUrls: ['./project-material.component.scss']
})
export class ProjectMaterialComponent implements OnInit {
  @Input() projectId;

  getRight: any;
  RightObj: {};
  dtOptions: DataTables.Settings = {};

  public result;
  public materials;

  constructor(
    public activeModal: NgbActiveModal,
    public rightService: RightService,
    public helperService: HelperService,
    public apiService: ApiService,
    public ProjectApiService: ProjectApiService,
    private modalService: NgbModal
  ) {
    this.getRight = this.rightService.getRight(); }

  dtTrigger: Subject<any> = new Subject<any>();

  ngOnInit(): void {
    this.dtOptions = this.helperService.setDtOptions();
    this.RightObj = this.getRight['__zone_symbol__value'];
    this.reloadData();
  }


  async reloadData() {
    (await this.ProjectApiService.getMaterial(this.projectId)).subscribe((res) => {
        this.materials = res as any;
        this.dtTrigger.next(null);
    });

  }


  download(url) {
    this.apiService.download(url).subscribe((res) => {
      if (url.indexOf('pdf') > -1) {
        this.apiService.downLoadFile(res, 'application/pdf');
      }
    },
      (err) => {
        Swal.fire({
          title: '系統找不到指定的檔案',
          confirmButtonText: '確定',
          confirmButtonColor: '#17A2B8',
          icon: 'error'
        });
      });
  }
}
