<app-purchase-form-process-section1 *ngIf="component[1].actions.allowShow"
  (refreshFormTaskDetailData)="refreshFormTaskDetailData()" [title]="component[1].title" [formTaskNo]="formTaskNo"
  [action]="component[1].actions" [component]="component[1].btns" [parentComp]="this" [isMenuExtended]="true"
  [formAndProjectData]="formAndProjectData" [detailData]="detailData" [formTaskId]="formTaskId">
</app-purchase-form-process-section1>


<app-purchase-form-process-section2 *ngIf="component[2].actions.allowShow" [title]="component[2].title"
  [action]="component[2].actions" [component]="component[2].btns" [isMenuExtended]="true" [formTaskId]="formTaskId"
  [taskStatusId]="taskStatusId">
</app-purchase-form-process-section2>


<app-purchase-form-process-section3 *ngIf="component[3].actions.allowShow" [title]="component[3].title"
  [action]="component[3].actions" [component]="component[3].btns" [isMenuExtended]="true" [formTaskId]="formTaskId"
  [taskStatusId]="taskStatusId" [scope]="scope" [rule]="rule">
</app-purchase-form-process-section3>

<app-purchase-form-process-section4 *ngIf="component[4].actions.allowShow" [title]="component[4].title"
  [action]="component[4].actions" [component]="component[4].btns" [parentComp]="this" [isMenuExtended]="true"
  [formTaskId]="formTaskId" [taskStatusId]="taskStatusId">
</app-purchase-form-process-section4>

<app-purchase-form-process-section5 *ngIf="component[5].actions.allowShow" [title]="component[5].title"
  [action]="component[5].actions" [component]="component[5].btns" [parentComp]="this" [isMenuExtended]="true"
  [formTaskId]="formTaskId" [taskStatusId]="taskStatusId">
</app-purchase-form-process-section5>


<app-purchase-form-process-section6 *ngIf="component[6].actions.allowShow" [title]="component[6].title"
  [action]="component[6].actions" [component]="component[6].btns" [parentComp]="this" [isMenuExtended]="true"
  [formAndProjectData]="formAndProjectData" [formTaskId]="formTaskId" [taskStatusId]="taskStatusId">
</app-purchase-form-process-section6>
