<div class="modal-header">
    <h4 class="modal-title">修改表單</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="editNewForm">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>表單編號</label>
            <input type="text" formControlName="formCode" class="form-control" required>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label>表單名稱</label>
            <input type="text" formControlName="formName" class="form-control" required>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label>表單欄位名稱</label>
            <input type="text" formControlName="formEName" class="form-control" required>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group">
            <label>專案階段</label>
            <ng-select2
                    width="100%"
                    required
                    formControlName="projectStepId">
              <option *ngFor="let data of projectStepList" [value]="data._id">{{data.stepName}}</option>
            </ng-select2>
          </div>
        </div>


      </div>
    </form>
</div>
<div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-default btn-outline-dark" (click)="activeModal.close('Close click')">關閉</button>
    <button class="btn btn-info" (click)="editFormData()">確定</button>
</div>
