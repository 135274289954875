import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '@services/api.service';
import { HelperService } from '@services/helper.service';
import { ProjectApiService } from '@services/projectapi.service';
import { Select2OptionData } from 'ng-select2';

@Component({
  selector: 'app-other-fee-card',
  templateUrl: './other-fee-card.component.html',
  styleUrls: ['./other-fee-card.component.scss']
})
export class OtherFeeCardComponent implements OnInit {
  @Input() k1;
  @Input() id;
  @Input() formTaskId;
  constructor(
    public apiService: ApiService,
    public ProjectApiService: ProjectApiService,
    public helperService: HelperService,
    public master_apiService:ApiService
  ) { }

  options: any = {
    multiple: true
  };

  ngOnInit(): void {
  this.ddlPaymentType();
  var whereObj = [{ key: "_id", value: this.formTaskId, subdocName: "" }, { key: "_id", value: this.id, subdocName: "formTaskContent.calculateForm.otherFeeList", subdocumnet_type: "list" }]
  this.helperService.callUpdate("formTask", whereObj, this.formTaskId, "isPlus", "false", "其他加項減項" + (this.k1+1) + ":加減項", "String")

  }



  s2PaymentTypes: Select2OptionData[];
  AllPaymentType = [];
  async ddlPaymentType() {
    let arrPaymentType = [];
    let k = 0;
    (await this.master_apiService.getPaymentTypes()).subscribe((res) => {
      this.AllPaymentType = (res as any);
      this.AllPaymentType.forEach(element => {
        arrPaymentType.push({ id: element['paymentTypeName'], text: element['paymentTypeName'] });
        k++;
        if (k == this.AllPaymentType.length) {
          arrPaymentType = arrPaymentType.sort((a, b) => a.text.localeCompare(b.text))
          this.s2PaymentTypes = arrPaymentType;
        }
      });
    });
  }

  onChange_PaymentType(val: String, k, subdocId): void {
    if (val == null) val = "";
    if (val != undefined) {
      var whereObj = [{ key: "_id", value: this.formTaskId, subdocName: "" }, { key: "_id", value: subdocId, subdocName: "formTaskContent.calculateForm.otherFeeList", subdocumnet_type: "list" }]
      this.helperService.callUpdate("formTask", whereObj, this.formTaskId, "feeName", val, "其他加項減項" + (k+1) + ":款項名稱", "String")
    }
  }

  del(index) {
    $("#o_" + index).hide();
    (this.helperService.DelDataByColumn("formTask", this.formTaskId, "formTaskContent.calculateForm.otherFeeList",this.id)).subscribe((res) => { });
  }

}
