import { FormTaskProcessApiService } from './../../../services/form-task-process-api.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { openCloseAnimation, rotateAnimation } from './detail-section.animations'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ActionComponent } from '../action/action.component';
import Swal from 'sweetalert2';
import { ApiService } from '@services/api.service';
import { api_url } from '../../../services/api_config';
import { TokenStorageService } from '@services/token-storage.service';

@Component({
  selector: 'app-detail-section',
  templateUrl: './detail-section.component.html',
  styleUrls: ['./detail-section.component.scss'],
  animations: [openCloseAnimation, rotateAnimation]
})
export class DetailSectionComponent implements OnInit {

  public isExpandable: boolean = false;
  public isMainActive: boolean = false;
  public isOneOfChildrenActive: boolean = false;
  @Input() title;
  @Input() action;
  @Input() expand;
  @Input() isMenuExtended;
  @Input() component;
  @Input() parentComp;
  @Output() refreshFormTaskDetailData = new EventEmitter<object>();
  @Input() formId;

  @Input() formTaskId;
  @Input() taskStatusId;

  getUser:any;

  api_url = api_url;
  constructor(
    private modalService: NgbModal,
    private FormTaskProcessApiService: FormTaskProcessApiService,
    private apiService: ApiService,
    private tokenStorage: TokenStorageService
  ) { }

  ngOnInit(): void {
    this.isExpandable = true;
    this.calculateIsActive();
    this.getUser = this.tokenStorage.getUser();

  }
  public handleMainMenuAction() {
    if (this.isExpandable) {
      this.toggleMenu();
      return;
    }
  }
  public toggleMenu() {
    this.isMenuExtended = !this.isMenuExtended;
  }
  public calculateIsActive() {
    this.isMainActive = true;
    this.isOneOfChildrenActive = true;
    if (!this.isMainActive && !this.isOneOfChildrenActive) {
      this.isMenuExtended = false;
    }
  }

  openModal(action) {
    let modalRef = this.modalService.open(ActionComponent, { windowClass: 'action-form' });
    modalRef.componentInstance.parentComp = this.parentComp;
    modalRef.componentInstance.action = action;
  }
}
