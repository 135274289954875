import { AddContractComponent } from './add-contract/add-contract.component';
import { ApiService } from '@services/api.service';
import { filter } from 'rxjs/operators';
import { HelperService } from './../../../../../services/helper.service';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, Renderer2, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { FormTaskProcessApiService } from '@services/form-task-process-api.service';
import { FormSectionService } from '@services/form-section.service';

import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { openCloseAnimation, rotateAnimation } from '../../../../../../app/pages/form-task-process/detail-section/detail-section.animations'
import { ProjectApiService } from '@services/projectapi.service';
import { FormtaskapiService } from '@services/formtaskapi.service';
import { ContractItemDetailSetPriceComponent } from '../add-form-process-section4/contract-item-detail-set-price/contract-item-detail-set-price.component';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-add-form-process-section6',
  templateUrl: './add-form-process-section6.component.html',
  styleUrls: ['./add-form-process-section6.component.scss'],
  animations: [openCloseAnimation, rotateAnimation]

})
export class AddFormProcessSection6Component implements OnInit {
  public isExpandable: boolean = false;
  public isMainActive: boolean = false;
  public isOneOfChildrenActive: boolean = false;
  @Input() title;
  @Input() action;
  @Input() isMenuExtended;
  @Input() component;
  @Input() formTaskId;
  @Input() taskStatusId;
  @Input() projectId;
  @Input() contractId;
  @Input() detailData;
  @Input() workTypesList;

  formId = "";
  dtOptions: DataTables.Settings = {};
  dtTrigger_contractItem: Subject<any> = new Subject<any>();

  total1 = 0;
  total2 = 0;
  result: any;

  constructor(
    public master_apiService: ApiService,
    public apiService: FormTaskProcessApiService,
    public formtaskapiService: FormtaskapiService,

    private toastr: ToastrService,
    public formSectionService: FormSectionService,
    public helperService: HelperService,
    public ProjectApiService: ProjectApiService,
    public elementRef: ElementRef,
    public renderer: Renderer2,
    private modalService: NgbModal,


  ) { }
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  ngOnInit(): void {
    this.dtOptions = this.helperService.setDtOptions();

    this.isExpandable = true;
    this.formSectionService.calculateIsActive(this.isMenuExtended);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.detailData != undefined && changes.detailData.currentValue) {
      this.helperService.AutoSave(this.renderer, this.elementRef.nativeElement, "formTask", this.detailData?._id);

      this.formId = this.detailData['formId'];

      localStorage.setItem('inputValidate6', "true");
      this.detailData?.formTaskContent?.addForm?.contractItemList.forEach(element => {
        // if (!this.helperService.checkVal(element['subContractId'])) {
        //   localStorage.setItem('inputValidate6', "false");
        // }

        var dTotal1 = 0;
        var dTotal2 = 0;
        if (element['contractDetailList'] != null) {
          element['contractDetailList'].forEach(element2 => {
            if (parseInt(element2['quantity']) > 0) {
              this.total1 += (element2['quantity'] * element2['price']);
              dTotal1 += (element2['quantity'] * element2['price']);
            } else {
              this.total2 += (element2['quantity'] * element2['price']);
              dTotal2 += (element2['quantity'] * element2['price']);
            }
          })
        }
        element['total'] = dTotal1 + dTotal2;
        element['percent'] = this.roundDecimal(parseFloat(element['prePay']) / parseFloat(element['total']) * 100, 2) + "%";

      });


      if (changes.detailData.previousValue != undefined && this.dtElement.dtInstance != undefined) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          // dtInstance.destroy();
          // this.dtTrigger.next(null);
        });
      } else {
        setTimeout(() => {
          this.dtOptions = this.helperService.setDtOptions();
          this.dtTrigger_contractItem.next(null);
        }, 1000);

      }
    }
    if (changes.workTypesList != undefined && changes.workTypesList.currentValue) {
      this.getContract();
    }
  }

  contract: [];
  async getContract() {
    (await this.ProjectApiService.getContact(this.projectId)).subscribe((res) => {
      this.contract = (res as any).filter(w => this.helperService.checkVal(w.validateDate) || w.addFormId != null);
      this.detailData?.formTaskContent?.addForm?.contractItemList.forEach(element => {
        var thisContract = this.contract.filter(w => w['_id'] == element['contractId'])[0];
        element['workTypeName'] = this.mappingWorkType(thisContract['workType']['_id']);
        this.master_apiService.getSupplierById(thisContract['supplierId']).subscribe((res) => {
          element['supplierName'] = res['supplierName'];
          element['businessNo'] = res['businessNo'];
          element['contactName'] = res['contactName'];
          element['contactPhone'] = res['contactPhone'];
          element['remark'] = res['remark'];
        })
        // element['newContractNo'] = null;
        // var existContract = this.contract.filter(w => w['addFormId'] == this.detailData?.formTaskContent?.addForm?._id && w['masterContractId'] == element['contractId'])[0];
        // if (existContract != null) {
        //   element['newContractNo'] = existContract['contractNo'];
        //   if(this.helperService.checkVal(existContract['contractFile']) && this.helperService.checkVal(existContract['contractFile']))
        //   newContract++;
        // }
      });
    })
  }

  mappingWorkType(current) {
    if (current != null) {
      var element = this.workTypesList.filter(w => w._id == current)[0];
      if (element != undefined) {
        var selectText = element['workTypeName'];
        if (element['rootWorkTypeId'] != '' && element['rootWorkTypeId'] != null) {
          selectText = this.helperService.getRootWorkType(this.workTypesList.sort((a, b) => a.sort - b.sort), selectText, element['rootWorkTypeId'])
        }
        return selectText;
      }
    }
  }

  async refresh() {
    (await this.apiService.getFormTaskDetailData(this.formTaskId, this.taskStatusId)).subscribe((res) => {
      this.detailData = res;
      localStorage.setItem('inputValidate6', "true");
      this.detailData?.formTaskContent?.addForm?.contractItemList.forEach(element => {
        // if (!this.helperService.checkVal(element['subContractId'])) {
        //   localStorage.setItem('inputValidate6', "false");
        // }

        var dTotal1 = 0;
        var dTotal2 = 0;
        if (element['contractDetailList'] != null) {
          element['contractDetailList'].forEach(element2 => {
            if (parseInt(element2['quantity']) > 0) {
              dTotal1 += (element2['quantity'] * element2['price']);
            } else {
              dTotal2 += (element2['quantity'] * element2['price']);
            }
          })
        }
        element['total'] = dTotal1 + dTotal2;
        element['percent'] = this.roundDecimal(parseFloat(element['prePay']) / parseFloat(element['total']) * 100, 2) + "%";
      });
      this.getContract();

      // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      //   dtInstance.destroy();
      //   this.dtTrigger_contractItem.next(null);
      // });
    })
  }


  handleMainMenuAction() {
    this.formSectionService.handleMainMenuAction(this.isExpandable, this.isMenuExtended)
  }


  openModal(action, datas) {
    let modalRef = null;
    if (action == 'setPrice') {
      modalRef = this.modalService.open(ContractItemDetailSetPriceComponent, { windowClass: 'setPrice', size: 'xl' });
      modalRef.componentInstance.projectId = this.projectId;
      modalRef.componentInstance.formTaskId = this.formTaskId;
      modalRef.componentInstance.contractItemList = datas;
      var newAction = JSON.parse(JSON.stringify(this.action));
      newAction.allowEdit = false;
      modalRef.componentInstance.action = newAction;
      modalRef.componentInstance.detailData = this.detailData;

      modalRef.componentInstance.showDownloadBtn=true;
      modalRef.componentInstance.MasterId=datas.contractId;

      modalRef.componentInstance.refresh.subscribe((result) => {
        this.refresh();
      })
    } else if (action == 'contract') {
      modalRef = this.modalService.open(AddContractComponent, { windowClass: 'contract' });
      modalRef.componentInstance.projectId = this.projectId;
      modalRef.componentInstance.formTaskId = this.formTaskId;
      modalRef.componentInstance.contractItemList = datas;
      modalRef.componentInstance.action = this.action;
      modalRef.componentInstance.detailData = this.detailData;
      modalRef.componentInstance.MasterId = datas.contractId;

      modalRef.closed.subscribe((result) => {
        this.refresh();
      });

    }
  }


  viewPdf(url) {
    this.formSectionService.viewPdf(url);
  }


  roundDecimal = function (val, precision) {
    return Math.round(Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10) / Math.pow(10, (precision || 0));
  }

  createContractNo(element) {
    Swal.fire({
      title: '確定取得合約編號?',
      // text: 'Really, Would you delete this record?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: "#17a2b8",
      confirmButtonText: '確定',
      cancelButtonText: '取消',
      focusCancel: true
    }).then((result) => {
      if (result.value) {
        this.apiService.addContractItemNo(this.formTaskId, element['contractId'], element['contractDetailList'])
          .subscribe((res) => {
            this.result = res as any;
            if (this.result.status == false) {
              Swal.fire({
                title: this.result.message,
                icon: 'error'
              });
            } else {
              element['subContractNo'] = res['newContractNo'];
              this.refresh();
            }
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log();
      }
    })
  }

}
