import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { api_url } from './api_config'
@Injectable({
  providedIn: 'root'
})
export class FormtaskapiService {

  constructor(
    private http: HttpClient
  ) { }

  async getForm(param): Promise<Observable<any>> {
    try {
      const result = this.http.get(api_url + '/formManage/' + param.formId);
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  async getBasicData(param): Promise<Observable<any>> {
    try {
      const result = this.http.get(api_url + '/formTask/init/' + param.formId + "/" + param.projectId);
      return result;
    } catch (error) {
      console.log(error);
    }
  }
  async getFormTask(param): Promise<Observable<any>> {
    try {
      const result = this.http.get(api_url + '/formTask/filter/' + param.formId + '/' + param.projectId + '/' + param.taskStatusId);
      return result;
    } catch (error) {
      console.log(error);
    }
  }
  getFormTaskMax(formTaskNo): Observable<any> {
    try {
      const result = this.http.get(
        api_url + '/formTask/getFormTaskMax/' + formTaskNo
      );
      return result;
    } catch (error) {
      console.log(error);
    }
  }
  addFormTask(postData): Observable<any> {
    try {
      const result = this.http.post(
        api_url + '/formTask',
        postData
      );
      return result;
    } catch (error) {
      console.log(error);
    }
  }


  async getCalculateNumberMax(contractId, projectId): Promise<Observable<any>> {
    try {
      const result = this.http.get(
        api_url + '/formTask/getCalculateNumberMax/' + contractId + "/" + projectId
      );
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  getEmployeeById(empId): Observable<any> {
    try {
      const result = this.http.get(api_url + '/employee/' + empId);
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  getAllFormTask(sDate = '', eDate = ''): Observable<any> {
    try {
      const result = this.http.post(api_url + '/formSign/', {
        sDate: sDate,
        eDate: eDate
      });
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  getFormTaskProcess(_id): Observable<any> {
    try {
      const result = this.http.get(api_url + '/formSign/findProcessById/' + _id);
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  getTaskStatus(): Observable<any> {
    try {
      const result = this.http.get(api_url + '/taskStatus');
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  findCalculateFormByContractId(contractId, calculateNumber): Observable<any> {
    try {
      const result = this.http.get(api_url + '/formTask/findByCalculateNumber/' + contractId + "/" + calculateNumber);
      return result;
    } catch (error) {
      console.log(error);
    }
  }
}
