<div class="modal-header">
  <h4 class="modal-title">查詢現有物料與合約
  </h4>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close"
    (click)="activeModal.close('Close click')">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-3">
      <div class="form-group">
        <label><span class="text-danger">*</span>選擇工項</label>
        <ng-select2 width="100%" placeholder="工項名稱" [data]="s2WorkTypes"  [allowClear]="true" (valueChanged)="onChange_WorkTypes($event)">
        </ng-select2>
      </div>
    </div>
    <div class="col-md-9">
      <div class="form-group">
        <label>物料編號與名稱</label>
        <input type="text" class="form-control" [(ngModel)]="queryPcces"
          placeholder="0279622112 密級配改質瀝青混凝土鋪面 第二類型 粗粒料50mm 改質Ⅰ型 工廠交貨">
      </div>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-md-12">
      <button (click)="query()" type="button" class="btn btn-info float-left mr-2">
        查詢
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
        class="table table-striped table-bordered table-hover">
        <thead>
          <tr>
            <th>選擇</th>
            <th>合約編號</th>
            <th>物料</th>
            <th>廠商</th>
            <th>圖說</th>
            <th>規格</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of contractList">
            <td style="text-align: center;">
              <label>
                <input *ngIf="item.ckb" type="checkbox" value="{{item._id}}" name="ckbItem">
                <input *ngIf="!item.ckb" disabled type="checkbox" value="" name="ckbItem" checked>

              </label>
            </td>
            <td>{{item.contractNo}}</td>
            <td>{{item.pccesId}}<br>{{item.pccesDes}}</td>
            <td>{{item.supplierName}}</td>
            <td>{{item.diagramNo}}</td>
            <td>{{item.spec}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="modal-footer justify-content-between">
  <button type="button" class="btn btn-default" data-dismiss="modal"
    (click)="activeModal.close('Close click')">關閉</button>
  <button type="button" class="btn btn-info" (click)="save()">新增</button>
</div>
