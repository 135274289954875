<div class="card">
  <div class="card-header">
    <h5 class="card-title mt-2">
      {{title}}
    </h5>
    <div class="card-tools">
      <button type="button" class="btn bnt-tool float-right mr-2" data-card-widget="collapse"
        (click)="handleMainMenuAction()">
        <i [ngClass]="isMenuExtended ? 'fas fa-minus' : 'fas fa-plus'" [@rotate]="isMenuExtended"></i>
      </button>
      <a *ngIf="taskStatusId === '2022102718360004'" href="{{api_url}}/formTask/setCalculateExcel/{{formTaskId}}/{{taskStatusId}}/{{getUser.id}}" class="btn btn-success float-left mr-2">
        報表下載
      </a>
    </div>
  </div>
  <div class="card-body" [@openClose]="isMenuExtended">
    <div class="row">
      <div class="col-md-9">
        <div class="form-group">
          <label>工程名稱</label>
          <input type="text" class="form-control" [value]="detailData?.formTaskContent?.calculateForm?.project.projectName" placeholder="工程名稱" disabled>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>專案編號</label>
          <input type="text" class="form-control" [value]="detailData?.formTaskContent?.calculateForm?.project.projectNo" placeholder="專案編號" disabled>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <div class="form-group">
          <label>工項名稱</label>
          <input type="text" class="form-control" [value]="workTypeName" placeholder="工項名稱" disabled>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>廠商名稱</label>
          <input type="text" class="form-control"
            [value]="detailData?.formTaskContent?.calculateForm?.supplier?.supplierName" placeholder="廠商名稱" disabled>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>合約編號</label>
          <input type="text" class="form-control"
            [value]="detailData?.formTaskContent?.calculateForm?.contract?.contractNo" placeholder="合約編號" disabled>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>預付款</label>
          <input type="text" class="form-control" [value]="prepay" placeholder="預付款" disabled>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <div class="form-group">
          <label>估驗期數</label>
          <input type="text" class="form-control" [value]="detailData?.formTaskContent?.calculateForm?.calculateNumber"
            placeholder="估驗期數" disabled>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>估驗日期</label>
          <input *ngIf="action.allowEdit" type="date" name="calculateDate" placeholder="估驗日期"
            [value]="detailData?.formTaskContent?.calculateForm?.calculateDate | date:'yyyy-MM-dd':'CST'"
            class="form-control  updateDataByColumn updateDataByListColumn" data-label="估驗日期"
            data-subdocName="formTaskContent.calculateForm"
            data-subdocId="{{detailData?.formTaskContent?.calculateForm?._id}}" data-subdocumnet_type="">

          <input *ngIf="!action.allowEdit" disabled type="date" class="form-control"
            [value]="detailData?.formTaskContent?.calculateForm?.calculateDate | date:'yyyy-MM-dd':'CST'" placeholder="估驗日期">
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label><span class="text-danger">*</span>發票號碼</label>
          <input *ngIf="action.allowEdit" type="text" name="invoiceNumber" placeholder="發票號碼"
            [value]="detailData?.formTaskContent?.calculateForm?.invoiceNumber"
            class="form-control  updateDataByColumn updateDataByListColumn" data-label="發票號碼"
            data-subdocName="formTaskContent.calculateForm"
            data-subdocId="{{detailData?.formTaskContent?.calculateForm?._id}}" data-subdocumnet_type="">

          <input *ngIf="!action.allowEdit" disabled class="form-control" type="text" name="invoiceNumber" placeholder="發票號碼"
            [value]="detailData?.formTaskContent?.calculateForm?.invoiceNumber">
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label><span class="text-danger">*</span>發票日期</label>
          <input *ngIf="action.allowEdit" type="date" name="invoiceDate" placeholder="發票日期"
            [value]="detailData?.formTaskContent?.calculateForm?.invoiceDate | date:'yyyy-MM-dd':'CST'"
            class="form-control  updateDataByColumn updateDataByListColumn" data-label="發票日期"
            data-subdocName="formTaskContent.calculateForm"
            data-subdocId="{{detailData?.formTaskContent?.calculateForm?._id}}" data-subdocumnet_type="">

          <input *ngIf="!action.allowEdit" disabled type="date" class="form-control"
            [value]="detailData?.formTaskContent?.calculateForm?.invoiceDate  | date:'yyyy-MM-dd':'CST'" placeholder="發票日期">
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <div class="form-group">
          <label><span class="text-danger">*</span>銷售額</label>

          <input *ngIf="action.allowEdit" type="number" name="amonutNoTax" placeholder="銷售額"
            [value]="detailData?.formTaskContent?.calculateForm?.amonutNoTax"
            class="form-control  updateDataByColumn updateDataByListColumn" data-label="銷售額"
            data-subdocName="formTaskContent.calculateForm"  id="amonutNoTax"
            data-subdocId="{{detailData?.formTaskContent?.calculateForm?._id}}" data-subdocumnet_type=""
            (change)="cTotal(0)">

          <input *ngIf="!action.allowEdit" disabled type="number" class="form-control"
            [value]="detailData?.formTaskContent?.calculateForm?.amonutNoTax" id="amonutNoTax" placeholder="銷售額">

        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label><span class="text-danger">*</span>稅別</label>

          <select *ngIf="action.allowEdit" name="taxType" [value]="c_taxType" id="taxType" (change)="cTotal(0)"
            class="form-control  updateDataByColumn updateDataByListColumn" data-label="稅別"
            data-subdocName="formTaskContent.calculateForm"
            data-subdocId="{{detailData?.formTaskContent?.calculateForm?._id}}" data-subdocumnet_type="">
            <option value="應稅">應稅</option>
            <option value="未稅">未稅</option>
          </select>
          <select *ngIf="!action.allowEdit" disabled class="form-control" [value]="c_taxType" id="taxType">
            <option value="應稅">應稅</option>
            <option value="未稅">未稅</option>
          </select>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label><span class="text-danger">*</span>稅額</label>

          <input *ngIf="action.allowEdit" type="number" name="tax" placeholder="稅額" [value]="c_tax"
            class="form-control  updateDataByColumn updateDataByListColumn" data-label="稅額"
            data-subdocName="formTaskContent.calculateForm" (change)="cTotal(1)" id="tax"
            data-subdocId="{{detailData?.formTaskContent?.calculateForm?._id}}" data-subdocumnet_type="">

          <input *ngIf="!action.allowEdit" disabled type="number" class="form-control" [value]="c_tax" id="tax"
            placeholder="稅額" >
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label><span class="text-danger">*</span>總計</label>
          <input *ngIf="action.allowEdit" type="number" name="amount" placeholder="總計" [value]="c_total"
            class="form-control  updateDataByColumn updateDataByListColumn" data-label="總計"
            data-subdocName="formTaskContent.calculateForm" id="amount"
            data-subdocId="{{detailData?.formTaskContent?.calculateForm?._id}}" data-subdocumnet_type="">

          <input *ngIf="!action.allowEdit" disabled type="number" class="form-control" [value]="c_total"
            placeholder="總計">

        </div>
      </div>
    </div>
  </div>
</div>
