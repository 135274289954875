import { filter } from 'rxjs/operators';
import { ApiService } from '@services/api.service';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { FormtaskapiService } from '@services/formtaskapi.service';
import { Router } from '@angular/router';
import { HelperService } from '@services/helper.service';
import { ProjectApiService } from '@services/projectapi.service';
import { Select2OptionData } from 'ng-select2';
import { UUID } from 'angular2-uuid';
import internal from 'stream';
import { parse } from 'path';

@Component({
  selector: 'app-calculate-form-add',
  templateUrl: './calculate-form-add.component.html',
  styleUrls: ['./calculate-form-add.component.scss']
})
export class CalculateFormAddComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    public master_apiService: ApiService,
    public apiService: FormtaskapiService,
    public router: Router,
    public helperService: HelperService,
    public ProjectApiService: ProjectApiService,
    public formtaskapiService: FormtaskapiService,

  ) { }


  calculateFormSection = new FormGroup({
    emergencyTypeId: new FormControl('', Validators.required),
    contractId: new FormControl('', Validators.required),
  });

  @Input() workTypeList;
  @Input() emergencyTypeList;
  @Input() formAndProjectData
  @Input() tableData;

  projectId;
  workType = new Array();

  s2WorkTypes: Select2OptionData[];
  s2Suppliers: Select2OptionData[];

  saveCalculateForm(type: String) {
    let max_calculateNo = ('00' + localStorage.getItem('max_calculateNo')).slice(-3);
    //主約+附約
    var thisContract = this.contract.filter(w => (w['_id'] == this.calculateFormSection.controls['contractId'].value || w['masterContractId'] == this.calculateFormSection.controls['contractId'].value));
    thisContract = (thisContract as any).sort((a, b) => a._id.localeCompare(b._id));

    var contractDetailList = new Array();

    for (var i = 0; i < thisContract.length; i++) {
      (thisContract[i]['contractDetailList'] as any).forEach(element2 => {
        if (element2['quantity'] < 0) {
          var exist = contractDetailList.filter(w => w['pccesId'] == element2['pccesId'] && w['diagramNo'] == element2['diagramNo'] && w['spec'] == element2['spec'])[0];
          if (exist) {
            exist['quantity'] = parseInt(exist['quantity']) + parseInt(element2['quantity'])
          }
        } else {
          contractDetailList.push(element2);
        }
      });
    }

    var eachQuantity = new Array();
    contractDetailList.forEach(element => {
      eachQuantity.push({ _id: UUID.UUID(), contractDetailId: element['_id'], quantity: 0 })
    })

    var _id = UUID.UUID();
    var _id2 = UUID.UUID();
    var _id3 = UUID.UUID();
    var otherFeeList = [{ _id: _id, feeName: "保留款", isPlus: false, fee: 0 }, { _id: _id2, feeName: "訂金", isPlus: false, fee: 0 }, { _id: _id3, feeName: "郵資", isPlus: false, fee: 0 }];
    let formdata = {
      formId: this.formAndProjectData.formId,
      formTaskNo: this.formAndProjectData.projectNo + '-C' + max_calculateNo,
      taskSiteList: null,
      emergencyTypeId: this.calculateFormSection.controls['emergencyTypeId'].value,
      formTaskContent: {
        calculateForm: {
          projectId: this.formAndProjectData.projectId,
          calculateNo: max_calculateNo,
          contractId: this.calculateFormSection.controls['contractId'].value,
          calculateNumber: this.calculateNumber,
          calculateDate: new Date(),
          contractDetailList: contractDetailList,
          eachQuantity: eachQuantity,
          otherFeeList: otherFeeList,
          taxType: '應稅'
        }
      }
    };
    if (!this.calculateFormSection.valid) {
      Swal.fire({
        title: "請填寫必填欄位",
        icon: 'warning',
        confirmButtonColor: "#17a2b8",
      });
      return;
    }
    this.apiService
      .addFormTask(formdata)
      .subscribe((res) => {
        if (res.status == false) {
          Swal.fire({
            title: res.message,
            icon: 'error'
          });
        } else {
          this.activeModal.close();
          this.router.navigateByUrl('/formTask/' + this.formAndProjectData.formId + "/" + this.formAndProjectData.projectId + "/" + res._id + "/" + res.taskStatusId + "/" + "false");
        }
      });
  }


  ngOnInit(): void {
    this.projectId = this.formAndProjectData.projectId;
    this.getContract();
    // setTimeout(() => {
    //   this.ddlWorkType();
    // }, 100);

  }



  contract: [];
  async getContract() {
    (await this.ProjectApiService.getContact(this.projectId)).subscribe((res) => {
      this.contract = (res as any).filter(w => this.helperService.checkVal(w.validateDate) && w.taskStatusId == "2022102718360004");
      this.ddlWorkType();

      // this.contract.forEach(async element => {
      //   var thisContractDetail = element['contractDetailList'] as any;
      //   var historyEachQuantity = [];
      //   var calculateNumber = this.tableData.filter(w => w.formTaskContent?.calculateForm?.contractId == element['_id']).length;
      //   (await this.formtaskapiService.findCalculateFormByContractId(element['_id'], calculateNumber)).subscribe(async (res) => {
      //     var history = res as any;
      //     history.forEach(element2 => {
      //       element2?.formTaskContent?.calculateForm?.eachQuantity.forEach(element3 => {
      //         historyEachQuantity.push(element3);
      //       });
      //     });

      //     if (historyEachQuantity.length > 0) {
      //       thisContractDetail.forEach(element2 => {
      //         var filterHistory = historyEachQuantity.filter(w => w['contractDetailId'] == element2['_id']);
      //         var sum = 0;
      //         filterHistory.forEach(element3 => {
      //           sum += parseInt(element3['quantity']);
      //         })
      //         if (element2['quantity'] <= sum) {
      //           (element2['complete'] as boolean) = true;
      //         }
      //       });
      //       if (thisContractDetail.filter(w => w['complete'] == true).length != thisContractDetail.length) {
      //         (this.contract as any) = this.contract.filter(w => w['_id'] != element['_id']);
      //       }
      //     }
      //   })
      // })
    });
  }

  async ddlWorkType() {
    let arrWorkType = [];
    this.contract.forEach(element => {
      if (this.workType.filter(w => w['_id'] == element['workType']['_id']).length == 0) {
        this.workType.push(element['workType']);
      }
    });
    this.workType.filter(w => w['_id']).forEach(element => {
      if (element['level'] === 3) {
        var selectText = element['workTypeName'];
        if (element['rootWorkTypeId'] != '' && element['rootWorkTypeId'] != null) {
          selectText = this.helperService.getRootWorkType(this.workTypeList.sort((a, b) => a.sort - b.sort), selectText, element['rootWorkTypeId'])
        }
        arrWorkType.push({ _id: element['_id'], workTypeName: selectText });
      }
    })
    this.workType = arrWorkType;
    this.onChange_WorkTypes(this.workType[0]['_id']);
  }


  supplierList = [];
  async ddlSupplier() {
    this.contract.filter(w => w['workType']['_id'] == this.select_WorkTypes).forEach(element => {
      if (this.supplierList.filter(w => w._id == element['supplierId'] && w.supplierName == element['supplierName']).length == 0) {
        this.supplierList.push({ _id: element['supplierId'], supplierName: element['supplierName'] });
      }
    });
    this.onChange_Suppliers(this.supplierList[0]['_id']);
  }

  onChange_EmergencyType(val: string) {
    this.calculateFormSection.controls['emergencyTypeId'].setValue(val);
  }

  select_WorkTypes: String;
  onChange_WorkTypes(val: String) {
    this.select_WorkTypes = val;
    this.supplierList = [];
    this.contractList = [];
    this.ddlSupplier();
  }

  select_Suppliers: String;
  onChange_Suppliers(val: String) {
    this.select_Suppliers = val;
    this.contractList = [];
    this.ddlContract();
  }

  select_Contracts: String;
  onChange_Contracts(val: string) {
    this.calculateFormSection.controls['contractId'].setValue(val);
    this.select_Contracts = val;
    this.getCalculateNumber(val,this.projectId);
  }

  contractList = [];
  async ddlContract() {
    this.contractList = [];
    this.contract.filter(w => w['workType']['_id'] == this.select_WorkTypes && w['supplierId'] == this.select_Suppliers && !w['masterContractId']).forEach(element => {
      this.contractList.push({ _id: element['_id'], contractNo: element['contractNo'] });
    });
    this.onChange_Contracts(this.contractList[0]['_id']);
  }


  calculateNumber = 0;
  async getCalculateNumber(contractId,projectId) {
    (await this.formtaskapiService.getCalculateNumberMax(contractId,projectId)).subscribe((res) => {
      this.calculateNumber = parseInt(res['max'] as any);
    });
  }
}
