<div class="card">
  <div class="card-header">
    <h5 class="card-title mt-2">
      {{title}}
    </h5>
    <div class="card-tools">
      <button type="button" class="btn bnt-tool float-right mr-2" data-card-widget="collapse"
        (click)="handleMainMenuAction()">
        <i [ngClass]="isMenuExtended ? 'fas fa-minus' : 'fas fa-plus'" [@rotate]="isMenuExtended"></i>
      </button>
    </div>
  </div>
  <div class="card-body" [@openClose]="isMenuExtended">
    <div class="row">
      <div class="col-md-6 form-group">
        <label for="contractPayMethod">合約付款方式</label>
        <ng-container *ngIf="action.allowEdit">
          <input type="text" class="form-control   updateDataByColumn updateDataByListColumn"
            [value]="contractPayMethod" (ngModel)="contractPayMethod" cols="30" rows="3" placeholder="合約付款方式"
            data-subdocName="formTaskContent.calculateForm" name="contractPayMethod" data-label="合約付款方式"
            data-subdocId="{{detailData?.formTaskContent?.calculateForm?._id}}" data-subdocumnet_type="" />
        </ng-container>
        <ng-container *ngIf="!(action.allowEdit)">
          <input type="text" readonly class="form-control" [value]="contractPayMethod" (ngModel)="contractPayMethod"
            cols="30" rows="3" />
        </ng-container>

      </div>
      <div class="col-md-6 form-group">
        <label for="actualPayMethod">實際付款方式</label>
        <ng-container *ngIf="action.allowEdit">
          <input type="text" class="form-control   updateDataByColumn updateDataByListColumn" [value]="actualPayMethod"
            (ngModel)="actualPayMethod" cols="30" rows="3" placeholder="實際付款方式"
            data-subdocName="formTaskContent.calculateForm" name="actualPayMethod" data-label="實際付款方式"
            data-subdocId="{{detailData?.formTaskContent?.calculateForm?._id}}" data-subdocumnet_type="" />
        </ng-container>
        <ng-container *ngIf="!(action.allowEdit)">
          <input type="text" readonly class="form-control" [value]="actualPayMethod" (ngModel)="actualPayMethod"
            cols="30" rows="3" />
        </ng-container>

      </div>
    </div>



  </div>
</div>
