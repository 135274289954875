<app-add-form-process-section1 *ngIf="component[1].actions.allowShow"
  (refreshFormTaskDetailData)="refreshFormTaskDetailData()" [title]="component[1].title" [formTaskNo]="formTaskNo"
  [action]="component[1].actions" [component]="component[1].btns" [parentComp]="this" [isMenuExtended]="true"
  [formAndProjectData]="formAndProjectData" [detailData]="detailData" [projectId]="projectId" [contractId]="contractId">
</app-add-form-process-section1>

<app-add-form-process-section2 *ngIf="component[2].actions.allowShow" [title]="component[2].title"
  [action]="component[2].actions" [component]="component[2].btns" [isMenuExtended]="true" [formTaskId]="formTaskId"
  [taskStatusId]="taskStatusId" [projectId]="projectId" [contractId]="contractId" [detailData]="detailData"
  [workTypesList]="workTypes">
</app-add-form-process-section2>

<app-add-form-process-section7 *ngIf="component[7].actions.allowShow" [title]="component[7].title"
  [action]="component[7].actions" [component]="component[7].btns" [isMenuExtended]="true" [formTaskId]="formTaskId"
  [taskStatusId]="taskStatusId" [projectId]="projectId" [contractId]="contractId" [detailData]="detailData"
  [workTypesList]="workTypes">
</app-add-form-process-section7>

<app-add-form-process-section3 *ngIf="component[3].actions.allowShow" [title]="component[3].title"
  [action]="component[3].actions" [component]="component[3].btns" [isMenuExtended]="true" [formTaskId]="formTaskId"
  [taskStatusId]="taskStatusId" [projectId]="projectId" [contractId]="contractId" [detailData]="detailData">
</app-add-form-process-section3>


<app-add-form-process-section4 *ngIf="component[4].actions.allowShow" [title]="component[4].title"
  [action]="component[4].actions" [component]="component[4].btns" [isMenuExtended]="true" [formTaskId]="formTaskId"
  [taskStatusId]="taskStatusId" [projectId]="projectId" [contractId]="contractId" [detailData]="detailData"
  [workTypesList]="workTypes">
</app-add-form-process-section4>

<app-add-form-process-section6 *ngIf="component[6].actions.allowShow" [title]="component[6].title"
  [action]="component[6].actions" [component]="component[6].btns" [isMenuExtended]="true" [formTaskId]="formTaskId"
  [taskStatusId]="taskStatusId" [projectId]="projectId" [contractId]="contractId" [detailData]="detailData"
  [workTypesList]="workTypes">
</app-add-form-process-section6>

<app-add-form-process-section5 *ngIf="component[5].actions.allowShow" [title]="component[5].title"
  [action]="component[5].actions" [component]="component[5].btns" [isMenuExtended]="true" [formTaskId]="formTaskId"
  [taskStatusId]="taskStatusId" [projectId]="projectId" [contractId]="contractId" [detailData]="detailData">
</app-add-form-process-section5>
