<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>職務權限管理</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a [routerLink]="['/']" href="/">Home</a></li>
          <li class="breadcrumb-item active">職務權限管理</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <button type="button" *ngIf="this.RightObj['insert']" class="btn btn-info float-right" (click)="open('add', null)">
          <i class="fas fa-plus"></i> 新增
        </button>
        <h5 class="modal-title">職務權限列表</h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">

            <div class="form-group">
              <label>選擇職務</label>
              <ng-select2 [data]="s2Jobs" [width]="'100%'"  [placeholder]="'請選擇'"
                (valueChanged)="onChange_Jobs($event)" [allowClear]="true">
              </ng-select2>
            </div>

          </div>
          <div class="col-md-3">
            <button class="btn btn-info my-4" (click)="refreshData()"><i class="fa fa-search" ></i> 查詢</button>
        </div>
        </div>
        <div class="row">

          <div class="col-md-12">
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
              class="table table-striped table-bordered table-hover nowrap nowrap">
              <thead>
                <tr>
                  <th>職務</th>
                  <th>上層模組</th>
                  <th>功能模組</th>
                  <th>顯示於清單中</th>
                  <th>新增權限</th>
                  <th>修改權限</th>
                  <th>刪除權限</th>
                  <th>排序</th>
                  <th *ngIf="this.RightObj['update'] || this.RightObj['delete']"  >編輯</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of jobModuleses">
                  <td>{{ item.jobName }}</td>
                  <td>{{ item.rootFunctionName[0] }}</td>
                  <td>{{ item.moduleName }}</td>
                  <td>{{ item.showInMenu === true ? "是" : "否" }}</td>
                  <td>{{ item.insert === true ? "Y" : "N" }}</td>
                  <td>{{ item.update === true ? "Y" : "N"}}</td>
                  <td>{{ item.delete === true ? "Y" : "N"}}</td>
                  <td>{{ item.sort }}</td>
                  <td *ngIf="this.RightObj['update'] || this.RightObj['delete'] "  >
                    <button type="button" *ngIf="this.RightObj['update']" class="btn btn-tool" data-widget="editData" data-toggle="tooltip"
                      title="修改職務權限" (click)="open('edit', item)">
                      <i class="fas fa-edit"></i>
                    </button>
                    <button type="button" *ngIf="this.RightObj['delete']" class="btn btn-tool text-danger" data-widget="addData" data-toggle="tooltip"
                      title="刪除職務權限" (click)="open('delete', item)">
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
  </div>
  <!-- /.card -->
</section>
<!-- /.content -->
