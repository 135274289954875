<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>施工照片查詢</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a [routerLink]="['/']" href="/">Home</a></li>
          <li class="breadcrumb-item active">施工照片查詢</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h5 class="modal-title">施工照片查詢</h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>工程名稱</label>
              <ng-select2 [data]="s2Projects" [width]="'100%'" [placeholder]="'請選擇'"
                (valueChanged)="onChange_Projects($event)"  [value]="projectId">
              </ng-select2>
            </div>
          </div>
          <div class="col-md-3">
            <button class="btn btn-info my-4" (click)="refreshData()"><i class="fa fa-search"></i> 查詢</button>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-12">
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
              class="table table-striped table-bordered table-hover  nowrap">
              <thead>
                <tr>
                  <th>施工日期</th>
                  <th>天氣</th>
                  <th>預計完成</th>
                  <th>實際完成</th>
                  <th>施工照片</th>
                  <th>下載</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of dailyReport">
                  <td>{{item.dailyDate | date:'yyyy/MM/dd'}}</td>
                  <td>上午：{{item.morningWeather}}<br>
                    下午：{{item.afternoonWeather}}</td>
                  <td>{{item.actualComplete}}%</td>
                  <td>{{item.expectComplete}}%</td>
                  <td><button class="btn btn-default" (click)="open(item.dailyDate,item.workImgList)">施工照片</button></td>
                  <td><button class="btn btn-default" (click)="downloadworkImg(item.workImgList,item.projectShortName,item.dailyDate)">下載</button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
  </div>
  <!-- /.card -->
</section>
<!-- /.content -->
