import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '@services/api.service';
import Swal from 'sweetalert2';
import { Select2OptionData } from 'ng-select2';
import { HelperService } from '@services/helper.service';

@Component({
  selector: 'app-work-type-add',
  templateUrl: './work-type-add.component.html',
  styleUrls: ['./work-type-add.component.scss']
})
export class WorkTypeAddComponent implements OnInit {
  public result;
  public CreateForm: FormGroup;
  s2WorkTypes: Select2OptionData[];
  @Input() selectNode;

  constructor(
    public activeModal: NgbActiveModal,
    public apiService: ApiService,
    public helperService: HelperService,
  ) { }

  ngOnInit(): void {
    this.CreateForm = new FormGroup({
      workTypeName: new FormControl('', Validators.required),
      rootWorkTypeId: new FormControl(''),
      sort: new FormControl(0, Validators.required),
      level: new FormControl(0, Validators.required),
      enable: new FormControl(true),
    });

    this.ddlWorkType();

    if(this.helperService.checkVal(this.selectNode)){
      this.CreateForm.controls['rootWorkTypeId'].setValue(this.selectNode);
    }

  }

  AllWorkType = [];
  async ddlWorkType() {
    let arrWorkType = [];
    let k = 0;
    (await this.apiService.getWorkTypes()).subscribe((res) => {
      this.AllWorkType = (res as any).filter(w => w.enable).sort((a, b) => a.sort - b.sort);
      this.AllWorkType.forEach(element => {
        if (element['level'] < 3 && element['enable']) {
          var selectText = element['workTypeName'];
          if (element['rootWorkTypeId'] != '' && element['rootWorkTypeId'] != null) {
            selectText = this.helperService.getRootWorkType(this.AllWorkType,selectText, element['rootWorkTypeId'])
          }
          arrWorkType.push({ id: element['_id'], text: selectText });
        }
        k++;
        if (k == this.AllWorkType.length) {
          // arrWorkType = arrWorkType.sort((a, b) => a.text.localeCompare(b.text))
          this.s2WorkTypes = arrWorkType;
        }
      });
    });
  }

  onChange_WorkTypes(val: String): void {
    if (val == null) val = "";
    this.CreateForm.controls['rootWorkTypeId'].setValue(val);
  }

  addWorkType() {
    if (this.CreateForm.valid) {
      this.apiService
        .addWorkTypes(
          this.CreateForm.value
        )
        .subscribe((res) => {
          this.result = res as any;
          if (this.result.result_status == false) {
            Swal.fire({
              title: this.result.result_message,
              icon: 'error'
            });
          } else {
            if(this.helperService.checkVal(this.CreateForm.controls['rootWorkTypeId'].value)){
              this.activeModal.close(this.CreateForm.controls['rootWorkTypeId'].value);
            }else{
              this.activeModal.close();
            }
          }
        });
    } else {
      Swal.fire({
        title: '請填寫必填欄位',
        confirmButtonText: '確定',
        confirmButtonColor: '#17A2B8',
        icon: 'error'
      });
    }


  }
}
