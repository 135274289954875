import { filter } from 'rxjs/operators';
import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormSectionService } from '@services/form-section.service';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { InquiryDetailAddComponent } from './inquiry-detail-add/inquiry-detail-add.component';
import { InquiryAddComponent } from './inquiry-add/inquiry-add.component';
import { ApiService } from '@services/api.service';
import { FormTaskProcessApiService } from '@services/form-task-process-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { openCloseAnimation, rotateAnimation } from '../../../../../../app/pages/form-task-process/detail-section/detail-section.animations'
import { HelperService } from '@services/helper.service';


@Component({
  selector: 'app-purchase-form-process-section4',
  templateUrl: './purchase-form-process-section4.component.html',
  styleUrls: ['./purchase-form-process-section4.component.scss'],
  animations: [openCloseAnimation, rotateAnimation]
})
export class PurchaseFormProcessSection4Component implements OnInit {
  public isExpandable: boolean = false;
  public isMainActive: boolean = false;
  public isOneOfChildrenActive: boolean = false;
  @Input() title;
  @Input() action;
  @Input() isMenuExtended;
  @Input() component;

  @Input() formTaskId;
  @Input() taskStatusId;

  @Input() parentComp;

  dtOptions: DataTables.Settings = {};
  dtTrigger_inquiry: Subject<any> = new Subject<any>();
  result: any;
  pccesData: any;

  loaded: any = 0;
  load_selected: any = 0;

  quotation: string;
  formData = new FormData();


  inquiryData: any;

  constructor(
    private toastr: ToastrService,
    public master_apiService: ApiService,
    public apiService: FormTaskProcessApiService,
    public formSectionService: FormSectionService,
    private modalService: NgbModal,
    public helperService: HelperService
  ) { }

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  ngOnInit(): void {
    this.dtOptions = this.helperService.setDtOptions();
    this.isExpandable = true;
    this.formSectionService.calculateIsActive(this.isMenuExtended);
    this.getPccesData();
  }



  async reloadFormTaskDetailData() {
    (await this.apiService.getFormTaskDetailData(this.formTaskId, this.taskStatusId)).subscribe((res) => {
      this.inquiryData = res?.formTaskContent?.purchaseForm?.inquiryDataList;
      localStorage.setItem('inputValidate4', "true");
      if (this.inquiryData.filter(w => w.supplierId != null).length == 0) {
        localStorage.setItem('inputValidate4', "false");
      } else {
        this.inquiryData.forEach(element => {
          if (element.inquiryDetailList.length == 0 || element.inquiryDetailList.length != this.pccesData.length || !this.helperService.checkVal(element.quotation)) {
            localStorage.setItem('inputValidate4', "false");
          }
        })
      }
      this.dtTrigger_inquiry.next(null);
    });
  }

  async refreshFormTaskDetailData() {
    (await this.apiService.getFormTaskDetailData(this.formTaskId, this.taskStatusId)).subscribe((res) => {
      this.inquiryData = res?.formTaskContent?.purchaseForm?.inquiryDataList;
      localStorage.setItem('inputValidate4', "true");
      if (this.inquiryData.filter(w => w.supplierId != null).length == 0) {
        localStorage.setItem('inputValidate4', "false");
      } else {
        this.inquiryData.forEach(element => {
          if (element.inquiryDetailList.length == 0 || element.inquiryDetailList.length != this.pccesData.length || !this.helperService.checkVal(element.quotation)) {
            localStorage.setItem('inputValidate4', "false");
          }
        })
      }
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtElement.dtTrigger.next(null);
      });
    });
  }

  handleMainMenuAction() {
    this.formSectionService.handleMainMenuAction(this.isExpandable, this.isMenuExtended)
  }

  async getPccesData() {
    (await this.apiService.getFormTaskDetailData(this.formTaskId, this.taskStatusId)).subscribe((res) => {
      this.pccesData = res?.formTaskContent?.purchaseForm?.pccesDataList;
      this.reloadFormTaskDetailData();

    });
  }

  deleteInquiryData(id: any) {
    let formData = {
      id: id,
      formTaskId: this.formTaskId
    }
    this.apiService.deleteInquiryData(formData)
      .subscribe((res) => {
        this.result = res as any;
        if (this.result.status == false) {
          Swal.fire({
            title: this.result.message,
            icon: 'error'
          });
        } else {
          this.toastr.success("Successfully Deleted!");
          this.refreshFormTaskDetailData();
        }
      });
  }

  quotationUpload(e, _id) {
    const file: File = e.target.files[0];
    if (file) {
      this.quotation = file.name;
      var FileExtensionArr = ["pdf"];
      if (FileExtensionArr.indexOf((this.quotation.split('.')[this.quotation.split('.').length - 1]).toLowerCase()) > -1) {
        this.formData.delete("url");
        this.formData.delete("uploadFile");
        this.formData.append('url', "formTask_PurchaseForm_inquiry_quotation");
        this.formData.append('uploadFile', file);

        this.apiService
          .uploadData(
            this.formData
          )
          .subscribe({
            next: (event: any) => {
              if (event.type === HttpEventType.UploadProgress) {
                this.load_selected = "quotation";
                this.loaded = Math.round(100 * event.loaded / event.total);
              } else if (event instanceof HttpResponse) {

                setTimeout(() => {
                  this.loaded = 0;
                }, 200);

                let formData = {
                  quotation: "formTask_PurchaseForm_inquiry_quotation/" + event.body.name,
                  formTaskId: this.formTaskId,
                  inquiryDataId: _id
                }
                this.apiService.addInquiryQuotation(formData)
                  .subscribe((res1) => {
                    this.result = res1 as any;
                    if (this.result.status == false) {
                      Swal.fire({
                        title: this.result.message,
                        icon: 'error'
                      });
                    } else if (event.body.msg != null) {
                      Swal.fire({
                        title: event.body.msg,
                        icon: 'error'
                      });
                      this.quotation = "";
                    }else {
                      this.quotation = "formTask_PurchaseForm_inquiry_quotation/" + event.body.name;
                      this.toastr.success("資料已儲存");
                      this.refreshFormTaskDetailData();
                    }
                  });
              }
            },
            error: (err: any) => {
              this.loaded = 0;
              console.log(err);
            }
          });

      } else {
        this.quotation = "";
        Swal.fire({
          title: '檔案類型不正確!',
          icon: 'error'
        });
      }
    }
  }

  viewPdf(url) {
    this.formSectionService.viewPdf(url);
  }

  openModal(type, id: any, actions) {
    let modalRef = null;
    if (type == 'inquiry-detail-add') {
      modalRef = this.modalService.open(InquiryDetailAddComponent, { windowClass: 'inquiry-detail-add' });
      modalRef.componentInstance.pccesData = this.pccesData;
      modalRef.componentInstance.inquiryData = this.inquiryData.filter((item) => item._id == id)[0];
      modalRef.componentInstance.formTaskId = this.formTaskId;
      modalRef.componentInstance.inquiryDataId = id;
      modalRef.componentInstance.actions = actions;
      modalRef.componentInstance.refreshFormTaskDetailData.subscribe(($e) => {
        this.refreshFormTaskDetailData();
      })
      modalRef.closed.subscribe((result) => {
        this.refreshFormTaskDetailData();
      });
    }
    else if (type == 'inquiry-add') {
      modalRef = this.modalService.open(InquiryAddComponent, { windowClass: 'inquiry-add' });
      modalRef.componentInstance.parentComp = this.parentComp;
      modalRef.closed.subscribe((result) => {
        this.refreshFormTaskDetailData();
      });
    }
  }
}
