<div class="modal-header">
  <h4 class="modal-title">新增PCCES物料編碼</h4>
  <button type="button" class="btn btn-tool" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <i class="fas fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form class="form-horizontal" [formGroup]="CreateForm" method="post" action="">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label><code>*</code>編碼</label>
          <input type="text" formControlName="_id" class="form-control">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label><code>*</code>編碼類型</label>
          <input type="text" formControlName="itemKind" class="form-control">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label><code>*</code>物料名稱</label>
          <input type="text" formControlName="description" class="form-control">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label><code>*</code>單位</label>
          <input type="text" formControlName="unit" class="form-control">
        </div>
      </div>
    </div>
  </form>

  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-default" (click)="activeModal.close('Close click')">關閉</button>
    <button type="button" class="btn btn-info" (click)="addPccesCode()">確定</button>
  </div>
