import { ApiService } from '@services/api.service';
import { filter } from 'rxjs/operators';
import { HelperService } from './../../../../../services/helper.service';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, Renderer2, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { FormTaskProcessApiService } from '@services/form-task-process-api.service';
import { FormSectionService } from '@services/form-section.service';

import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { openCloseAnimation, rotateAnimation } from '../../../../../../app/pages/form-task-process/detail-section/detail-section.animations'
import { ProjectApiService } from '@services/projectapi.service';
import { FormtaskapiService } from '@services/formtaskapi.service';
import { ContractItemDetailSetPriceComponent } from './contract-item-detail-set-price/contract-item-detail-set-price.component';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-add-form-process-section4',
  templateUrl: './add-form-process-section4.component.html',
  styleUrls: ['./add-form-process-section4.component.scss'],
  animations: [openCloseAnimation, rotateAnimation]

})
export class AddFormProcessSection4Component implements OnInit {
  public isExpandable: boolean = false;
  public isMainActive: boolean = false;
  public isOneOfChildrenActive: boolean = false;
  @Input() title;
  @Input() action;
  @Input() isMenuExtended;
  @Input() component;
  @Input() formTaskId;
  @Input() taskStatusId;
  @Input() projectId;
  @Input() contractId;
  @Input() detailData;
  @Input() workTypesList;

  formId = "";
  dtOptions: DataTables.Settings = {};
  dtTrigger_contractItem: Subject<any> = new Subject<any>();

  total1 = 0;
  total2 = 0;
  result: any;

  constructor(
    public master_apiService: ApiService,
    public apiService: FormTaskProcessApiService,
    public formtaskapiService: FormtaskapiService,

    private toastr: ToastrService,
    public formSectionService: FormSectionService,
    public helperService: HelperService,
    public ProjectApiService: ProjectApiService,
    public elementRef: ElementRef,
    public renderer: Renderer2,
    private modalService: NgbModal,


  ) { }
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  ngOnInit(): void {
    this.dtOptions = this.helperService.setDtOptions();

    this.isExpandable = true;
    this.formSectionService.calculateIsActive(this.isMenuExtended);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.detailData != undefined && changes.detailData.currentValue) {
      this.helperService.AutoSave(this.renderer, this.elementRef.nativeElement, "formTask", this.detailData?._id);

      localStorage.setItem('inputValidate4', "true");
      // this.detailData?.formTaskContent?.addForm?.contractItemList.forEach(element => {
      //   if(!this.helperService.checkVal(element['quotation'])){
      //     localStorage.setItem('inputValidate4', "false");
      //   }
      // });

      this.helperService.AutoSave(this.renderer, this.elementRef.nativeElement, "formTask", this.detailData?._id);
      this.formId = this.detailData['formId'];

      this.detailData?.formTaskContent?.addForm?.contractItemList.forEach(element => {
        var dTotal1 = 0;
        var dTotal2 = 0;
        if (element['contractDetailList'] != null) {
          element['contractDetailList'].forEach(element2 => {
            if (parseInt(element2['quantity']) > 0) {
              this.total1 += (element2['quantity'] * element2['price']);
              dTotal1 += (element2['quantity'] * element2['price']);
            } else {
              this.total2 += (element2['quantity'] * element2['price']);
              dTotal2 += (element2['quantity'] * element2['price']);
            }
          })
        }
        element['total'] = dTotal1 + dTotal2;
        var p = parseFloat(element['prePay']) / parseFloat(element['total']) * 100;
        if (p != Infinity && p.toString() != 'NaN') {
          element['percent'] = this.roundDecimal(parseFloat(element['prePay']) / parseFloat(element['total']) * 100, 2) + "%";
        } else {
          element['percent'] = "0%";
        }
      });

      if (changes.detailData.previousValue != undefined && this.dtElement.dtInstance != undefined) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          // dtInstance.destroy();
          // this.dtTrigger.next(null);
        });
      } else {
        setTimeout(() => {
          this.dtOptions = this.helperService.setDtOptions();
          this.dtTrigger_contractItem.next(null);
        }, 1000);

      }
    }
    if (changes.workTypesList != undefined && changes.workTypesList.currentValue) {
      this.getContract();
    }
  }

  contract: [];
  async getContract() {
    (await this.ProjectApiService.getContact(this.projectId)).subscribe((res) => {
      this.contract = (res as any).filter(w => this.helperService.checkVal(w.validateDate));

      this.detailData?.formTaskContent?.addForm?.contractItemList.forEach(element => {
        var thisContract = this.contract.filter(w => w['_id'] == element['contractId'])[0];
        element['workTypeName'] = this.mappingWorkType(thisContract['workType']['_id']);
        this.master_apiService.getSupplierById(thisContract['supplierId']).subscribe((res) => {
          element['supplierName'] = res['supplierName'];
          element['businessNo'] = res['businessNo'];
          element['contactName'] = res['contactName'];
          element['contactPhone'] = res['contactPhone'];
          element['remark'] = res['remark'];
        })

      });

    })
  }


  mappingWorkType(current) {
    if (current != null) {
      var element = this.workTypesList.filter(w => w._id == current)[0];
      if (element != undefined) {
        var selectText = element['workTypeName'];
        if (element['rootWorkTypeId'] != '' && element['rootWorkTypeId'] != null) {
          selectText = this.helperService.getRootWorkType(this.workTypesList.sort((a, b) => a.sort - b.sort), selectText, element['rootWorkTypeId'])
        }
        return selectText;
      }
    }
  }

  async refresh() {
    this.total1=0;
    this.total2=0;

    (await this.apiService.getFormTaskDetailData(this.formTaskId, this.taskStatusId)).subscribe((res) => {
      this.detailData = res;

      localStorage.setItem('inputValidate4', "true");
      this.detailData?.formTaskContent?.addForm?.contractItemList.forEach(element => {
        // if(!this.helperService.checkVal(element['quotation'])){
        //   localStorage.setItem('inputValidate4', "false");
        // }

        var dTotal1 = 0;
        var dTotal2 = 0;
        if (element['contractDetailList'] != null) {
          element['contractDetailList'].forEach(element2 => {
            if (parseInt(element2['quantity']) > 0) {
              this.total1 += (element2['quantity'] * element2['price']);
              dTotal1 += (element2['quantity'] * element2['price']);
            } else {
              this.total2 += (element2['quantity'] * element2['price']);
              dTotal2 += (element2['quantity'] * element2['price']);
            }
          })
        }
        element['total'] = dTotal1 + dTotal2;
        var p = parseFloat(element['prePay']) / parseFloat(element['total']) * 100;
        if (p != Infinity  && p.toString() != 'NaN') {
          element['percent'] = this.roundDecimal(parseFloat(element['prePay']) / parseFloat(element['total']) * 100, 2) + "%";
        } else {
          element['percent'] = "0%";
        }
      });
      this.getContract();

      // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      //   dtInstance.destroy();
      //   this.dtTrigger_contractItem.next(null);
      // });
    })
  }


  handleMainMenuAction() {
    this.formSectionService.handleMainMenuAction(this.isExpandable, this.isMenuExtended)
  }

  prePayChange(event, element) {
    var target = event.target;
    var value = target.value;
    element['prePay'] = value;
    element['percent'] = this.roundDecimal(parseFloat(value) / parseFloat(element['total']) * 100, 2) + "%";
    this.refresh();
  }

  openModal(action, datas) {
    let modalRef = null;
    if (action == 'setPrice') {
      modalRef = this.modalService.open(ContractItemDetailSetPriceComponent, { windowClass: 'setPrice', size: 'xl' });
      modalRef.componentInstance.projectId = this.projectId;
      modalRef.componentInstance.formTaskId = this.formTaskId;
      modalRef.componentInstance.contractItemList = datas;
      modalRef.componentInstance.action = this.action;
      modalRef.componentInstance.detailData = this.detailData;
      modalRef.componentInstance.showDownloadBtn=false;
      modalRef.componentInstance.MasterId=datas.contractId;
      modalRef.componentInstance.refresh.subscribe((result) => {
        this.refresh();
      })
    }
  }

  loaded: any = 0;
  load_selected: any = 0;
  formData = new FormData();
  quotation = "";
  FileSelected(e, id = null, type) {
    const file: File = e.target.files[0];
    if (file) {
      switch (type) {
        case "quotation":
          this.quotation = file.name;
          break;
      }
      var FileExtensionArr = ["pdf"];
      if (FileExtensionArr.indexOf((file.name.split('.')[file.name.split('.').length - 1]).toLowerCase()) > -1) {
        var FileExtensionArr = ["pdf"];
        this.formData.delete("url");
        this.formData.delete("uploadFile");
        this.formData.append('url', "formTask_AddForm_" + type);
        this.formData.append('uploadFile', file);

        this.apiService
          .uploadData(
            this.formData
          )
          .subscribe({
            next: (event: any) => {
              if (event.type === HttpEventType.UploadProgress) {
                this.load_selected = type;
                this.loaded = Math.round(100 * event.loaded / event.total);
              } else if (event instanceof HttpResponse) {
                setTimeout(() => {
                  this.loaded = 0;
                }, 200);

                let formData = {
                  fileName: "formTask_AddForm_" + type + "/" + event.body.name,
                  formTaskId: this.formTaskId,
                  type: type,
                  contractItemId: id
                }
                this.apiService.uploadAddData(formData)
                  .subscribe((res1) => {
                    var result = res1 as any;
                    if (result.status == false) {
                      Swal.fire({
                        title: result.message,
                        icon: 'error'
                      });
                    }  else if (event.body.msg != null) {
                      Swal.fire({
                        title: event.body.msg,
                        icon: 'error'
                      });
                      this.quotation = "";
                    }else {
                      this.toastr.success("資料已儲存");
                      switch (type) {
                        case "quotation":
                          this.quotation = "formTask_AddForm_" + type + "/" + event.body.name
                          break;
                      }

                      this.refresh();
                    }
                  });
              }
            },
            error: (err: any) => {
              this.loaded = 0;
              console.log(err);
            }
          });
      }
    }
    else {
      switch (type) {
        case "quotation":
          this.quotation = "";
          break;
      }
      Swal.fire({
        title: '檔案類型不正確!',
        icon: 'error'
      });
    }
  }

  viewPdf(url) {
    this.formSectionService.viewPdf(url);
  }

  roundDecimal = function (val, precision) {
    return Math.round(Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10) / Math.pow(10, (precision || 0));
  }

}
