import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class FormSectionService {
  static handleMainMenuAction(isExpandable: boolean, isMenuExtended: any) {
    throw new Error('Method not implemented.');
  }
  static calculateIsActive(isMenuExtended: any) {
    throw new Error('Method not implemented.');
  }
  public isExpandable: boolean = false;
  public isMainActive: boolean = false;
  public isOneOfChildrenActive: boolean = false;

  constructor(
    public master_apiService: ApiService,
  ) { }

  public handleMainMenuAction(isExpandable,isMenuExtended) {
    if (isExpandable) {
      this.toggleMenu(isMenuExtended);
      return;
    }
  }
  public toggleMenu(isMenuExtended) {
    isMenuExtended = !isMenuExtended;
  }
  public calculateIsActive(isMenuExtended) {
    this.isMainActive = true;
    this.isOneOfChildrenActive = true;
    if (!this.isMainActive && !this.isOneOfChildrenActive) {
      isMenuExtended = false;
    }
  }

  viewPdf(url) {
    this.master_apiService.download(url).subscribe((res) => {
      var blob = new Blob([res], { type: 'application/pdf' });
      window.open(window.URL.createObjectURL(blob), "_blank");
    },
      (err) => {
        Swal.fire({
          title: '系統找不到指定的檔案',
          icon: 'error'
        });
      });
  }


}
