import { HelperService } from '@services/helper.service';
import { ProjectApiService } from '@services/projectapi.service';
import { ApiService } from '@services/api.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-purchase-control-edit',
  templateUrl: './purchase-control-edit.component.html',
  styleUrls: ['./purchase-control-edit.component.scss']
})
export class PurchaseControlEditComponent implements OnInit {
  @Input() purchaseControl;

  constructor(
    public helperService:HelperService,
    public activeModal: NgbActiveModal,
    public apiService: ApiService,
    public ProjectApiService: ProjectApiService) { }

  public EditForm: FormGroup;

  ngOnInit(): void {
    this.EditForm = new FormGroup({
      projectId: new FormControl(this.purchaseControl.projectId, Validators.required),
      PurchaseControlId: new FormControl(this.purchaseControl.PurchaseControlId),
      controlItem: new FormControl(this.purchaseControl.controlItem, Validators.required),
      controlName: new FormControl(this.purchaseControl.controlName, Validators.required),
      supplierList: new FormControl(this.purchaseControl.supplierList),

      controlSpec: new FormControl(this.purchaseControl.controlSpec, Validators.required),
      controlUnit: new FormControl(this.purchaseControl.controlUnit, Validators.required),
      controlNumber: new FormControl(this.purchaseControl.controlNumber.toFixed(2), Validators.required),
      material: new FormControl(this.purchaseControl.material),
      work: new FormControl(this.purchaseControl.work),
      both: new FormControl(this.purchaseControl.both),
      data: new FormControl(this.purchaseControl.data),
      purchaseDate: new FormControl(this.purchaseControl.purchaseDate ? this.helperService.formatDate(this.purchaseControl.purchaseDate) : null),
      applyDate: new FormControl(this.purchaseControl.applyDate ? this.helperService.formatDate(this.purchaseControl.applyDate): null),
      materialId: new FormControl(this.purchaseControl.materialId),

    })
    this.ddlMaterial();
  }

  materials = [];
  async ddlMaterial() {
    (await this.ProjectApiService.getMaterial(this.purchaseControl.projectId)).subscribe((res) => {
      this.materials = res as any;
      setTimeout(() => {
        this.EditForm.get('materialId').setValue(this.purchaseControl.materialId);
      }, 100);
    });
  }

  result;
  editPurchaseControl() {
    if (this.EditForm.valid) {
      this.ProjectApiService
        .editPurchaseControl(
          this.EditForm.value,
          this.purchaseControl._id,

        )
        .subscribe((res) => {
          this.result = res as any;
          if (this.result.result_status == false) {
            Swal.fire({
              title: this.result.result_message,
              icon: 'error'
            });
          } else {
            this.activeModal.close();
          }
        });
    } else {
      Swal.fire({
        title: '請填寫必填欄位',
        icon: 'error'
      });
    }
  }
}
