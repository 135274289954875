<div #PurchaseControlList>
  <div class="row" id="pc_{{k4}}">
    <div class="col-md-5">
      <div class="form-group">
        <input type="text" class="form-control" name="controlItem" data-label="材料送審項目{{k4+1}}:項次名稱"
          class="form-control updateDataByColumn updateDataByListCollection" data-subdocId="{{id}}"   data-collectionName="purchaseControl">
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <input type="text" class="form-control" name="controlName" data-label="材料送審項目{{k4+1}}:項次名稱"
          class="form-control updateDataByColumn updateDataByListCollection" data-subdocId="{{id}}"   data-collectionName="purchaseControl">
      </div>
    </div>
    <div class="col-md-1">
      <div class="form-group">
        <button type="button" class="btn btn-tool text-danger" data-toggle="tooltip" title="刪除" (click)="del(k4)">
          <i class="fas fa-trash-alt"></i>
        </button>
      </div>
    </div>
  </div>
</div>
