import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-change-price-card',
  templateUrl: './change-price-card.component.html',
  styleUrls: ['./change-price-card.component.scss']
})
export class ChangePriceCardComponent implements OnInit {
  @Input() k1;
  constructor() { }

  ngOnInit(): void {
  }

  del() {
    $("#c_" + this.k1).hide();
    $("input[name='ChangePrice[" + this.k1 + "].changeDate']").val('')
    $("input[name='ChangePrice[" + this.k1 + "].changeReason']").val('')
    $("input[name='ChangePrice[" + this.k1 + "].changePrice']").val('')
  }
}
