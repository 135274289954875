<div class="modal-header">
  <h4 class="modal-title">新增計價單</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="card">
    <div class="card-header">
      <h5 class="card-title mt-2 text-danger">注意事項</h5>
      <div class="card-tools">
        <button type="button" class="btn bnt-tool" data-card-widget="collapse">
          <i class="fas fa-minus"></i>
        </button>
      </div>
    </div>
    <!-- /.card-header -->
    <div class="card-body" style="display: block;">
      <div class="row">
        <div class="col-md-12">
          <div class="callout callout-danger" style="background-color:#f6b5b5">
            <div class="row">
              <div class="col-md-6">
                <h3>廠商發票</h3>
                <p>1. 發票無任何塗改</p>
                <p>2. 發票資料正確(抬頭、統編、日期)</p>
                <p>3. 發票中文大寫正確</p>
                <p>4. 備註勿用原子筆</p>
                <p class="text-danger">以上任一錯誤者，請廠商重新開立發票</p>
              </div>
              <div class="col-md-6">
                <h5>其它</h5>
                <p>1. 現場確實施作，勿超額超前</p>
                <p>2. 缺失確實改善</p>
                <p>3. 待雇工確認單</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="callout callout-danger" style="background-color:#f6b5b5">
            <h3>工程師準備文件</h3>
            <div class="row">
              <div class="col-md-6">
                <p>1. 施工前置會議記錄</p>
                <p>2. 計價位置圖面</p>
                <p class="text-danger">以上任一錯誤者，將延誤請款時程，請注意</p>
              </div>
              <div class="col-md-6">
                <p>3. 計價數量計算式</p>
                <p>4. 相關證明文件、施工照片(各工項應附文件)</p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <form [formGroup]="calculateFormSection">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label><span class="text-danger">*</span>速別</label>
          <ng-select2 width="100%" placeholder="速別" formControlName="emergencyTypeId"
            (valueChanged)="onChange_EmergencyType($event)">
            <option *ngFor="let data of emergencyTypeList" [value]="data._id">{{data.emergencyName}}</option>
          </ng-select2>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label><span class="text-danger">*</span>工項名稱</label>
          <ng-select2 width="100%" placeholder="工項名稱" value=""
            (valueChanged)="onChange_WorkTypes($event)">
            <option *ngFor="let data of workType" [value]="data._id">{{data.workTypeName}}</option>
          </ng-select2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label><span class="text-danger">*</span>廠商名稱</label>
          <ng-select2 width="100%" placeholder="廠商名稱"
            (valueChanged)="onChange_Suppliers($event)">
            <option *ngFor="let data of supplierList" [value]="data._id">{{data.supplierName}}</option>
          </ng-select2>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label><span class="text-danger">*</span>合約編號</label>
          <ng-select2 width="100%" placeholder="合約編號" formControlName="contractId"
            (valueChanged)="onChange_Contracts($event)">
            <option *ngFor="let data of contractList" [value]="data._id">{{data.contractNo}}</option>
          </ng-select2>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer justify-content-between">
  <button type="button" class="btn btn-default btn-outline-dark" (click)="activeModal.close('Close click')">關閉</button>
  <button class="btn btn-info" (click)="saveCalculateForm('add')">確定</button>
</div>
