<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>廠商資料管理</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a [routerLink]="['/']" href="/">Home</a></li>
          <li class="breadcrumb-item active">廠商資料管理</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <button type="button" *ngIf="this.RightObj['insert']" class="btn btn-info float-right"
          (click)="open('add', null)">
          <i class="fas fa-plus"></i> 新增
        </button>
        <h5 class="modal-title">廠商資料管理</h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>選擇業務範圍</label>
              <ng-select2 [data]="s2Locations" [width]="'100%'" [options]="options" [placeholder]="'請選擇'"
                (valueChanged)="onChange_Locations($event)" [allowClear]="true">
              </ng-select2>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>選擇工項</label>
              <ng-select2 [data]="s2WorkTypes" [width]="'100%'" [options]="options" [placeholder]="'請選擇'"
                (valueChanged)="onChange_WorkTypes($event)" [allowClear]="true">
              </ng-select2>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>合作模式</label>
              <ng-select2 [data]="s2SupplierTypes" [width]="'100%'" [options]="options" [placeholder]="'請選擇'"
              (valueChanged)="onChange_SupplierTypes($event)" [allowClear]="true">
            </ng-select2>
            </div>
          </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <div class="form-group">
                <label>地址</label>
               <input type="text" [(ngModel)]="QueryAddress" class="form-control" >
              </div>
            </div>
          <div class="col-md-3">
            <button class="btn btn-info my-4" (click)="refreshData()"><i class="fa fa-search"></i> 查詢</button>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-12">
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
              class="table table-striped table-bordered table-hover nowrap">
              <thead>
                <tr>
                  <th>廠商資料</th>
                  <th style="width: 15%">廠商類型</th>
                  <th>業務範圍</th>
                  <th>聯絡人</th>
                  <th>銀行資訊</th>
                  <th>合作關係</th>
                  <th>合約</th>
                  <th>最近配合日</th>
                  <th>廠商關懷</th>
                  <th>維護採發</th>
                  <th>備註</th>
                  <th *ngIf="this.RightObj['update'] || this.RightObj['delete']">編輯</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of suppliers">
                  <td>
                    {{item.businessNo}} <br>
                    {{ item.supplierName }}<br>
                    <span class="badge bg-secondary">{{item.locationItem.locationCName}}</span>
                  </td>
                  <td>
                    <span class="badge bg-info">{{item.supplierTypeName}}</span>
                    <span class="badge bg-info">工班數 {{item.workersNum}}</span><br>
                    <span *ngFor="let workType of item.workTypeNameList" class="badge bg-secondary">
                      {{workType}}
                    </span>
                  </td>
                  <td>
                    <button type="button" data-widget="detail" data-toggle="tooltip" title="明細" class="btn btn-default"
                      (click)="open('locationAreaList', item)">明細</button>
                  </td>
                  <td>
                    {{item.contactName}} <br>
                    {{item.contactPhone}}
                  </td>
                  <td>
                    <button type="button"
                      *ngIf="item.supplierBankData.bankId !== null && item.supplierBankData.branchName !== '' && item.supplierBankData.accountNo !== '' && item.supplierBankData.userName !== '' && item.supplierBankData.bookUrl !== ''"
                      data-widget="bank" data-toggle="tooltip" title="銀行" class="btn btn-default"
                      (click)="open('supplierBankData', item)">銀行</button>

                  </td>
                  <td>
                    <span *ngIf="!item.badRecord" class="badge bg-secondary">無不良紀錄</span>
                    <br *ngIf="!item.badRecord">
                    已合作過：{{item.cooperation}}<br>
                    正在執行：{{item.implement}}
                  </td>
                  <td>
                    <button type="button" data-widget="bank" data-toggle="tooltip" title="合約" class="btn btn-default "
                      (click)="open('contract', item)">合約({{item.cooperation+item.implement}})</button>
                  </td>
                  <td>{{item.contractDate === null ? "" : item.contractDate | date:'yyyy/MM/dd'}}</td>
                  <td>
                    <button type="button" *ngIf="!item.careEnable" data-widget="care1" data-toggle="tooltip" title="紀錄"
                      class="btn btn-default" (click)="open('supplierCareList1', item)">紀錄</button>
                    <button type="button" *ngIf="item.careEnable" data-widget="care2" data-toggle="tooltip" title="紀錄"
                      class="btn btn-danger" (click)="open('supplierCareList2', item)">紀錄</button>
                  </td>
                  <td>{{item.employeeName}}</td>
                  <td>{{item.remark}}</td>
                  <td *ngIf="this.RightObj['update'] || this.RightObj['delete'] ">
                    <button type="button" *ngIf="this.RightObj['update']" class="btn btn-tool" data-widget="editData"
                      data-toggle="tooltip" title="修改廠商資料" (click)="open('edit', item)">
                      <i class="fas fa-edit"></i>
                    </button>
                    <button *ngIf="item.enable && this.RightObj['delete']" type="button"
                      class="btn btn-tool text-primary" data-widget="disabledData" data-toggle="tooltip" title="停用廠商資料"
                      (click)="open('disabled', item)">
                      <i class="fas fa-lock"></i>
                    </button>

                    <br>
                    <span *ngIf="!item.enable" class="badge bg-danger"> {{ item.disableDay | date:'yyyy/MM/dd'}}
                      終止合作</span>
                    <br>
                    <span *ngIf="!item.enable" class="text-danger">{{item.disableRemark}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
  </div>
  <!-- /.card -->
</section>
<!-- /.content -->
