<div class="modal-header">
  <h4 class="modal-title">編輯</h4>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close"
    (click)="activeModal.close('Close click')">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">
  <form class="form-horizontal" [formGroup]="createForm" method="post" action="">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label>預付款</label>
          <ng-container *ngIf="actions.allowEdit">
            <input type="number" formControlName="prepayMoney" class="form-control">
          </ng-container>
          <ng-container *ngIf="!(actions.allowEdit)">
            <input disabled type="number" formControlName="prepayMoney" class="form-control">
          </ng-container>

        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>總金額</label>
          <input type="text" formControlName="subtotal" class="form-control" disabled>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
          class="table table-striped table-bordered table-hover nowrap">
          <thead>
            <tr>
              <th>物料編號</th>
              <th>物料名稱</th>
              <th>圖說編號</th>
              <th>規格</th>
              <th>申購數量</th>
              <th>單位</th>
              <th style="min-width: 5rem;">單價</th>
              <th>小計</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let datas of gridData ;">
              <td>{{datas.pccesId}}</td>
              <td><span class="pccesName">{{datas.description}}</span></td>
              <td>{{datas.diagramNo}}</td>
              <td>{{datas.spec}}</td>
              <td>{{datas.quantity}}</td>
              <td>{{datas.unit}}</td>
              <td style="min-width: 5rem;">
                <input *ngIf="actions.allowEdit" type="number" [value]="datas.price" class="form-control"
                  (focusout)="priceChange($event, datas.pccesId,datas.diagramNo,datas.spec)">
                {{actions.allowEdit?'':datas.price | currency:'TWD': 'symbol':'1.0'}}
              </td>
              <td>{{datas.total | currency:'TWD': 'symbol':'1.0'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer justify-content-between">
  <button type="button" class="btn btn-default" data-dismiss="modal"
    (click)="activeModal.close('Close click')">關閉</button>
  <button *ngIf="actions.allowEdit" type="button" class="btn btn-info" (click)="saveInquiryDetail()">確定</button>
</div>
