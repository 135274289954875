<div class="modal-header">
  <h4 class="modal-title">公共工程紀錄月報表
  </h4>
  <button type="button" class="btn btn-tool" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <i class="fas fa-times"></i>
  </button>
</div>
<div class="modal-body">
    <div class="row">
      <div class="col-md-9">
          <input type="month" [formControl]="searchYM" class="form-control">
        </div>
        <div class="col-md-3">
          <a type="button" class="btn btn-info" href="{{api_url}}/dailyReport/setExcelMonth/{{searchYM.value}}" >下載</a>
        </div>
      </div>
    </div>

  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-default" (click)="activeModal.close('Close click')">關閉</button>
  </div>
