import { filter } from 'rxjs/operators';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '@services/api.service';
import { ProjectApiService } from '@services/projectapi.service';
import { Subject } from 'rxjs';
import { RightService } from '@services/right.service';
import { HelperService } from '@services/helper.service';
import Swal from 'sweetalert2';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-project-step',
  templateUrl: './project-step.component.html',
  styleUrls: ['./project-step.component.scss']
})
export class ProjectStepComponent implements OnInit {
  @Input() project;
  getRight: any;
  RightObj: {};
  dtOptions: DataTables.Settings = {};

  public result;
  public ParentgetProjectSteps = new Array();
  public CreateForm: FormGroup;
  constructor(
    public activeModal: NgbActiveModal,
    public rightService: RightService,
    public helperService: HelperService,
    public apiService: ApiService,
    public ProjectApiService: ProjectApiService,

  ) {
    this.getRight = this.rightService.getRight();
  }

  dtTrigger: Subject<any> = new Subject<any>();

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  ngOnInit(): void {
    this.dtOptions = this.helperService.setDtOptions();
    this.RightObj = this.getRight['__zone_symbol__value'];

    this.CreateForm = new FormGroup({
      stepComment: new FormControl('', Validators.required),
      projectStepId: new FormControl('', Validators.required),
      beginDate: new FormControl('', Validators.required),
      projectStepList: new FormControl('', Validators.required),
    });

    this.CreateForm.controls['projectStepList'].setValue(this.project.projectStepList);
    this.ddlProjectStep(this.project.projectStepId);

  }


  async reloadData() {
    this.dtTrigger.next(null);
  }

  async refreshData() {
    this.ddlProjectStep(this.CreateForm.controls['projectStepId'].value);
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next(null);
    });
  }


  async ddlProjectStep(id) {
    (await this.apiService.getProjectSteps()).subscribe((res) => {
      var AllProjectSteps = res as any;
      AllProjectSteps = AllProjectSteps.reverse();
      const projectStepId = (element) => element._id == id;
      var currentIndex = AllProjectSteps.findIndex(projectStepId);
      AllProjectSteps.forEach((element, index) => {
        if (index == currentIndex + 1) {
          this.ParentgetProjectSteps.push({ id: element._id, name: element.stepName, selected: true });
          this.CreateForm.controls['projectStepId'].setValue(element._id);
        }
        else {
          //this.ParentgetProjectSteps.push({ id: element._id, name: element.stepName });
        }
      });
    });
  }


  addProjectStep() {

    if (this.project.projectStepId == '20221008202509355') {
      this.CreateForm.controls['beginDate'].setValue(' ');
      this.CreateForm.controls['projectStepId'].setValue('20221008202509355');
    }

    if (this.CreateForm.valid) {
      var projectStepList = this.project.projectStepList;
      var pIndex = 0;
      if (this.project.projectStepId != '20221008202509355') {
        var newProjectStepList = {
          projectStepName: this.ParentgetProjectSteps.filter(w => w.id == this.CreateForm.controls['projectStepId'].value)[0].name,
          stepComment: "",
          beginDate: this.CreateForm.controls['beginDate'].value
        };
        projectStepList.push(newProjectStepList);
        pIndex = projectStepList.length - 2;
      }else{
      pIndex =projectStepList.length - 1;
      }

      projectStepList[pIndex]['stepComment'] = this.CreateForm.controls['stepComment'].value;
      this.CreateForm.controls['projectStepList'].setValue(projectStepList);


      this.ProjectApiService
        .updateProjectStep(
          this.project._id,
          this.CreateForm.controls['projectStepId'].value,
          this.CreateForm.controls['projectStepList'].value
        )
        .subscribe((res) => {
          this.result = res as any;
          if (this.result.result_status == false) {
            Swal.fire({
              title: this.result.result_message,
              icon: 'error'
            });
          } else {
            this.activeModal.close();
          }
        });
    } else {
      Swal.fire({
        title: '請填寫必填欄位',
        icon: 'error'
      });
    }
  }
}
