import { filter } from 'rxjs/operators';
import { Component, OnInit, Input, Renderer2, ElementRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@services/api.service';
import { ProjectApiService } from '@services/projectapi.service';
import { Subject } from 'rxjs';
import { RightService } from '@services/right.service';
import { HelperService } from '@services/helper.service';
import { FormSignFlowComponent } from '@pages/form-sign/form-sign-flow/form-sign-flow.component';

@Component({
  selector: 'app-project-progress',
  templateUrl: './project-progress.component.html',
  styleUrls: ['./project-progress.component.scss']
})
export class ProjectProgressComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  @Input() projectId;
  getRight: any;
  RightObj: {};
  public Process;
  public result;
  constructor(
    public activeModal: NgbActiveModal,
    public rightService: RightService,
    public helperService: HelperService,
    public apiService: ApiService,
    public ProjectApiService: ProjectApiService,
    private modalService: NgbModal,
    public renderer: Renderer2,
    public elementRef: ElementRef,

  ) {
    this.getRight = this.rightService.getRight();
  }

  dtTrigger: Subject<any> = new Subject<any>();



  TabActive1 = true;
  TabActive2 = true;
  TabActive3 = true;
  TabActive4 = true;

  public innerWidth: any;
  ngOnInit(): void {
    this.dtOptions = this.helperService.setDtOptions();
    this.RightObj = this.getRight['__zone_symbol__value'];

    this.reloadData();

    this.renderer.listen(this.elementRef.nativeElement, 'click',  (event) => {
      var target = event.target;
      var taskId = target.dataset.taskid;
      if(taskId){
        this.openDetail(taskId);
      }
    })


  }

  async reloadData() {
    (await this.ProjectApiService.getProcess(this.projectId)).subscribe((res) => {
      this.Process = res as any;
      this.initStepBar(1);
      this.initStepBar(2);
      this.initStepBar(3);
      this.initStepBar(4);

      this.changeTab(1);
      this.dtTrigger.next(null);
    });

  }


  initStepBar(tab) {
    var formId = "";
    switch (tab) {
      case 1:
        formId = "20221027173636377";
        break;
      case 2:
        formId = "20221027173807501";
        break;
      case 3:
        formId = "20221027174030564";
        break;
      case 4:
        formId = "20221027174030564";
        break;
    }

    var data = [];
    var data2 = [];
    var Title = [];
    var current = [];
    var idData = [];

    this.Process.filter(w => w.formId == formId).forEach(async (element, index) => {
      var detailData = [];
      var detailData2 = [];

      detailData.push(element['creatorDepName'] + "-" + element['creatorName']);
      detailData2.push(element['beginTime'] ? this.helperService.formatDate(element['beginTime']) : '');

      if((element['process'] as any).length > 7){
        element['process'] = (element['process'] as any).slice(-4);
      }
      element['process'].forEach(async (element2, index2) => {
        detailData.push(element2['depName'] + "-" + element2['empName']);
        detailData2.push(element2['finishTime'] ? this.helperService.formatDate(element2['finishTime']) : '');
        if (element2['current']) {
          current.push(index2 + 1);
        }

      })
      Title.push(element['formTaskNo'] );
      detailData.push('結案歸檔');
      detailData2.push('');
      if (current[index] == null) {
        current[index] = element['process'].length + 1;
      }
      data.push(detailData);
      data2.push(detailData2);
      idData.push(element['formTaskId']);

    })
    for (var i = 0; i < data.length; i++) {
      var k = i + 1;
      $("#stepbar" + tab).append("<button class='btn btn-outline-info btn-block flowBtn mb-2' data-taskId='"+idData[i]+"'>"+Title[i]+"</button> <div class='callout callout-info  flowDiv'><h5>  <button class='btn btn-info btn-sm' data-taskId='"+idData[i]+"'><i data-taskId='"+idData[i]+"' class='fa fa-list-ul'></i></button> " + Title[i] + "</h5><div id='stepbarDraw_" + tab + "_" + k + "'></div></div><br>");

      (<any>$("#stepbarDraw_" + tab + "_" + k)).stepbar({
        items: data[i],
        dates: data2[i],
        color: '#ccc',
        fontColor: '#000',
        selectedColor: '#2AA7C1',
        selectedFontColor: '#fff',
        current: current[i]
      });
    }

    this.innerWidth = window.innerWidth;
    if(this.innerWidth > 1000){
      $(".flowBtn").hide();
      $(".flowDiv").show();
    }else{
      $(".flowBtn").show();
      $(".flowDiv").hide();
    }
  }
  changeTab(tabIndex) {
    switch (tabIndex) {
      case 1:
        this.TabActive1 = true;
        this.TabActive2 = false;
        this.TabActive3 = false;
        this.TabActive4 = false;
        break;
      case 2:
        this.TabActive1 = false;
        this.TabActive2 = true;
        this.TabActive3 = false;
        this.TabActive4 = false;
        break;
      case 3:
        this.TabActive1 = false;
        this.TabActive2 = false;
        this.TabActive3 = true;
        this.TabActive4 = false;
        break;
      case 4:
        this.TabActive1 = false;
        this.TabActive2 = false;
        this.TabActive3 = false;
        this.TabActive4 = true;
        break;
    }


  }

  openDetail(taskId){
    const modalRef = this.modalService.open(FormSignFlowComponent, { size: 'lg' });
    modalRef.componentInstance._id = taskId;
  }
}
