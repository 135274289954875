<div class="card">
  <div class="card-header">
    <h5 class="card-title mt-2">
      {{title}}
    </h5>
    <div class="card-tools">
      <button type="button" class="btn bnt-tool float-right mr-2" data-card-widget="collapse"
        (click)="handleMainMenuAction()">
        <i [ngClass]="isMenuExtended ? 'fas fa-minus' : 'fas fa-plus'" [@rotate]="isMenuExtended"></i>
      </button>
      <ng-container *ngFor="let data of component">
        <button *ngIf="action.allowEdit" (click)="openModal('pcces-add')" type="button"
          class="btn btn-info float-right mr-2">
          <i class="{{data.icon}}"></i> {{data.name}}
        </button>
      </ng-container>
    </div>
  </div>
  <div class="card-body" [@openClose]="isMenuExtended">


    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger_pcces"
      class="table table-striped table-bordered table-hover nowrap" id="pcces_table">
      <thead>
        <tr>
          <th>物料編號</th>
          <th>物料名稱</th>
          <th>圖說編號</th>
          <th>規格</th>
          <th>申購數量</th>
          <th>單位</th>
          <th *ngIf="action.allowEdit">刪除</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let datas of pccesData;">
          <td>{{datas.pccesId}}</td>
          <td>{{datas.description}}</td>
          <td>{{datas.diagramNo}}</td>
          <td>{{datas.spec}}</td>
          <td>
            <input *ngIf="action.allowEdit" type="number" [value]="datas.quantity" class="form-control"
              (focusout)="quantityChange($event, datas._id)" min="0">
            {{action.allowEdit?'':datas.quantity}}
          </td>
          <td>{{datas.unit}}</td>
          <td *ngIf="action.allowEdit" style="text-align: center;"><a style="cursor: pointer;"
              (click)="deletePccesData(datas._id)"><i class="fas fa-trash-alt text-danger mr-2"></i></a></td>
        </tr>
      </tbody>
    </table>


  </div>
</div>
