<div class="modal-header">
  <h4 class="modal-title">{{dailyDate | date:'yyyy/MM/dd'}} 施工照片</h4>
  <button type="button" class="btn btn-tool" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <i class="fas fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="workImgList.length > 0" class="row">
    <div class="col-sm-6 col-md-4 col-lg-2" *ngFor="let image of _albums; let i=index" id="workImg_{{i}}">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <input type="text" class="form-control updateDataByColumn updateDataByListColumn"
              name="workImgList[{{i}}].workImgTitle" [value]="image.caption" data-label="每日施工照片{{i+1}}"
              data-subdocName="workImgList" data-subdocId="{{image._id}}" readonly>
          </div>
        </div>
        <div class="col-12" style="text-align: center;    height: 100px;">
          <img class="workImg" [src]="image.thumb" (click)="openWorkImg(i)" />
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="workImgList.length === 0" class="row">
    <div class="col-12">
      <p class="text-center text-danger">此日誌尚未上傳施工照片!</p>
    </div>
  </div>
</div>
<div class="modal-footer justify-content-between">
  <button type="button" class="btn btn-default" (click)="activeModal.close('Close click')">關閉</button>
</div>
