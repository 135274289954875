<div class="modal-body">
  <form class="form-horizontal" [formGroup]="EditForm" method="post" action="">
    <div class="card">
      <div class="card-header">
        <h5 class="card-title mt-2">主要資料</h5>
        <div class="card-tools">


          <button type="button" class="btn  btn-danger float-left  login-with-google-btn" #loginRef>
            綁定Google帳號 </button>


          <button type="button" class="btn bnt-tool" data-card-widget="collapse">
            <i class="fas fa-minus"></i>
          </button>
        </div>
      </div>
      <!-- /.card-header -->
      <div class="card-body" style="display: block;">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label><code>*</code>員工編號</label>
              <input type="text" formControlName="account" class="form-control" readonly>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label><code>*</code>姓名</label>
              <input type="text" formControlName="empName" class="form-control" readonly>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label><code>*</code>身分證字號</label>
              <input type="text" formControlName="personalNo" class="form-control" readonly>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label><code>*</code>性別</label>
              <ng-select2 [data]="s2Genders" [width]="'100%'" [placeholder]="'請選擇性別'"
                (valueChanged)="onChange_Genders($event)" formControlName="gender" [disabled]="true"
                [allowClear]="true">
              </ng-select2>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label><code>*</code>生日</label>
              <input type="date" formControlName="birthday" class="form-control" readonly>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label><code>*</code>公司信箱</label>
              <input type="text" formControlName="email" class="form-control" readonly>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label><code>*</code>部門</label>
              <ng-select2 [data]="s2Departments" [value]="DepartmentIdv" [width]="'100%'" [placeholder]="'請選擇部門'"
                (valueChanged)="onChange_Departments($event)" formControlName="departmentId" [disabled]="true"
                [allowClear]="true">
              </ng-select2>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label>職務</label>
              <ng-select2 [data]="s2Jobs" [width]="'100%'" [value]="Jobs" [placeholder]="'請選擇功能職務'"
                (valueChanged)="onChange_Jobs($event)" [disabled]="true" [allowClear]="true">
              </ng-select2>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label><code>*</code>到職日</label>
              <input type="date" formControlName="onBoardDate" class="form-control" readonly>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- <div class="col-md-4">
            <div class="form-group">
              <label>到期日期</label>
              <input type="date" [formControl]="expireDate" class="form-control" readonly>
            </div>
          </div> -->
          <div class="col-md-4">
            <div class="form-group">
              <br>
              <label>
                <input type="checkbox" [value]="true" formControlName="boss" disabled>
                是否為主管
              </label>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <br>
              <label *ngIf="googleId !== ''">
                <input type="checkbox" disabled checked>
                已綁定Google帳號 ({{googleMail}})
              </label>

              <label *ngIf="googleId === ''">
                <input type="checkbox" disabled>
                未綁定Google帳號
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10">
            <div class="form-group">
              <label>印章電子檔上傳(建議尺寸 200x200px)</label>
              <div class="input-group">
                <div class="custom-file">
                  <input #fileUpload2 type="file" class="custom-file-input" accept=".jpg,.png,.jpeg"
                    (change)="fileSelected($event,'seal')" disabled>
                  <label class="custom-file-label" for="fileUpload">{{fileName2 ? fileName2 : '請上傳檔案'}}</label>
                </div>
              </div>

            </div>
          </div>
          <div class="col-md-2" style="text-align:center;max-width:100px;height:100px;">

            <img *ngIf="this.imgsrc2 === '' && EmployeSealBuffer === undefined" src="assets/img/seal.png"
              style="max-width: 100%;">
            <img id="Img2" [src]="EmployeSealBuffer || this.imgsrc2" style="max-width: 100%;" />
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
  </form>
  <div class="card">
    <div class="card-header">
      <h5 class="card-title mt-2">其他資料</h5>
      <div class="card-tools">
        <!-- <button type="button" *ngIf="!btnShow1 && this.RightObj['update']" class="btn btn-info"  (click)="enableCard(1)">
          編輯
         </button>
         <button type="button" *ngIf="btnShow1 && this.RightObj['update']" class="btn btn-info"  (click)="editEmployee(1)">
           儲存
         </button> -->

        <button type="button" class="btn bnt-tool" data-card-widget="collapse">
          <i class="fas fa-minus"></i>
        </button>

      </div>
    </div>
    <!-- /.card-header -->
    <div class="card-body" style="display: block;">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label>個人照上傳(建議尺寸 300x300px)</label>
            <div class="input-group">
              <div class="custom-file">
                <input #fileUpload type="file" class="custom-file-input" accept=".jpg,.png,.jpeg"
                  (change)="fileSelected($event,'pic')">
                <label class="custom-file-label" for="fileUpload">{{fileName ? fileName : '請上傳檔案'}}</label>
              </div>
            </div>
            <br>
            <img *ngIf="this.imgsrc === '' && EmployePicBuffer === undefined"
              src="assets/img/198930468-47ea8747-19d6-4c3a-b93e-b706a83277e9.png" style="max-width: 100%;">
            <img id="Img" [src]="EmployePicBuffer || this.imgsrc" style="max-width: 100%;" />
          </div>
        </div>
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label><code>*</code>密碼</label>
                <input type="text" [formControl]="password" name="password" data-label="密碼"
                  class="form-control updateDataByColumn updateEncrypt">
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <label>聯絡電話</label>
                <input type="text" [formControl]="phone" name="phone" data-label="連絡電話"
                  class="form-control updateDataByColumn">
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>聯絡地址</label>
                <input type="text" [formControl]="address" name="address" data-label="聯絡地址"
                  class="form-control updateDataByColumn">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>專長</label>
                <input type="text" [formControl]="expertise" name="expertise" data-label="專長"
                  class="form-control updateDataByColumn">
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>興趣</label>
                <input type="text" [formControl]="interest" name="interest" data-label="興趣"
                  class="form-control updateDataByColumn">
              </div>
            </div>
          </div>
        </div>
      </div>

      <form class="form-horizontal" id="EmergencyContactForm">
        <div #contact>
          <div class="row">
            <div class="col-md-12">
              <button type="button" class="btn btn-info float-right" id="addEmergencyContact"
                (click)="addEmergencyContact()">
                <i class="fas fa-plus"></i> 緊急連絡人
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4"> <label><code>*</code>緊急聯絡人</label> </div>
            <div class="col-md-3"> <label>關係</label> </div>
            <div class="col-md-4"> <label>聯絡電話</label> </div>
            <div class="col-md-1"> </div>
          </div>
          <div class="row" *ngFor="let item of contactList let index=index;" id="e_{{index}}">
            <div class="col-md-4">
              <div class="form-group">
                <input type="hidden" name="EmergencyContact[{{index}}]._id" [value]="item._id">
                <input type="text" class="form-control updateDataByColumn updateDataByListColumn"
                  name="EmergencyContact[{{index}}].contactName" [value]="item.contactName"
                  data-label="緊急聯絡人{{index+1}}" data-subdocName="contactList" data-subdocId="{{item._id}}"
                  data-subdocumnet_type="list">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <input type="text" class="form-control updateDataByColumn updateDataByListColumn"
                  name="EmergencyContact[{{index}}].relationship" [value]="item.relationship"
                  data-label="緊急聯絡人{{index+1}}:關係" data-subdocName="contactList" data-subdocId="{{item._id}}"
                  data-subdocumnet_type="list">
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <input type="text" class="form-control updateDataByColumn updateDataByListColumn"
                  name="EmergencyContact[{{index}}].contactPhone" [value]="item.contactPhone"
                  data-label="緊急聯絡人{{index+1}}:聯絡電話" data-subdocName="contactList" data-subdocId="{{item._id}}"
                  data-subdocumnet_type="list">
              </div>
            </div>
            <div class="col-md-1">
              <div class="form-group">
                <button type="button" class="btn btn-tool text-danger" data-toggle="tooltip" title="刪除"
                  (click)="del('e', index)">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <!-- /.card-body -->
    </div>
  </div>
  <form class="form-horizontal" id="LicenseForm">
    <div class="card">
      <div class="card-header">
        <h5 class="card-title mt-2">證照資料</h5>
        <div class="card-tools">
          <!-- <button type="button" *ngIf="!btnShow2 && this.RightObj['update']" class="btn btn-info"  (click)="enableCard(2)">
           編輯
          </button>
          <button type="button" *ngIf="btnShow2 && this.RightObj['update']" class="btn btn-info"  (click)="editEmployee(2)">
            儲存
          </button> -->

          <button type="button" class="btn bnt-tool " data-card-widget="collapse">
            <i class="fas fa-minus"></i>
          </button>

        </div>
      </div>
      <!-- /.card-header -->
      <div #license class="card-body" style="display: block;">
        <div class="row">
          <div class="col-md-12">
            <button type="button" class="btn btn-info  float-right" id="addLicense" (click)="addLicense()">
              <i class="fas fa-plus"></i> 證照資料
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2"> <label><code>*</code>類別</label> </div>
          <div class="col-md-2"> <label><code>*</code>證照名稱</label> </div>
          <div class="col-md-3"> <label><code>*</code>予以公司使用</label> </div>
          <div class="col-md-2"> <label><code>*</code>是否回訓</label></div>
          <div class="col-md-2"> <label>下次回訊日</label></div>
          <div class="col-md-1"></div>
        </div>
        <div class="row" *ngFor="let item of licenseList let index=index;" id="l_{{index}}">
          <div class="col-md-2">
            <div class="form-group">
              <input type="hidden" name="License[{{index}}]._id" [value]="item._id">

              <select #selectParentgetlicenseTypes class="form-control updateDataByColumn updateDataByListColumn"
                name="License[{{index}}].licenseTypeId" data-label="證照資料{{index+1}}:類別" data-subdocName="licenseList"
                data-subdocId="{{item._id}}" data-subdocumnet_type="list">
                <option value="">請選擇類別</option>
                <option *ngFor="let items of ParentgetlicenseTypes" [value]="items.id"
                  [selected]="items.id === item['licenseTypeId']" s>
                  {{items.name}}</option>
              </select>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <input type="text" class="form-control updateDataByColumn updateDataByListColumn"
                name="License[{{index}}].licenseName" [value]="item.licenseName" data-label="證照資料{{index+1}}:證照名稱"
                data-subdocName="licenseList" data-subdocId="{{item._id}}" data-subdocumnet_type="list">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <div class="custom-control custom-radio">
                <input class="custom-control-input updateDataByColumn updateDataByListColumn" type="radio"
                  id="License[{{index}}].customRadio1_0" name="License[{{index}}].approveUse" value="true"
                  [checked]="item.approveUse === true" data-label="證照資料{{index+1}}:予以公司使用" data-subdocName="licenseList"
                  data-subdocId="{{item._id}}" data-subdocumnet_type="list">
                <label for="License[{{index}}].customRadio1_0" class="custom-control-label">同意</label>
              </div>
              <div class="custom-control custom-radio">
                <input class="custom-control-input updateDataByColumn updateDataByListColumn" type="radio"
                  id="License[{{index}}].customRadio1_1" name="License[{{index}}].approveUse" value="false"
                  [checked]="item.approveUse === false" data-label="證照資料{{index+1}}:予以公司使用"
                  data-subdocName="licenseList" data-subdocId="{{item._id}}" data-subdocumnet_type="list">
                <label for="License[{{index}}].customRadio1_1" class="custom-control-label">不同意</label>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group">
              <div class="custom-control custom-radio">
                <input class="custom-control-input updateDataByColumn updateDataByListColumn" type="radio"
                  id="License[{{index}}].customRadio2_0" name="License[{{index}}].needTrain" value="true"
                  [checked]="item.needTrain === true" data-label="證照資料{{index+1}}:是否回訓" data-subdocName="licenseList"
                  data-subdocId="{{item._id}}" data-subdocumnet_type="list">
                <label for="License[{{index}}].customRadio2_0" class="custom-control-label">需要</label>
              </div>
              <div class="custom-control custom-radio">
                <input class="custom-control-input updateDataByColumn updateDataByListColumn" type="radio"
                  id="License[{{index}}].customRadio2_1" name="License[{{index}}].needTrain" value="false"
                  [checked]="item.needTrain === false" data-label="證照資料{{index+1}}:是否回訓" data-subdocName="licenseList"
                  data-subdocId="{{item._id}}" data-subdocumnet_type="list">
                <label for="License[{{index}}].customRadio2_1" class="custom-control-label">不需要</label>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <!-- Date -->
            <div class="form-group">
              <input type="date" name="License[{{index}}].nextTrainDate"
                class="form-control updateDataByColumn updateDataByListColumn" [value]="item.nextTrainDate"
                data-label="證照資料{{index+1}}:下次回訊日" data-subdocName="licenseList" data-subdocId="{{item._id}}"
                data-subdocumnet_type="list">
            </div>
          </div>
          <div class="col-md-1">
            <div class="form-group">
              <button type="button" class="btn btn-tool text-danger card2" data-toggle="tooltip" title="刪除"
                (click)="del('l', index)">
                <i class="fas fa-trash-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
  </form>



  <!-- <div class="row">
    <div class="col-md-12">
    <button type="button" class="btn btn-info float-right" (click)="editEmployee(0)">確定</button>
  </div> -->
</div>
