<div class="modal-header">
  <h4 class="modal-title">{{purchaseControl.controlName}}</h4>
  <button type="button" class="btn btn-tool" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <i class="fas fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form class="form-horizontal" [formGroup]="EditForm" method="post" action="">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label><code>*</code>規格</label>
          <input type="text" class="form-control" formControlName="controlSpec">
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label><code>*</code>單位</label>
          <input type="text" class="form-control" formControlName="controlUnit">
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label><code>*</code>數量</label>
          <input type="number" class="form-control" formControlName="controlNumber">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input class="custom-control-input" type="checkbox" id="customCheckbox1" [value]="true" formControlName="material">
            <label for="customCheckbox1" class="custom-control-label">純料</label>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input class="custom-control-input" type="checkbox" id="customCheckbox2" [value]="true" formControlName="work">
            <label for="customCheckbox2" class="custom-control-label">純工</label>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input class="custom-control-input" type="checkbox" id="customCheckbox3" [value]="true" formControlName="both">
            <label for="customCheckbox3" class="custom-control-label">工料合併</label>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input class="custom-control-input" type="checkbox" id="customCheckbox4" [value]="true" formControlName="data">
            <label for="customCheckbox4" class="custom-control-label">送審資料</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label><code>*</code>請採期限</label>
          <input type="date" class="form-control" formControlName="purchaseDate">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label><code>*</code>採購申請日</label>
          <input type="date" class="form-control" formControlName="applyDate">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>材料送審</label>
          <ng-select2 width="100%" placeholder="材料送審" formControlName="materialId">
            <option *ngFor="let data of materials" [value]="data._id">{{data.materialItem}} -
              {{data.materialName}}</option>
          </ng-select2>
        </div>
      </div>
    </div>
  </form>

  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-default" (click)="activeModal.close('Close click')">關閉</button>
    <button type="button" class="btn btn-info" (click)="editPurchaseControl()">確定</button>
  </div>
