<div class="modal-header">
  <h4 class="modal-title">施工日誌</h4>
  <button type="button" class="btn btn-tool" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <i class="fas fa-times"></i>
  </button>
</div>
<div class="modal-body">

    <div class="row">
      <div class="col-md-12">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered table-hover">
          <thead>
            <tr>
              <th>日期</th>
              <th>完成進度</th>
              <th>本日施作工項</th>
              <th>上級提醒注意事項</th>
              <th>需公司協助事項</th>
              <th>內容</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of dailyReport">
              <td>{{item.dailyDate | date:'yyyy/MM/dd' }}<br>
                上午：{{item.morningWeather}}
                下午：{{item.afternoonWeather}}</td>
                <td>{{item.actualComplete}}% / {{item.expectComplete}}%</td>
                <td>
                  <span *ngIf="item.todayItem?.length <= 20">{{item.todayItem}}</span>
                  <span *ngIf="item.todayItem?.length > 20">{{item.todayItem?.substr(0,25)}} ...</span>
                </td>
                <td>
                  <span *ngIf="item.bossSuggest?.length <= 20">{{item.bossSuggest}}</span>
                  <span *ngIf="item.bossSuggest?.length > 20">{{item.bossSuggest?.substr(0,25)}} ...</span>
                </td>
                <td>
                  <span *ngIf="item.waitToSlove?.length <= 20">{{item.waitToSlove}}</span>
                  <span *ngIf="item.waitToSlove?.length > 20">{{item.waitToSlove?.substr(0,25)}} ...</span>
                </td>
                <td><a href="/#/DailyReportView/{{item._id}}" target="_blank" type="button" data-widget=""
                    data-toggle="tooltip" title="檢視" class="btn btn-default mr-2">檢視</a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-default" (click)="activeModal.close('Close click')">關閉</button>
  </div>
