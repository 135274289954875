import { element } from 'protractor';
import { FormtaskapiService } from '@services/formtaskapi.service';
import { filter } from 'rxjs/operators';
import { FormTaskProcessApiService } from '@services/form-task-process-api.service';
import { ApiService } from '@services/api.service';
import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select2OptionData } from 'ng-select2';
import { ProjectApiService } from '@services/projectapi.service';
import { HelperService } from '@services/helper.service';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { outputAst } from '@angular/compiler';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contract-item-detail-add',
  templateUrl: './contract-item-detail-add.component.html',
  styleUrls: ['./contract-item-detail-add.component.scss']
})
export class ContractItemDetailAddComponent implements OnInit {
  @Input() projectId;
  @Input() formTaskId;
  @Input() contractItemList;
  thisContractItemList
  @Input() action;
  @Input() detailData;

  createForm: any;
  result: any;
  pccesCodeData: any;
  pccesId: any = "";
  description: any = "";
  viewMode: boolean = false;
  unit: any;
  itemKind: any;

  QueryPType = 1;
  s2PccesCodeF5: Select2OptionData[];
  s2PccesCode = new Array<Select2OptionData[]>;
  CodeSection: String;
  pccesCodeBys2 = new Array;
  pccesCodeBys2T = new Array;

  @Output() refresh: EventEmitter<any> = new EventEmitter();
  constructor(
    public apiService: ApiService,
    public activeModal: NgbActiveModal,
    public ProjectApiService: ProjectApiService,
    public helperService: HelperService,
    public FormTaskProcessApiService: FormTaskProcessApiService,
    private toastr: ToastrService,

  ) { }

  ngOnInit(): void {
    this.createForm = new FormGroup({
      pccesId: new FormControl('', Validators.required),
      diagramNo: new FormControl('', Validators.required),
      spec: new FormControl('', Validators.required),
      quantity: new FormControl('', Validators.required),
    });

    this.ddlPccesF5();
  }

  clear() {
    const pccesResult = document.getElementById('pccesResult') as HTMLInputElement | null;
    if (pccesResult != null) {
      pccesResult.value = '';
    }
    const pccesNameResult = document.getElementById('pccesNameResult') as HTMLInputElement | null;
    if (pccesNameResult != null) {
      pccesNameResult.value = '';
    }

    this.createForm.get('pccesId').reset();
    this.createForm.get('diagramNo').reset();
    this.createForm.get('spec').reset();
    this.createForm.get('quantity').reset();

    this.pccesId = '';
    this.itemKind = '';
    this.description = '';
    this.unit = '';
    this.CodeSection = '';

    this.s2PccesCodeF5 = [];
    this.ddlPccesF5();
    for (var i = 0; i < this.s2PccesCode.length; i++) {
      this.s2PccesCode[i] = [];
    }
    this.s2PccesNameQ=[];


    this.queryCNName();
    this.s2ABResultQ=[];
    this.s2ABResult2Q=[];
  }

  QueryPTypeFunc(t) {
    this.QueryPType = t;

    const pccesResult = document.getElementById('pccesResult') as HTMLInputElement | null;
    if (pccesResult != null) {
      pccesResult.value = '';
    }
    const pccesNameResult = document.getElementById('pccesNameResult') as HTMLInputElement | null;
    if (pccesNameResult != null) {
      pccesNameResult.value = '';
    }

    this.pccesId = '';
    this.itemKind = '';
    this.description = '';
    this.unit = '';
    this.CodeSection = '';

    this.s2PccesCodeF5 = [];
    this.ddlPccesF5();
    for (var i = 0; i < this.s2PccesCode.length; i++) {
      this.s2PccesCode[i] = [];
    }
    this.createForm.controls['pccesId'].setValue('');
    this.s2PccesNameQ=[];

  }

  async ddlPccesF5() {
    (await this.FormTaskProcessApiService.getPccesF5())
      .subscribe((res) => {
        this.result = res as any;
        if (this.result.status == false) {
          Swal.fire({
            title: this.result.message,
            icon: 'error'
          });
        } else {
          var PccesCodeF5 = (res as any);
          var arr = [];
          var k = 0;
          PccesCodeF5.forEach(element => {
            arr.push({ id: element['itemCode'], text: element['itemCode'] + "-" + element['cname'] });
            k++;
            if (k == PccesCodeF5.length) {
              this.s2PccesCodeF5 = arr;
            }
          });
        }
      });
  }

  async ddlPccesChapCode(ChapCode, selfRowValue) {
    (await this.FormTaskProcessApiService.getPccesChapCode(this.CodeSection, ChapCode, selfRowValue))
      .subscribe((res) => {
        this.result = res as any;
        if (this.result.status == false) {
          Swal.fire({
            title: this.result.message,
            icon: 'error'
          });
        } else {
          var PccesCodeF5 = (res as any);
          var arr = [];
          var k = 0;
          PccesCodeF5.forEach(element => {
            arr.push({ id: element['selfRow'], text: element['code'] + "-" + element['content'] });
            k++;
            if (k == PccesCodeF5.length) {
              this.s2PccesCode[ChapCode - 6] = arr;
            }
          });

        }
      });
  }

  onChange_PccesCodeF5(val: String): void {
    this.CodeSection = val;
    this.pccesCodeBys2[0] = this.CodeSection;
    for (var i = 0; i < this.s2PccesCode.length; i++) {
      this.s2PccesCode[i] = [];
    }
    if (val != undefined) {
      this.pccesCodeBys2T[0] = this.s2PccesCodeF5.filter(w => w['id'] == val)[0]['text'].split('-')[1];
      this.ddlPccesChapCode(6, '');
    }
  }
  onChange_PccesCode(val: String, k): void {
    this.s2PccesCode[k] = [];
    if (val != undefined) {
      this.ddlPccesChapCode(k + 1, val);

      this.pccesCodeBys2[k - 5] = this.s2PccesCode[k - 6].filter(w => w['id'] == val)[0]['text'].split('-')[0];
      this.pccesCodeBys2T[k - 5] = this.s2PccesCode[k - 6].filter(w => w['id'] == val)[0]['text'].split('-')[1];
      if (k == 10) {
        this.pccesId = "";
        this.description = "";
        for (var i = 0; i < this.pccesCodeBys2.length; i++) {
          this.pccesId += this.pccesCodeBys2[i];
          if (this.pccesCodeBys2T[i] != "" && i != 5) {
            this.description += this.pccesCodeBys2T[i] + " ";
          }
        }
        this.unit = this.s2PccesCode[k - 6].filter(w => w['id'] == val)[0]['text'].split('-')[1];
        this.createForm.controls['pccesId'].setValue(this.pccesId);
      }
    }
  }
  async queryPcces(val = "") {
    const code = document.getElementById('queryPcces') as HTMLInputElement | null;
    if (code != null) {
      val = code.value;
    }
    (await this.FormTaskProcessApiService.getPccesById(val))
      .subscribe((res) => {
        this.result = res as any;
        const pccesResult = document.getElementById('pccesResult') as HTMLInputElement | null;
        if (this.result._id != "") {
          if (pccesResult != null) {
            pccesResult.value = res['description'] + " " + res['unit'];
          }
          this.pccesId = code ? code.value : val;
          this.itemKind = res['itemKind'];
          this.description = res['description'];
          this.unit = res['unit'];
          this.createForm.controls['pccesId'].setValue(code? code.value : val);
        } else {
          pccesResult.value = '查無資料';
        }
      });
  }

  s2PccesNameQ: Select2OptionData[];
  onChange_PccesNameQ(val: string): void {
    if (val != undefined) {
      this.queryPcces(val);
    }
  }
  async queryPccesName() {
    const code = document.getElementById('queryPccesName') as HTMLInputElement | null;

    (await this.FormTaskProcessApiService.getPccesByDes(code.value))
      .subscribe((res) => {
        var result = res as any;
        this.s2PccesNameQ = [];
        var arr = [];
        var k = 0;
        result.forEach(element => {
          arr.push({ id: element['_id'], text: element['_id'] + "-" + element['description'] });
          k++;
          if (k == result.length) {
            this.s2PccesNameQ = arr;
          }
        });
      });
  }
  save() {
    if (!this.createForm.valid) {
      Swal.fire({
        title: "請填寫必填欄位",
        icon: 'warning',
        confirmButtonColor: "#17a2b8",
      });
      return;
    }

    let formData = {
      pccesId: this.pccesId,
      diagramNo: this.createForm.get("diagramNo").value,
      spec: this.createForm.get("spec").value,
      quantity: this.createForm.get("quantity").value,
      price: 0,
      summary: 0,
    }

    this.FormTaskProcessApiService
      .editContractItem(formData, this.formTaskId, this.contractItemList.contractId, this.contractItemList._id)
      .subscribe((res) => {
        this.toastr.success("資料已儲存");
        this.refresh.emit();
        this.clear();
      });
  }
  s2ABResultQ: Select2OptionData[];
  s2ABResult2Q: Select2OptionData[];
  loading=false;
  async queryCNName() {
    const code = document.getElementById('queryCNName') as HTMLInputElement | null;
    (await this.FormTaskProcessApiService.findKeywordByAB(code.value))
      .subscribe((res) => {
        var result = res as any;
        this.s2ABResultQ = [];
        var arr = [];
        var k = 0;
        result.forEach(element => {
          arr.push({ id: element.split(',')[0], text: element.split(',')[1] });
          k++;
          if (k == result.length) {
            this.s2ABResultQ = arr;
          }
        });

      });
  }
  async queryPccesById(val = "") {
    (await this.FormTaskProcessApiService.findByChapCode(val))
      .subscribe((res) => {
        var result = (res as any);
        this.s2ABResult2Q = [];
        var arr = [];
        var k = 0;
        result.forEach(element => {
          arr.push({ id: element._id, text: element.description + " " +element.unit });
          k++;
          if (k == result.length) {
            this.s2ABResult2Q = arr;
          }
        });
        this.loading = false;
      });
  }

  onChange_ABResultQ(val: string): void {
    if (val != undefined) {
      this.loading = true;
      this.queryPccesById(val);
    }
  }
  onChange_ABResult2Q(val: string): void {
    if (val != undefined) {
      debugger
      this.queryPcces(val);
    }
  }
}
