<div class="modal-header">
  <h4 class="modal-title">合約</h4>
  <button type="button" class="btn btn-tool" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <i class="fas fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <div class="alert alert-info alert-dismissible">
        <h5 class="mt-2"> 已請採總金額  {{contractSubtotal | currency:'TWD': 'symbol':'1.0'}} 元 ｜ 已計價總金額 {{calculateSubtotal | currency:'TWD': 'symbol':'1.0'}} 元 (總計價率{{percentSubtotal}})</h5>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <!-- <div class="btn-group">
        <button type="button" class="btn btn-default active"  (click)="queryData('all')">全部</button>
        <button type="button" class="btn btn-default" (click)="queryData('p')">請採</button>
        <button type="button" class="btn btn-default"  (click)="queryData('a')">追加減</button>
      </div> -->
    </div>
  </div>
  <br>
    <div class="row">
      <div class="col-md-12">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered table-hover nowrap">
          <thead>
            <tr>
              <th>類別</th>
              <th>工項名稱</th>
              <th>合約編號</th>
              <th>廠商</th>
              <th>合約金額</th>
              <th>預付款</th>
              <th>已計價</th>
              <th>計價率</th>
              <th>計價紀錄</th>
              <th>追加減紀錄</th>
              <th>合約</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of contract">
              <td>{{item.contractTypeName}}</td>
              <td>{{item.workTypeName}}</td>
              <td>{{item.contractNo}}</td>
              <td>{{item.supplierName}}</td>
              <td>{{item.subtotal | currency:'TWD': 'symbol':'1.0'}}</td>
              <td>{{item.prepayMoney | currency:'TWD': 'symbol':'1.0'}}</td>
              <td>{{item.calculateSum | currency:'TWD': 'symbol':'1.0'}}</td>
              <td>{{item.calculatePercent}}</td>
              <td>
                <button type="button" *ngIf="item.testReport !== null" data-widget="" data-toggle="tooltip"
                title="計價" class="btn btn-default"  (click)="open(item.calculateForm,item._id,item.subtotal)">計價</button>
              </td>
              <td>
                <button type="button" *ngIf="item.testReport !== null" data-widget="" data-toggle="tooltip"
                title="追加減" class="btn btn-default"  (click)="open2(item.addFormLogList)">追加減</button>
              </td>
              <td>
                <button type="button" *ngIf="item.testReport !== null" data-widget="" data-toggle="tooltip"
                  title="檢視" class="btn btn-default"  (click)="download(item.contractFile)">檢視</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-default" (click)="activeModal.close('Close click')">關閉</button>
  </div>
