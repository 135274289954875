<div class="modal-header">
  <h4 class="modal-title">新增工項類型</h4>
  <button type="button" class="btn btn-tool" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <i class="fas fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form class="form-horizontal" [formGroup]="CreateForm" method="post" action="">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label><code>*</code>工項類型名稱</label>
          <input type="text" formControlName="workTypeName" class="form-control">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>上層工項</label>
          <ng-select2 [data]="s2WorkTypes" [width]="'100%'" [placeholder]="'請選擇'" formControlName="rootWorkTypeId"
          (valueChanged)="onChange_WorkTypes($event)" [allowClear]="true" >
        </ng-select2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label><code>*</code>排序</label>
          <input type="number" formControlName="sort" class="form-control">
        </div>
      </div>
      <!-- <div class="col-md-6">
        <div class="form-group">
          <label><code>*</code>階層</label>
          <input type="number" formControlName="level" class="form-control">
        </div>
      </div> -->
    </div>
  </form>

  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-default" (click)="activeModal.close('Close click')">關閉</button>
    <button type="button" class="btn btn-info" (click)="addWorkType()">確定</button>
  </div>
