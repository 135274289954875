<div class="card">
  <div class="card-header">
    <h5 class="card-title mt-2">
      {{title}}
    </h5>
    <div class="card-tools">
      <button type="button" class="btn bnt-tool float-right mr-2" data-card-widget="collapse"
        (click)="handleMainMenuAction()">
        <i [ngClass]="isMenuExtended ? 'fas fa-minus' : 'fas fa-plus'" [@rotate]="isMenuExtended"></i>
      </button>
    </div>
  </div>
  <div class="card-body" [@openClose]="isMenuExtended">

<span></span>
<div class="alert alert-info alert-dismissible">
  <h5 class="mt-2"> 本期金額總計：{{total | currency:'TWD': 'symbol':'1.0'}}</h5>
</div>

<br>
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger_pcces"
      class="table table-striped table-bordered table-hover " id="pcces_table">
      <thead>
        <tr>
          <th>主/附約</th>
          <th>物料編號/名稱</th>
          <th>規格</th>
          <th>總數量</th>
          <th>單位</th>
          <th>單價</th>
          <th>小計</th>
          <th>前期數量</th>
          <th>前期金額</th>
          <th><span class="text-danger">*</span>本期數量</th>
          <th>本期金額</th>
          <th>累計數量</th>
          <th>累計金額</th>
          <th>完成率</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let datas of detailData?.formTaskContent?.calculateForm?.contractDetailList;">
          <td>
            <span *ngIf="!datas.addContractId">主約</span>
            <span *ngIf="datas.addContractId">{{datas._id.substring(0,8)}}<br>追加附約</span>
          </td>
          <td>
            <i *ngIf="datas.danger" title="計價數量超過原採購數量。" class="fas fa-exclamation-triangle fa-fw text-danger"></i>
            {{datas.pccesId}}<br>
            {{datas.description}}
          </td>
          <td>{{datas.spec}}</td>
          <td>{{datas.quantity}}</td>
          <td>{{datas.unit}}</td>
          <td>{{datas.price | currency:'TWD': 'symbol':'1.0'}}</td>
          <td>{{datas.summary | currency:'TWD': 'symbol':'1.0'}}</td>
          <td>{{datas.p_quantity}}</td>
          <td>{{datas.p_price | currency:'TWD': 'symbol':'1.0'}}</td>
          <td>
            <input *ngIf="action.allowEdit" type="number" id="q_{{datas._id}}" [value]="datas.this_quantity" min="0"
              class="form-control" (focusout)="quantityChange($event, datas._id,datas)" data-subId="{{datas.this_id}}">

            <input *ngIf="!action.allowEdit" type="number" id="q_{{datas._id}}" [value]="datas.this_quantity"
              class="form-control" data-subId="{{datas.this_id}}" readonly>

          </td>
          <td>
            <span *ngIf="datas.danger" class="text-danger font-weight-bold">
              {{datas.this_price | currency:'TWD': 'symbol':'1.0'}}
            </span>
            <span *ngIf="!datas.danger">
              {{datas.this_price | currency:'TWD': 'symbol':'1.0'}}
            </span>


          </td>
          <td>{{datas.a_quantity}}</td>
          <td>{{datas.a_price | currency:'TWD': 'symbol':'1.0'}}</td>
          <td>
            <span *ngIf="datas.danger" class="text-danger font-weight-bold">{{datas.percent}}%</span>
            <span *ngIf="!datas.danger">{{datas.percent}}%</span>
          </td>
        </tr>
      </tbody>
    </table>


  </div>
</div>
