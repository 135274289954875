<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>施工日誌</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a [routerLink]="['/']" href="/">Home</a></li>
          <li class="breadcrumb-item active">施工日誌</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <button type="button" class="btn btn-info float-right" (click)="open('monthReport', null)">
          月報表
        </button>

        <button (click)="Add()" target="_blank"  *ngIf="this.RightObj['insert']"   class="btn btn-info float-right mr-2">
          <i class="fas fa-plus"></i> 新增日誌
        </button>


        <h5 class="modal-title">施工日誌資料</h5>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
              class="table table-striped table-bordered table-hover ">
              <thead>
                <tr>
                  <th>日期/天氣</th>
                  <th>完成進度</th>
                  <th>本日施作工項</th>
                  <th>上級提醒注意事項</th>
                  <th>需公司協助事項</th>
                  <th>內容</th>
                </tr>
              </thead>
              <tbody>
                 <tr *ngFor="let item of dailyReport">
                    <td>{{item.dailyDate | date:'yyyy/MM/dd'}}<br>
                      上午：{{item.morningWeather}}<br>
                      下午：{{item.afternoonWeather}}</td>
                    <td>{{item.actualComplete}}% / {{item.expectComplete}}%</td>
                    <td>
                      <span *ngIf="item.todayItem?.length <= 20">{{item.todayItem}}</span>
                      <span *ngIf="item.todayItem?.length > 20">{{item.todayItem?.substr(0,25)}} ...</span>
                    </td>
                    <td>
                      <span *ngIf="item.bossSuggest?.length <= 20">{{item.bossSuggest}}</span>
                      <span *ngIf="item.bossSuggest?.length > 20">{{item.bossSuggest?.substr(0,25)}} ...</span>
                    </td>
                    <td>
                      <span *ngIf="item.waitToSlove?.length <= 20">{{item.waitToSlove}}</span>
                      <span *ngIf="item.waitToSlove?.length > 20">{{item.waitToSlove?.substr(0,25)}} ...</span>
                    </td>
                  <td>
                    <!-- item.DailyReport._id -->
                    <a *ngIf="isDate7(item.dailyDate)" href="/#/DailyReportEdit/{{item._id}}/false" target="_blank"
                      type="button" data-widget="" data-toggle="tooltip" title="檢視" class="btn btn-default mr-2">編輯</a>

                    <a href="/#/DailyReportView/{{item._id}}" target="_blank" type="button" data-widget=""
                      data-toggle="tooltip" title="檢視" class="btn btn-default mr-2">檢視</a>

                    <!-- <button type="button" data-widget="" data-toggle="tooltip" title="下載" class="btn btn-default"
                      (click)="open('download', item._id)">下載</button> -->

                    <!-- <button type="button" data-widget="" data-toggle="tooltip" title="下載" class="btn btn-default"
                      (click)="open('download', item._id)">下載</button> -->

                    <a href="{{api_url}}/dailyReport/setExcel/{{item._id}}" target="_blank" type="button" data-widget=""
                      data-toggle="tooltip" title="下載" class="btn btn-default mr-2">下載</a>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- /.card-body -->
    </div>
  </div>
  <!-- /.card -->
</section>
<!-- /.content -->
