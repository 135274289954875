import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@services/api.service';
import { ProjectApiService } from '@services/projectapi.service';
import { Subject } from 'rxjs';
import { RightService } from '@services/right.service';
import { HelperService } from '@services/helper.service';

@Component({
  selector: 'app-project-dailyreport',
  templateUrl: './project-dailyreport.component.html',
  styleUrls: ['./project-dailyreport.component.scss']
})
export class ProjectDailyReportComponent implements OnInit {
  @Input() projectId;
  public dailyReport;
  dtOptions: DataTables.Settings = {};
  getRight: any;
  RightObj: {};

  public result;
  constructor(
    public activeModal: NgbActiveModal,
    public rightService: RightService,
    public helperService: HelperService,
    public apiService: ApiService,
    public ProjectApiService: ProjectApiService,
    private modalService: NgbModal
  ) {
    this.getRight = this.rightService.getRight(); }

  dtTrigger: Subject<any> = new Subject<any>();

  ngOnInit(): void {
    var order = [[0, 'desc']];
    this.dtOptions = this.helperService.setDtOptions(order);
    this.RightObj = this.getRight['__zone_symbol__value'];

    this.reloadData();
  }


  async reloadData() {
    (await this.ProjectApiService.getDailyReport(this.projectId)).subscribe((res) => {
        this.dailyReport = res as any;
        this.dtTrigger.next(null);
    });

  }

}
