import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '@services/api.service';
import { ProjectApiService } from '@services/projectapi.service';
import { Subject } from 'rxjs';
import { RightService } from '@services/right.service';
import { HelperService } from '@services/helper.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-project-purchase-control',
  templateUrl: './project-purchase-control.component.html',
  styleUrls: ['./project-purchase-control.component.scss']
})
export class ProjectPurchaseControlComponent implements OnInit {
  @Input() projectId;

  getRight: any;
  RightObj: {};
  dtOptions: DataTables.Settings = {};

  totalItem;
  checkItem;
  checkPercent;
  public purchaseControls;

  constructor(
    public activeModal: NgbActiveModal,
    public rightService: RightService,
    public helperService: HelperService,
    public apiService: ApiService,
    public ProjectApiService: ProjectApiService,
    private modalService: NgbModal
  ) {
    this.getRight = this.rightService.getRight(); }

  dtTrigger: Subject<any> = new Subject<any>();

  ngOnInit(): void {
    this.dtOptions = this.helperService.setDtOptions();
    this.RightObj = this.getRight['__zone_symbol__value'];
    this.reloadData();
  }


  async reloadData() {
    (await this.ProjectApiService.getPurchaseControl(this.projectId)).subscribe((res) => {
      this.purchaseControls = res as any;
      this.totalItem = this.purchaseControls.length;
      this.checkItem = this.purchaseControls.filter(w => w.materialData?.pass).length;
      this.checkPercent = Math.round(this.checkItem / this.totalItem * 100);

      this.dtTrigger.next(null);
    });
  }


}
