<div class="card">
  <div class="card-header">
    <h5 class="card-title mt-2">
      {{title}}
    </h5>
    <div class="card-tools">
      <button type="button" class="btn bnt-tool float-right mr-2" data-card-widget="collapse"
        (click)="handleMainMenuAction()">
        <i [ngClass]="isMenuExtended ? 'fas fa-minus' : 'fas fa-plus'" [@rotate]="isMenuExtended"></i>
      </button>
    </div>
  </div>
  <div class="card-body" [@openClose]="isMenuExtended">
    <div class="row">
      <div class="col-md-4 form-group">
        <label for=""><span style="color:red">*</span>扣保留款</label>
        <ng-container *ngIf="action.allowEdit">

          <input *ngIf="action.allowEdit" type="number" name="otherFeeList.fee" [value]="defaultFee1"
            (ngModel)="defaultFee1" placeholder="扣保留款" class="form-control  updateDataByColumn updateDataByListColumn"
            data-label="扣保留款" data-subdocName="formTaskContent.calculateForm.otherFeeList"
            data-subdocId="{{defaultFee1_id}}" data-subdocumnet_type="list">

        </ng-container>
        <ng-container *ngIf="!action.allowEdit">
          <input type="text" readonly class="form-control" [value]="defaultFee1" (ngModel)="defaultFee1" />
        </ng-container>

      </div>
      <div class="col-md-4 form-group">
        <label for=""><span style="color:red">*</span>扣訂金</label>
        <ng-container *ngIf="action.allowEdit">
          <input *ngIf="action.allowEdit" type="number" name="otherFeeList.fee" [value]="defaultFee2"
            (ngModel)="defaultFee2" placeholder="扣訂金" class="form-control  updateDataByColumn updateDataByListColumn"
            data-label="扣訂金" data-subdocName="formTaskContent.calculateForm.otherFeeList"
            data-subdocId="{{defaultFee2_id}}" data-subdocumnet_type="list">
        </ng-container>
        <ng-container *ngIf="!(action.allowEdit)">
          <input type="text" readonly class="form-control" [value]="defaultFee2" (ngModel)="defaultFee2" cols="30"
            rows="3" />
        </ng-container>

      </div>
      <div class="col-md-4 form-group">
        <label for=""><span style="color:red">*</span>扣郵資</label>
        <ng-container *ngIf="action.allowEdit">
          <input *ngIf="action.allowEdit" type="number" name="otherFeeList.fee" [value]="defaultFee3"
            (ngModel)="defaultFee3" placeholder="扣郵資" class="form-control  updateDataByColumn updateDataByListColumn"
            data-label="扣郵資" data-subdocName="formTaskContent.calculateForm.otherFeeList"
            data-subdocId="{{defaultFee3_id}}" data-subdocumnet_type="list">
        </ng-container>
        <ng-container *ngIf="!(action.allowEdit)">
          <input type="text" readonly class="form-control" [value]="defaultFee3" (ngModel)="defaultFee3" cols="30"
            rows="3" />
        </ng-container>

      </div>
    </div>
    <div class="row bg-light">
      <div class="col-md-12 mb-2">
        <button *ngIf="action.allowEdit" type="button" class="btn btn-info float-sm-right updateDataByColumn"
          (click)="AddCard()">
          <i class="fas fa-plus"></i> 新增
        </button>
      </div>
      <div class="col-md-12">
        <div #otherFeeList1>
          <div class="row">
            <div class="col-md-2"> <label>加減項</label></div>
            <div class="col-md-5"> <label>款項名稱</label></div>
            <div class="col-md-4"> <label>金額</label></div>
            <div class="col-md-1" *ngIf="action.allowEdit"> <label>刪除</label></div>
          </div>
          <div class="row" *ngFor="let item of otherFeeList let index=index;" id="o_{{index}}">
            <div class="col-md-2">
              <div class="custom-control custom-radio">
                <input *ngIf="action.allowEdit" class="custom-control-input  updateDataByColumn updateDataByListColumn"
                  type="radio" id="otherFeeList[{{index}}].isPlus0" value="true" name="otherFeeList[{{index}}].isPlus"
                  [checked]="item.isPlus === true" data-label=" 其他加項減項{{index+1}}:加減項"
                  data-subdocName="formTaskContent.calculateForm.otherFeeList" data-subdocId="{{item._id}}"
                  data-subdocumnet_type="list">


                <input *ngIf="!action.allowEdit" disabled class="custom-control-input " type="radio"
                  [checked]="item.isPlus === true">
                <label for="otherFeeList[{{index}}].isPlus0" class="custom-control-label"><i
                    class="fas fa-plus text-danger"></i> 加項</label>

              </div>
              <div class="custom-control custom-radio">
                <input *ngIf="action.allowEdit" class="custom-control-input  updateDataByColumn updateDataByListColumn"
                  type="radio" id="otherFeeList[{{index}}].isPlus1" value="false" name="otherFeeList[{{index}}].isPlus"
                  [checked]="item.isPlus === false" data-label=" 其他加項減項{{index+1}}:加減項"
                  data-subdocName="formTaskContent.calculateForm.otherFeeList" data-subdocId="{{item._id}}"
                  data-subdocumnet_type="list">

                <input *ngIf="!action.allowEdit" disabled class="custom-control-input" type="radio"
                  [checked]="item.isPlus === false">
                <label for="otherFeeList[{{index}}].isPlus1" class="custom-control-label"><i
                    class="fas fa-minus text-primary"></i> 減項</label>

              </div>
            </div>

            <div class="col-md-5">
              <div class="form-group">
                <ng-select2 *ngIf="action.allowEdit" width="100%" placeholder="款項名稱" [value]="item.feeName"
                  (valueChanged)="onChange_PaymentType($event,index,item._id)"  [data]="s2PaymentTypes">
                </ng-select2>

                <ng-select2 *ngIf="!action.allowEdit" [disabled]="true" width="100%" placeholder="款項名稱" [value]="item.feeName" [data]="s2PaymentTypes">
                </ng-select2>

              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <input *ngIf="action.allowEdit" type="text" name="otherFeeList[{{index}}].fee" [value]="item.fee"
                  class="form-control  updateDataByColumn updateDataByListColumn" data-label=" 其他加項減項{{index+1}}:金額"
                  data-subdocName="formTaskContent.calculateForm.otherFeeList" data-subdocId="{{item._id}}"
                  data-subdocumnet_type="list">

                <input *ngIf="!action.allowEdit" disabled type="text" name="otherFeeList[{{index}}].fee"
                  [value]="item.fee" class="form-control  updateDataByColumn updateDataByListColumn"
                  data-label=" 其他加項減項{{index+1}}:金額" data-subdocName="formTaskContent.calculateForm.otherFeeList"
                  data-subdocId="{{item._id}}" data-subdocumnet_type="list">
              </div>
            </div>
            <div class="col-md-1" *ngIf="action.allowEdit">
              <div class="form-group">
                <button *ngIf="action.allowEdit" type="button" class="btn btn-tool text-danger  updateDataByColumn"
                  data-toggle="tooltip" title="刪除" (click)="del(index,item._id)">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>
